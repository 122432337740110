import React from 'react';
import styled from 'styled-components';

const StyledHeader = styled.div`
    background: var(--color-background-subsection-header);
    color: var(--color-text-subsection-header);
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 3rem;
`;

export default ({children, ...props}) => <StyledHeader {...props}>{children}</StyledHeader>