import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'

import FullscreenPageLayout from '../../components/fullscreenPageLayout';
import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import PageBrandingHead from '../../components/fullscreenPageLayout/brandHead';
import PageCard from '../../components/fullscreenPageLayout/card';
import PageTitle from '../../components/fullscreenPageLayout/pageTitle';
import PageFoot from '../../components/fullscreenPageLayout/footer';
import Input from '../../components/forms/InputOld';

import Icon from '../../components/icons';

import PasswordStrengthMeter from '../../components/forms/PasswordStrengthMeter';

import './style.css';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

const StyledProfileUrl = styled.div`
    display: block;
    font-size: .8rem;

    .link {
        color: var(--color-background-accent)
    }
`;

const StyledContainer = styled.div`
    @media(min-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledJoinOptionContainer = styled.div`
    padding: 0.5rem 0;
`;

const StyledEmailButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    background-color: #ffffff;
    color: #424242;
    border: 1px solid #424242;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;

    svg {
        margin-right: .4rem;
    }

    :hover {
        background: #424242;
        color: #ffffff;
        text-decoration: none;
    }

    :focus {
        background: #2b2b2b;
        color: #ffffff;
        text-decoration: none;
        outline: none;
    }
`;

const StyledDiscordButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    background-color: #ffffff;
    color: var(--color-brand-discord);
    border: 1px solid var(--color-brand-discord);
    border-radius: 5px;
    font-weight: 500;

    svg {
        margin-right: .4rem;
    }

    :hover {
        background: var(--color-brand-discord);
        color: #ffffff;
        text-decoration: none;
    }

    :focus {
        background: var(--color-brand-discord-alt);
        color: #ffffff;
        text-decoration: none;
        outline: none;
    }
`;

const StyledLogInContainer = styled.div`
    font-size: .9rem;
    font-family: 'Inter', sans-serif;
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    a {
        margin-left: .3rem;
        color: var(--color-background-accent-secondary);
        transition: all ease-in-out .1s;
    }

    a:hover {
        color: var(--color-background-accent);
        text-decoration: none;
    }
`;

const StyledPrettyCheckboxContainer = styled.div`
    margin-right: .4rem !important;
`;

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            passwordConfirm: '',
            email: '',
            privacyPolicyAgreement: false,
            redirect: this.props.redirect || null,
            isRegisteringWithEmail: false,
        };
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCheckboxChange = (e) => this.setState({ [e.target.name]: e.target.checked });

    onRegister = () => {
        const fields = { username: '', password: '', passwordConfirm: '', email: '' };
        Object.keys(fields).map(key => document.getElementById(`register-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`register-${key}-error`).innerHTML = '');

        const { username = '', password = '', passwordConfirm = '', email = '' } = this.state;
        const errors = {
            username: [],
            password: [],
            passwordConfirm: [],
            email: [],
        };

        if (username.length < 3 || username.length > 30)
            errors.username.push('Username must be between 3 and 30 characters.');
        
        if (!RegExp(/^[a-z0-9]+$/i).test(username))
            errors.username.push('Username must be alphanumeric.');

        if (password.length < 8 || password.length > 128 )
            errors.password.push('Password must be between 8 and 128 characters.');

        if (!(RegExp(/^(?=.*?[a-z])/).test(password)))
            errors.password.push('Password must contain at least one lowercase letter');

        if (!(RegExp(/^(?=.*?[A-Z])/).test(password)))
            errors.password.push('Password must contain at least one uppercase letter');

        if (!(RegExp(/^(?=.*?[0-9])/).test(password)))
            errors.password.push('Password must contain at least one number');

        if (!(RegExp(/^(?=.*?[#?!@$%^&*-])/).test(password)))
            errors.password.push('Password must contain at least one special character');

        if (!(RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm).test(email)))
            errors.email.push('Email must be valid.');
        
        if (password != passwordConfirm) {
            errors.password.push('Passwords must match!');
            errors.passwordConfirm.push('Passwords must match!');
        }

        // if (privacyPolicyAgreement != true)
        //     errors.privacyPolicyAgreement.push('Users must agree to our privacy policy.')

        if (email.length > 50)
            errors.email.push('Email must be less than 50 characters.');

        if (errors.username.length + errors.password.length + errors.passwordConfirm.length + errors.email.length > 0) {
            for(const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`register-${error}`).className += ' error'
                    document.getElementById(`register-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else
            this.props.register(this.state.username, this.state.password, this.state.email)
                .then(res => {
                    if (!res.success)
                        Swal.fire(
                            'Error',
                            res.message,
                            'error'
                        )
                    else {
                        this.setState({ redirect: '/settings' });

                        Swal.mixin({
                            confirmButtonText: 'Ok',
                            progressSteps: ['1', '2']
                        }).queue([
                            {
                                icon: 'success',
                                title: `Welcome ${username}!`,
                                text: 'You have successfully signed up.'
                            },
                            {
                                icon: 'info',
                                title: 'Customize Your Experience',
                                text: 'Start by customizing your preferences and customizing your profile!'
                            },
                        ]);
                    }
                });
        }

    optionChosen(optionNum) {
        switch(optionNum) {
            case 1:
                this.setState({ isRegisteringWithEmail: true });
                break;
        }
    }

    renderEmailRegistrationForm() {
        return (
            <div className="register-form">
                <div className="field">
                    <span style={{
                        color: '#9658fe',
                    }}>
                        <strong>Security Tip:</strong> Do not use your official APB credentials
                        </span>
                </div>
                <div id="register-username" className="field">
                    <StyledLabel htmlFor="username">Username:</StyledLabel>
                    <Input name="username" type="text" value={this.state.username} onChange={(e) => this.handleChange(e)} />
                    <div id="register-username-error" className="error-message"></div>
                    <StyledProfileUrl>Your profile url will be: <span className="link">apb.social/profile/{this.state.username.toLocaleLowerCase()}</span></StyledProfileUrl>
                </div>
                <div id="register-email" className="field">
                    <StyledLabel htmlFor="email">Email:</StyledLabel>
                    <Input name="email" type="email" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                    <div id="register-email-error" className="error-message"></div>
                </div>
                <div id="register-password" className="field">
                    <StyledLabel htmlFor="password">Password:</StyledLabel>
                    <Input name="password" type="password" maxLength="128" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                    <PasswordStrengthMeter password={this.state.password} />
                    <div id="register-password-error" className="error-message"></div>
                </div>
                <div id="register-passwordConfirm" className="field">
                    <StyledLabel htmlFor="passwordConfirm">Confirm Password:</StyledLabel>
                    <Input name="passwordConfirm" type="password" maxLength="128" value={this.state.passwordConfirm} onChange={(e) => this.handleChange(e)} />
                    <div id="register-passwordConfirm-error" className="error-message"></div>
                </div>
                {/* <div id="register-privacyPolicyAgreement" className="field">
                    <StyledPrettyCheckboxContainer className="pretty p-default p-curve p-smooth">
                        <Input name="privacyPolicyAgreement" htmlFor="privacyPolicyAgreement" type="checkbox" value={this.state.privacyPolicyAgreement} onChange={(e) => this.handleCheckboxChange(e)} />
                        <div className="state p-primary">
                            <label name="privacyPolicyAgreement" htmlFor="privacyPolicyAgreement">I have read and agree to the</label>
                        </div>
                    </StyledPrettyCheckboxContainer>
                    <span><a href="https://apb.social/privacy-policy" target="_blank">privacy policy</a>.</span>
                    <div id="register-privacyPolicyAgreement-error" className="error-message"></div>
                </div> */}

                <div className="register-button">
                    <button className="submit" onClick={this.onRegister}>Sign Up</button>
                </div>
            </div>
        )
    }

    renderOptions() {
        return (
            <>
                <StyledJoinOptionContainer>
                    <StyledEmailButton onClick={() => this.optionChosen(1)}>
                        <Icon className="icon" name="mail" stroke="currentColor" width="25px" /> Continue with Email
                    </StyledEmailButton>
                </StyledJoinOptionContainer>
                <StyledJoinOptionContainer>
                    <StyledDiscordButton href="https://discord.com/api/oauth2/authorize?client_id=710354136578916382&redirect_uri=https%3A%2F%2Fapb.social%2Fauth%2Fdiscord%3Ftype%3Djoin&response_type=code&scope=identify">
                        <Icon className="icon" name="discord" fill="currentColor" stroke="none" width="30px" /> Continue with Discord
                    </StyledDiscordButton>
                </StyledJoinOptionContainer>
            </>
        )
    }

    renderStage() {
        return (
            this.state.isRegisteringWithEmail ? this.renderEmailRegistrationForm() : this.renderOptions()
        );
    }

    render() {
        const { redirect } = this.state;

        return (
            <FullscreenPageLayout>
                <FullscreenPageLayoutWave />
                {redirect
                    ? <Redirect to={redirect} />
                    : (
                        <StyledContainer>
                            <PageBrandingHead />
                            <PageCard>
                                <PageTitle>
                                    Sign Up
                                </PageTitle>
                                <div className="form">
                                    {this.renderStage()}

                                    <StyledLogInContainer>
                                        Already a member?<Link to="/login">Sign in now</Link>
                                    </StyledLogInContainer>
                                </div>
                            </PageCard>
                            <PageFoot />
                        </StyledContainer>
                    )}
            </FullscreenPageLayout>
        )
    }
}

export default Register;