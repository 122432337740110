import React, { useEffect } from 'react';
import styled from 'styled-components';

import Cover from '../../components/homepageCover/v2';
import { GeneratePostGrid, RegeneratePostGrid } from '../../components/posts/DynamicPostGrid';
import StickyContentHeader from '../../components/fullscreenPageLayout/contentStickyHeader';

import { retrieveHomeDashboard } from '../../services/DashboardService';

const StyledImageContainer = styled.div`
  padding: 10px;
`;

const Home = (props) => {

  useEffect(() => {
    props.getPosts(props.nsfw)
      .then(() => RegeneratePostGrid());
  }, []);

  return (
    <>
      <Cover />

      <StickyContentHeader>
        Home
      </StickyContentHeader>

      <StyledImageContainer>
        {GeneratePostGrid(props.posts, false)}
      </StyledImageContainer>
    </>
  );
}

export default Home;
