import React, { Component } from 'react';
import styled from 'styled-components';

import Icon from '../../components/icons';
import ReactTooltip from 'react-tooltip';

const StyledDropdown = styled.div`
    display: block;
    position: relative;
`;

// const StyledDropdownMenu = styled.div`
//     display: ${ props => props.active ? 'block' : 'none' };
//     z-index: 1020;
//     border-radius: 5px;
//     min-width: 200px;
//     padding: ${ props => props.padding ? props.padding : '25px' };
//     background: #fff;
//     color: #666;
//     box-shadow: 0 5px 12px rgba(0,0,0,0.15);
//     ${ props => props.right ? 'right: 0; left: auto;' : null }
//     margin-top: 10px;

//     flex-direction: column;
//     position: absolute;
//     line-height: 20px;

//     & :last-child {
//         margin-bottom: 0;
//     }
// `;

const StyledDropdownMenu = styled.div`
    display: ${props => props.active ? 'block' : 'none'};
    z-index: 1020;
    border-radius: 5px;
    min-width: 200px;
    padding: ${props => props.padding ? props.padding : '20px'};
    background: var(--color-background-dropdown);
    color: var(--color-text-dropdown-primary);
    box-shadow: 0 5px 12px rgba(0,0,0,0.15);
    ${props => props.right ? 'right: 0; left: auto;' : 'left: 0;'}
    margin-top: 4px;
    font-size: .9rem;
    font-weight: 400;

    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;

    position: absolute;
    line-height: 20px;

    & :last-child {
        margin-bottom: 0;
    }
`;

const StyledDropdownTrigger = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;


class Dropdown extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu = e => {
        e.preventDefault();

        if (!this.state.showMenu) {
            if (this.props.onShowMenu)
                this.props.onShowMenu();

            this.setState({ showMenu: true }, () => {
                document.addEventListener('click', this.closeMenu);
            });
        }
    }

    closeMenu = e => {
        if (!!this.dropdownMenu)
            if (!this.dropdownMenu.contains(e.target) || (this.dropdownMenu.contains(e.target) && e.target instanceof HTMLAnchorElement)) {
                this.hideDropdown();
            }
    }

    hideDropdown = () => {
        if (this.props.onCloseMenu)
            this.props.onCloseMenu();

        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        return (
            <>
                <StyledDropdown>
                    <StyledDropdownTrigger className="dropdown_trigger" onClick={this.showMenu} data-tip={!!this.props.dropdownTooltipId} data-for={this.props.dropdownTooltipId}>
                        {children.shift()}
                        { this.props.dropdownIcon && (
                            <Icon name='chevron-down' width="16" style={{
                                marginLeft: '.4rem',
                            }} />
                        )}
                    </StyledDropdownTrigger>

                    <StyledDropdownMenu
                        ref={(element) => {
                            this.dropdownMenu = element;
                        }}
                        active={this.state.showMenu}
                        right={this.props.right || false}
                        padding={this.props.padding}
                    >
                        {children}
                    </StyledDropdownMenu>
                </StyledDropdown>
                {   !!this.props.dropdownTooltip &&
                    (
                        <ReactTooltip id={this.props.dropdownTooltipId} effect='solid'>
                            <span>{this.props.dropdownTooltip}</span>
                        </ReactTooltip>
                    )
                }
            </>
        );
    }
}

export default Dropdown;