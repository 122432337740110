import { toast } from 'react-toastify';

import {
  REQUEST_NOTIFICATIONS,
  RECEIVE_NOTIFICATIONS,
  REQUEST_MARK_NOTIFICATIONS_READ,
  RECEIVE_MARK_NOTIFICATIONS_READ,
  MARK_STATE_NOTIFICATIONS_READ,
  RECEIVE_NOTIFICATION,
  REMOVE_NOTIFICATION
} from '../actions/notificationActions'

import {
  RECEIVE_LOGIN,
  RECEIVE_AUTHENTICATED_USER,
} from '../actions/loginActions';

import {
  RECEIVE_LOGOUT
} from '../actions/logoutActions';

const notifications = (
  state = {
    isFetching: false,
    // notifications for dropdown
    unreadNotificationCount: 0,
    notifications: [],
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATION:
      // TODO: Implement switch case dependant on type of notification i.e. trade requests
      // console.log('received notification', action.notification);

      // Check to see if it's a duplicate notification
      const noti = state.notifications.find(notification => notification._id === action.notification._id);

      if (noti === undefined) {
        toast('You have a new notification!');

        return Object.assign({}, state, {
          ...state,
          notifications: [
            action.notification,
            ...state.notifications,
          ],
          unreadNotificationCount: state.unreadNotificationCount + 1,
        })
      }
      else
        return state;
    case REMOVE_NOTIFICATION:
      // console.log('received delete notification', action.notificationId);

      const notifi = state.notifications.find(notification => notification._id === action.notificationId);

      if (notifi != undefined) {
        const nc = notifi.read ? state.unreadNotificationCount : state.unreadNotificationCount - 1;
        const newNoti = state.notifications.filter(notification => notification._id != action.notificationId);

        return Object.assign({}, state, {
          ...state,
          notifications: newNoti,
          unreadNotificationCount: nc,
        })
      } else
        return state;
    case REQUEST_NOTIFICATIONS:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case RECEIVE_NOTIFICATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        // notifications: action.notifications,
      })
    case REQUEST_MARK_NOTIFICATIONS_READ:
      if (!!action.notificationsIds && action.notificationsIds.length > 0) {
        let newUnreadNotificationCount = state.unreadNotificationCount - action.notificationsIds.length;

        return Object.assign({}, state, {
          unreadNotificationCount: newUnreadNotificationCount,
        });
      } else
        return state;
      break;
    case RECEIVE_MARK_NOTIFICATIONS_READ:
      if (action.success)
        return Object.assign({}, state, {
          unreadNotificationCount: action.unreadCount,
        });
      else
        return state;
    case MARK_STATE_NOTIFICATIONS_READ:
      if (!!state.notifications) {
        let noti = state.notifications.map(notification => { return { ...notification, read: action.notificationIds.includes(notification._id) ? true : notification.read } });
        
        return Object.assign({}, state, {
          ...state,
          notifications: noti,
        });
      }
      else
        return state;
    case RECEIVE_LOGIN:
      return Object.assign({}, state, {
        notifications: action.notifications,
        unreadNotificationCount: action.unreadNotificationCount,
      })
    case RECEIVE_AUTHENTICATED_USER:
      return Object.assign({}, state, {
        notifications: action.notifications,
        unreadNotificationCount: action.unreadNotificationCount,
      })
    case RECEIVE_LOGOUT:
      return Object.assign({}, state, {
        isFetching: false,
        notifications: [],
      })
    default:
      return state
  }
}

export default notifications;