import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "2",
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M7.16,12.229c-0.311-0.433-0.649-0.831-0.907-1.274
		c-1.984-3.421,0.087-7.821,3.988-8.49c3.161-0.542,6.127,1.58,6.65,4.76c0.498,3.037-1.611,5.995-4.654,6.526
		c-0.914,0.16-1.812,0.111-2.702-0.149c-0.099-0.031-0.229-0.029-0.322,0.011c-2.524,1.088-4.051,2.979-4.584,5.678
		c-0.011,0.055-0.019,0.109-0.033,0.191c0.084,0,0.157,0,0.23,0c2.626,0,5.251-0.002,7.876,0c0.566,0,1.009,0.341,1.128,0.857
		c0.134,0.592-0.291,1.194-0.895,1.268c-0.083,0.01-0.165,0.013-0.247,0.013c-3.049,0-6.099,0-9.148,0
		c-0.563,0-0.981-0.295-1.118-0.787c-0.031-0.114-0.039-0.236-0.042-0.354c-0.029-1.322,0.282-2.577,0.802-3.779
		c0.813-1.881,2.117-3.34,3.868-4.4C7.095,12.27,7.138,12.243,7.16,12.229z M11.226,11.703c1.989-0.002,3.594-1.611,3.591-3.6
		c-0.005-1.981-1.606-3.584-3.586-3.586c-1.99-0.002-3.598,1.602-3.599,3.59C7.63,10.097,9.237,11.704,11.226,11.703z"/>
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M16.373,16.37c0-0.696-0.004-1.361,0.001-2.027
		c0.004-0.476,0.293-0.873,0.734-1.022c0.427-0.149,0.895-0.011,1.176,0.344c0.171,0.214,0.23,0.46,0.23,0.728
		c-0.001,0.581-0.001,1.163-0.001,1.747c0,0.068,0,0.138,0,0.231c0.084,0,0.151,0,0.219,0c0.606,0,1.215-0.009,1.822,0.001
		c0.535,0.006,0.989,0.408,1.056,0.911c0.069,0.556-0.234,1.038-0.757,1.182c-0.126,0.035-0.262,0.046-0.394,0.046
		c-0.569,0.003-1.14,0.001-1.708,0.001c-0.069,0-0.137,0-0.238,0c0,0.09,0,0.163,0,0.235c0,0.596,0.009,1.189-0.002,1.785
		c-0.015,0.761-0.713,1.266-1.415,1.032c-0.435-0.144-0.72-0.545-0.723-1.028c-0.004-0.597-0.001-1.189-0.001-1.785
		c0-0.069,0-0.139,0-0.229c-0.085-0.004-0.157-0.01-0.229-0.01c-0.595,0-1.19,0.004-1.785-0.001
		c-0.46-0.006-0.802-0.218-0.995-0.634c-0.183-0.401-0.124-0.782,0.15-1.12c0.215-0.27,0.509-0.385,0.851-0.386
		c0.595-0.002,1.188,0,1.784,0C16.216,16.37,16.285,16.37,16.373,16.37z"/>
    </svg>
);

export default SVG;