import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import ReactTooltip from 'react-tooltip';

import styled from 'styled-components';
import Icon from '../../components/icons';
import Spinner from '../../components/spinner';

import { searchPrimaryWeaponsDetailed, searchSecondaryWeaponsDetailed } from '../../services/GameDataService';

import {
    StyledCardSplitMainInputBoxContainer,
    StyledCardSplitMainInputBox,
    StyledCardSplitMainInputBoxButton,
    StyledLoadoutManagementListItemActionsContainer,
    StyledLoadoutManagementListItemAction,
    StyledLoadoutManagementListItemActionLink,
} from './components';

import GameItemWeapon from '../../components/forms/GameItems/GameItem/Weapon';

import { randomLoadoutProfileCategories } from '../../util/enums';

const StyledSearchResults = styled.ul`
    color: #ffffff;
    padding: 0px;

    max-height: 500px;
    overflow: auto;


    margin: 0px;
    list-style: none;

    li {
        transition: background 0.1s ease;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 10px;
        background: #202225;
    }

    ::-webkit-scrollbar-track {
        padding: .2rem;
    }

    ::-webkit-scrollbar-thumb {
        background: #989898;
    }
`;

const StyledSearchResultContainer = styled.li`
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 2px;
    padding: 0.5rem;

    :hover {
        background: #202225;
    }

    .item-info .item-name {
        color: #fff;
    }

    .item-info .item-sapbdb {
        color: #cecece;
    }
`;

const StyledButtonThin = styled.button`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #fff;
    display: block;
    padding: 0.5rem;
    background: #323338;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #464749;
    outline: none;
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    :hover {
        background: #2d2e33;
    }

    :active {
        background: #7668cb;
    }
`;

const EmptySearchMessage = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    color: #ffffff;
    padding: 0.5rem;
    font-weight: 400;
`;

export const LoadoutWeaponManager = (props) => {
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [waitingForResults, setWaitingForResults] = useState(false);

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleSearch();
        }
    }
    
    const handleSearchButton = (e) => handleSearch();
    
    const handleSearch = () => {
        const { searchItemType } = props;

        if (searchText.length >= 2) {
            setSearchResults([]);
            setWaitingForResults(true);

            switch (searchItemType) {
                case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                    searchPrimaryWeaponsDetailed(searchText)
                        .then(result => {
                            setSearchResults(result || []);
                            setWaitingForResults(false);
                            ReactTooltip.rebuild();
                        });
                    break;
                case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                    searchSecondaryWeaponsDetailed(searchText)
                        .then(result => {
                            setSearchResults(result || []);
                            setWaitingForResults(false);
                            ReactTooltip.rebuild();
                        });
                    break;
                default:
            }
        } else {
            clearSearchResults();
        }
    }

    const clearSearch = () => {
        setSearchText('');
        setSearchResults([]);
    };

    const clearSearchResults = () => setSearchResults([]);

    const handleSelectItem = (item) => {
        const { searchItemType } = props;

        ReactTooltip.hide();
        props.onItemSelected(item, searchItemType);
        clearSearch();
    }

    const handleRemoveItem = (sapbdb) => {
        ReactTooltip.hide();
        props.onItemRemoved(sapbdb, props.searchItemType);
    }

    const renderSearchResults = () => {
        return searchResults.length > 0 ? (
            <>
                <StyledButtonThin onClick={e => clearSearch()}>
                    Clear Search
                </StyledButtonThin>
                {searchResults.map((item, index) => (
                    <StyledSearchResultContainer key={index}>
                        <GameItemWeapon key={item.sapbdb} sdisplayname={item.sdisplayname} sapbdb={item.sapbdb} modifications={item.modifications} modificationsVacant={item.modificationsVacant} icon_url={item.icon_url} />
                        <StyledLoadoutManagementListItemActionsContainer>
                            <StyledLoadoutManagementListItemActionLink data-tip='Inspect Item' data-for='__tooltip' href={item.url} target="_blank">
                                <Icon name='globe' fill='currentColor' stroke='none' />
                            </StyledLoadoutManagementListItemActionLink>
                            {
                                props.isItemSelected(item.sapbdb, props.searchItemType) ? (
                                    <StyledLoadoutManagementListItemAction hoverColor='#7668cb' data-tip='Item Already Selected' data-for='__tooltip' disabled>
                                        <Icon name='plus-circle' />
                                    </StyledLoadoutManagementListItemAction>
                                ) : (
                                    <StyledLoadoutManagementListItemAction hoverColor='#7668cb' onClick={() => handleSelectItem(item)} data-tip='Select Item' data-for='__tooltip'>
                                        <Icon name='plus-circle' />
                                    </StyledLoadoutManagementListItemAction>
                                )
                            }
                        </StyledLoadoutManagementListItemActionsContainer>
                    </StyledSearchResultContainer>
                ))}
            </>) : (
                <>
                    {props.selectedWeapons.map((item, index) => (
                        <StyledSearchResultContainer key={index}>
                            <GameItemWeapon key={item.sapbdb} sdisplayname={item.sdisplayname} sapbdb={item.sapbdb} modifications={item.modifications} modificationsVacant={item.modificationsVacant} icon_url={item.icon_url} />
                            <StyledLoadoutManagementListItemActionsContainer>
                                <StyledLoadoutManagementListItemAction hoverColor='#f94f4f' onClick={() => handleRemoveItem(item.sapbdb)} data-tip='Remove Item' data-for='__tooltip'>
                                    <Icon name='trash-2' fill='currentColor' stroke='none' />
                                </StyledLoadoutManagementListItemAction>
                            </StyledLoadoutManagementListItemActionsContainer>
                        </StyledSearchResultContainer>
                    ))}
                </>
            )
    }

    return (
            <>
            <StyledCardSplitMainInputBoxContainer>
                <StyledCardSplitMainInputBox type="text" value={searchText} placeholder='Search to add weapons..' maxLength={30} onChange={e => setSearchText(e.target.value)} onKeyDown={handleOnKeyDown} autoComplete="off" />
                <StyledCardSplitMainInputBoxButton onClick={handleSearchButton}>
                    <Icon name='search' strokeWidth={2} />
                </StyledCardSplitMainInputBoxButton>
            </StyledCardSplitMainInputBoxContainer>

            <StyledSearchResults>
                {waitingForResults ? (
                    <EmptySearchMessage><Spinner /></EmptySearchMessage>
                ) : (
                    renderSearchResults()
                )}
            </StyledSearchResults>
        </>
    )
};