import React from 'react';
import styled from 'styled-components';

import moment from 'moment';

import { Link } from 'react-router-dom';

import withUser from '../hoc/withUser';

import Icon from '../icons';

import Dropdown from '../dropdown/Dropdown';
import { DropdownItem, DropdownItemDivider } from '../dropdown/DropdownItem';

const StyledNotificationDropdownItem = styled.li`
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 2px;
    ${props => props.read ? '' : 'background: var(--color-background-dropdown-notification-unread);'}

    :hover, :hover .notification-icon {
        ${props => props.read ? 'background: var(--color-background-dropdown-notification-hover);' : 'background: var(--color-background-dropdown-notification-hover);'}
    }

    .unread, a .notification-icon {
        background: var(--color-background-dropdown-notification-unread);
    }

    > a { 
        display: flex;
        position: relative;
        align-items: center;
        text-decoration: none;
        flex-grow: 1;
        padding: 1rem;

    }

    > a:hover {
        text-decoration: none;
    }

    a .notification-icon {
        display: flex;
        position: absolute;
        bottom: -7px;
        right: -7px;
        background: ${props => props.read ? 'var(--color-background-dropdown)' : 'var(--color-background-dropdown-notification-unread)'};
        padding: 5px;
        border-radius: 50px;
        color: #9658fe;
    }

    a .avatar {
        margin-right: .7rem;
        display: inherit;
        position: relative;
    }

    a .notification-data {
        display: block;
    }

    a .notification-data .notification-info, .notification-data .notification-info .username {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    a .notification-data .notification-info {
        margin-bottom: .3rem;
    }

    a .notification-data .notification-info .username {
        font-size: .9rem;
        font-weight: 600;
        line-height: 1;
        color: var(--color-text-dropdown-primary);
        text-decoration: none;
        margin-right: .6rem;
    }

    a .notification-data .notification-info .username:hover {
        text-decoration: none;
    }

    a .notification-data .notification-time {
        font-size: .8rem;
        color: var(--color-text-dropdown-secondary);
    }

    a .notification-data .text {
        font-size: .8rem;
        word-break: break-word;
        color: var(--color-text-dropdown-primary);
    }
`;

const Avatar = styled.img`
  border-radius: 3px;
  max-width: 44px;
  max-height: 44px;
  display: block;
  position: relative;
`;

const generateIcon = (notificationType) => {
    switch (notificationType) {
        case 'Comment':
            return <Icon name="comment-icon" strokeWidth="2" stroke={'none'} fill={'currentColor'} width={16} height={16} />;
        case 'Like':
            return <Icon name="heart" strokeWidth="2" stroke={'none'} fill={'currentColor'} width={16} height={16} />;
        case 'Follow':
            return <Icon name="eye" strokeWidth="2" stroke={'transparent'} fill={'currentColor'} width={16} height={16} />;
    }
}

const generateMessage = (notificationType, referenceEntity) => {
    switch (notificationType) {
        case 'Comment':
            return (
                <div className="text">
                    Commented on your post {referenceEntity.title}
                </div>
            );
        case 'Like':
            return (
                <div className="text">
                    Liked your post {referenceEntity.title}
                </div>
            );
        case 'Follow':
            return (
                <div className="text">
                    Followed you!
                </div>
            );
    }
};

const getNotificationLink = (notificationType, referenceEntity, senderUsername) => {
    switch (notificationType) {
        case 'Comment':
        case 'Like':
            return `/post/${referenceEntity._id}`;
        case 'Follow':
            return `/profile/${senderUsername}`;
    }
};

const NotificationItem = ({ notificationType, referenceEntity, senderUsername, senderAvatar, timestamp, read, closeDropdown }) => (
    <StyledNotificationDropdownItem read={read} onClick={closeDropdown}>
        <Link to={getNotificationLink(notificationType, referenceEntity, senderUsername)}>
            <div className="avatar">
                <Avatar src={senderAvatar || 'https://i.imgur.com/I8xE73K.png'} alt={senderUsername} />
                <div className="notification-icon">
                    {notificationType && generateIcon(notificationType)}
                </div>
            </div>
            <div className="notification-data">
                <div className="notification-info">
                    <div className="username">
                        {senderUsername}
                    </div>
                </div>
                {referenceEntity && generateMessage(notificationType, referenceEntity)}
                <time className="notification-time">{moment(timestamp).fromNow()}</time>
            </div>
        </Link>
    </StyledNotificationDropdownItem>
);

export default withUser(NotificationItem);