import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';

import { gameServers } from '../../../util/enums';

import Button from '../Button';
import Input from '../Input';
import Select from '../Select';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: var(--color-text-input-label);
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

class ClaimCharacterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            server: 'DEFAULT',
            name: '',
            gender: 'DEFAULT',
            faction: 'DEFAULT',
        };
    }

    onClaim = e => {
        const fields = { server: '', name: '', gender: '', faction: '' };
        const { server, name, gender, faction } = this.state;
        Object.keys(fields).map(key => document.getElementById(`character-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`character-${key}-error`).innerHTML = '');

        const errors = {
            server: [],
            name: [],
            gender: [],
            faction: [],
        };

        let hasError = false;

        if (name.length < 3 || name.length > 30) {
            errors.name.push('Name must be between 3 and 30 characters.');
            hasError = true;
        }

        if (name.indexOf(' ') >= 0)
            errors.name.push('Name cannot contain any spaces.');

        if (server == null || server == 'DEFAULT') {
            errors.server.push('Must choose a server.');
            hasError = true;
        }
        
        if (faction == null || faction == 'DEFAULT') {
            errors.faction.push('Must choose a faction.');
            hasError = true;
        }

        if (gender == null || gender == 'DEFAULT') {
            errors.gender.push('Must choose a gender.');
            hasError = true;
        }

        if (hasError) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`character-${error}`).className += ' error'
                    document.getElementById(`character-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else {
            this.props.onSubmit(server, name, faction, gender);
            this.setState({ server: 'DEFAULT', name: '', faction: 'DEFAULT', gender: 'DEFAULT' });
        }
    };

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        return (
            <div>
                <div ref={this.claimCharacterForm} style={{ display: this.props.active ? 'block' : 'none', }}>
                    <div id="character-server" className="field">
                        <StyledLabel htmlFor="server">Server:</StyledLabel>
                        {/* Dynamically generate options */}
                        <Select name="server" value={this.state.server} onChange={e => this.handleChange(e)}>
                            <option value="DEFAULT" disabled> Choose a server </option>
                            <option value="5">{gameServers.getName(5)}</option>
                            <option value="6">{gameServers.getName(6)}</option>
                            <option value="1">{gameServers.getName(1)}</option>
                            <option value="2">{gameServers.getName(2)}</option>
                            <option value="3">{gameServers.getName(3)}</option>
                            <option value="4">{gameServers.getName(4)}</option>
                        </Select>
                        <div id="character-server-error" className="error-message"></div>
                    </div>
                    <div id="character-name" className="field">
                        <StyledLabel htmlFor="name">Name:</StyledLabel>
                        <Input name="name" type="text" value={this.state.name} onChange={e => this.handleChange(e)}></Input>
                        <div id="character-name-error" className="error-message"></div>
                    </div>
                    <div id="character-gender" className="field">
                        <StyledLabel htmlFor="gender">Gender:</StyledLabel>
                        <Select name="gender" value={this.state.gender} onChange={e => this.handleChange(e)}>
                            <option value="DEFAULT" disabled> Choose a gender </option>
                            <option value="0">Male</option>
                            <option value="1">Female</option>
                        </Select>
                        <div id="character-gender-error" className="error-message"></div>
                    </div>
                    <div id="character-faction" className="field">
                        <StyledLabel htmlFor="faction">Faction:</StyledLabel>
                        <Select name="faction" value={this.state.faction} onChange={e => this.handleChange(e)}>
                            <option value="DEFAULT" disabled> Choose a faction </option>
                            <option value="0">Enforcer</option>
                            <option value="1">Criminal</option>
                        </Select>
                        <div id="character-faction-error" className="error-message"></div>
                    </div>
                    <div className="field edit-user-button">
                        <Button onClick={this.onClaim}>Claim Character</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClaimCharacterForm;