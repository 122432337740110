import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'

import FullscreenPageLayout from '../../components/fullscreenPageLayout';
import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import PageBrandingHead from '../../components/fullscreenPageLayout/brandHead';
import PageCard from '../../components/fullscreenPageLayout/card';
import PageFoot from '../../components/fullscreenPageLayout/footer';
import Input from '../../components/forms/InputOld';

import './style.css';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

const StyledWelcomeMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    .avatar {
        display: flex;
        border-radius: 50%;
        margin-bottom: .5rem;
        border: 3px solid #efefef;
    }
`;

const StyledProfileUrl = styled.div`
    display: block;
    font-size: .8rem;

    .link {
        color: var(--color-background-accent)
    }
`;

const StyledContainer = styled.div`
    @media(min-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledDiscordUsername = styled.div`
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 1rem 0 0.7rem 0;
`;

const StyledDiscordInstruction = styled.div`
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0 1rem 0;
`;

class DiscordRegister extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            redirect: this.props.redirect || null,
        };
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    onRegister = () => {
        const fields = { username: '', email: '' };
        Object.keys(fields).map(key => document.getElementById(`register-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`register-${key}-error`).innerHTML = '');

        const { username = '', email = '' } = this.state;
        const errors = {
            username: [],
            email: [],
        };

        if (username.length < 3 || username.length > 30)
            errors.username.push('Username must be between 3 and 30 characters.');

        if (!RegExp(/^[a-z0-9]+$/i).test(username))
            errors.username.push('Username must be alphanumeric.');

        if (!(RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm).test(email)))
            errors.email.push('Email must be valid.');

        if (email.length > 50)
            errors.email.push('Email must be less than 50 characters.');

        if (errors.username.length + errors.email.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`register-${error}`).className += ' error'
                    document.getElementById(`register-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else
            this.props.registerWithDiscord(this.props.registrationTokenId, this.state.username, this.state.email)
                .then(res => {
                    if (!res.success)
                        Swal.fire(
                            'Error',
                            res.message,
                            'error'
                        )
                    else {
                        this.setState({ redirect: '/settings' });

                        Swal.mixin({
                            confirmButtonText: 'Ok',
                            progressSteps: ['1', '2']
                        }).queue([
                            {
                                icon: 'success',
                                title: `Welcome ${username}!`,
                                text: 'You have successfully signed up.'
                            },
                            {
                                icon: 'info',
                                title: 'Customize Your Experience',
                                text: 'Start by customizing your preferences and customizing your profile!'
                            },
                        ]);
                    }
                });
    }

    renderRegistrationForm() {
        return (
            <div className="register-form">
                <div className="field">
                    <span style={{
                        color: '#9658fe',
                    }}>
                        <strong>Security Tip:</strong> Do not use your official APB credentials
                    </span>
                </div>
                <div id="register-username" className="field">
                    <StyledLabel htmlFor="username">Username:</StyledLabel>
                    <Input name="username" type="text" value={this.state.username} onChange={(e) => this.handleChange(e)} />
                    <div id="register-username-error" className="error-message"></div>
                    <StyledProfileUrl>Your profile url will be: <span className="link">apb.social/profile/{this.state.username.toLocaleLowerCase()}</span></StyledProfileUrl>
                </div>
                <div id="register-email" className="field">
                    <StyledLabel htmlFor="email">Email:</StyledLabel>
                    <Input name="email" type="email" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                    <div id="register-email-error" className="error-message"></div>
                </div>

                <div className="register-button">
                    <button className="submit" onClick={this.onRegister}>Sign Up</button>
                </div>
            </div>
        )
    }

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : (
                <FullscreenPageLayout>
                    <FullscreenPageLayoutWave />
                    <StyledContainer>
                        <PageBrandingHead />
                        <PageCard>
                            <div className="form">
                                <StyledWelcomeMessage>
                                    <div>
                                        <img src={this.props.discordAvatar} alt={`${this.props.discordUsername} Avatar`} className="avatar" />
                                    </div>
                                    <StyledDiscordUsername>
                                        {this.props.discordUsername},
                                    </StyledDiscordUsername>
                                    <StyledDiscordInstruction>
                                        Complete your registration below.
                                    </StyledDiscordInstruction>
                                </StyledWelcomeMessage>

                                {this.renderRegistrationForm()}
                            </div>
                        </PageCard>
                        <PageFoot />
                    </StyledContainer>
                </FullscreenPageLayout>
            )
    }
}

export default DiscordRegister;