import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'
import { withAlert } from 'react-alert';

import { resetPasswordWithToken } from '../../services/LoginService';

import FullscreenPageLayout from '../../components/fullscreenPageLayout';
import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import PageBrandingHead from '../../components/fullscreenPageLayout/brandHead';
import PageCard from '../../components/fullscreenPageLayout/card';
import PageTitle from '../../components/fullscreenPageLayout/pageTitle';
import PageFoot from '../../components/fullscreenPageLayout/footer';
import Input from '../../components/forms/InputOld';

import ReCAPTCHA from "react-google-recaptcha";

import './style.css';

const StyledContainer = styled.div`
    @media(min-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,
            password: '',
            passwordConfirm: '',
            captcha: null,
            redirect: null,
            awaiting: false,
        };

        this.recaptchaRef = React.createRef();
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCaptcha = (captchaResult) => this.setState({ captcha: captchaResult });

    onRequestPasswordReset = () => {
        const fields = { password: '', passwordConfirm: '' };
        const { password, passwordConfirm, captcha } = this.state;
        Object.keys(fields).map(key => document.getElementById(`reset-password-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`reset-password-${key}-error`).innerHTML = '');

        const errors = {
            password: [],
            passwordConfirm: [],
        };

        if (password.length < 8 || password.length > 128)
            errors.password.push('Password must be between 8 and 128 characters.');

        if (!(RegExp(/^(?=.*?[a-z])/).test(password)))
            errors.password.push('Password must contain at least one lowercase letter');

        if (!(RegExp(/^(?=.*?[A-Z])/).test(password)))
            errors.password.push('Password must contain at least one uppercase letter');

        if (!(RegExp(/^(?=.*?[0-9])/).test(password)))
            errors.password.push('Password must contain at least one number');

        if (!(RegExp(/^(?=.*?[#?!@$%^&*-])/).test(password)))
            errors.password.push('Password must contain at least one special character');
        
        if (password != passwordConfirm) {
            errors.password.push('Passwords must match!');
            errors.passwordConfirm.push('Passwords must match!');
        }

        if (errors.password.length + errors.passwordConfirm.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`reset-password-${error}`).className += ' error'
                    document.getElementById(`reset-password-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else {
            this.setState({ awaiting: true });

            resetPasswordWithToken(this.state.token, this.state.password, this.state.captcha)
                .then(res => {
                    if (res.success) {
                        this.setState({ redirect: `/login` });

                        Swal.fire({
                            icon: 'success',
                            title: res.message,
                        })
                    } else
                        Swal.fire({
                            icon: 'error',
                            title: 'This code is invalid or has already been used.',
                        })
                    
                    if (!!this.recaptchaRef.current) {
                        this.setState({ captcha: null });
                        this.recaptchaRef.current.reset();
                    }

                    this.setState({ awaiting: false });
                });
        }
    }

    renderForm = () => {
        return (
            <div className="reset-password-form">

                <div id="reset-password-password" className="field">
                    <StyledLabel htmlFor="password">New Password:</StyledLabel>
                    <Input name="password" type="password" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                    <div id="reset-password-password-error" className="error-message"></div>
                </div>

                <div id="reset-password-passwordConfirm" className="field">
                    <StyledLabel htmlFor="passwordConfirm">Confirm New Password:</StyledLabel>
                    <Input name="passwordConfirm" type="password" value={this.state.passwordConfirm} onChange={(e) => this.handleChange(e)} />
                    <div id="reset-password-passwordConfirm-error" className="error-message"></div>
                </div>

                <div className="field reset-password-captcha">
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey="6Le6ofIUAAAAAC7IzdsrYxC7iPBna2pdxrBrRbLA"
                        onChange={this.handleCaptcha}
                    />
                </div>

                <div className="field reset-password-button">
                    <button className="submit" disabled={this.state.captcha == null || this.state.password.length <= 0 || this.state.passwordConfirm.length <= 0 || this.state.awaiting} onClick={this.onRequestPasswordReset}>Continue</button>
                </div>
            </div>
        )
    }

    renderResetPassword() {
        const { redirect } = this.props;

        return (
            <FullscreenPageLayout>
                <FullscreenPageLayoutWave />
                {redirect
                    ? <Redirect to={redirect} />
                    : (
                        <StyledContainer>
                            <PageBrandingHead />
                            <PageCard>
                                <PageTitle>
                                    Reset Password
                                </PageTitle>
                                {this.renderForm()}
                            </PageCard>
                            <PageFoot />
                        </StyledContainer>
                    )}
            </FullscreenPageLayout>
        )
    };

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderResetPassword()
    }
}

export default withAlert()(ResetPassword);