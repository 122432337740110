import { getUserByUsername, getUserDetailsById, editUser, unbanUser, updateAvatar, linkDiscord, unlinkDiscord, followUser, unfollowUser } from '../services/UserService';

export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const RESET_PROFILE = 'RESET_PROFILE';
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const RECEIVE_UPDATE_USER = 'RECEIVE_UPDATE_USER';
export const REQUEST_UNBAN_USER = 'REQUEST_UNBAN_USER';
export const RECEIVE_UNBAN_USER = 'RECEIVE_UNBAN_USER';
export const REQUEST_UPDATE_USER_AVATAR = 'REQUEST_UPDATE_USER_AVATAR';
export const RECEIVE_UPDATE_USER_AVATAR = 'RECEIVE_UPDATE_USER_AVATAR';
export const REQUEST_LINK_USER_DISCORD = 'REQUEST_LINK_USER_DISCORD';
export const RECEIVE_LINK_USER_DISCORD = 'RECEIVE_LINK_USER_DISCORD';
export const REQUEST_UNLINK_USER_DISCORD = 'REQUEST_UNLINK_USER_DISCORD';
export const RECEIVE_UNLINK_USER_DISCORD = 'RECEIVE_UNLINK_USER_DISCORD';
export const REQUEST_FOLLOW_USER = 'REQUEST_FOLLOW_USER';
export const RECEIVE_FOLLOW_USER = 'RECEIVE_FOLLOW_USER';
export const REQUEST_UNFOLLOW_USER = 'REQUEST_UNFOLLOW_USER';
export const RECEIVE_UNFOLLOW_USER = 'RECEIVE_UNFOLLOW_USER';

const requestUser = (id) => ({
  type: REQUEST_USER,
  id,
});

const receiveUser = user => ({
  type: RECEIVE_USER,
  user,
});

const requestUserDetails = id => ({
  type: REQUEST_USER_DETAILS,
  id,
});

const receiveUserDetails = userDetails => ({
  type: RECEIVE_USER_DETAILS,
  userDetails,
});

const requestUpdateUser = (id) => ({
  type: REQUEST_UPDATE_USER,
  id
})

const receiveUpdateUser = (success, message, id, username, email, profileColor, avatar, verified, roles, settingViewNSFW) => ({
  type: RECEIVE_UPDATE_USER,
  success,
  message,
  id,
  username,
  email,
  profileColor,
  avatar,
  verified,
  roles,
  settingViewNSFW,
})

const requestUnbanUser = (id) => ({
  type: REQUEST_UNBAN_USER,
  id
})

const receiveUnbanUser = (success, message, id, banned) => ({
  type: RECEIVE_UNBAN_USER,
  message,
  success,
  id,
  banned,
})

const requestUpdateUserAvatar = (id) => ({
  type: REQUEST_UPDATE_USER_AVATAR,
  id
})

const receiveUpdateUserAvatar = (success, message, id, avatar) => ({
  type: RECEIVE_UPDATE_USER_AVATAR,
  success,
  message,
  id,
  avatar,
})

const requestLinkUserDiscord = (code) => ({
  type: REQUEST_LINK_USER_DISCORD,
  code,
})

const receiveLinkUserDiscord = (success, message, discordId) => ({
  type: RECEIVE_LINK_USER_DISCORD,
  message,
  success,
  discordId,
})

const requestDiscordDisassociation = () => ({
  type: REQUEST_UNLINK_USER_DISCORD,
})

const receiveDiscordDisassociation = (success, message, discordId) => ({
  type: RECEIVE_UNLINK_USER_DISCORD,
  success,
  message,
  discordId,
})

const requestFollowUser = userId => ({
  type: REQUEST_FOLLOW_USER,
  userId
})

const receiveFollowUser = (success, userId) => ({
  type: RECEIVE_FOLLOW_USER,
  success,
  userId
}) 

const requestUnfollowUser = userId => ({
  type: REQUEST_UNFOLLOW_USER,
  userId
})

const receiveUnfollowUser = (success, userId) => ({
  type: RECEIVE_UNFOLLOW_USER,
  success,
  userId
}) 

export const resetProfile = () => (
  dispatch => {
    dispatch({
      type: RESET_PROFILE
    })
  }
);

export const fetchUser = (id, getNsfwPosts) => (
  dispatch => {
    dispatch(requestUser(id))
    return getUserByUsername(id, getNsfwPosts)
      .then(user => dispatch(receiveUser(user)))
  }
);

export const fetchUserDetails = id => (
  dispatch => {
    dispatch(requestUserDetails(id))
    return getUserDetailsById(id)
      .then(userDetails => dispatch(receiveUserDetails(userDetails)))
  }
)

export const updateSelfUserData = (userId, data) => (
  dispatch => {
    dispatch(requestUpdateUser(userId))
    return editUser(userId, data)
      .then(result => dispatch(receiveUpdateUser(result.success, result.message, result._id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW)))
  })

export const unbanUserById = (id) => (
  dispatch => {
    dispatch(requestUnbanUser(id))
    return unbanUser(id)
      .then(result => dispatch(receiveUnbanUser(result.success, result.message, result.id, result.banned)))
  }
)

export const updateUserAvatar = (id, avatarBlob) => (
  dispatch => {
    dispatch(requestUpdateUserAvatar(id))
    return updateAvatar(id, avatarBlob)
      .then(result => dispatch(receiveUpdateUserAvatar(result.success, result.message, result._id, result.avatar)))
  }
)

export const linkUserDiscord = (code) => (
  dispatch => {
    dispatch(requestLinkUserDiscord(code))
    return linkDiscord(code)
      .then(result => dispatch(receiveLinkUserDiscord(result.success, result.message, result.discordId)))
  }
);

export const unlinkUserDiscord = () => (
  dispatch => {
    dispatch(requestDiscordDisassociation())
    return unlinkDiscord()
      .then(result => dispatch(receiveDiscordDisassociation(result.success, result.message, result.discordId)))
  }
);

export const followUserById = (userId) => (
  dispatch => {
    dispatch(requestFollowUser(userId))
    return followUser(userId)
      .then(result => dispatch(receiveFollowUser(result.success, result.userId)))
  }
);

export const unfollowUserById = (userId) => (
  dispatch => {
    dispatch(requestUnfollowUser(userId))
    return unfollowUser(userId)
      .then(result => dispatch(receiveUnfollowUser(result.success, result.userId)))
  }
);