import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'
import { withAlert } from 'react-alert';

import { requestPasswordReset } from '../../services/LoginService';

import FullscreenPageLayout from '../../components/fullscreenPageLayout';
import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import PageBrandingHead from '../../components/fullscreenPageLayout/brandHead';
import PageCard from '../../components/fullscreenPageLayout/card';
import PageTitle from '../../components/fullscreenPageLayout/pageTitle';
import PageFoot from '../../components/fullscreenPageLayout/footer';
import Input from '../../components/forms/InputOld';

import ReCAPTCHA from "react-google-recaptcha";

import './style.css';

const StyledContainer = styled.div`
    @media(min-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

class RequestResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            captcha: null,
            redirect: null,
            awaiting: false,
        };
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCaptcha = (captchaResult) => this.setState({ captcha: captchaResult });

    onRequestPasswordReset = () => {
        const fields = { email: '' };
        const { email, captcha } = this.state;
        Object.keys(fields).map(key => document.getElementById(`reset-password-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`reset-password-${key}-error`).innerHTML = '');

        const errors = {
            email: [],
        };

        if (!(RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm).test(email)))
            errors.email.push('Email must be valid.');

        if (email.length > 50)
            errors.email.push('Email must be less than 50 characters.');

        if (errors.email.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`reset-password-${error}`).className += ' error'
                    document.getElementById(`reset-password-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else {
            this.setState({ awaiting: true });

            requestPasswordReset(this.state.email, this.state.captcha)
                .then(res => {
                    if (res.success) {
                        this.setState({ redirect: `/` });

                        Swal.fire({
                            icon: 'success',
                            title: res.message,
                        })
                    } else
                        Swal.fire({
                            icon: 'error',
                            title: res.message,
                        })
                });
        }
    }

    renderForm = () => {
        return (
            <div className="reset-password-form">

                <div id="reset-password-email" className="field">
                    <StyledLabel htmlFor="email">Email:</StyledLabel>
                    <Input name="email" type="text" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                    <div id="reset-password-email-error" className="error-message"></div>
                </div>

                <div className="field reset-password-captcha">
                    <ReCAPTCHA 
                        sitekey="6Le6ofIUAAAAAC7IzdsrYxC7iPBna2pdxrBrRbLA"
                        onChange={this.handleCaptcha}
                    />
                </div>

                <div className="field reset-password-button">
                    <button className="submit" disabled={this.state.captcha == null || this.state.email.length <= 0 || this.state.awaiting} onClick={this.onRequestPasswordReset}>Continue</button>
                </div>
            </div>
        )
    }

    renderResetPassword() {
        const { redirect } = this.props;

        return (
            <FullscreenPageLayout>
                <FullscreenPageLayoutWave />
                {redirect
                    ? <Redirect to={redirect} />
                    : (
                        <StyledContainer>
                            <PageBrandingHead />
                            <PageCard>
                                <PageTitle>
                                    Reset Password
                                </PageTitle>
                                {this.renderForm()}
                            </PageCard>
                            <PageFoot />
                        </StyledContainer>
                    )}
            </FullscreenPageLayout>
        )
    };

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderResetPassword()
    }
}

export default withAlert()(RequestResetPassword);