import React from 'react';
import styled from 'styled-components';

import Section from '../../components/section/Section';

const VerifiedContainer = styled.div`
  text-align: center;
  font-family: 'Roboto Mono', monospace;
`;

const VerifiedTitle = styled.h1`
  color: rgba(0,0,0,.8);
  font-size: 2rem;
  font-weight: 400;
`;

const VerifiedEmoji = styled.h1`
  color: rgba(0,0,0,.4);
  font-size: 10rem;
  font-weight: 400;
`;

const VerifiedText = styled.h3`
  color: rgba(0,0,0,.4);
  line-height: 21px;
  margin-top: 60px;
  white-space: pre-wrap;
  font-size: 1.3rem;
  font-weight: 400;
`;

class Verified extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      verified: null,
    }
  }

  componentWillMount() {
    this.props.verifyUser(this.props.match.params.token)
      .then(res => {
        const { verified } = res;

        this.setState({ verified });
        setTimeout(() => this.props.history.push('/'), 3000);
      });
  }

  componentDidMount() {
    // setTimeout(() => this.props.history.push('/'), 3000);
  }

  renderMessage() {
    switch (this.state.verified) {
      case null:
        return (
          <>
            <VerifiedTitle>Attempting to Verify</VerifiedTitle>
            <VerifiedEmoji>(o^^)o</VerifiedEmoji>
            <VerifiedText>Please wait...</VerifiedText>
            <VerifiedText>Token: {this.props.match.params.token}</VerifiedText>
          </>
        );
        break;
      case true:
        return (
          <>
            <VerifiedTitle>Thank you for verifying!</VerifiedTitle>
            <VerifiedEmoji>(o^^)o</VerifiedEmoji>
            <VerifiedText>You will now be redirected</VerifiedText>
            <VerifiedText>Token: {this.props.match.params.token}</VerifiedText>
          </>
        );
        break;
      case false:
        return (
          <>
            <VerifiedTitle>An error has occured</VerifiedTitle>
            <VerifiedEmoji>:(</VerifiedEmoji>
            <VerifiedText>This code is invalid or has already been used.</VerifiedText>
            <VerifiedText>You will now be redirected</VerifiedText>
            <VerifiedText>Token: {this.props.match.params.token}</VerifiedText>
          </>
        );
        break;
    }
  }

  render() {
    return (
      <Section>
        <VerifiedContainer>
          {this.renderMessage()}
        </VerifiedContainer>
      </Section>
    );
  }
};

export default Verified;
