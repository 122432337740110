const getNotificationsBySession = (page) => (
  fetch(`/api/user/notifications?page=${page}`)
    .then(res => res.json())
);

const sendReadNotifications = (notificationIds) => (
  fetch(`/api/user/notifications`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      notificationIds
    })
  })
    .then(res => res.json())
);

export {
  getNotificationsBySession,
  sendReadNotifications
}
