import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JustifiedGrid from 'react-justified-grid';

const Image = styled.img`
  border: 1px solid var(--color-border-grid-post-image);
  background-color: var(--color-background-grid-post-image);
  transition: border .1s;
  :hover {
    border-color: var(--color-border-grid-post-image-hover);
  }
`;

const PostList = props => {
    const [maxRowHeight, setMaxRowHeight] = useState(props.maxRowHeight || 300);
    const [gutter, setGutter] = useState(props.gutter || 2);
    const [rows, setRows] = useState(props.rows || 2);
    const [showIncompleteRow, setShowIncompleteRow] = useState(props.showIncompleteRow || false);
    const { posts } = props;

    return (
        <JustifiedGrid images={posts} maxRowHeight={maxRowHeight} gutter={gutter} showIncompleteRow={showIncompleteRow}>
            {
                processedImages => {
                    return (
                        <>
                            {processedImages.map((image, index) => {
                                const { src, width, height, left, top, originalData } = image;
                                return (
                                    <div key={index} style={{ position: 'absolute', top: `${top}px`, left: `${left}px` }}>
                                        <Link to={`/post/${originalData.postId}`}>
                                            <Image
                                                src={src}
                                                width={width}
                                                height={height}
                                                alt={originalData.title}
                                            />
                                        </Link>
                                    </div>
                                );
                            })}
                        </>
                    );
                }
            }
        </JustifiedGrid>
    );
}

export default PostList;