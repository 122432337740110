import { connect } from 'react-redux'

import AuthRoute from '../components/AuthRoute'

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        canAccess: currentUser.isLoggedIn,
    }
}

export default connect(mapStateToProps)(AuthRoute);