import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '../icons';

const StyledFooter = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
    color: #3c3c3c;
    border-top: 2px solid var(--color-border-footer);
    background: #ffffff;
    width: 100%;
    padding: 1.5rem 0;

    @media(min-width: 425px) {
        background: none;
        border: 0;
        padding: 2rem 0;
        color: rgb(255 255 255 / 52%);
    }
`;

const StyledSocials = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    a, a > svg {
        display: flex;
    }
`;

const StyledSocialIcon = styled.a`
    margin: 0 .2rem;
    transition: all ease-in-out .1s;

    @media(min-width: 425px) {
        .discord:hover {
            color: var(--color-brand-discord);
        }

        .twitter:hover {
            color: var(--color-brand-twitter);
        }
    }
`;

const StyledPrivacyPolicyContainer = styled.div`
    a {
        text-decoration: underline;
    }
`;


export const Footer = () => (
    <StyledFooter>
        <StyledSocials>
            <StyledSocialIcon href="https://discord.gg/VHsZB2r" target="_blank">
                <Icon className="icon discord" name="discord" fill="currentColor" stroke="none" width="30px" />
            </StyledSocialIcon>
            <StyledSocialIcon href="https://twitter.com/blank_apb" target="_blank">
                <Icon className="icon twitter" name="twitter" fill="currentColor" stroke="none" width="26px" />
            </StyledSocialIcon>
        </StyledSocials>
        {/* <p className="copyright">
            APB.Social © Dreamscale, LLC.<br />APB:Reloaded © Little Orbit, LLC. All Rights Reserved Worldwide.
        </p>
        <StyledPrivacyPolicyContainer>
            <Link to="/privacy-policy">Privacy Policy</Link>
        </StyledPrivacyPolicyContainer> */}
    </StyledFooter>
)

export default Footer;