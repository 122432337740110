import React from 'react';
import ReactPaginate from 'react-paginate';

import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'
import { toast } from 'react-toastify';

import Section from '../../components/section/Section';

import { GeneratePostGrid, RegeneratePostGrid } from '../../components/posts/DynamicPostGrid';

import { pruneUserContent } from '../../services/UserService';

import { Link } from 'react-router-dom';

import Icon from '../../components/icons';
import Dropdown from '../../components/dropdown/Dropdown'
import { DropdownItem, DropdownItemDivider } from '../../components/dropdown/DropdownItem';

import withUser from '../../components/hoc/withUser';

import ReactTooltip from 'react-tooltip';

import RoleBadge from '../../components/profile/RoleBadge';
import ProfileRole from '../../components/profile/ProfileRole';

import moment from 'moment';

import './style.css';

const StyledCover = styled.div`
  height: 150px;
  background-color: ${props => props.color};
  border-color: rgba(0, 0, 0, 0.23);
  border-style: solid;
  border-width: 1px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media (min-width: 760px) {
      .cover {
          width: 100%;
          margin: 0;
      }
  }
`;

const StyledImageContainer = styled.div`
  // padding: 10px;
`;

const StyledProfileSection = styled.div`
  padding: 1rem;
`;

class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      postsInState: false,
      category: 0,
      postPageCount: null,
    }
  }

  componentWillMount() {
    this.props.fetchUser(this.props.showNsfwPosts)
      .then(res => {
        RegeneratePostGrid();
      });
  }

  loadPosts = (category, page) => {
    // send comments request, set post comments state on response
    this.props.fetchPosts(this.props.user._id, category, page, this.props.showNsfwPosts)
      .then(res => {
        this.setState({ posts: res.posts, postsInState: true, postPageCount: res.postPageCount });
      });
  }

  handleUnbanUser = () => {
    Swal.fire({
      title: `Unban ${this.props.user.username}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.props.unbanUser(this.props.user._id)
          .then(response => {
            Swal.fire({
              title: `Ohayo ${this.props.user.username}-chan`,
              icon: 'success',
            })
          })
      }
    })
  }

  handlePruneUser = () => {
    Swal.fire({
      title: `Prune ${this.props.user.username}'s Content?`,
      text: `This will delete ${this.props.user.username}'s Posts, Comments, Likes, Notifications, Sessions, and Characters.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        pruneUserContent(this.props.user._id)
          .then(response => {
            response.success ? Swal.fire({ title: `Pruned ${this.props.user.username}`, icon: 'success' }) : Swal.fire({ title: `Failed to Prune ${this.props.user.username}`, icon: 'error' });
          });
      }
    })
  }

  handlePostPageClick = data => {
    // Normalize from page 0 to 1
    let selected = data.selected + 1;

    this.loadPosts(this.state.category, selected);
  };

  handleFollowUser = () => {
    this.props.followUser(this.props.user._id)
      .then(result => {
        result.success
          ? toast(`Followed ${this.props.user.username}`, { type: toast.TYPE.SUCCESS })
          : toast(`Failed to follow ${this.props.user.username}`, { type: toast.TYPE.ERROR });
      });
  };

  handleUnfollowUser = () => {
    this.props.unfollowUser(this.props.user._id)
      .then(result => {
        result.success
          ? toast(`Unfollowed ${this.props.user.username}`, { type: toast.TYPE.SUCCESS })
          : toast(`Failed to unfollow ${this.props.user.username}`, { type: toast.TYPE.ERROR });
      });
  };

  populateGallery() {
    const { user } = this.props;

    const posts = this.state.postsInState ? this.state.posts : user.posts || [];

    if (posts.length > 0) {
      return GeneratePostGrid(posts, true);
    } else
      return null;
  }

  renderProfileMenu() {
    const { user } = this.props;

    return (
      <div className="right-menu">
        {/* <div className="item message">
          Message
        </div> */}
        {
          this.props.currentUserId != this.props.user._id && 
          (
            this.props.user.following
              ? <div className="item follow following" onClick={() => this.handleUnfollowUser()}>
                Following
            </div>
              : <div className="item follow" onClick={() => this.handleFollowUser()}>
                + Follow
            </div>
          )
        }
        { this.props.isModerator(this.props.roles) &&
          <Dropdown right={true}>
            <div className="item options">
              <Icon name="more-horizontal" />
            </div>
            <DropdownItem><Link to={`/user/${user._id}/edit`}>Edit User</Link></DropdownItem>
            <DropdownItemDivider />
            {(this.props.currentUserId != this.props.user._id) &&
              <DropdownItem>
                {user.banned ? <span onClick={this.handleUnbanUser}>Unban User</span> : <Link to={`/user/${user._id}/ban`}>Ban User</Link>}
              </DropdownItem>
            }
            <DropdownItem><span onClick={this.handlePruneUser}>Prune Content</span></DropdownItem>
          </Dropdown>
        }
      </div>)
  }

  renderProfile() {
    const { user } = this.props;

    return (
      <>
      <StyledCover color={user.profileColor} />
      <StyledProfileSection>
        <div className={`info ${this.props.user.banned ? 'banned' : ''}`}>
          <div className="avatar">
            <img className="avatar-image" src={(!!user.avatar && user.avatar) || 'https://i.imgur.com/I8xE73K.png'} alt="avatar" />
          </div>

          <div className="details">
              <h1 className="username">
                {user.username}
                {/* <Icon className="verified" name="verified" width={22} height={22} stroke="currentColor" /> */}
              </h1>
              
              {
                user.roles && <ProfileRole roles={user.roles} />
              }

              <div className="join-date">
                Joined {moment(user.createdAt).format('MMMM Do YYYY')}
              </div>

              <div className="metrics">
                <div className="following">
                  {user.numFollowing || 0} <span className="follow-type">Following</span>
                </div>
                <div className="followers">
                  {user.numFollowers || 0} <span className="follow-type">Followers</span>
                </div>
              </div>

              {/* <div className="social-media">
                <a className="site" href="https://forums.gamersfirst.com/profile/3632-biank/">
                  <Icon className="icon gamersfirst" name="gamersfirst" fill="currentColor" stroke="none" width={28} height={28} />
                </a>
                <a className="site" href="https://twitch.com/blank_apb">
                  <Icon className="icon twitch" name="twitch" fill="currentColor" stroke="none" width={22} height={22} />
                </a>
                <a className="site" href="https://twitter.com/hazulu">
                  <Icon className="icon twitter" name="twitter" fill="currentColor" stroke="none" width={24} height={24} />
                </a>
                <a className="site" href="https://twitch.com/blank_apb">
                  <Icon className="icon youtube" name="youtube" fill="currentColor" stroke="none" width={26} height={26} />
                </a>
              </div> */}
          </div>
        </div>

        <div className="profile-menu">
          <div className="item active">
              Creations ({this.props.user.numPosts})
          </div>

          {/* <div className="right-menu">
            <div className="item message">
              Message
            </div>
            <div className="item follow">
              + Follow
            </div>
            <div className="item follow following">
              Following
            </div>
              {this.props.isModerator(this.props.roles) &&
                <Dropdown right={true}>
                  <div className="item options">
                    <Icon name="more-horizontal" />
                  </div>
                  <DropdownItem><Link to={`/user/${user._id}/edit`}>Edit User</Link></DropdownItem>
                  <DropdownItemDivider />
                  {(this.props.currentUserId != this.props.user._id) &&
                    <DropdownItem>
                      {user.banned ? <span onClick={this.handleUnbanUser}>Unban User</span> : <Link to={`/user/${user._id}/ban`}>Ban User</Link>}
                    </DropdownItem>
                  }
                  <DropdownItem><span onClick={this.handlePruneUser}>Prune Content</span></DropdownItem>
                </Dropdown>
              }
          </div> */}

          {!!this.props.currentUserId && this.renderProfileMenu()}
        </div>

        <StyledImageContainer>
          {this.populateGallery()}
        </StyledImageContainer>

        {user.postPageCount > 1 && (
          <ReactPaginate
            previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
            nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
            breakClassName={'break-me'}
            pageCount={user.postPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={this.handlePostPageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </StyledProfileSection>
      </>
    )
  }

  renderUserNotFound() {
    return (
      <Section>
        <h2>
          <center>
            No profile found
          </center>
        </h2>
      </Section>
    )
  }

  render() {
    const { user } = this.props;

    if (this.props.isFetching) {
      return (
        <div className="fill"></div>
      );
    } else
      return (user && Object.entries(user).length > 0) ? this.renderProfile() : this.renderUserNotFound(); 
  }
}

export default withUser(Profile);