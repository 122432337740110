import React from 'react';
import PostList2 from './PostListv2';

const determineImageHeight = category => {
    switch (category) {
        case 0: // symbol
            return 340;
        case 3: // char customization
        case 1: // clothing
            return 570;
        case 2: // car
            return 340;
        default:
            return 0;
    }
};

const determineImageWidth = category => {
    switch (category) {
        case 0:
            return 340;
        case 3:
        case 1:
            return 340;
        case 2:
            return 570;
        default:
            return 0;
    }
};

export const RegeneratePostGrid = () => {
    const event = new Event('resize');
    dispatchEvent(event);
};

export const GeneratePostGrid = (posts, showIncompleteRow, maxRowHeight = 250) => {
    if (!!posts) {
        const postList = [];

        posts.map(post => postList.push({ postId: post._id, src: post.images[0], width: determineImageWidth(post.category), height: determineImageHeight(post.category), title: post.title }));

        return <PostList2 posts={postList} gutter={10} maxRowHeight={maxRowHeight} showIncompleteRow={showIncompleteRow} />
    } else
        return null;
};