import {
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  REQUEST_DISCORD_LOGIN,
  REQUEST_AUTHENTICATED_USER,
  RECEIVE_AUTHENTICATED_USER,
} from '../actions/loginActions'

import {
  RECEIVE_LOGOUT,
} from '../actions/logoutActions'

import {
  RECEIVE_VERIFY,
} from '../actions/verifyActions'

import {
  RECEIVE_UPDATE_USER,
  RECEIVE_UPDATE_USER_AVATAR,
  RECEIVE_LINK_USER_DISCORD,
  RECEIVE_UNLINK_USER_DISCORD,
} from '../actions/userActions'

import {
  RECEIVE_CLAIM_CHARACTER
} from '../actions/characterActions'

import {
  RECEIVE_EMAIL_REGISTRATION,
  RECEIVE_DISCORD_REGISTRATION,
} from '../actions/registrationActions'
  
const login = (
  state = {
    isFetching: false,
    isLoggedIn: false,
    id: null,
    username: null,
    email: null,
    profileColor: null,
    avatar: null,
    verified: null,
    roles: null,
    settingViewNSFW: null,
    characters: null,
    discordId: null,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_LOGIN:
    case REQUEST_DISCORD_LOGIN:
      return Object.assign({}, state, {
        isFetching: true,
      })
      break;
    case RECEIVE_LOGIN:
      return Object.assign({}, state, {
        isFetching: false,
        isLoggedIn: true,
        id: action.id,
        username: action.username,
        email: action.email,
        profileColor: action.profileColor,
        avatar: action.avatar,
        verified: action.verified,
        roles: action.roles,
        settingViewNSFW: action.settingViewNSFW,
        characters: action.characters,
        discordId: action.discordId,
      })
      break;
    case RECEIVE_LOGOUT:
      return Object.assign({}, state, {
        isLoggedIn: false,
        id: null,
        username: null,
        email: null,
        profileColor: null,
        avatar: null,
        verified: null,
        roles: null,
        settingViewNSFW: null,
        characters: null,
        discordId: null,
      })
      break;
    case RECEIVE_VERIFY:
      if (action.verified === true)
        return Object.assign({}, state, {
          verified: true,
        });
      else
        return state;
      break;
    case REQUEST_AUTHENTICATED_USER:
      return Object.assign({}, state, {
        isFetching: true,
      })
      break;
    case RECEIVE_AUTHENTICATED_USER:
      if (action.success)
        return Object.assign({}, state, {
          isFetching: false,
          isLoggedIn: true,
          id: action.id,
          username: action.username,
          email: action.email,
          profileColor: action.profileColor,
          avatar: action.avatar,
          verified: action.verified,
          roles: action.roles,
          settingViewNSFW: action.settingViewNSFW,
          characters: action.characters,
          discordId: action.discordId,
        })
      else
        return Object.assign({}, state, {
          isFetching: false,
          isLoggedIn: false,
          id: null,
          username: null,
          email: null,
          profileColor: null,
          avatar: null,
          verified: null,
          roles: null,
          settingViewNSFW: null,
          characters: null,
          discordId: null,
        })
      break;
    case RECEIVE_UPDATE_USER:
      if (action.success && action.id == state.id)
        return Object.assign({}, state, {
          username: action.username,
          email: action.email,
          profileColor: action.profileColor,
          avatar: action.avatar,
          verified: action.verified,
          roles: action.roles,
          settingViewNSFW: action.settingViewNSFW
        })
      else
        return state;
      break;
    case RECEIVE_CLAIM_CHARACTER:
      if (action.success && action.ownerId == state.id)
        return Object.assign({}, state, {
          characters: action.characters
        })
      else
        return state;
      break;
    case RECEIVE_UPDATE_USER_AVATAR:
      if (action.success && action.id == state.id)
        return Object.assign({}, state, {
          avatar: action.avatar,
        })
      else
        return state;
    case RECEIVE_LINK_USER_DISCORD:
      if (action.success && action.discordId)
        return Object.assign({}, state, {
          discordId: action.discordId,
        })
      else
        return state;
    case RECEIVE_UNLINK_USER_DISCORD:
      if (action.success)
        return Object.assign({}, state, {
          discordId: action.discordId,
        })
      else
        return state;
    case RECEIVE_EMAIL_REGISTRATION:
    case RECEIVE_DISCORD_REGISTRATION:
        if (action.success)
          return Object.assign({}, state, {
            isFetching: false,
            isLoggedIn: true,
            id: action.id,
            username: action.username,
            email: action.email,
            profileColor: action.profileColor,
            avatar: action.avatar,
            verified: action.verified,
            roles: action.roles,
            settingViewNSFW: action.settingViewNSFW,
            characters: action.characters,
            discordId: action.discordId,
          })
        else
          return state;
    default:
      return state
  }
}
  
export default login;