import React from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import './style.css';

import Icon from '../../../icons';

const PreviewContainer = styled.div`
    position: relative;
    margin-bottom: 4px;
`;

const UploadImagePreview = styled.img`
    display: block;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    min-height: ${props => props.height}px;
    background-color: var(--color-background-upload-image-preview);
    border-radius: 5px;
`;

const ImageAction = styled.div`
    display: flex;
    background: var(--color-background-upload-image-preview-action);
    border-radius: 5px;
    cursor: pointer;
    padding: .5rem;
    margin-right: .3rem;
    transition: .3s background;

    :hover {
        background: var(--color-background-upload-image-preview-action-hover);
    }
`;

class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { imageId, currentImageAmount, handleCrop, handleRemove, handleMove, image, width, height } = this.props;
        return (
            <div className="image-selector">
                <PreviewContainer>
                    <UploadImagePreview src={URL.createObjectURL(image)} width={width} height={height} />
                </PreviewContainer>
                <div className="preview-controls">
                    <ImageAction data-tip data-for="delete-image" onClick={handleRemove}>
                        <Icon name="trash" width={18} height={18} strokeWidth={2} alt="Remove Image" />
                    </ImageAction>
                    <ReactTooltip id='delete-image' effect='solid' place="bottom">
                        <span>Remove</span>
                    </ReactTooltip>

                    <ImageAction data-tip data-for="crop-image" onClick={handleCrop}>
                        <Icon name="crop" width={18} height={18} strokeWidth={2} alt="Crop Image" />
                    </ImageAction>
                    <ReactTooltip id='crop-image' effect='solid' place="bottom">
                        <span>Re-crop</span>
                    </ReactTooltip>

                    {imageId > 0 && (imageId != 0 && currentImageAmount != 0) && (
                        <>
                            <ImageAction data-tip data-for="move-left" onClick={() => handleMove(imageId, false)} >
                                <Icon name="arrow-left" width={18} height={18} strokeWidth={2} alt="Move Image Left" />
                            </ImageAction>
                            <ReactTooltip id='move-left' effect='solid' place="bottom">
                                <span>Move Left</span>
                            </ReactTooltip>
                        </>
                    )}

                    {imageId < currentImageAmount - 1 && !(imageId === 0 && currentImageAmount === 1) && (
                        <>
                            <ImageAction data-tip data-for="move-right" onClick={() => handleMove(imageId, true)} >
                                <Icon name="arrow-right" width={18} height={18} strokeWidth={2} alt="Move Image Right" />
                            </ImageAction>
                            <ReactTooltip id='move-right' effect='solid' place="bottom">
                                <span>Move Right</span>
                            </ReactTooltip>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default ImagePreview;
