import { connect } from 'react-redux'
import Home from '../pages/home';

import { requestHomePostFeed } from '../actions/feedActions';

const mapStateToProps = (state, ownProps) => {
  const { currentUser, postFeeds } = state;
  return {
    nsfw: currentUser.settingViewNSFW,
    posts: postFeeds.home.posts
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPosts: nsfw => dispatch(requestHomePostFeed(nsfw))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);