import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={width}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} d="M12 5c3.198 0 6.138 1.917 8.892 5.511l.258.342.408.551a1 1 0 01.071 1.082l-.07.11-.41.55C18.323 16.962 15.3 19 12 19c-3.198 0-6.138-1.917-8.892-5.511l-.258-.342-.408-.551a1 1 0 01-.071-1.082l.07-.11.41-.55C5.677 7.038 8.7 5 12 5zm0 2a5 5 0 100 10 5 5 0 000-10zm.354 2.354l2.292 2.292a.5.5 0 010 .708l-2.292 2.292a.5.5 0 01-.708 0l-2.292-2.292a.5.5 0 010-.708l2.292-2.292a.5.5 0 01.708 0z" fillRule="evenodd"></path>
    </svg>
);

export default SVG;
