import React from 'react';
import styled from 'styled-components';

import BreakwaterLogo from '../../static/images/breakwater_logotype_color.svg';

const StyledBrandContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem 0 3rem 0;

    @media(min-width: 425px) {
        padding: 2rem 0;
    }
`;

const StyledLogo = styled.img`
    max-width: 90%;
    margin: 1rem 0;

    @media(min-width: 425px) {
        margin-top: 0;
        max-width: 400px;
    }
`;

const StyledSlogan = styled.div`
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
`;

export const BrandingHeader = (props) => (
    <StyledBrandContainer>
        <StyledLogo src={BreakwaterLogo} alt="Breakwater Logo" />
        <StyledSlogan>
            Create. Share. Socialize.
        </StyledSlogan>
    </StyledBrandContainer>
)

export default BrandingHeader;