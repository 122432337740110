import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
    strokeLinecap = "round",
    strokeLinejoin = "round",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            fill={fill}
            stroke={stroke}
        >
            <line fill={fill} stroke={stroke} x1="19" y1="12" x2="5" y2="12"></line>
            <polyline fill={fill} stroke={stroke} points="12 19 5 12 12 5"></polyline>
        </svg>
    );

export default SVG;