import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

import Section from '../section/Section';
import Icon from '../icons';

const Footer = () => (
    <div className="footer">
        <Section>
            <div className="contents">
                <div className="apb-dot-socials">
                    <a href="https://discord.gg/VHsZB2r" target="_blank">
                        <Icon className="icon" name="discord" fill="currentColor" stroke="none" width="30px" />
                    </a>
                    <a href="https://twitter.com/blank_apb" target="_blank">
                        <Icon className="icon" name="twitter" fill="currentColor" stroke="none" width="26px" />
                    </a>
                </div>
                <p className="copyright">
                    APB.Social © Dreamscale, LLC.<br />APB:Reloaded © Little Orbit, LLC. All Rights Reserved Worldwide.
                </p>
                <p className="privacy">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </p>
            </div>
        </Section>
    </div>
);

export default Footer;
