import React from 'react';
import ReactDOM from 'react-dom';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter } from 'react-router-dom';
import App from './pages';
import * as serviceWorker from './serviceWorker';

import ScrollToTop from './components/ScrollToTop';

// optional cofiguration
const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const ReactApp = () => (
  <BrowserRouter>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <ToastContainer />
      <ScrollToTop />
      <App />
    </AlertProvider>
  </BrowserRouter>
)

ReactDOM.render(<ReactApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
