// import { claimCharacter } from '../services/UserService';

export const CREATE_LOADOUT_INVENTORY = 'CREATE_LOADOUT_INVENTORY';
export const DELETE_LOADOUT_INVENTORY = 'DELETE_LOADOUT_INVENTORY';
export const RENAME_LOADOUT_INVENTORY = 'RENAME_LOADOUT_INVENTORY';
export const IMPORT_LOADOUT_INVENTORY = 'IMPORT_LOADOUT_INVENTORY';

export const ADD_PRIMARY_WEAPON = 'ADD_PRIMARY_WEAPON';
export const REMOVE_PRIMARY_WEAPON = 'REMOVE_PRIMARY_WEAPON';

export const ADD_PRIMARY_WEAPON_MODIFICATION = 'ADD_PRIMARY_WEAPON_MODIFICATION';
export const REMOVE_PRIMARY_WEAPON_MODIFICATION = 'REMOVE_PRIMARY_WEAPON_MODIFICATION';

export const ADD_SECONDARY_WEAPON = 'ADD_SECONDARY_WEAPON';
export const REMOVE_SECONDARY_WEAPON = 'REMOVE_SECONDARY_WEAPON';

export const ADD_GRENADE = 'ADD_GRENADE';
export const REMOVE_GRENADE = 'REMOVE_GRENADE';

export const ADD_CHARACTER_MODIFICATION = 'ADD_CHARACTER_MODIFICATION';
export const REMOVE_CHARACTER_MODIFICATION = 'REMOVE_CHARACTER_MODIFICATION';


const createLoadoutInventory = name => ({
    type: CREATE_LOADOUT_INVENTORY,
    name,
});

const deleteLoadoutInventory = index => ({
    type: DELETE_LOADOUT_INVENTORY,
    index,
});

const renameLoadoutInventory = (name, index) => ({
    type: RENAME_LOADOUT_INVENTORY,
    name,
    index,
});

const importLoadoutInventory = ({name, primaryWeapons, primaryWeaponModifications, secondaryWeapons, grenades, characterModifications}) => ({
    type: IMPORT_LOADOUT_INVENTORY,
    name,
    primaryWeapons,
    primaryWeaponModifications,
    secondaryWeapons,
    grenades,
    characterModifications
});

const addPrimaryWeapon = (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) => ({
    type: ADD_PRIMARY_WEAPON,
    profileIndex,
    sdisplayname,
    sapbdb,
    icon_url,
    url,
    modifications,
    modificationsVacant,
    einfracategory,
});

const removePrimaryWeapon = (profileIndex, sapbdb) => ({
    type: REMOVE_PRIMARY_WEAPON,
    profileIndex,
    sapbdb
});

const addPrimaryWeaponModification = (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) => ({
    type: ADD_PRIMARY_WEAPON_MODIFICATION,
    profileIndex,
    sdisplayname,
    sapbdb,
    icon_url,
    url,
    emodifiercategory,
});

const removePrimaryWeaponModification = (profileIndex, sapbdb) => ({
    type: REMOVE_PRIMARY_WEAPON_MODIFICATION,
    profileIndex,
    sapbdb
});

const addSecondaryWeapon = (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) => ({
    type: ADD_SECONDARY_WEAPON,
    profileIndex,
    sdisplayname,
    sapbdb,
    icon_url,
    url,
    modifications,
    modificationsVacant,
    einfracategory,
});

const removeSecondaryWeapon = (profileIndex, sapbdb) => ({
    type: REMOVE_SECONDARY_WEAPON,
    profileIndex,
    sapbdb
});

const addGrenade = (profileIndex, sdisplayname, sapbdb, icon_url, url, einfracategory) => ({
    type: ADD_GRENADE,
    profileIndex,
    sdisplayname,
    sapbdb,
    icon_url,
    url,
    einfracategory
});

const removeGrenade = (profileIndex, sapbdb) => ({
    type: REMOVE_GRENADE,
    profileIndex,
    sapbdb
});

const addCharacterModification = (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) => ({
    type: ADD_CHARACTER_MODIFICATION,
    profileIndex,
    sdisplayname,
    sapbdb,
    icon_url,
    url,
    emodifiercategory,
});

const removeCharacterModification = (profileIndex, sapbdb) => ({
    type: REMOVE_CHARACTER_MODIFICATION,
    profileIndex,
    sapbdb
});

export const createLoadoutSet = (name) => (dispatch => dispatch(createLoadoutInventory(name)));

export const deleteLoadoutSet = (index) => (dispatch => dispatch(deleteLoadoutInventory(index)));

export const renameLoadoutSet = (name, index) => (dispatch => dispatch(renameLoadoutInventory(name, index)));

export const importLoadoutSet = (profileData) => (dispatch => dispatch(importLoadoutInventory(profileData)));

export const addPrimaryWeaponToSet = (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) => (
    dispatch => (
        dispatch(addPrimaryWeapon(profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory))
    )
);

export const removePrimaryWeaponFromSet = (profileIndex, sapbdb) => (
    dispatch => (
        dispatch(removePrimaryWeapon(profileIndex, sapbdb))
    )
);

export const addPrimaryWeaponModificationToSet = (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) => (
    dispatch => (
        dispatch(addPrimaryWeaponModification(profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory))
    )
);

export const removePrimaryWeaponModificationFromSet = (profileIndex, sapbdb) => (
    dispatch => (
        dispatch(removePrimaryWeaponModification(profileIndex, sapbdb))
    )
);

export const addSecondaryWeaponToSet = (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) => (
    dispatch => (
        dispatch(addSecondaryWeapon(profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory))
    )
);

export const removeSecondaryWeaponFromSet = (profileIndex, sapbdb) => (
    dispatch => (
        dispatch(removeSecondaryWeapon(profileIndex, sapbdb))
    )
);

export const addGrenadeToSet = (profileIndex, sdisplayname, sapbdb, icon_url, url, einfracategory) => (
    dispatch => (
        dispatch(addGrenade(profileIndex, sdisplayname, sapbdb, icon_url, url, einfracategory))
    )
);

export const removeGrenadeFromSet = (profileIndex, sapbdb) => (
    dispatch => (
        dispatch(removeGrenade(profileIndex, sapbdb))
    )
);

export const addCharacterModificationToSet = (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) => (
    dispatch => (
        dispatch(addCharacterModification(profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory))
    )
);

export const removeCharacterModificationFromSet = (profileIndex, sapbdb) => (
    dispatch => (
        dispatch(removeCharacterModification(profileIndex, sapbdb))
    )
);