import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { withAlert } from 'react-alert';
import Swal from 'sweetalert2';

import withUser from '../../components/hoc/withUser'

import Section from '../../components/section/Section';
import SectionHead from '../../components/section/SectionHead';

import { banUser, getBanVars } from '../../services/UserService';


const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
    .error & {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

class BanUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            banned: false,
            banTemporary: false,
            banExpiryDays: 7,
            redirect: null,
            isFetching: true,
        };
    }

    componentWillMount() {
        const userId = this.props.match.params.user_id;

        getBanVars(userId)
            .then(user => {
                if (user.banned)
                    return this.setState({ redirect: '/' });

                this.setState({ username: user.username, banned: user.banned, isFetching: false })
            });
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value, formChanged: true });
    handleCheckboxChange = (e) => this.setState({ [e.target.name]: e.target.checked, formChanged: true });

    onBan = () => {
        const { banTemporary, banExpiryDays } = this.state;

        if (banTemporary && (banExpiryDays < 0 || !!!banExpiryDays)) {

            const error = 'Please enter a number of days greater than 0.';
            
            document.getElementById(`ban-banExpiryDays`).className += ' error'
            document.getElementById(`ban-banExpiryDays-error`).innerHTML = error;

        } else {
            const userId = this.props.match.params.user_id;
            const username = this.state.username;
            const expiryDays = !!banTemporary ? banExpiryDays : null;

            Swal.fire({
                title: `Ban ${username}?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    banUser(userId, expiryDays)
                        .then(res => {
                            if (!res.success)
                                Swal.fire({
                                    icon: 'error',
                                    title: res.message,
                                })
                            else {
                                Swal.fire({
                                    icon: 'success',
                                    title: `Sayonara, ${username}.`,
                                    confirmButtonText: 'good riddance'
                                })

                                this.setState({ redirect: `/profile/${username}` });
                            }
                        });
                }
            })
        }
    }

    renderFormFields = () => {
        return (
            <div className="edit-user-form">
                <div id="ban-banTemporary" className="field">
                    <StyledLabel htmlFor="banTemporary">Temporary Ban:</StyledLabel>
                    <input name="banTemporary" type="checkbox" checked={this.state.banTemporary} onChange={(e) => this.handleCheckboxChange(e)} />
                </div>

                { this.state.banTemporary &&
                    <div id="ban-banExpiryDays" className="field">
                        <StyledLabel htmlFor="banExpiryDays">Ban Length (Days):</StyledLabel>
                        <input name="banExpiryDays" type="number" value={this.state.banExpiryDays} onChange={(e) => this.handleChange(e)} />
                        <div id="ban-banExpiryDays-error" className="error-message"></div>
                    </div>
                }

                <div className="field edit-user-button">
                    <button className="submit" onClick={this.onBan}>Ban User</button>
                </div>
            </div>
        )
    }

    renderForm = () => {
        return (
            <Section>
                <SectionHead
                    title={`Ban ${this.state.username}`}
                />

                {this.state.isFetching ? (
                    <div>
                        Loading...
                        </div>
                ) : (
                        this.renderFormFields()
                    )}
            </Section>
        )
    }

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderForm()
    }
}

export default withAlert()(withUser(BanUser));