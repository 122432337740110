import { connect } from 'react-redux'
import {    addPrimaryWeaponToSet,
            removePrimaryWeaponFromSet,
            addPrimaryWeaponModificationToSet,
            removePrimaryWeaponModificationFromSet,
            addSecondaryWeaponToSet,
            removeSecondaryWeaponFromSet,
            addGrenadeToSet,
            removeGrenadeFromSet,
            addCharacterModificationToSet,
            removeCharacterModificationFromSet,
        } from '../actions/loadoutActions';
    
import Loadout from '../pages/loadout'

const mapStateToProps = (state, ownProps) => {
    const { currentLoadouts } = state;

    return {
        loadoutProfileNames: currentLoadouts.loadoutSets.map(loadout => loadout.name),
        getProfileInventory: (profileIndex) =>
            currentLoadouts.loadoutSets[profileIndex],
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
})

export default connect(mapStateToProps, null)(Loadout);