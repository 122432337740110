import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';
import Icon from '../../../icons';

import { searchGameItems } from '../../../../services/GameDataService';

import './style.css';

const StyledSearchInputContainer = styled.div`
    display: flex;
    min-height: ${props => props.minHeight || '50px'};
    color: #3b3b3b;

    input {
        flex-grow: 1;
        height: auto !important;
        border-radius: ${props => props.activeDropdown ? '5px 0 0 0 !important' : '5px 0 0 5px !important'};
    }

    button {
        border-radius: ${props => props.activeDropdown ? '0 5px 0 0' : '0 5px 5px 0'};
        border: 1px solid #e5e5e5;
        border-left: none;
        transition-property: color, background-color, border;
        transition: .2s ease-in-out;
        cursor: pointer;
        outline: none;
    }

    > input:focus + button, button:focus {
        background-color: #9658fe;
        color: #fff;
        border-color: #9658fe;
    }
`;

const StyledSearchResults = styled.ul`
    z-index: 1020;
    position: absolute;
    left: 0;
    right: 0;

    background: #fff;
    color: #666;
    border: 1px #e5e5e5 solid;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 0.2rem 0;

    max-height: 330px;
    overflow: auto;


    margin: 0px;
    list-style: none;

    li, li .notification-icon {
        transition: background 0.1s ease;
    }

    ::-webkit-scrollbar {
        // border-left: 1px solid #f1f1f1;
        width: 8px;
        height: 10px;
        border-radius: 15px;
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-track {
        padding: .2rem;
    }

    ::-webkit-scrollbar-thumb {
        background: #c2c9d2;
        border-radius: 20px;
    }
`;

const StyledGameItemSearchResult = styled.li`
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 2px;
    padding: 0.5rem;
    cursor: pointer;

    :hover {
        background: #e4e4e4;
    }

    .item-icon {
        background: #27282c;
        border: 2px #1a1a1a solid;
        padding: 0.2rem;
        margin-right: 0.5rem;
        border-radius: 2px;
    }

    .item-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .item-info .item-name {
        color: #1a1a1a;
        font-weight: 600;
    }

    .item-info .item-properties {
        font-size: 12px;
    }
`;

const EmptySearchMessage = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    color: #1a1a1a;
    padding: 0.5rem;

    color: #1a1a1a;
    font-weight: 400;
`;

class GameItemSearchInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchResults: null,
            showDropdown: false,
            // subcatFilter: null,
            // infracatFilter: null,
        };

    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.handleSearch(1);
        }
    }
    handleSearchButton = (e) => this.handleSearch();
    handleSearch = () => {
        const { searchText } = this.state;
        searchGameItems(searchText, '&cat=Clothing')
            .then(result => {
                this.setState({ searchResults: result });
            });
    }
    handleSearchFocus = () => {
        if (!this.state.showDropdown)
            this.setState({ showDropdown: true }, () => {
                document.addEventListener('mousedown', this.closeDropdown);
            });
    }
    handleSearchBlur = () => {
        console.log('hello');
        this.hideSearchResults();
    }

    closeDropdown = e => {
        if (!!this.searchContainer)
            if (!this.searchContainer.contains(e.target))
                this.hideSearchResults()
    }

    hideSearchResults = () => {
        console.log('hello')
        this.setState({ showDropdown: false, searchText: '', searchResults: null }, () => {
            document.removeEventListener('mousedown', this.closeDropdown);
        });
    }
    
    handleSelectItem = (item) => {
        console.log(item.sapbdb);
        // this.props.onItemSelected()
        this.hideSearchResults();
    }

    render() {
        return (
            <div className="game-item-search" ref={(element) => { this.searchContainer = element; }}>
                <StyledSearchInputContainer activeDropdown={this.state.showDropdown}>
                    <input name="searchText" type="text" onChange={this.handleChange} onKeyDown={this.handleOnKeyDown} onFocus={this.handleSearchFocus} placeholder="Search" maxLength={30} value={this.state.searchText} autoComplete="off" />
                    <button className="search" onClick={this.handleSearchButton}><Icon name='search' stroke="currentColor" strokeWidth={1.8} width={20} height={20} /></button>
                </StyledSearchInputContainer>
                
                { this.state.showDropdown && (
                    <StyledSearchResults>
                        { this.state.searchResults ? (
                            <>
                                { this.state.searchResults.map(item => (
                                    <StyledGameItemSearchResult key={item.sapbdb} onClick={() => this.handleSelectItem(item)}>
                                        <img className="item-icon" src={item.hudimage} width={54} height={54} onError={(e) => { e.target.src ='https://legacy.apbdb.com/static/img/general/no_picture.png'}} title={item.sapbdb} />
                                        <div className="item-info">
                                            <span className="item-name">{item.sdisplayname}</span>
                                            <span className="item-properties">{item.bcriminal === 1 ? <Icon name='criminal' fill="currentColor" width={15} height={15} /> : ''}{item.benforcer === 1 ? <Icon name='enforcer' fill="currentColor" width={15} height={15} /> : ''}</span>
                                        </div>
                                    </StyledGameItemSearchResult>
                                ))}
                            </>
                        ) : (
                            <EmptySearchMessage>Press enter to search the db</EmptySearchMessage>
                        )}
                    </StyledSearchResults>
                )}
            </div>
        );
    }
}

export default GameItemSearchInput;