import { connect } from 'react-redux'
import { attemptLogin } from '../actions/loginActions'
import Login from '../pages/login'

const mapStateToProps = (state, ownProps) => {
  const { currentUser } = state;
  return {
    redirect: currentUser.id ? `/` : null,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: (username, password, captcha) => {
    return dispatch(attemptLogin(username, password, captcha))
      .then(result => {
        if (result.success === true && result.id)
          dispatch({ type: 'WS_CONNECT' });

        return result;
      });
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);