import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { withAlert } from 'react-alert';
import { toast } from 'react-toastify';

import styled from 'styled-components';

import FullscreenPageLayout from '../../components/fullscreenPageLayout';
import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import PageBrandingHead from '../../components/fullscreenPageLayout/brandHead';
import PageCard from '../../components/fullscreenPageLayout/card';
import PageTitle from '../../components/fullscreenPageLayout/pageTitle';
import PageFoot from '../../components/fullscreenPageLayout/footer';
import Input from '../../components/forms/InputOld';

import Icon from '../../components/icons';

import { feedbackAlertMessages } from '../../util/enums';

import './style.css';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #222;
    font-size: .8rem;
`;

const StyledContainer = styled.div`
    @media(min-width: 425px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const StyledLoginSeparator = styled.div`
    font-size: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
`;

const StyledDiscordButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0 30px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: .1s ease-in-out;
    transition-property: color,background-color,border-color;
    background-color: #ffffff;
    color: var(--color-brand-discord);
    border: 1px solid var(--color-brand-discord);
    border-radius: 5px;
    font-weight: 500;

    svg {
        margin-right: .4rem;
    }

    :hover {
        background: var(--color-brand-discord);
        color: #ffffff;
        text-decoration: none;
    }

    :focus {
        background: var(--color-brand-discord-alt);
        color: #ffffff;
        text-decoration: none;
        outline: none;
    }
`;

const StyledSignUpContainer = styled.div`
    font-size: .9rem;
    font-family: 'Inter', sans-serif;
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    a {
        margin-left: .3rem;
        color: var(--color-background-accent-secondary);
        transition: all ease-in-out .1s;
    }

    a:hover {
        color: var(--color-background-accent);
        text-decoration: none;
    }
`;

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            captcha: null,
        };
        // this.recaptchaRef = React.createRef();
    }

    handleChange = (e) => this.setState({[e.target.name]: e.target.value});
    handleCaptcha = (captchaValue) => this.setState({captcha: captchaValue});

    handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            // if (!!this.state.captcha)
            this.attemptLogin();
        }
    }

    attemptLogin = () => {      
        this.props.login(this.state.email, this.state.password, this.state.captcha)
            .then(res => {
                const alert = this.props.alert;

                if (!res.success)
                    toast.error(res.message);
                else
                    toast.success(feedbackAlertMessages.USER_LOG_IN_SUCCESS);

                // if (this.recaptchaRef.current) {
                //     this.setState({ captcha: null });
                //     this.recaptchaRef.current.reset();
                // }
            });
    }

    render() {
        const { redirect } = this.props;

        return (
            <FullscreenPageLayout>
                <FullscreenPageLayoutWave />
                {redirect
                    ? <Redirect to={redirect} />
                    : (
                        <StyledContainer>
                            <PageBrandingHead />
                            <PageCard>
                                <PageTitle>
                                    Sign In
                                </PageTitle>
                                <div className="login-form">
                                    <div className="field">
                                        <StyledLabel htmlFor="email">Email address</StyledLabel>
                                        <Input name="email" type="email" onKeyDown={this.handleOnKeyDown} value={this.state.email} onChange={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="field">
                                        <StyledLabel htmlFor="password">Password</StyledLabel>
                                        <Input name="password" type="password" onKeyDown={this.handleOnKeyDown} value={this.state.password} onChange={(e) => this.handleChange(e)} />
                                        <Link className="reset-password" to='/reset-password'>Forgot your password?</Link>
                                    </div>

                                    <div className="login-button">
                                        <button className="submit" disabled={!this.state.email || this.state.password.length < 8 || this.state.password.length > 128} onClick={this.attemptLogin}>Sign In</button>
                                    </div>

                                    <StyledLoginSeparator>
                                        OR
                                    </StyledLoginSeparator>

                                    <div className="login-button">
                                        <StyledDiscordButton href="https://discord.com/api/oauth2/authorize?client_id=710354136578916382&redirect_uri=https%3A%2F%2Fapb.social%2Fauth%2Fdiscord%3Ftype%3Dlogin&response_type=code&scope=identify">
                                            <Icon className="icon" name="discord" fill="currentColor" stroke="none" width="30px" /> Continue with Discord
                                        </StyledDiscordButton>
                                    </div>

                                    <StyledSignUpContainer>
                                        Not a member?<Link to="/join">Sign up now</Link>
                                    </StyledSignUpContainer>
                                </div>
                            </PageCard>
                            <PageFoot />
                        </StyledContainer>
                    )}
            </FullscreenPageLayout>
        )
        };
    };

export default withAlert()(Login);
