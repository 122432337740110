import { connect } from 'react-redux';
import { setStateNotificationsRead, sendUserReadNotifications } from '../actions/notificationActions';
import NotificationDropdown from '../components/navigation/NotificationDropdown';

const mapStateToProps = (state, ownProps) => {
    const { currentUser, notifications } = state;
    return {
        isLoggedIn: !!currentUser.id,
        userUsername: currentUser.username,
        userAvatar: currentUser.avatar,
        userIsVerified: currentUser.verified,
        userRoles: currentUser.roles,
        notifications: !!notifications.notifications ? notifications.notifications.slice(0,5) : [],
        unreadNotificationCount: notifications.unreadNotificationCount,
        isFetching: notifications.isFetching,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    markStateNotificationsRead: (notificationIds) =>
        dispatch(setStateNotificationsRead(notificationIds)),
    sendReadNotificationsToServer: (notificationIds) =>
        dispatch(sendUserReadNotifications(notificationIds)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);