import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 192 192"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 192 192"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        fill={fill}
        stroke={stroke}
    >
        <path d="M180.3,53.4c-2-7.6-8-13.6-15.6-15.7C151,34,96,34,96,34s-55,0-68.8,3.7c-7.6,2-13.5,8-15.6,15.7C8,67.2,8,96,8,96
		s0,28.8,3.7,42.6c2,7.6,8,13.6,15.6,15.7C41,158,96,158,96,158s55,0,68.8-3.7c7.6-2,13.5-8,15.6-15.7C184,124.8,184,96,184,96
		S184,67.2,180.3,53.4 M78,122.2V69.8L124,96L78,122.2z"/>
    </svg>
);

export default SVG;