
const searchGameItems = (searchText, searchOptions = '') => (
    fetch(`https://api.apbdb.com/beacon/items?search=${searchText}${searchOptions}`, { method: 'GET' })
        .then(res => res.json())
        .then(res => res.items)
        .catch(err => console.error(err))
);

const getGameItemDetail = sapbdb => (
    fetch(`https://api.apbdb.com/beacon/items/${sapbdb}`, { method: 'GET' })
        .then(res => res.json())
        .catch(err => console.error(err))
);

const getWeaponWithModificationData = sapbdb => {
    let weaponWithModificationData = {};

    return getGameItemDetail(sapbdb)
        .then(gameItem => {
            weaponWithModificationData = normalizeWeaponData(gameItem);

            weaponWithModificationData.modificationsVacant = 0;

            const weaponMod1 = gameItem.detail.eFnMod_0;
            const weaponMod2 = gameItem.detail.eFnMod_1;
            const weaponMod3 = gameItem.detail.eFnMod_2;

            const weaponModificationIds = [weaponMod1, weaponMod2, weaponMod3];

            const modificationData = [];

            weaponModificationIds.map(modification => {
                if (modification.sAPBDB != 'Mod_None' && modification.sAPBDB != 'Mod_Vacant')
                    modificationData.push(modification.eInventoryItemType);
                else if (modification.sAPBDB === 'Mod_Vacant')
                    weaponWithModificationData.modificationsVacant += 1;
            });

            weaponWithModificationData.modifications = normalizeWeaponModificationDataArray(modificationData);

            return weaponWithModificationData;
        })
        .catch(err => console.error(err))
};

const normalizeWeaponData = ({sDisplayName, sAPBDB, eInfraCategory, eHUDImage}) => (
    {
        sdisplayname: sanitizeDisplayName(sDisplayName),
        sapbdb: sAPBDB,
        einfracategory: eInfraCategory,
        url: `https://apbdb.com/items/${sAPBDB}`,
        icon_url: eHUDImage
    }
);

const normalizeWeaponModificationData = ({sDisplayName, sAPBDB, eHUDImage, eModifierCategory}) => (
    {
        sdisplayname: sDisplayName,
        sapbdb: sAPBDB,
        icon_url: eHUDImage,
        url: `https://apbdb.com/items/${sAPBDB}`,
        emodifiercategory: eModifierCategory
    }
);

const normalizeWeaponModificationDataArray = (weaponModificationDataArray) => {
    return weaponModificationDataArray.map(weaponModification => normalizeWeaponModificationData(weaponModification));
}

const filterInactiveWeapons = (weaponArray) => {
    return weaponArray.filter(weaponData => weaponData.bIsTestItem != 1 && weaponData.bRussianOnly != 1);
}

const sanitizeDisplayName = sdisplayname => {
    if (sdisplayname.includes('</Color>')) {
        const nameArray = sdisplayname.split('>');
        // Remove </Color>
        return nameArray[1].slice(0, -7);
    } else
        return sdisplayname;
};

const searchPrimaryWeaponsDetailed = (searchText) => (
    searchGameItems(searchText, '&subcat=WeaponPrimary&limit=20')
        .then(res => {
            console.log(res);
            const promises = [];
            const activeWeapons = filterInactiveWeapons(res);

            activeWeapons.forEach(searchResult => promises.push(getWeaponWithModificationData(searchResult.sAPBDB)))
            
            return Promise.all(promises);
        })
        .then(weaponDetailArray => {
            return weaponDetailArray;
        })
        .catch(err => console.error(err))
);

const searchSecondaryWeaponsDetailed = (searchText) => (
    searchGameItems(searchText, '&subcat=WeaponSecondary&limit=20')
        .then(res => {
            const promises = [];
            const activeWeapons = filterInactiveWeapons(res);

            activeWeapons.forEach(searchResult => promises.push(getWeaponWithModificationData(searchResult.sAPBDB)))

            return Promise.all(promises);
        })
        .then(weaponDetailArray => {
            return weaponDetailArray;
        })
        .catch(err => console.error(err))
);

const getDetailWeaponDataFromArray = sapbdbArray => {
    const promises = [];

    sapbdbArray.forEach(sapbdb => promises.push(getWeaponWithModificationData(sapbdb)));
    return Promise.all(promises);
};

export {
    searchGameItems,
    getGameItemDetail,
    searchPrimaryWeaponsDetailed,
    searchSecondaryWeaponsDetailed,
    getDetailWeaponDataFromArray,
}
