import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { postCategories } from '../../util/enums';

import SidebarItem from './SidebarItem';
import Icon from '../icons';

const StyledSidebar = styled.div.attrs(props => ({
}))`
    display: none;
    border-right: 1px solid var(--color-border-sidebar);
    background: var(--color-background-sidebar);
    color: var(--color-text-sidebar);
    width: 72px;

    @media(min-width: 768px) {
        display: block;
    }
`;

const StyledStaticSidebarContainer = styled.div.attrs(props => ({
}))`
    display: block;
    position: sticky;
    top: 58px;
`;

const StyledStaticSidebar = styled.div.attrs(props => ({
}))`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const StyledSidebarGroup = styled.div`
    border-top: 1px solid var(--color-border-sidebar);
    border-bottom: 1px solid var(--color-border-sidebar);
`;

const isSidebarItemActive = itemName => {
    switch(itemName) {
        case 'home' :
            return window.location.pathname === '/';
        case 'popular':
            return window.location.pathname === '/popular';
        case 'following':
            return window.location.pathname === '/following' || window.location.pathname === '/following/';
        case 'symbols':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.SYMBOL;
        case 'clothing':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.CLOTHING;
        case 'vehicles':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.VEHICLE;
        case 'character':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.CHARACTER_CUSTOMIZATION;
        default:
            return false;
    }
}

const renderSidebar = props => {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    return (
        <StyledSidebar>
            <StyledStaticSidebarContainer>
                <StyledStaticSidebar>
                    <SidebarItem linkTo='/' active={isSidebarItemActive('home')} onClick={forceUpdate}>
                        <Icon name='home' fill='currentColor' stroke='none' height='24'/>
                    </SidebarItem>
                    { props.isLoggedIn &&
                        <SidebarItem linkTo='/following' active={isSidebarItemActive('following')} onClick={forceUpdate}>
                            <Icon name='eye-sidebar' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem>
                    }
                    <StyledSidebarGroup>
                        <SidebarItem linkTo='/explore?category=0' active={isSidebarItemActive('symbols')} onClick={forceUpdate}>
                            <Icon name='symbol-icon' fill='currentColor' stroke='none' height='24'/>
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=1' active={isSidebarItemActive('clothing')} onClick={forceUpdate}>
                            <Icon name='clothing-icon' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=2' active={isSidebarItemActive('vehicles')} onClick={forceUpdate}>
                            <Icon name='vehicle-icon' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=3' active={isSidebarItemActive('character')} onClick={forceUpdate}>
                            <Icon name='char-customization-icon' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem>
                        {/* <SidebarItem linkTo='/explore?category=4'>
                            <Icon name='music-studio-icon' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem> */}
                    </StyledSidebarGroup>

                    {props.children}
                </StyledStaticSidebar>
            </StyledStaticSidebarContainer>
        </StyledSidebar>
    )
}

export default props => renderSidebar(props);
