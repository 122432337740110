const getPostById = (id) => (
  fetch(`/api/post/${id}`)
    .then(res => res.json())
);

const getPostsByUserId = (userId, category, page, nsfw) => (
  fetch(`/api/user/${userId}/posts?category=${category}&page=${page}&nsfw=${!!nsfw}`)
    .then(res => res.json())
);

const getNewestPosts = (page, category, nsfw) => (
  fetch(`/api/posts/newest?page=${page || 0}&category=${category}&nsfw=${!!nsfw}`, { method: 'POST' })
    .then(res => res.json())
);

const createPost = (title, category, images, description, tags, nsfw, creator, forSale, forSalePrice, captcha) => {
  const data = new FormData();
  
  data.append('title', title);

  data.append('category', category);
  
  const max_filecount = images.length > 4 ? 4 : images.length;

  for (var i = 0; i < max_filecount; i++)
    if(images[i] !== null)
      data.append('images', images[i], `upload_${i}.png`);

  if (!!description)
      data.append('description', description);

  if (!!tags)
    for (var i = 0; i < tags.length; i++)
      data.append('tags', tags[i]);
  
  if (!!creator)
    data.append('creator', creator);

  if (!!forSale)
    data.append('forSale', forSale);

  if (forSalePrice != null && !isNaN(forSalePrice))
    data.append('forSalePrice', forSalePrice);
      
  data.append('nsfw', nsfw);
  // data.append('captcha', captcha);

  return fetch(`/api/post/create`, {
    method: 'POST',
    body: data,
  })
    .then(res => res.json())
};

const getEditPostDetail = (id) => (
  fetch(`/api/post/${id}/edit`)
    .then(res => res.json())
);

const updatePost = (id, title, description, tags, nsfw, creator, forSale, forSalePrice)  =>
  fetch(`/api/post/${id}`, {
    method: 'PATCH',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      title,
      description,
      tags,
      nsfw,
      creator,
      forSale,
      forSalePrice,
    })
  })
    .then(res => res.json())

const deletePost = id =>
  fetch(`/api/post/${id}/delete`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    }
  });

const likePost = id => {
  return fetch(`/api/post/${id}/like`, {
    method: 'GET',
  })
    .then(res => res.json())
}

const unlikePost = id => {
  return fetch(`/api/post/${id}/unlike`, {
    method: 'GET',
  })
    .then(res => res.json())
}

const getPostComments = (id, page) => (
  fetch(`/api/post/${id}/comments?page=${page || 0}`)
    .then(res => res.json())
)

const createComment = (id, text) => (
  fetch(`/api/post/${id}/comment/create`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      text,
    })
  })
    .then(res => res.json())
);

const deleteComment = id => (
  fetch(`/api/comment/${id}/delete`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
)

const searchPosts = (searchText = '', category, nsfw = false, page = 0, filterForSale, filterPriceRangeMin = 0, filterPriceRangeMax = 9999999999, filterCreatorUsername, filterCreatorCharacterName, filterCreatorServer) => {
  
  if(category) {
    if (!Array.isArray(category))
      category = [category];

    category = category.map(category => parseInt(category));
  }

  // Certain decisions were made specifically for the following reasons:
  // ** After implementing the aggregation pipeline, the datatypes need to be correct or it will search for the wrong thing. i.e. looking for string instead of numbers **

  return fetch(`/api/posts/search`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...(!!searchText && { query: searchText }),
      ...(!!category && { filterCategory: category }),
      ...(filterForSale && { filterForSale: true, filterPriceRangeMin, filterPriceRangeMax }),
      ...(nsfw === 'true' && { nsfw: true }),
      ...(!!parseInt(page) && { page: parseInt(page) }),
      ...(!!filterCreatorUsername && { filterCreatorUsername }),
      ...(!!filterCreatorCharacterName && { filterCreatorCharacterName }),
      ...(!!parseInt(filterCreatorServer) && { filterCreatorServer: parseInt(filterCreatorServer) }),
    })
  })
    .then(res => res.json())
}

export {
  getPostById,
  getPostsByUserId,
  getNewestPosts,
  createPost,
  likePost,
  unlikePost,
  updatePost,
  getEditPostDetail,
  deletePost,
  getPostComments,
  createComment,
  deleteComment,
  searchPosts,
}
  