import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    width = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            {/* <path fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" d="M18.727,9.399
	c0.719-3.234-1.319-6.44-4.555-7.16c-3.234-0.72-6.44,1.319-7.16,4.553c-1.521,6.833-4.884,8.133-4.884,8.133l17.57,3.911
	C19.698,18.836,17.205,16.23,18.727,9.399"/>

        <path fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" d="M11.733,21.161
	c-0.749,0.812-2.014,0.864-2.826,0.114c-0.256-0.235-0.446-0.534-0.551-0.866"/> */}

            {/* <path fill={fill} stroke={stroke} d="M11.8286 15.999A2.995 2.995 0 0112 17.0008c0 1.6569-1.3431 3-3 3s-3-1.3431-3-3c0-.3513.0604-.6885.1714-1.0018h5.6572zM9 0c3.3323 0 7 2.5 7 7v4l1 2h1v2H0v-2h1l1-2V7c0-4.5 3.6677-7 7-7z"></path>  */}

            <path fillRule="evenodd" fill={fill} stroke={stroke} d="M15 19a2 2 0 01-2 2h-2a2 2 0 01-2-2h6zM13 2a1 1 0 011 1v1h2.656c.429 0 .835.41.907.917L19 13l1.707 1.707a1 1 0 01.293.707V17a1 1 0 01-1 1H4a1 1 0 01-1-1v-1.586a1 1 0 01.293-.707L5 13l1.437-8.083C6.51 4.41 6.915 4 7.344 4H10V3a1 1 0 011-1h2z"></path>
        </svg>
    );

export default SVG;
