import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} stroke={stroke} d="M15.698,17.327c-0.215-0.151-0.428-0.303-0.641-0.455
	c-0.162-0.114-0.325-0.229-0.484-0.346c-0.045-0.032-0.078-0.036-0.128-0.01c-0.309,0.164-0.628,0.304-0.965,0.4
	c-0.058,0.017-0.069,0.053-0.077,0.103c-0.068,0.412-0.138,0.823-0.205,1.234c-0.007,0.045-0.025,0.056-0.067,0.056
	c-0.749-0.001-1.497-0.001-2.246-0.002c-0.005,0-0.01,0-0.027-0.004c-0.022-0.127-0.045-0.259-0.067-0.39
	c-0.049-0.295-0.098-0.59-0.147-0.884c-0.007-0.045-0.024-0.073-0.074-0.087c-0.346-0.095-0.674-0.232-0.99-0.401
	c-0.05-0.026-0.083-0.023-0.128,0.009c-0.352,0.254-0.705,0.506-1.059,0.758c-0.021,0.015-0.043,0.029-0.054,0.036
	c-0.548-0.544-1.092-1.085-1.64-1.63c0.066-0.094,0.134-0.19,0.203-0.287c0.196-0.277,0.39-0.555,0.589-0.828
	c0.038-0.053,0.035-0.09,0.006-0.142c-0.163-0.306-0.305-0.621-0.395-0.957c-0.015-0.057-0.049-0.068-0.096-0.076
	c-0.393-0.065-0.787-0.129-1.179-0.198c-0.196-0.034-0.168,0.017-0.168-0.193c-0.001-0.681,0-1.36,0-2.041
	c0-0.11,0-0.108,0.109-0.125c0.42-0.063,0.841-0.125,1.261-0.191c0.026-0.004,0.062-0.034,0.069-0.058
	C7.198,10.264,7.339,9.926,7.511,9.6C7.534,9.555,7.52,9.528,7.495,9.494c-0.252-0.346-0.504-0.693-0.756-1.04
	c-0.015-0.021-0.03-0.043-0.049-0.07c0.552-0.543,1.102-1.085,1.656-1.63C8.475,6.844,8.6,6.932,8.725,7.02
	C8.967,7.191,9.21,7.361,9.451,7.533C9.49,7.561,9.521,7.568,9.565,7.544c0.315-0.169,0.64-0.314,0.985-0.413
	c0.027-0.008,0.057-0.053,0.063-0.086c0.073-0.426,0.143-0.853,0.213-1.28c0.003-0.021,0.01-0.041,0.019-0.073
	c0.023,0,0.048,0,0.074,0c0.717,0,1.434,0.001,2.151-0.001c0.067,0,0.088,0.019,0.099,0.086c0.065,0.412,0.136,0.823,0.204,1.234
	c0.009,0.058,0.03,0.092,0.091,0.11c0.329,0.096,0.641,0.231,0.943,0.392c0.053,0.028,0.091,0.028,0.142-0.008
	c0.347-0.244,0.695-0.483,1.043-0.724c0.024-0.017,0.048-0.032,0.077-0.051c0.544,0.552,1.086,1.102,1.631,1.656
	c-0.072,0.101-0.142,0.197-0.211,0.293c-0.195,0.27-0.391,0.541-0.587,0.809c-0.033,0.044-0.036,0.078-0.008,0.127
	c0.171,0.303,0.303,0.622,0.396,0.958c0.015,0.055,0.04,0.078,0.094,0.086c0.394,0.064,0.787,0.13,1.18,0.196
	c0.057,0.01,0.114,0.021,0.178,0.033c0,0.03,0,0.056,0,0.082c0,0.714-0.001,1.43,0.002,2.145c0,0.068-0.017,0.093-0.089,0.103
	c-0.419,0.059-0.836,0.123-1.254,0.188c-0.027,0.005-0.065,0.036-0.073,0.063c-0.099,0.341-0.24,0.665-0.409,0.978
	c-0.024,0.047-0.021,0.08,0.011,0.122c0.256,0.356,0.509,0.716,0.766,1.072c0.029,0.04,0.034,0.067-0.006,0.106
	c-0.516,0.514-1.029,1.028-1.545,1.543C15.73,17.302,15.716,17.313,15.698,17.327z M9.558,12.048
	c-0.01,1.298,1.032,2.427,2.399,2.439c1.388,0.014,2.485-1.078,2.483-2.447c-0.002-1.339-1.112-2.448-2.448-2.445
	C10.654,9.597,9.557,10.703,9.558,12.048z"/>
    </svg>
);

export default SVG;
