import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';

import { Croppie } from "croppie";

import ReactTooltip from 'react-tooltip';

import ImagePreview from '../ImagePreview';

import SaveIcon from '../../../../static/images/svg/save.svg';
import UploadIcon from '../../../../static/images/svg/upload.svg';

import './style.css';


const UploadMessage = styled.div`
    text-align: center;
    font-size: 22px;
    cursor: pointer;

    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 1rem;

    padding: 28px 0;
    background: rgba(120, 155, 236, 0.06);
    border: 2px dashed #d5dee8;
    border-radius: 14px;
    color: #bbc2d4;
    transition: all 0.2s ease;
`;

const ImageAction = styled.div`
    width: ${props => props.width && Math.round(props.width / 4)}px;
    height: ${props => props.height && Math.round(props.height / 4)}px;
    min-height: ${props => props.height && Math.round(props.height / 4)}px;
    background-color: #d8dde3;
    border: 1px solid #c3c8ce;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DEFAULT_MAX_IMAGE_AMOUNT = 4;
const DEFAULT_CROP_WIDTH = 340;
const DEFAULT_CROP_HEIGHT = 570;
const DEFAULT_CROP_BOUNDARY_HEIGHT = 590;

let c = null;

class ImageUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFileUploaded: false,
            currentImageAmount: 0,
            isOnUploadStep: true,
            selectedImage: 0,
            uploadedImageData: [],
            croppedImageData: [],
        };

        this.croppieOptions = {
            showZoomer: true,
            enableOrientation: true,
            // mouseWheelZoom: 'true',
            viewport: {
                width: this.props.width || DEFAULT_CROP_WIDTH,
                height: this.props.height || DEFAULT_CROP_HEIGHT,
                type: "square"
            },
            boundary: {
                height: (this.props.boundaryHeight && `${this.props.boundaryHeight}px`) || `${DEFAULT_CROP_BOUNDARY_HEIGHT}px`
            }
        };

        this.file = React.createRef();
        this.croppie = React.createRef();
        this.img = React.createRef();
    }

    componentDidMount() {
        // c = new Croppie(this.croppie.current, croppieOptions);
    }

    onFileUpload = e => {
        if (!this.state.isFileUploaded)
            c = new Croppie(this.croppie.current, this.croppieOptions);

        const fileInput = this.file.current;
        const file = fileInput.files[0];

        if (file) {
            const { currentImageAmount } = this.state;
            // const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT } = this.props;
            const newImageSelection = currentImageAmount > 0 ? currentImageAmount : 0;

            this.setState({ isFileUploaded: true, isOnUploadStep: false, selectedImage: newImageSelection }, () => {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => {
                    c.bind({ url: reader.result });

                    const { uploadedImageData } = this.state;
                    uploadedImageData[newImageSelection] = reader.result;

                    this.setState({ uploadedImageData });
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };

                fileInput.value = '';
            });
        }
    };

    onSave = e => {
        c.result("blob").then(base64 => {
            const { currentImageAmount, selectedImage, croppedImageData } = this.state;
            const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT } = this.props;

            let imageAmount = currentImageAmount + 1 < maxImageAmount ? currentImageAmount + 1 : maxImageAmount;

            if (selectedImage != currentImageAmount)
                imageAmount = currentImageAmount;
            
            const images = croppedImageData;
            images[selectedImage] = base64;
            this.setState(
                { croppedImageData: images, currentImageAmount: imageAmount, isOnUploadStep: true }, () => (this.props.handleUpdate(croppedImageData)) 
            );
        });
    };

    cropImage = (imageNum) => {
        const { uploadedImageData, selectedImage, currentImageAmount } = this.state;
        
        if (!!uploadedImageData[imageNum] && ((currentImageAmount == 1 && selectedImage == 0) || selectedImage != imageNum)) {
            c.bind({ url: uploadedImageData[imageNum], zoom: 0 });
            this.setState({ selectedImage: imageNum, isOnUploadStep: true });
        }
    }

    removeImage = (imageNum) => {
        const { croppedImageData, uploadedImageData, currentImageAmount, selectedImage } = this.state;
        const newImageAmount = currentImageAmount - 1 >= 0 ? currentImageAmount - 1 : 0;
        const newSelectedImage = selectedImage > imageNum ? selectedImage - 1 : selectedImage;

        uploadedImageData.splice(imageNum, 1);
        croppedImageData.splice(imageNum, 1);

        this.setState({ croppedImageData, uploadedImageData, currentImageAmount: newImageAmount, selectedImage: newSelectedImage });
    }

    // dir will be right == false ==== left
    moveImage = (imageNum, right) => {
        const { croppedImageData, uploadedImageData, selectedImage } = this.state;
        const positionModifier = right ? 1 : -1;
        const switchNum = imageNum + positionModifier;

        let newSelectedImage = selectedImage;

        // if selected image is the one we're moving
        if (selectedImage === imageNum)
            newSelectedImage = switchNum;
        // if selected image is the one we're moving to
        else if (selectedImage === switchNum)
            newSelectedImage = imageNum;

        // switch images in array
        [croppedImageData[imageNum], croppedImageData[switchNum]] = [croppedImageData[switchNum], croppedImageData[imageNum]];
        [uploadedImageData[imageNum], uploadedImageData[switchNum]] = [uploadedImageData[switchNum], uploadedImageData[imageNum]];

        // update state
        this.setState({ croppedImageData, uploadedImageData, selectedImage: newSelectedImage })
    }


    renderImages = () => {
        const { currentImageAmount, isOnUploadStep, selectedImage } = this.state;
        const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT, width, height } = this.props;
        const imagePreviews = [];

        for (let i = 0; i < currentImageAmount; i++)
            imagePreviews.push(<ImagePreview 
                                    key={i}
                                    imageId={i}
                                    cropWidth={width || DEFAULT_CROP_WIDTH}
                                    cropHeight={height || DEFAULT_CROP_HEIGHT}
                                    currentImageAmount={currentImageAmount}
                                    maxImageAmount={maxImageAmount}
                                    image={this.state.croppedImageData[i]}
                                    handleClick={e => this.cropImage(i)}
                                    handleRemove={e => this.removeImage(i)}
                                    handleSave={this.onSave}
                                    handleMove={this.moveImage}
                                    allowDelete={currentImageAmount > 1}
                                    selected={selectedImage === i} />
                                );

        if (currentImageAmount < maxImageAmount)
            if (isOnUploadStep)
                imagePreviews.push((
                <>
                    <ImageAction key="upload" data-tip data-for="upload-image" width={width || DEFAULT_CROP_WIDTH} height={height || DEFAULT_CROP_HEIGHT} onClick={e => this.file.current.click()} ><img src={UploadIcon} /></ImageAction>
                    <ReactTooltip id='upload-image' effect='solid'>
                        <span>Upload Image</span>
                    </ReactTooltip>
                </>
            ));
            else
                imagePreviews.push((
                <>
                    <ImageAction key="save" data-tip data-for="save-initial-image" width={width || DEFAULT_CROP_WIDTH} height={height || DEFAULT_CROP_HEIGHT} onClick={this.onSave} ><img src={SaveIcon} /></ImageAction>
                    <ReactTooltip id='save-initial-image' effect='solid'>
                        <span>Save Image</span>
                    </ReactTooltip>
                </>
            ));
            
        return imagePreviews;
    }

    render() {
        const { currentImageAmount, isFileUploaded } = this.state;
        const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT } = this.props;

        return (
            <div>
                { !isFileUploaded &&
                    (
                        <UploadMessage onClick={e => this.file.current.click()}>
                            Upload Image
                        </UploadMessage>
                    )
                }
                <div ref={this.croppie} />
                <input
                    type="file"
                    id="files"
                    style={{
                        visibility: 'hidden',
                    }}
                    ref={this.file}
                    onChange={this.onFileUpload}
                />

                { isFileUploaded && 
                    (
                        <div className="image-preview-list">
                            {this.renderImages()}
                        </div>
                    )
                }
            </div>
        );
    }
}

export default ImageUploader;