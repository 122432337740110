import { retrieveHomeDashboard, retrieveFollowingDashboard } from '../services/DashboardService';
import { getNewestPosts } from '../services/PostService';

export const REQUEST_HOME_POSTS = 'REQUEST_POSTS';
export const RECEIVE_HOME_POSTS = 'RECEIVE_POSTS';

export const REQUEST_FOLLOWING_POSTS = 'REQUEST_FOLLOWING_POSTS';
export const RECEIVE_FOLLOWING_POSTS = 'RECEIVE_FOLLOWING_POSTS';

export const REQUEST_EXPLORE_POSTS = 'REQUEST_EXPLORE_POSTS';
export const RECEIVE_EXPLORE_POSTS = 'RECEIVE_EXPLORE_POSTS';

const requestHomePosts = (getNsfwPosts) => ({
    type: REQUEST_HOME_POSTS,
    getNsfwPosts
});

const receiveHomePosts = (posts) => ({
    type: RECEIVE_HOME_POSTS,
    posts
});

const requestFollowingPosts = (pageNumber, getNsfwPosts) => ({
    type: REQUEST_FOLLOWING_POSTS,
    pageNumber,
    getNsfwPosts
});

const receiveFollowingPosts = (pageNumber, posts, pageCount) => ({
    type: RECEIVE_FOLLOWING_POSTS,
    pageNumber,
    posts,
    pageCount
});

const requestExplorePosts = (pageNumber, category, getNsfwPosts) => ({
    type: REQUEST_EXPLORE_POSTS,
    pageNumber,
    category,
    getNsfwPosts
});

const receiveExplorePosts = (pageNumber, category, posts, pageCount) => ({
    type: RECEIVE_EXPLORE_POSTS,
    pageNumber,
    category,
    posts,
    pageCount
});

export const requestHomePostFeed = (getNsfwPosts) => (
    dispatch => {
        dispatch(requestHomePosts(getNsfwPosts))
        return retrieveHomeDashboard(getNsfwPosts)
            .then(result => dispatch(receiveHomePosts(result.posts)));
    }
);

export const requestFollowingPostFeed = (pageNumber, getNsfwPosts) => (
    dispatch => {
        dispatch(requestFollowingPosts(pageNumber, getNsfwPosts))
        return retrieveFollowingDashboard(pageNumber, getNsfwPosts)
            .then(result => dispatch(receiveFollowingPosts(pageNumber, result.posts, result.pageCount)));
    }
);

export const requestExplorePostFeed = (pageNumber, category, getNsfwPosts) => (
    dispatch => {
        dispatch(requestExplorePosts(pageNumber, category, getNsfwPosts))
        return getNewestPosts(pageNumber, category, getNsfwPosts)
            .then(result => dispatch(receiveExplorePosts(pageNumber, category, result.posts, result.pageCount)));
    }
);