import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round" 
        >
            <path fill={fill} stroke={stroke} d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle fill={fill} stroke={stroke} cx="9" cy="7" r="4"></circle>
            <path fill={fill} stroke={stroke} d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path fill={fill} stroke={stroke} d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
    );

export default SVG;