import React, { useState } from 'react';
import styled from 'styled-components';

const StyledGameItemToggleContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    padding: 10px;
`;

const GameItemToggleList = props => {
        return (
        <StyledGameItemToggleContainer>
            { props.children }
        </StyledGameItemToggleContainer>
    );
};

export default GameItemToggleList;