import { connect } from 'react-redux'
import Following from '../pages/following';

import { requestFollowingPostFeed } from '../actions/feedActions';

const mapStateToProps = (state, ownProps) => {
    const { currentUser, postFeeds } = state;
    const feed = postFeeds.following || { posts: [], pageCount: 0, currentPage: 0 };

    return {
        nsfw: currentUser.settingViewNSFW,
        posts: feed.posts,
        pageCount: feed.pageCount,
        currentPage: feed.currentPage
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getPosts: nsfw => dispatch(requestFollowingPostFeed(nsfw))
})

export default connect(mapStateToProps, mapDispatchToProps)(Following);