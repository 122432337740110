import { combineReducers } from 'redux'
import loginReducer from './loginReducer';
import userReducer from './userReducer';
import notificationsReducer from './notificationReducer';
import postReducer from './postReducer';
import settingsReducer from './settingsReducer';
import registrationReducer from './registrationReducer';
import loadoutReducer from './loadoutReducer';
import feedReducer from './feedReducer';
import sidebarReducer from './sidebarReducer';

export default combineReducers({
  currentUser: loginReducer,
  currentProfile: userReducer,
  currentRegistration: registrationReducer,
  notifications: notificationsReducer,
  currentPost: postReducer,
  currentSettings: settingsReducer,
  currentLoadouts: loadoutReducer,
  postFeeds: feedReducer,
  sidebar: sidebarReducer
});