import ColorConvert from 'color-convert';

const APBHSL_MAX_VALUES = [31, 7, 15];
const HSL_MAX_VALUES = [360, 100, 100];

const HSL = {
    HUE: 0,
    SAT: 1,
    LUM: 2
};

export const convertRGBToAPBHSL = rgbArray => convertHexToAPBHSL(ColorConvert.rgb.hex(rgbArray));

export const convertHexToAPBHSL = hexCode => {
    const convertedHSL = ColorConvert.hex.hsl(hexCode);
    let convertedAPBHSL = [];

    convertedHSL.forEach((hslValue, index) => {
        const apbhslValue = ((hslValue / HSL_MAX_VALUES[index]) * APBHSL_MAX_VALUES[index]);

        convertedAPBHSL.push(Math.round(apbhslValue));
    });

    return convertedAPBHSL;
};

export const convertAPBHSLToHex = apbHSL => {
    let convertedHSL = [];

    apbHSL.forEach((apbHSLValue, index) => {
        const hslValue = ((apbHSLValue / APBHSL_MAX_VALUES[index]) * HSL_MAX_VALUES[index]);

        convertedHSL.push(Math.round(hslValue));
    });

    const convertedHex = ColorConvert.hsl.hex(convertedHSL);

    return convertedHex;
};