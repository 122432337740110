import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={width}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
    >
        <path fill={fill} stroke={stroke} d="M14.038 3.801l.564 2.318a1 1 0 00.255.461l4.86 4.994a1 1 0 01.283.697v6.163a1 1 0 01-.486.857l-2.878 1.728L15 21v-2.623a1 1 0 00-.248-.66l-1.36-1.55a1 1 0 01-.25-.66v-.78a.457.457 0 00-.79-.312L9.27 17.712a1 1 0 00-.27.683V21l-1.98-.048-2.488-1.315A1 1 0 014 18.753v-4.81a1 1 0 01.244-.655l1.641-1.897a1 1 0 011.41-.102 1 1 0 01.056.051L9 13l.027-5.708a1 1 0 01.269-.678l3.04-3.258a1 1 0 011.702.445z" fillRule="evenodd"></path>
    </svg>
);

export default SVG;