import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import queryString from 'query-string';

import Icon from '../../components/icons';
import { GeneratePostGrid, RegeneratePostGrid } from '../../components/posts/DynamicPostGrid';
import StickyContentHeader from '../../components/fullscreenPageLayout/contentStickyHeader';

import { postCategories } from '../../util/enums';

import { getNewestPosts } from '../../services/PostService';

const StyledImageContainer = styled.div`
  padding: 10px;
`;

const StyledPaginateContainer = styled.div`
  padding-bottom: 3rem;
`;

const Explore = props => {

  useEffect(() => {
    const page = queryString.parse(props.location.search).page || 1;
    let category = queryString.parse(props.location.search).category || postCategories.SYMBOL;

    // trying to add multiple categories breaks the call, lets use the first one
    if (Array.isArray(category))
      category = category[0];

    // loadPosts(page, category);
    props.getPosts(page, category, props.nsfw)
      .then(() => RegeneratePostGrid());
  }, [])

  const handlePageClick = data => {
    // Normalize from page 0 to 1
    let selected = data.selected + 1;

    props.history.push(`${window.location.pathname}?category=${props.category}&page=${selected}`);
  };

  return (
    <>
      <StickyContentHeader>
        Explore
      </StickyContentHeader>

      <StyledImageContainer>
        {GeneratePostGrid(props.posts, true)}
      </StyledImageContainer>

      { props.pageCount > 1 &&
        <StyledPaginateContainer>
          <ReactPaginate
            forcePage={props.currentPage}
            previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
            nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
            breakClassName={'break-me'}
            pageCount={props.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </StyledPaginateContainer>
      }
    </>
  );
};

export default Explore;
