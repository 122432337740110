import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1.5",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round" 
        >
            <polyline fill={fill} stroke={stroke} points="6 9 12 15 18 9"></polyline>
        </svg>
    );

export default SVG;