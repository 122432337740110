import React from 'react';

class RefreshSession extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.props.refreshUserData(this.props.userId)
                .then(result => {
                    this.props.isLoggedIn ? this.props.openSocketConnection() : this.props.closeSocketConnection();
                })
        }
    }

    render() {
        return this.props.children
    }
}

export default RefreshSession;
