import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import queryString from 'query-string';
import { postCategories } from '../../../util/enums';

import SidebarItem from '../../sidebar/SidebarItem';
import Icon from '../../icons';

import './style.css';

const StyledSidebar = styled.div.attrs(props => ({
}))`
    background: var(--color-background-sidebar);
    color: var(--color-text-sidebar);
`;

const StyledStaticSidebarContainer = styled.div.attrs(props => ({
}))`
    display: block;
`;

const StyledStaticSidebar = styled.div.attrs(props => ({
}))`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const StyledSidebarGroup = styled.div`
    border-bottom: 1px solid var(--color-border-sidebar);
`;

const StyledSidebarItemName = styled.div`
    ${props => props.icon ? 'margin-left: 0.8rem;' : null}
`;

const StyledMobileContainer = styled.div`
    @media(min-width: 760px) {
        display: none;
    }
`;

const isSidebarItemActive = itemName => {
    switch (itemName) {
        case 'home':
            return window.location.pathname === '/';
        case 'popular':
            return window.location.pathname === '/popular';
        case 'following':
            return window.location.pathname === '/following' || window.location.pathname === '/following/';
        case 'symbols':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.SYMBOL;
        case 'clothing':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.CLOTHING;
        case 'vehicles':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.VEHICLE;
        case 'character':
            return (window.location.pathname === '/explore' || window.location.pathname === '/explore/') && queryString.parse(window.location.search).category == postCategories.CHARACTER_CUSTOMIZATION;
        default:
            return false;
    }
}

const renderSidebar = props => {

    const closeMenu = () => props.closeSidebarMenu();
    const location = useLocation();

    useEffect(() => {

        const cleanup = () => {
            closeMenu();
        }

        window.addEventListener('beforeunload', cleanup);

        return () => {
            closeMenu();
            window.removeEventListener('beforeunload', cleanup);
        }
    }, [location]);

    return (
        <StyledSidebar>
            <StyledStaticSidebarContainer>
                <StyledStaticSidebar>
                    <StyledSidebarGroup>
                        <SidebarItem height="58px" />
                    </StyledSidebarGroup>
                    <StyledSidebarGroup>
                        <SidebarItem linkTo='/' active={isSidebarItemActive('home')} onClick={closeMenu}>
                            <Icon name='home' fill='currentColor' stroke='none' height='24' />
                            <StyledSidebarItemName icon={true}>
                                Home
                            </StyledSidebarItemName>
                        </SidebarItem>
                        {
                            props.isLoggedIn &&
                                <SidebarItem linkTo='/following' active={isSidebarItemActive('following')} onClick={closeMenu}>
                                    <Icon name='eye-sidebar' fill='currentColor' stroke='none' height='24' />
                                    <StyledSidebarItemName icon={true}>
                                        Following
                                    </StyledSidebarItemName>
                                </SidebarItem>
                        }
                    </StyledSidebarGroup>
                    <StyledSidebarGroup>
                        <SidebarItem linkTo='/explore?category=0' active={isSidebarItemActive('symbols')} onClick={closeMenu}>
                            <Icon name='symbol-icon' fill='currentColor' stroke='none' height='24' />
                            <StyledSidebarItemName icon={true}>
                                Symbols
                            </StyledSidebarItemName>
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=1' active={isSidebarItemActive('clothing')} onClick={closeMenu}>
                            <Icon name='clothing-icon' fill='currentColor' stroke='none' height='24' />
                            <StyledSidebarItemName icon={true}>
                                Clothing
                            </StyledSidebarItemName>
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=2' active={isSidebarItemActive('vehicles')} onClick={closeMenu}>
                            <Icon name='vehicle-icon' fill='currentColor' stroke='none' height='24' />
                            <StyledSidebarItemName icon={true}>
                                Vehicles
                            </StyledSidebarItemName>
                        </SidebarItem>
                        <SidebarItem linkTo='/explore?category=3' active={isSidebarItemActive('character')} onClick={closeMenu}>
                            <Icon name='char-customization-icon' fill='currentColor' stroke='none' height='24' />
                            <StyledSidebarItemName icon={true}>
                                Character
                            </StyledSidebarItemName>
                        </SidebarItem>
                        {/* <SidebarItem linkTo='/explore?category=4'>
                            <Icon name='music-studio-icon' fill='currentColor' stroke='none' height='24' />
                        </SidebarItem> */}
                    </StyledSidebarGroup>

                    <StyledMobileContainer>
                        <StyledSidebarGroup>
                            <SidebarItem linkTo='/loadout' onClick={closeMenu}>
                                <StyledSidebarItemName icon={false}>
                                    Random Loadout Gen.
                                </StyledSidebarItemName>
                            </SidebarItem>
                            <SidebarItem linkTo='/colorconverter' onClick={closeMenu}>
                                <StyledSidebarItemName icon={false}>
                                    APB Color Converter
                                </StyledSidebarItemName>
                            </SidebarItem>
                            <a href="https://forums.gamersfirst.com/topic/13865-apbreloaded-streaming-starter-pack/" target="_blank">
                                <SidebarItem onClick={closeMenu}>
                                    <StyledSidebarItemName icon={false}>
                                        Streaming Resources
                                    </StyledSidebarItemName>
                                </SidebarItem>
                            </a>
                        </StyledSidebarGroup>
                        { props.isLoggedIn ?
                            <>
                                <StyledSidebarGroup>
                                    <SidebarItem linkTo={`/profile/${props.userUsername}`} onClick={closeMenu}>
                                        <img src={props.userAvatar} width="24" height="24" />
                                        <StyledSidebarItemName icon={true}>
                                            My Profile
                                        </StyledSidebarItemName>
                                    </SidebarItem>
                                    <SidebarItem linkTo={`/settings`} onClick={closeMenu}>
                                        <Icon name='cog' width='34' height='34' />
                                        <StyledSidebarItemName icon={true}>
                                            Settings
                                        </StyledSidebarItemName>
                                    </SidebarItem>
                                    <SidebarItem linkTo='/notifications' onClick={closeMenu}>
                                        <Icon name='bell' fill='currentColor' stroke='none' height='24' />
                                        <StyledSidebarItemName icon={true}>
                                            Notifications{props.unreadNotificationCount > 0 ? ` (${props.unreadNotificationCount})` : null}
                                        </StyledSidebarItemName>
                                    </SidebarItem>
                                </StyledSidebarGroup>
                                <StyledSidebarGroup>
                                    <SidebarItem linkTo='/' onClick={props.logout}>
                                        <StyledSidebarItemName icon={false}>
                                            Logout
                                        </StyledSidebarItemName>
                                    </SidebarItem>
                                </StyledSidebarGroup>
                            </>
                            :
                            <StyledSidebarGroup>
                                <SidebarItem linkTo='/join' onClick={closeMenu}>
                                    <StyledSidebarItemName icon={false}>
                                        Join
                                    </StyledSidebarItemName>
                                </SidebarItem>
                                <SidebarItem linkTo='/login' onClick={closeMenu}>
                                    <StyledSidebarItemName icon={false}>
                                        Login
                                    </StyledSidebarItemName>
                                </SidebarItem>
                            </StyledSidebarGroup>
                        }
                    </StyledMobileContainer>

                </StyledStaticSidebar>
            </StyledStaticSidebarContainer>
        </StyledSidebar>
    )
}

export default props => renderSidebar(props);
