import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavItem = styled.div.attrs(props => ({
    buttonPadding: !!props.link ? '0' : '0.5rem 1rem',
    linkPadding: !!props.link ? '0.5rem 1rem' : '0',
    hoverColor: props.hoverColor ? props.hoverColor : 'var(--color-text-top-nav-hover)',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: ${props => props.buttonPadding};
    position: relative;

    font-size: .9rem;
    font-weight: 600;
    background: transparent;

    transition: all 0.1s ease-in-out;

    :hover {
        color: ${props => props.hoverColor};
    }

    :active {
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: ${props => props.linkPadding};
    }

    a:hover {
        text-decoration: none;
    }
`;

const renderNavItem = props => {
    if (props.linkTo)
        return (
            <StyledNavItem link={!!props.linkTo}>
                <Link to={props.linkTo}>
                    {props.children}
                </Link>
            </StyledNavItem>
        );
    else if (props.onClick)
        return (
            <StyledNavItem link={!!props.linkTo} onClick={props.onClick}>
                {props.children}
            </StyledNavItem>
        );
    else
        return (
            <StyledNavItem link={!!props.linkTo}>
                {props.children}
            </StyledNavItem>
        );
}

export default props => renderNavItem(props);
        