import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { Provider } from 'react-redux';
import configureStore from '../util/configureStore';

import Session from '../containers/refreshSession';

import AuthRoute from '../containers/authRoute';
import ModRoute from '../containers/modRoute';
import Login from '../containers/login';
import Register from '../containers/register';
import DiscordRegister from '../containers/discordRegister';
import EditProfile from '../containers/editProfile';
import Home from '../containers/home';
import Profile from '../containers/profile';
import Post from '../containers/post';
import NewPost from '../containers/newPost';
import EditPost from '../containers/editPost';
import Notifications from '../containers/notifications';
import Explore from '../containers/explore';
import Search from '../containers/search';
import Verified from '../containers/verify';
import Error404 from './error/404';
import BanUser from '../pages/ban';
import PrivacyPolicy from '../pages/privacy-policy';
import PasswordResetRequest from '../pages/reset-password';
import PasswordReset from '../pages/reset-password/reset';
import DiscordRedirect from '../pages/discord';
import LoadoutRandomizer from '../containers/loadout';
import ManageLoadoutInventories from '../containers/manageLoadout';
import ColorConverter from '../pages/color-converter';
import Following from '../containers/following';

import styled from 'styled-components';

import Navbar from '../containers/navigation';
import LayoutContainer from '../components/layout/LayoutContainer';
import Sidebar from '../containers/staticSidebar';
import ContentContainer from '../components/layout/ContentContainer';
import Footer from '../components/footer';
import SlidingSidebarMenu from '../containers/slidingSidebar';

import useGoogleAnalytics from '../util/useGoogleAnalytics';

import ReactTooltip from 'react-tooltip';

import './index.css';

const store = configureStore;

const StyledContentContainer = styled.div`
  ${props =>
    props.active ? (
        `min-height: 100vh; /* will cover the 100% of viewport */
        /*overflow: hidden;*/
        display: block;
        position: relative;
        padding-bottom: 200px; /* height of the footer */`
    ) : ''
  }
`;

const showHeaderFooter = () =>  window.location.pathname !== '/login'
                                && window.location.pathname !== '/login/'
                                && window.location.pathname !== '/join'
                                && window.location.pathname !== '/join/'
                                && window.location.pathname !== '/join/discord'
                                && window.location.pathname !== '/join/discord/'
                                && window.location.pathname !== '/discord/test'
                                && window.location.pathname !== '/discord/test/'
                                && window.location.pathname !== '/loadout'
                                && window.location.pathname !== '/loadout/'
                                && window.location.pathname !== '/loadout/manage'
                                && window.location.pathname !== '/loadout/manage/'
                                && window.location.pathname !== '/apbhsl'
                                && window.location.pathname !== '/apbhsl/'
                                && window.location.pathname !== '/colorconverter'
                                && window.location.pathname !== '/colorconverter/'
                                && window.location.pathname !== '/reset-password'
                                && window.location.pathname !== '/reset-password/'
                                && window.location.pathname.split('/').splice(0, 3).join('/') !== '/reset-password/reset'
                                ;
const showSidebar = () => window.location.pathname === '/'
                          || window.location.pathname === '/following'
                          || window.location.pathname === '/following/'
                          || window.location.pathname === '/explore'
                          || window.location.pathname === '/explore/'

const App = () => {
  useGoogleAnalytics();
  
  return (
  <Provider store={store}>
    <Session>
      <SlidingSidebarMenu />
      <StyledContentContainer active={showHeaderFooter()}>
        <ReactTooltip id='__tooltip' getContent={(dataTip) => `${dataTip}`} />
        {showHeaderFooter() && <Navbar />}
        <LayoutContainer hasPadding={showHeaderFooter()}>
            {showSidebar() && <Sidebar />}
            <ContentContainer>
              <Switch>
                <Route exact path='/' component={withRouter(Home)} />
                <Route exact path='/login' component={withRouter(Login)} />
                <Route exact path='/join' component={withRouter(Register)} />
                <Route exact path='/join/discord' component={withRouter(DiscordRegister)} />
                <Route exact path='/reset-password' component={withRouter(PasswordResetRequest)} />
                <Route exact path='/reset-password/reset/:token' component={withRouter(PasswordReset)} />
                <Route exact path='/privacy-policy' component={withRouter(PrivacyPolicy)} />
                <ModRoute exact path='/user/:user_id/edit' component={withRouter(EditProfile)} />
                <ModRoute exact path='/user/:user_id/ban' component={withRouter(BanUser)} />
                <AuthRoute exact path='/settings' component={withRouter(EditProfile)} />
                <Route exact path='/profile/:user_id' component={withRouter(Profile)} />
                <AuthRoute exact path='/post/create' component={withRouter(NewPost)} />
                <Route exact path='/post/:post_id' component={withRouter(Post)} />
                <AuthRoute exact path='/post/:post_id/edit' component={withRouter(EditPost)} />
                <Route exact path='/verify/:token' component={withRouter(Verified)} />
                <AuthRoute exact path='/notifications' component={withRouter(Notifications)} />
                <AuthRoute exact path='/following' component={withRouter(Following)} />
                <Route exact path='/explore' component={withRouter(Explore)} />
                <Route exact path='/search' component={withRouter(Search)} />
                <Route exact path='/auth/discord' component={withRouter(DiscordRedirect)} />
                <Route exact path='/apbhsl'>
                  <Redirect to="/colorconverter" />
                </Route>
                <Route exact path='/colorconverter' component={ColorConverter} />
                <Route exact path='/loadout' component={LoadoutRandomizer} />
                <Route exact path='/loadout/manage' component={ManageLoadoutInventories} />
                <Route exact path='/404' component={Error404} />
                <Route component={Error404} />
              </Switch>
            </ContentContainer>
        </LayoutContainer>
        {showHeaderFooter() && <Footer />}
      </StyledContentContainer>
    </Session>
  </Provider>
)};

export default App;
