import React from 'react';
import styled from 'styled-components';

import ModificationIcon from '../../../gameObject/modificationIcon';

const StyledGameItemWeapon = styled.div`
    display: flex;
    flex: 1;

    .item-icon {
        background: #27282c;
        border: 2px #1a1a1a solid;
        padding: 0.2rem;
        margin-right: 0.5rem;
        border-radius: 2px;

        background: #646464;
        background: -moz-linear-gradient(top, #000000 0%, #3e3e3e 49%, #2c2c2c 50%, #282828 100%);
        background: -webkit-linear-gradient(top, #000000 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);
        background: linear-gradient(to bottom, #000000 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);

        align-self: flex-end;
    }

    .item-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .item-info .item-name {
        color: #1a1a1a;
        font-weight: 600;
    }

    .item-info .item-sapbdb {
        font-size: 12px;
        margin-bottom: 4px;
        word-break: break-word;
    }

    .item-info .item-mods {
        display: flex;
        flex-wrap: wrap;
    }

    .item-info .item-mods .modification {
        background: #000;
    }
`;

const StyledGameItemWeaponModification = styled.div`
    background: #000000;
    margin-right: .2rem;
    border: 1px solid #000;
`;

const renderSearchResultModifications = (modifications, modificationsVacant) => {
    const modificationsArray = modifications.map((mod, index) =>
        <StyledGameItemWeaponModification key={`mod${index}`}>
            <ModificationIcon width={30} height={30} icon_url={mod.icon_url} sdisplayname={mod.sdisplayname} />
        </StyledGameItemWeaponModification>
    );

    for (let i = 0; i < modificationsVacant; i++) {
        modificationsArray.push(
            <StyledGameItemWeaponModification key={`vacant${i}`}>
                <ModificationIcon width={30} height={30} icon_url={'https://legacy.apbdb.com/static/img/APBMenus_Art/ItemIcons/Item_Vacant.png'} sdisplayname='Modification Vacant' />
            </StyledGameItemWeaponModification>
        )
    }

    const lockedModificationSlots = 3 - modificationsArray.length;

    for (let i = 0; i < lockedModificationSlots; i++) {
        modificationsArray.push(
            <StyledGameItemWeaponModification key={`locked${i}`}>
                <ModificationIcon width={30} height={30} icon_url={'https://legacy.apbdb.com/static/img/APBMenus_Art/ItemIcons/Item_None.png'} sdisplayname='Modification None' />
            </StyledGameItemWeaponModification>
        )
    }

    return modificationsArray;
}

const Weapon = props => {

    const { sdisplayname, sapbdb, icon_url, modifications, modificationsVacant, onClick } = props;

    return (
        <StyledGameItemWeapon onClick={onClick}>
            <img className="item-icon" src={icon_url} width={54} height={54} onError={(e) => { e.target.src = 'https://legacy.apbdb.com/static/img/general/no_picture.png' }} title={sapbdb} />
            <div className="item-info">
                <span className="item-name">{sdisplayname}</span>
                <span className="item-sapbdb">{sapbdb}</span>
                <div className="item-mods">
                    {renderSearchResultModifications(modifications, modificationsVacant)}
                </div>
            </div>
        </StyledGameItemWeapon>
    );
}

export default Weapon;