import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M19.699,7.682c-0.03,0.227-0.061,0.447-0.091,0.665
		c-0.166,1.172-0.332,2.346-0.498,3.52c-0.213,1.522-0.43,3.045-0.646,4.567c-0.146,1.041-0.288,2.083-0.434,3.122
		c-0.102,0.717-0.202,1.434-0.304,2.151c-0.031,0.213-0.146,0.308-0.372,0.308c-1.584,0-3.167-0.003-4.752-0.003
		c-1.687,0-3.371,0.003-5.056,0.003c-0.307,0-0.612,0-0.918-0.003c-0.218-0.004-0.325-0.092-0.356-0.309
		c-0.133-0.937-0.263-1.874-0.396-2.811c-0.162-1.137-0.328-2.273-0.486-3.406c-0.168-1.198-0.33-2.392-0.498-3.586
		C4.76,10.936,4.62,9.976,4.483,9.015C4.42,8.575,4.363,8.135,4.301,7.682C9.428,7.682,14.551,7.682,19.699,7.682z M12.622,14.856
		c-0.002,0-0.004,0-0.005,0c0-1.403,0.001-2.808-0.002-4.211c0-0.095-0.007-0.196-0.037-0.284c-0.097-0.284-0.383-0.441-0.682-0.391
		c-0.299,0.049-0.519,0.306-0.521,0.619c0,0.55,0.007,1.098,0.007,1.646c0.002,1.062,0.002,2.121,0,3.183
		c0,0.853-0.007,1.709-0.007,2.564c0,0.375,0.002,0.754,0.009,1.13c0.005,0.362,0.306,0.636,0.658,0.608
		c0.345-0.026,0.579-0.287,0.581-0.652C12.622,17.664,12.622,16.261,12.622,14.856z M16.522,10.65
		c0.014-0.381-0.229-0.658-0.588-0.678c-0.342-0.017-0.616,0.237-0.646,0.599c-0.037,0.417-0.071,0.835-0.108,1.251
		c-0.121,1.391-0.243,2.781-0.365,4.171c-0.088,1.005-0.172,2.013-0.264,3.018c-0.03,0.349,0.203,0.653,0.548,0.69
		c0.349,0.04,0.656-0.207,0.69-0.562c0.084-0.951,0.166-1.901,0.25-2.853c0.092-1.041,0.183-2.08,0.271-3.118
		C16.381,12.33,16.452,11.491,16.522,10.65z M9.452,19.014c-0.03-0.324-0.068-0.689-0.099-1.059
		c-0.072-0.822-0.139-1.644-0.21-2.466c-0.115-1.322-0.231-2.646-0.347-3.971c-0.027-0.315-0.05-0.634-0.082-0.951
		c-0.037-0.372-0.33-0.617-0.686-0.585c-0.341,0.03-0.589,0.328-0.559,0.683c0.094,1.106,0.193,2.212,0.289,3.319
		c0.097,1.109,0.192,2.219,0.29,3.332c0.054,0.612,0.106,1.225,0.171,1.836c0.037,0.355,0.325,0.575,0.689,0.549
		C9.22,19.677,9.46,19.393,9.452,19.014z"/>
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M3.593,6.792C3.591,6.755,3.588,6.726,3.588,6.697
		C3.585,6.279,3.582,5.859,3.583,5.44c0.002-0.727,0.566-1.289,1.293-1.29c1.065-0.001,2.129-0.001,3.193-0.001
		c0.05,0,0.099,0,0.166,0c0-0.093,0-0.176,0-0.259c0-1.071,0.834-1.905,1.904-1.905c1.249,0.001,2.497,0.001,3.748,0.003
		c1.025,0.001,1.867,0.846,1.874,1.872c0,0.091,0,0.184,0,0.292c0.061,0,0.108,0,0.155,0c1.063,0,2.121,0,3.181,0.001
		c0.754,0,1.313,0.557,1.315,1.309c0.003,0.394,0.003,0.787,0.003,1.181c0,0.047,0,0.091,0,0.148
		C14.808,6.792,9.208,6.792,3.593,6.792z M9.499,4.143c1.672,0,3.332,0,4.999,0c0-0.123,0.013-0.237-0.004-0.347
		c-0.04-0.336-0.284-0.544-0.622-0.548c-0.105-0.002-0.21-0.002-0.314-0.002c-1.153,0-2.305-0.001-3.457,0
		c-0.217,0-0.387,0.096-0.507,0.276C9.464,3.714,9.494,3.927,9.499,4.143z"/>
    </svg>
);

export default SVG;