import {
    CREATE_LOADOUT_INVENTORY,
    DELETE_LOADOUT_INVENTORY,
    RENAME_LOADOUT_INVENTORY,
    IMPORT_LOADOUT_INVENTORY,
    ADD_PRIMARY_WEAPON,
    REMOVE_PRIMARY_WEAPON,
    ADD_PRIMARY_WEAPON_MODIFICATION,
    REMOVE_PRIMARY_WEAPON_MODIFICATION,
    ADD_SECONDARY_WEAPON,
    REMOVE_SECONDARY_WEAPON,
    ADD_GRENADE,
    REMOVE_GRENADE,
    ADD_CHARACTER_MODIFICATION,
    REMOVE_CHARACTER_MODIFICATION,
} from '../actions/loadoutActions';

const initialLoadoutSets = [
    {
        name: "Default Profile",
        primaryWeapons: [
            {
                sdisplayname: "STAR 556",
                sapbdb: "Weapon_AssaultRifle_STAR",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_AssaultRifle_STAR.png",
                url: "https://legacy.apbdb.com/items/Weapon_AssaultRifle_STAR",
                modifications: [],
                modificationsVacant: 0,
                einfracategory: 83
            }
        ],
        primaryWeaponModifications: [],
        secondaryWeapons: [
            {
                sdisplayname: "Obeya FBW",
                sapbdb: "Weapon_Pistol_FBW",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Pistol_FBW.png",
                url: "https://legacy.apbdb.com/items/Weapon_Pistol_FBW",
                modifications: [],
                modificationsVacant: 0,
                einfracategory: 94
            }
        ],
        characterModifications: [
            {
                sdisplayname: "Clotting Agent I",
                sapbdb: "FnMod_Character_ClottingAgent1",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_ClottingAgent1.png",
                url: "https://legacy.apbdb.com/items/FnMod_Character_ClottingAgent1",
                emodifiercategory: 2
            },
            {
                sdisplayname: "Happy Landings 1",
                sapbdb: "FnMod_Character_HappyLandings1",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_HappyLandings1.png",
                url: "https://legacy.apbdb.com/items/FnMod_Character_HappyLandings1",
                emodifiercategory: 4
            },
            {
                sdisplayname: "Med Spray",
                sapbdb: "Usable_Consumable_Medikit",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_MediKit.png",
                url: "https://legacy.apbdb.com/items/Usable_Consumable_Medikit",
                emodifiercategory: 8
            },
            {
                sdisplayname: "Epinephrine Injector",
                sapbdb: "Usable_Consumable_Epinephrine",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_Epinephrine.png",
                url: "https://legacy.apbdb.com/items/Usable_Consumable_Epinephrine",
                emodifiercategory: 8
            },
            {
                sdisplayname: "Satchel Charge",
                sapbdb: "Usable_Deployable_SatchelCharge",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_SatchelCharge.png",
                url: "https://legacy.apbdb.com/items/Usable_Deployable_SatchelCharge",
                emodifiercategory: 8
            },
            {
                sdisplayname: "Resupply Box (Large)",
                sapbdb: "Usable_Deployable_FieldSupplier-Large",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_FeildSupplier_Medium.png",
                url: "https://legacy.apbdb.com/items/Usable_Deployable_FieldSupplier-Large",
                emodifiercategory: 8
            },
            {
                sdisplayname: "Kevlar Implants 1",
                sapbdb: "FnMod_Character_Kevlar1",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Kevlar1.png",
                url: "https://legacy.apbdb.com/items/FnMod_Character_Kevlar1",
                emodifiercategory: 2
            },
            {
                sdisplayname: "Field Supplier",
                sapbdb: "FnMod_Character_FieldSupplier",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_FieldSupplier.png",
                url: "https://legacy.apbdb.com/items/FnMod_Character_FieldSupplier",
                emodifiercategory: 7
            },
            {
                sdisplayname: "Boom Box",
                sapbdb: "Usable_Deployable_BoomBox",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_BoomBox.png",
                url: "https://legacy.apbdb.com/items/Usable_Deployable_BoomBox",
                emodifiercategory: 8
            }
        ],
        grenades: [
            {
                sdisplayname: "Frag Grenade",
                sapbdb: "Weapon_Grenade_Frag",
                icon_url: "https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_Frag.png",
                url: "https://legacy.apbdb.com/items/Weapon_Grenade_Frag"
            }
        ]
    }
];

const loadouts = (
    state = {
        loadoutSets: initialLoadoutSets,
    },
    action
) => {
    switch (action.type) {
        case CREATE_LOADOUT_INVENTORY:
            // if (true) to create a local scope for newState
            if (true) {
                const newState = state;

                newState.loadoutSets.push(
                    {
                        name: action.name,
                        primaryWeapons: [],
                        primaryWeaponModifications: [],
                        secondaryWeapons: [],
                        characterModifications: [],
                        grenades: [],
                    }
                );

                return Object.assign({}, state, newState);
            }
        case DELETE_LOADOUT_INVENTORY:
            if (true) {
                const newState = state;

                newState.loadoutSets.splice(action.index, 1);

                return Object.assign({}, state, newState);
            }
        case RENAME_LOADOUT_INVENTORY:
            if (true) {
                const newState = state;

                newState.loadoutSets[action.index].name = action.name;

                return Object.assign({}, state, newState);
            }
        case IMPORT_LOADOUT_INVENTORY:
            if (true) {
                const newState = state;

                newState.loadoutSets.push(
                    {
                        name: action.name,
                        primaryWeapons: action.primaryWeapons,
                        primaryWeaponModifications: action.primaryWeaponModifications,
                        secondaryWeapons: action.secondaryWeapons,
                        characterModifications: action.characterModifications,
                        grenades: action.grenades,
                    }
                );

                return Object.assign({}, state, newState);
            }
        case ADD_PRIMARY_WEAPON: 
            if (state.loadoutSets[action.profileIndex].primaryWeapons.filter(weapon => weapon.sapbdb === action.sapbdb).length <= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].primaryWeapons.push({
                    sdisplayname: action.sdisplayname,
                    sapbdb: action.sapbdb,
                    icon_url: action.icon_url,
                    url: action.url,
                    modifications: action.modifications,
                    modificationsVacant: action.modificationsVacant,
                    einfracategory: action.einfracategory
                })

                return Object.assign({}, state, newState);
            } else
                return state;
        case REMOVE_PRIMARY_WEAPON:
            const primaryWeaponArrayIndex = state.loadoutSets[action.profileIndex].primaryWeapons.findIndex(weapon => weapon.sapbdb === action.sapbdb);

            if (primaryWeaponArrayIndex >= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].primaryWeapons.splice(primaryWeaponArrayIndex, 1);

                return Object.assign({}, state, newState);
            } else
                return state;
        case ADD_PRIMARY_WEAPON_MODIFICATION:
            // Does this weapon mod already exist in our set?
            if (state.loadoutSets[action.profileIndex].primaryWeaponModifications.filter(weaponMod => weaponMod.sapbdb === action.sapbdb).length <= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].primaryWeaponModifications.push({
                    sdisplayname: action.sdisplayname,
                    sapbdb: action.sapbdb,
                    icon_url: action.icon_url,
                    url: action.url,
                    emodifiercategory: action.emodifiercategory,
                })

                return Object.assign({}, state, newState);
            } else
                return state;
        case REMOVE_PRIMARY_WEAPON_MODIFICATION:
            const primaryWeaponModArrayIndex = state.loadoutSets[action.profileIndex].primaryWeaponModifications.findIndex(weaponMod => weaponMod.sapbdb === action.sapbdb);

            if (primaryWeaponModArrayIndex >= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].primaryWeaponModifications.splice(primaryWeaponModArrayIndex, 1);

                return Object.assign({}, state, newState);
            } else
                return state;
        case ADD_SECONDARY_WEAPON:
            if (state.loadoutSets[action.profileIndex].secondaryWeapons.filter(weapon => weapon.sapbdb === action.sapbdb).length <= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].secondaryWeapons.push({
                    sdisplayname: action.sdisplayname,
                    sapbdb: action.sapbdb,
                    icon_url: action.icon_url,
                    url: action.url,
                    modifications: action.modifications,
                    modificationsVacant: action.modificationsVacant,
                    einfracategory: action.einfracategory
                })

                return Object.assign({}, state, newState);
            } else
                return state;
        case REMOVE_SECONDARY_WEAPON:
            const secondaryWeaponArrayIndex = state.loadoutSets[action.profileIndex].secondaryWeapons.findIndex(weapon => weapon.sapbdb === action.sapbdb);

            console.log(state.loadoutSets[action.profileIndex].secondaryWeapons);

            if (secondaryWeaponArrayIndex >= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].secondaryWeapons.splice(secondaryWeaponArrayIndex, 1);

                return Object.assign({}, state, newState);
            } else
                return state;
        case ADD_GRENADE:
            // Does this already exist in our set?
            if (state.loadoutSets[action.profileIndex].grenades.filter(grenade => grenade.sapbdb === action.sapbdb).length <= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].grenades.push({
                    sdisplayname: action.sdisplayname,
                    sapbdb: action.sapbdb,
                    icon_url: action.icon_url,
                    url: action.url,
                    einfracategory: action.einfracategory,
                })

                return Object.assign({}, state, newState)
            } else
                return state;
        case REMOVE_GRENADE:
            const grenadeArrayIndex = state.loadoutSets[action.profileIndex].grenades.findIndex(grenade => grenade.sapbdb === action.sapbdb);

            if (grenadeArrayIndex >= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].grenades.splice(grenadeArrayIndex, 1);

                return Object.assign({}, state, newState);
            } else
                return state;
        case ADD_CHARACTER_MODIFICATION:
            // Does this weapon mod already exist in our set?
            if (state.loadoutSets[action.profileIndex].characterModifications.filter(characterMod => characterMod.sapbdb === action.sapbdb).length <= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].characterModifications.push({
                    sdisplayname: action.sdisplayname,
                    sapbdb: action.sapbdb,
                    icon_url: action.icon_url,
                    url: action.url,
                    emodifiercategory: action.emodifiercategory,
                })

                return Object.assign({}, state, newState);
            } else
                return state;
        case REMOVE_CHARACTER_MODIFICATION:
            const characterModArrayIndex = state.loadoutSets[action.profileIndex].characterModifications.findIndex(characterMod => characterMod.sapbdb === action.sapbdb);

            if (characterModArrayIndex >= 0) {
                const newState = state;

                newState.loadoutSets[action.profileIndex].characterModifications.splice(characterModArrayIndex, 1);

                return Object.assign({}, state, newState);
            } else
                return state;
        default:
            return state
    }
}

export default loadouts;