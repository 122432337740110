import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useSpring, animated } from 'react-spring';

import './style.css';

const AlertBadge = ({ alertNum }) => {
    const props = useSpring({ opacity: 1, from: { opacity: 0 } })
    
    return (
        <animated.div className="notification-badge" style={props}>{alertNum}</animated.div>
    )
};

export default AlertBadge;