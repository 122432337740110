import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.div`
    width: 100%;
    max-width: 1088px;
    min-height: 100px;
    margin: 0 auto;
    padding: 50px 20px;
`;

const Section = ({children}) => (
    <StyledSection>
        {children}
    </StyledSection>
);

export default Section;