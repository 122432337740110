import { connect } from 'react-redux';
import newPost from '../pages/post/new';

const mapStateToProps = (state, ownProps) => {
    const { currentUser = {} } = state;
    return {
        userId: currentUser.id,
        userUsername: currentUser.username,
        userAvatar: currentUser.avatar,
        userIsVerified: currentUser.verified,
        userCharacters: currentUser.characters,
    }
}

export default connect(mapStateToProps)(newPost);