import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { postCategories } from '../../../util/enums';
import Icon from '../../icons';

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: this.props.filterActive || false,
        };
    }

    handleToggleActive = () => {
        const newActiveState = !this.state.active;

        this.setState({ active: newActiveState });

        const { parentActiveState, onUpdate } = this.props;

        onUpdate(parentActiveState, newActiveState);
    };

    render() {
        const { filterIcon, filterName, filterActiveColor, iconName } = this.props;
        const { active } = this.state;

        return (
            <div className={`filter${ active ? ' active' : '' }`}>

                <div className="title" onClick={this.handleToggleActive}>
                    <div className={`icon ${ active ? 'active' : '' }`} style={{ background: active ? `#${filterActiveColor}` : 'var(--color-background-search-sidebar-filter-icon)' }}>
                        {filterIcon}
                        {/* <Icon name={iconName} stroke="currentColor" strokeWidth={1.8} width={25} height={25} /> */}
                    </div>
                    <span className="name">
                        {filterName}
                    </span>
                </div>

                <div className="filter-options" hidden={!this.state.active || !this.props.children}>
                    {this.props.children}
                </div>

            </div>
        )
    };
}

export default SearchBar;