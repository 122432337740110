import React from 'react';

import { Link } from 'react-router-dom';

import './style.css';

const DropdownItem = ({ link, children, ...props }) => (
    !!link ? (
        <Link className="dropdown-item" to={link} {...props} >{children}</Link>
    ) : (
        <div className="dropdown-item" {...props} >{children}</div>
    )
)

const DropdownItemDivider = () => (<div className="dropdown-item-divider"></div>)


export {
    DropdownItem,
    DropdownItemDivider
};