import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
    background: #ffffff;
    border-radius: 15px 15px 0 0;
    padding: 1.5rem 1rem;
    max-width: 425px;
    color: #3b3b3b;

    @media(min-width: 425px) {
        padding: 3rem;
        width: 425px;
        border-radius: 8px;
    }
`;


export const Card = (props) => (
    <StyledCard>
        {props.children}
    </StyledCard>
)

export default Card;