import React from 'react';
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  const { currentUser } = state;

  return {
    // isLoggedIn: currentUser.isLoggedIn,
    userId: currentUser.id,
    // username: currentUser.username,
    // avatar: currentUser.avatar,
    // verified: currentUser.verified,
    roles: currentUser.roles,
  }
}

const isModerator = (userRoles) => !!userRoles && (userRoles.includes(3) || userRoles.includes(2) || userRoles.includes(1));
  
const mapDispatchToProps = (dispatch, ownProps) => ({
  canDelete: (authorId, userId, userRoles) => userId === authorId || isModerator(userRoles),
  isModerator: userRoles => isModerator(userRoles),
})

export default component => connect(mapStateToProps, mapDispatchToProps)(component);
