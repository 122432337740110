import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { withAlert } from 'react-alert';
import { toast } from 'react-toastify';

import Swal from 'sweetalert2';
import withUser from '../../components/hoc/withUser'

import Section from '../../components/section/Section';
import SectionHead from '../../components/section/SectionHead';
import ColorPalette from '../../components/ColorPalette';
import AvatarUploader from '../../components/forms/AvatarUploader';
import Switch from '../../components/forms/Switch';
import Input from '../../components/forms/Input';
import Button from '../../components/forms/Button';

import ClaimCharacterForm from '../../components/forms/ClaimCharacter'

import Icon from '../../components/icons';

import { gameServers, gameFactions } from '../../util/enums';

import './style.css';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: var(--color-text-input-label);
    font-size: .8rem;
    .error & {
        color: var(--color-text-input-label-error);
        border-color: var(--color-border-input-error);
    }
`;

const StyledSubSection = styled.div`
    color: var(--color-text-section-sub-section);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 1rem 0;
`;

const StyledSplitSectionContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 900px) {
        flex-direction: row;
    }
`;

const StyledSplitSectionHalfLong = styled.div`
    @media(min-width: 900px) {
        padding-right: .5rem;
        width: 59%;
    }
`;

const StyledSplitSectionHalfShort = styled.div`
    @media(min-width: 900px) {
        padding-left: .5rem;
        width: 39%;
    }
`;

const StyledCharacterTable = styled.table`
    color: var(--color-text-character-table);
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    
    thead {
        background: var(--color-background-character-table-head);
        padding: 1rem;
        color: var(--color-text-character-table-head);
    }

    thead td {
        padding: .8rem;
    }

    thead tr td:first-child {
        border-top-left-radius: 3px;
    }

    thead tr td:last-child {
        border-top-right-radius: 3px;
    }
`;

const StyledCharacterTr = styled.tr`
    background: var(--color-background-character-table-row);
    transition: background 0.1s ease-in-out;

    &:last-child td:first-child {
        border-bottom-left-radius: 3px;
    }

    &:last-child td:last-child {
        border-bottom-right-radius: 3px;
    }

    :hover {
        background: var(--color-background-character-table-row-hover);
    }
`;

const StyledCharacterTd = styled.td`
    padding: 0.6rem 0.4rem;
`;

const StyledSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: .9rem;

    .title {
        margin-right: 1rem;
    }

    label {
        margin-left: auto;
    }
`;

const StyledLinkAccount = styled.div`
    display: flex;
    align-items: center;
    font-size: .9rem;

    .title {
        margin-right: 1rem;
    }

    button {
        margin-left: auto;
    }

    button.discord {
        padding: .2rem .5rem;
        border: 1px solid #7289DA;
        background: #ffffff;
        color: #7289DA;
        border-radius: 3px;
        transition: all ease-in-out 0.1s;
        cursor: pointer;
    }

    button.discord:hover {
        border: 1px solid #7289DA;
        background: #7289DA;
        color: #ffffff;
    }

    button.discord[disabled] {
        border: 1px solid #4E5D94;
        background: #ffffff;
        color: #4E5D94;
        cursor: auto;
    }
`;

// roles email banned banDate banExpiryDays username createdAt profileColor verified settingViewNSFW
class EditProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileColor: '',
            username: '',
            email: '',
            nsfw: null,
            verified: null,
            formChanged: false,
            redirect: null,
            showClaimCharacterForm: false,
        };
    }

    componentWillMount() {
        if (this.props.adminEdit)
            this.props.fetchUser();
    }

    //componentDidMount() {
        // Prepopulate values based on props
    //}

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value, formChanged: true });
    handleCheckboxChange = (e) => this.setState({ [e.target.name]: e.target.checked, formChanged: true });
    handleChangeProfileColor = (colorCode) => this.setState({ profileColor: colorCode, formChanged: true });

    handleAvatarChange = (avatarBlob) => {
        this.props.updateAvatar(this.props.id, avatarBlob)
            .then(res => {
                console.log(res);

                if (!res.success)
                    Swal.fire({
                        icon: 'error',
                        title: res.message,
                    });
                else {
                    Swal.fire({
                        icon: 'success',
                        title: res.message,
                    });
                }
            });
    };
    
    handleAvatarClear = () => {
        return;
    };


    onSave = () => {
        const fields = { username: '', email: '' };
        const { username, email } = this.state;
        Object.keys(fields).map(key => document.getElementById(`user-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`user-${key}-error`).innerHTML = '');

        const errors = {
            username: [],
        };

        if (!!username && username.length < 3 || username.length > 30)
            errors.username.push('Username must be between 3 and 30 characters.');

        if (!!username && !RegExp(/^[a-z0-9]+$/i).test(username))
            errors.username.push('Username must be alphanumeric.');

        if (!!email && !(RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm).test(email)))
            errors.email.push('Email must be valid.');

        if (!!email && email.length > 50)
            errors.email.push('Email must be less than 50 characters.');

        if (errors.username.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`user-${error}`).className += ' error'
                    document.getElementById(`user-${error}-error`).innerHTML = errors[error].join('<br />')
                }
        } else {
            const changes = { 
                ...(!!this.state.username && { username: this.state.username }),
                ...(!!this.state.profileColor && { profileColor: this.state.profileColor }),
                ...(this.state.nsfw != null && { settingViewNSFW: this.state.nsfw }),
                ...(this.props.isModerator(this.props.roles) && {
                    ...(!!this.state.email && { email: this.state.email }),
                    ...(this.state.verified != null && { verified: this.state.verified }),
                }),
            }

            console.log(changes);

            this.props.saveUser(this.props.id, changes)
                .then(res => {
                    console.log(res);
                    const alert = this.props.alert;

                    if (!res.success)
                        Swal.fire({
                            icon: 'error',
                            title: res.message,
                        });
                    else {
                        Swal.fire({
                            icon: 'success',
                            title: res.message,
                        });

                        this.setState({ redirect: `/profile/${res.username}` });
                    }
                });
        }  
    }

    addCharacter = (server, name, faction, gender) => {
        this.props.claimCharacter(this.props.id, server, name, faction, gender)
            .then(res => {
                console.log(res);
                const alert = this.props.alert;

                if (!res.success)
                    alert.show(res.message, {
                        type: 'error',
                    })
                else {
                    alert.show(res.message, {
                        type: 'success',
                    });

                    this.setState({ showClaimCharacterForm: false });
                }
            });
    }

    getIsVerified = () => {
        if (this.state.verified != null)
            return this.state.verified;
        else
            return this.props.verified;
    };

    getIsNSFW = () => {
        if (this.state.nsfw != null)
            return this.state.nsfw;
        else
            return this.props.nsfw;
    };

    handleLinkDiscord = () => {
        if(this.props.discordId)
            this.props.unlinkDiscord()
                .then(res => {
                    if (res.success === true)
                        toast.success(res.message);
                    else
                        toast.error(res.message);
                });
        else
            window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=710354136578916382&redirect_uri=https%3A%2F%2Fapb.social%2Fauth%2Fdiscord%3Ftype%3Dlink&response_type=code&scope=identify'; 
    };

    showClaimCharacterForm = () => {
        this.setState({ showClaimCharacterForm: true });
    }

    renderCharacterMenu = () => (
        <div className='edit-user-characters-form'>
            <StyledSubSection>
                Characters
            </StyledSubSection>

            <div className="field">
                <StyledCharacterTable>
                    <thead>
                        <tr>
                            <td>Faction</td>
                            <td>Name</td>
                            <td>Server</td>
                            <td>Gender</td>
                            {/* <td>Visible on Profile</td> */}
                        </tr>
                    </thead>
                    <tbody>
                        {!!this.props.characters &&
                            this.props.characters.map(character =>
                                <StyledCharacterTr key={character._id}>
                                    <StyledCharacterTd>
                                        <Icon name={character.faction === 0 ? 'enforcer' : 'criminal'} width="18" height="18" fill='currentColor' />
                                    </StyledCharacterTd>

                                    <StyledCharacterTd>
                                        {character.name}
                                    </StyledCharacterTd>

                                    <StyledCharacterTd>
                                        {gameServers.getName(character.server)}
                                    </StyledCharacterTd>

                                    <StyledCharacterTd>
                                        {character.gender != null && ((character.gender === 0 && '(M)') || (character.gender === 1 && '(F)'))}
                                    </StyledCharacterTd>
                                </StyledCharacterTr>
                            )
                        }
                    </tbody>
                </StyledCharacterTable>
            </div>
            {
                this.state.showClaimCharacterForm ?
                    <div className="field claim-characters-form">
                        <ClaimCharacterForm active={this.state.showClaimCharacterForm} onSubmit={this.addCharacter} />
                    </div>
                :
                    <div className="field edit-user-button">
                        <Button onClick={() => this.showClaimCharacterForm()}>Claim Character</Button>
                    </div>
            }
        </div>
    );

    renderProfileSection = () => {
        return (
            <div>
                <StyledSubSection>
                    Profile
                </StyledSubSection>

                <div className="fields">
                    <div className="field">
                        <StyledLabel htmlFor="avatar">Avatar:</StyledLabel>
                        <AvatarUploader defaultImage={this.props.avatar || 'https://i.imgur.com/I8xE73K.png'} handleUpdate={this.handleAvatarChange} handleModify={this.handleAvatarClear} />
                    </div>

                    <div className="field">
                        <StyledLabel htmlFor="avatar">Profile Color:</StyledLabel>
                        <ColorPalette preSelectColor={this.props.profileColor} onSelect={this.handleChangeProfileColor} />
                    </div>

                    <div id="user-username" className="field">
                        <StyledLabel htmlFor="username">Username ({this.state.username.length}/30):</StyledLabel>
                        <Input name="username" type="text" value={this.state.username} placeholder={this.props.username} onChange={(e) => this.handleChange(e)} />
                        <div id="user-username-error" className="error-message"></div>
                    </div>

                    <div id="user-email" className="field">
                        <StyledLabel htmlFor="email">Email:</StyledLabel>
                        <Input name="email" type="email" value={this.state.email} placeholder={this.props.email} disabled={!this.props.isModerator(this.props.roles)} onChange={(e) => this.handleChange(e)} />
                        <div id="user-email-error" className="error-message"></div>
                    </div>
                </div>
            </div>
        );
    }

    renderCharacterSection = () => {
        return this.renderCharacterMenu();
    }

    renderPreferencesSection = () => {
        return (
            <div>
                <StyledSubSection>
                    Preferences
                </StyledSubSection>

                <div className="fields">
                    <div id="user-nsfw" className="field">
                        <StyledSwitchContainer>
                            <div className="title">
                                Show NSFW posts on feed
                            </div>

                            <Switch name="nsfw" type="checkbox" checked={this.getIsNSFW()} onChange={(e) => this.handleCheckboxChange(e)} />
                        </StyledSwitchContainer>
                    </div>

                    {
                        this.props.isModerator(this.props.roles) && this.props.currentUserId != this.props.id && (
                            <>
                                <div id="user-verified" className="field">
                                    <StyledSwitchContainer>
                                        <div className="title">
                                            Verified Email:
                                        </div>

                                        <Switch name="verified" type="checkbox" checked={this.getIsVerified()} onChange={(e) => this.handleCheckboxChange(e)} />
                                    </StyledSwitchContainer>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }

    renderSocialAccountLinking = () => {
        return (
            <div>
                <StyledSubSection>
                    Linked Accounts
                </StyledSubSection>

                <div className="fields">
                    <StyledLinkAccount>
                        <div className="title">
                            Discord:
                        </div>

                        <button className="discord" onClick={() => this.handleLinkDiscord()} disabled={this.props.discordId || this.props.adminEdit}>{this.props.discordId ? 'Linked' : 'Link'}</button>
                    </StyledLinkAccount>
                </div>
            </div>
        );
    }

    renderForm = () => {
        return (
            <div className="edit-user-form">

                <StyledSplitSectionContainer>
                    <StyledSplitSectionHalfLong>
                        {this.renderProfileSection()}
                    </StyledSplitSectionHalfLong>
                    <StyledSplitSectionHalfShort>
                        {this.renderPreferencesSection()}
                        {this.renderSocialAccountLinking()}
                    </StyledSplitSectionHalfShort>
                </StyledSplitSectionContainer>

                <div className="field edit-user-button">
                    <Button onClick={this.onSave} disabled={!this.state.formChanged}>Save</Button>
                </div>

                {this.renderCharacterSection()}
            </div>
        )
    };

    renderPage = () => {
        return (
            <>
                <Section>
                    <SectionHead
                        title={this.props.adminEdit ? `${this.props.username}'s Settings` : 'Settings'}
                    />

                    {this.props.isFetching ? (
                        <div>
                            Loading...
                        </div>
                    ) : (
                            this.renderForm()
                        )}
                </Section>
            </>
        )
    }

    renderPageTwo = () => {
        return (
            <>
                <Section>
                    <SectionHead
                        title={this.props.adminEdit ? `${this.props.username}'s Settings` : 'Settings'}
                    />

                    <StyledSubSection>
                        Profile
                    </StyledSubSection>

                    {this.props.isFetching ? (
                            <div>
                                Loading...
                            </div>
                        ) : (
                            this.renderProfileSection()
                        )}
                </Section>
                <Section>
                    <StyledSubSection>
                        Preferences
                    </StyledSubSection>

                    {this.props.isFetching ? (
                        <div>
                            Loading...
                        </div>
                    ) : (
                            this.renderPreferencesSection()
                        )}
                </Section>
                <Section>
                    <StyledSubSection>
                        Characters
                    </StyledSubSection>

                    {this.props.isFetching ? (
                            <div>
                                Loading...
                            </div>
                        ) : (
                            this.renderCharacterSection()
                        )}
                </Section>
            </>
        )
    }

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderPage()
    }
}

export default withAlert()(withUser(EditProfile));