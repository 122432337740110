import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
    strokeLinecap = "round",
    strokeLinejoin = "round",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
        >
            <polyline fill={fill} stroke={stroke} points="3,6 5,6 21,6 " />
            <path fill={fill} stroke={stroke} d="M19,6v14c0,1.104-0.896,2-2,2H7
	c-1.104,0-2-0.896-2-2V6 M8,6V4c0-1.104,0.896-2,2-2h4c1.104,0,2,0.896,2,2v2"/>
            <line fill={fill} stroke={stroke} x1="10" y1="11" x2="10" y2="17" />
            <line fill={fill} stroke={stroke} x1="14" y1="11" x2="14" y2="17" />
        </svg>
    );

export default SVG;