import React from 'react';
import { BulletList } from 'react-content-loader';
import ReactPaginate from 'react-paginate';

import queryString from 'query-string';

import { Link } from 'react-router-dom';

import Section from '../../components/section/Section';
import SectionHead from '../../components/section/SectionHead';
import NotificationList from '../../components/notifications/NotificationList';
import NotificationItem from '../../components/notifications/NotificationItem';
import Icon from '../../components/icons';


class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            notificationPage: 1,
            notificationPageCount: 0,
        }
    }

    componentWillMount() {
        let page = queryString.parse(this.props.location.search).page || 1;

        page = isNaN(page) || page < 0 ? 1 : page;

        this.loadNotifications(page);
    }

    markNotificationsAsRead(notificationIds) {
        if (!!notificationIds && notificationIds.length > 0)
            this.props.sendReadNotificationsToServer(notificationIds);
    }

    loadNotifications = page => {
        // send comments request, set post comments state on response
        this.props.fetchNotifications(page)
            .then(result => {
                this.setState({ notifications: result.notifications, notificationPage: page - 1, notificationPageCount: result.pageCount });

                const unreadNotifications = this.state.notifications.filter(notification => !notification.read).map(notification => notification._id);
                if (!!unreadNotifications && unreadNotifications.length > 0)
                    this.markNotificationsAsRead(unreadNotifications);
            });
    };

    handleNotificationPageClick = data => {
        // Normalize from page 0 to 1
        let selected = data.selected + 1;

        this.props.history.push(`${window.location.pathname}?page=${selected}`);
    };

    renderContent = () => {
        let { notificationPage, notificationPageCount } = this.state;

        return (
            <>
                <section className="engagement">
                    <SectionHead
                        title='Notifications'
                        subtitle="View recent activity on your posts!"
                    />

                    {this.state.notifications && this.state.notifications.length > 0 ? (
                        <NotificationList>
                            {(this.state.notifications).map(notification =>
                                <NotificationItem key={notification._id} notificationType={notification.notifyingEntityType} referenceEntity={notification.referenceEntityId} referenceEntityType={notification.referenceEntityType} senderId={notification.sender._id} senderUsername={notification.sender.username} senderAvatar={notification.sender.avatar} timestamp={notification.createdAt} read={notification.read} />
                            )}
                        </NotificationList>)
                        : (
                            <div>No activity yet!</div>
                        )}

                    {notificationPageCount > 1 && (
                        <ReactPaginate
                            forcePage={notificationPage}
                            previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
                            nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
                            breakClassName={'break-me'}
                            pageCount={notificationPageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handleNotificationPageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    )}
                </section>
            </>
        )
    }

    render() {
        return (
            <Section>
                <article className="section-container post-container">
                    {
                        this.props.isFetching
                            ? <BulletList />
                            : this.renderContent()
                    }
                </article>
            </Section>
        );
    }
}

export default Notifications;