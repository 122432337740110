import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import ColorConvert from 'color-convert';

import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import Icon from '../../components/icons';
import ColorConverterLogo from '../../static/images/color-converter-logo.png';
import Advert from '../../components/advert';

import {
    StyledSplit,
    StyledSplitLeft,
    StyledSplitRight,
    StyledHSLContainer,
    StyledColorPicker,
    StyledHSLInputContainer,
    StyledHSLInputName,
    StyledHSLInput,
    StyledColorPreviewImage,
    StyledFooter,
    StyledFooterItems,
    StyledFooterItem,
} from './components';

import {
    StyledContainer,
    StyledContentContainer,
    StyledLogoContainer,
    StyledLogo,
    StyledCardContentContainer,
    StyledCardContainer,
    StyledCard,
    StyledCardHeader,
    StyledCardHeaderNavContainer,
    StyledCardTitle,
    StyledCardBody,
    StyledWideButton,
    StyledCardBodyText,
    StyledCardCombinedSelectContainer,
    StyledCardCombinedSelect,
    StyledCardCombinedSelectButton,
    StyledOrText,
    StyledRandomLoadoutSection,
    StyledRandomLoadoutSectionTitle,
    StyledRandomLoadoutSectionWeapon,
    StyledRandomLoadoutSectionWeaponIconContainer,
    StyledRandomLoadoutSectionWeaponNameContainer,
    StyledRandomLoadoutSectionModificationContainer,
    StyledRandomLoadoutSectionPresetModification,
    StyledRandomLoadoutSectionModification,
    StyledRandomLoadoutSectionLockedModification,
    StyledLoadoutManagementListItemAction,
} from '../loadout/components';

import ColorPreview from '../../static/images/renders/invyr/invyr-2.png';

import ColorPicker from './colorPicker';

import { convertHexToAPBHSL, convertAPBHSLToHex } from '../../util/convertColor';

const APBColorConverter = () => {
    const [convertColor, setConvertColor] = useState('121212');
    const [apbHSL_H, setAPBHSL_H] = useState(0);
    const [apbHSL_S, setAPBHSL_S] = useState(0);
    const [apbHSL_L, setAPBHSL_L] = useState(1);
    const [updateConvertColor, setUpdateConvertColor] = useState(false);
    const [showAbout, setShowAbout] = useState(false);

    useEffect(() => {
        if (updateConvertColor) {
            setConvertColor(convertAPBHSLToHex([apbHSL_H, apbHSL_S, apbHSL_L]));
            setUpdateConvertColor(false);
        }
    })

    const setAPBHSLValue = (value, index) => {
        switch (index) {
            case 2: 
                return setAPBHSL_L(value);
            case 1: 
                return setAPBHSL_S(value);
            case 0: 
                return setAPBHSL_H(value);
            default:
                console.log('cannot set apb hsl for index > 2');
        }
    };

    const navigateBack = () => setShowAbout(false);

    const handleAPBHSLChange = (value, index) => {
        setAPBHSLValue(value, index);
        setUpdateConvertColor(true);
    };

    const handleColorChange = colorHex => {
        setConvertColor(colorHex);

        const apbHSL = convertHexToAPBHSL(colorHex);
        setAPBHSLValue(apbHSL[0], 0);
        setAPBHSLValue(apbHSL[1], 1);
        setAPBHSLValue(apbHSL[2], 2);
    };

    const renderColorControls = () => (
        <>
            <StyledCardBodyText>
                The APB HSL system is a reduced value subset of standard HSL, <br /> true colour conversion for most colours is not possible.
            </StyledCardBodyText>

            <StyledCardBodyText>
                <span style={{color: 'red'}}>*</span> In-game brightness will affect color appearance.
            </StyledCardBodyText>

            <StyledHSLInputName>Color</StyledHSLInputName>
            <ColorPicker color={convertColor} onSelect={color => handleColorChange(color)} />
            {/* <div>#{convertColor}</div> */}

            <StyledHSLContainer>
                <StyledHSLInputContainer>
                    <StyledHSLInputName>Hue</StyledHSLInputName>
                    <StyledHSLInput type="number" min="0" max="31" value={apbHSL_H} onChange={e => handleAPBHSLChange(e.target.value, 0)} />
                </StyledHSLInputContainer>
                <StyledHSLInputContainer>
                    <StyledHSLInputName>Sat</StyledHSLInputName>
                    <StyledHSLInput type="number" min="0" max="7" value={apbHSL_S} onChange={e => handleAPBHSLChange(e.target.value, 1)} />
                </StyledHSLInputContainer>
                <StyledHSLInputContainer>
                    <StyledHSLInputName>Lum</StyledHSLInputName>
                    <StyledHSLInput type="number" min="0" max="15" value={apbHSL_L} onChange={e => handleAPBHSLChange(e.target.value, 2)} />
                </StyledHSLInputContainer>
            </StyledHSLContainer>
        </>
    );

    const renderAbout = () => (
        <StyledCardContainer width='750px'>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardHeaderNavContainer>
                        <StyledLoadoutManagementListItemAction data-tip='Back' data-for='__tooltip' onClick={e => navigateBack()}>
                            <Icon name='back-arrow' stroke='none' fill='currentColor' />
                        </StyledLoadoutManagementListItemAction>
                    </StyledCardHeaderNavContainer>
                    <StyledCardTitle>
                        About
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody padding='2rem'>
                    <StyledCardBodyText>
                        Credits to <a style={{ textDecoration: 'underline' }} href="https://forums.gamersfirst.com/profile/415-skay/" target="_blank">SKay</a> for creating the original <a style={{ textDecoration: 'underline' }} href="https://forums.gamersfirst.com/topic/2901-hex-to-apb-hsl-converter/" target="_blank">APB HSL Converter</a>.
                    </StyledCardBodyText>
                    <StyledCardBodyText>
                        "The APB HSL System is a method of colour reproduction using reduced values.<br />
                        The standard HSL system is a repesentation of colour using Hue, Saturation and Lightness
                        to achieve a specific colour. However, as the APB HSL system is a reduced value subset of standard HSL,
                        the values are condensed down to bands of values, which means that true colour reproduction for most colours
                        is just out of the question.<br /><br />

                        Normally the HSL system can accept 3.6 million values for colour (360x100x100), however the APB system only accepts 4096 colours (This would be 32x8x16).<br />
                        Essentially, the APB HSL system is a compression of the normal HSL system, and as such there are plenty of comprimises, with the biggest one being you won't get 100% accuracy
                        unless you use maxima/minima values for colour.<br /> In other words, if a colour is a weird one, probably not gunna see it in game.<br /><br />

                        The calculations are made by converting a HEX colour input and converting it to a standard HSL format, then converting it via division to the APB HSL system,
                        and then rounded to the nearest integer.<br /> Sounds simple? It ironically is, except it also introduces problems.<br /> I doubt anyone wants to find out that the pink
                        you loved suddenly is a red in game, and that is a real problem with this conversion, it loses fidelity in colours and the colour range."
                    </StyledCardBodyText>
                    <StyledCardBodyText>
                        <a style={{ textDecoration: 'underline' }} href="https://forums.gamersfirst.com/profile/415-skay/" target="_blank">- From the birthday man himself</a>
                    </StyledCardBodyText>
                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    const renderColorConverter = () => (
        <StyledCardContainer width='1280px'>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardTitle>
                        Color Converter
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody padding='2rem'>
                    <StyledSplit>
                        <StyledSplitLeft>
                            {renderColorControls()}
                        </StyledSplitLeft>
                        <StyledSplitRight>
                            <StyledColorPreviewImage src={ColorPreview} background={convertAPBHSLToHex([apbHSL_H, apbHSL_S, apbHSL_L])} />
                        </StyledSplitRight>
                    </StyledSplit>
                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    return (
        <StyledContainer>
            <FullscreenPageLayoutWave />
            <StyledContentContainer>
                <StyledLogoContainer>
                    <StyledLogo src={ColorConverterLogo} />
                </StyledLogoContainer>

                <StyledCardContentContainer>
                    {showAbout ? renderAbout() : renderColorConverter()}
                </StyledCardContentContainer>

                <StyledFooter>
                    <StyledFooterItems>
                        <StyledFooterItem>
                            <Link to="/">
                                Home
                            </Link>
                        </StyledFooterItem>

                        <StyledFooterItem onClick={() => setShowAbout(true)}>
                            About
                        </StyledFooterItem>
                    </StyledFooterItems>
                </StyledFooter>

                <div>
                    <Advert padding='2rem 0' />
                </div>
            </StyledContentContainer>
        </StyledContainer>
    );
};

export default APBColorConverter;