import { connect } from 'react-redux'
import StaticSidebar from '../components/sidebar/Sidebar';

const mapStateToProps = (state, ownProps) => {
    const { currentUser = {},
    } = state;

    return {
        isLoggedIn: !!currentUser.id,
    }
}

export default connect(mapStateToProps, null)(StaticSidebar);