import { connect } from 'react-redux';
import editPost from '../pages/post/edit';
import { fetchEditPostData } from '../actions/postActions';

const mapStateToProps = (state, ownProps) => {
    const { currentUser = {}, currentPost = {} } = state;
    return {
        userId: currentUser.id,
        userIsVerified: currentUser.verified,
        post: currentPost.editPost,
        isFetching: currentPost.isFetching,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchEditPostData: () =>
        dispatch(fetchEditPostData(ownProps.match.params.post_id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(editPost);