import { connect } from 'react-redux'
import { attemptVerify } from '../actions/verifyActions'
import Verified from '../pages/verified'

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
    verifyUser: (token) =>
        dispatch(attemptVerify(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Verified);