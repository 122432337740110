import React from 'react';
import styled from 'styled-components';

import moment from 'moment';

import { Link } from 'react-router-dom';

import withUser from '../hoc/withUser';

import Icon from '../icons';

import Dropdown from '../dropdown/Dropdown';
import { DropdownItem, DropdownItemDivider } from '../dropdown/DropdownItem';

const StyledNotificationItem = styled.article`
    display: -ms-flexbox;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: .2rem;
    padding: .5rem;
    border-radius: 3px;
    ${props => props.read ? null : 'background: var(--color-background-dropdown-notification-unread);'}

    .notification-icon {
        display: flex;
        margin-right: .7rem;
    }

    .avatar {
        margin-right: .7rem;
        display: inherit;
    }

    .notification-data {
        display: block;
    }

    .notification-data .notification-info, .notification-data .notification-info .username {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    .notification-data .notification-info {
        margin-bottom: .3rem;
    }

    .notification-data .notification-info .username {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;
        color: var(--color-text-username);
        text-decoration: none;
        margin-right: .6rem;
    }

    .notification-data .notification-info .username:hover {
        text-decoration: underline;
    }

    .notification-data .notification-info .notification-time {
        font-size: .8rem;
        color: var(--color-text-dropdown-secondary);
    }

    .notification-data .text {
        font-size: 1rem;
        word-break: break-word;
    }

    :hover > .options-container {
        visibility: visible;
        opacity: 1;
    }

    .options-container {
        visibility: hidden;
        opacity: 0;
        display: -ms-flexbox;
        display: flex;
        margin-left: auto;
        align-items: center;
        -webkit-transition: opacity .2s ease-in-out, visibility .1s ease-in-out;
        transition: opacity .2s ease-in-out, visibility .1s ease-in-out;
    }

    .at-me-bro, a {
        text-decoration: underline;
    }

    .unread-bubble {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        margin-right: 22px;
        background-color: #065fd4;
    }
`;

const Avatar = styled.img`
  border-radius: 3px;
  max-width: 44px;
  max-height: 44px;
  display: block;
`;

const generateIcon = (notificationType) => {
    switch (notificationType) {
        case 'Comment':
            return <Icon name="comment-icon" fill="#9658fe" strokeWidth="1" />;
        case 'Like':
            return <Icon name="heart" fill="#9658fe" strokeWidth="1" />;
        case 'Follow':
            return <Icon name="eye" fill="#9658fe" solidEye={true} strokeWidth="1" />;
    }
}

const generateMessage = (notificationType, referenceEntity) => {
    switch (notificationType) {
        case 'Comment':
            return (
                <div className="text">
                    Commented on your post <Link to={`/post/${referenceEntity._id}`}>{referenceEntity.title}</Link>
                </div>
            );
        case 'Like':
            return (
                <div className="text">
                    Liked your post <Link to={`/post/${referenceEntity._id}`}>{referenceEntity.title}</Link>
                </div>
            );
        case 'Follow':
            return (
                <div className="text">
                    Followed you!
                </div>
            );
    }
};

const NotificationItem = ({ notificationType, referenceEntity, senderUsername, senderAvatar, timestamp, read, deleteComment, userId, roles, canDelete }) => (
    <StyledNotificationItem read={read}>
        {/* { !read && <div className="unread-bubble"></div>} */}
        <div className="notification-icon">
            {notificationType && generateIcon(notificationType)}
        </div>
        <Link className="avatar" to={`/profile/${senderUsername}`}>
            <Avatar src={senderAvatar || 'https://i.imgur.com/I8xE73K.png'} alt={senderUsername} />
        </Link>
        <div className="notification-data">
            <div className="notification-info">
                <Link className="username" to={`/profile/${senderUsername}`}>
                    {senderUsername}
                </Link>
                <time className="notification-time">{moment(timestamp).fromNow()}</time>
            </div>
            {referenceEntity && generateMessage(notificationType, referenceEntity)}
        </div>
    </StyledNotificationItem>
);

export default withUser(NotificationItem);
