export const OPEN_SLIDING_SIDEBAR = 'OPEN_SLIDING_SIDEBAR';
export const CLOSE_SLIDING_SIDEBAR = 'CLOSE_SLIDING_SIDEBAR';
export const TOGGLE_SLIDING_SIDEBAR = 'TOGGLE_SLIDING_SIDEBAR';

export const openSlidingSidebar = () => ({
    type: OPEN_SLIDING_SIDEBAR,
});

export const closeSlidingSidebar = () => ({
    type: CLOSE_SLIDING_SIDEBAR,
});

export const toggleSlidingSidebar = () => ({
    type: TOGGLE_SLIDING_SIDEBAR,
});

export const openSidebar = () => dispatch => dispatch(openSlidingSidebar());

export const closeSidebar = () => dispatch => dispatch(closeSlidingSidebar());

export const toggleSidebar = () => dispatch => dispatch(toggleSlidingSidebar());