import React from 'react';
import styled from 'styled-components';

const SectionHeader = styled.div`
  color: var(--color-text-section-head);
  font-size: 14px;
  margin-bottom: 2rem;

  @media (min-width: 780px) {
      font-size: 16px;
  }
`;

const HeaderTitle = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: 2.4em;
  font-weight: 500;
`;

const Subtitle = styled.h3`
  font-size: 1.35em;
  font-weight: 400;
  margin-top: 0.2rem;
`;

const SectionHead = ({title, subtitle}) => (
    <SectionHeader>
        <HeaderTitle>{title}</HeaderTitle>
        { subtitle && 
            <Subtitle>
                {subtitle}
            </Subtitle>
        }
    </SectionHeader>
);

export default SectionHead;