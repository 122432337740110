import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M20.946,17.282c-1.723-3.468-4.916-3.804-8.349-3.901c0,1.114,0,2.168,0,3.33
	c-0.349-0.125-0.586-0.186-0.801-0.293c-2.844-1.416-5.687-2.835-8.522-4.267c-0.184-0.092-0.387-0.289-0.427-0.469
	c-0.024-0.107,0.23-0.333,0.402-0.419c2.922-1.474,5.851-2.936,8.78-4.395c0.146-0.072,0.319-0.088,0.583-0.158
	c0,1.14,0,2.197,0,3.234c1.028,0.114,1.976,0.174,2.905,0.332c1.723,0.292,3.284,0.94,4.428,2.334
	c1.077,1.31,1.348,2.842,1.149,4.485c-0.008,0.068-0.048,0.132-0.077,0.195C21.016,17.295,20.98,17.286,20.946,17.282z"/>
    </svg>
);

export default SVG;