import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle fill={fill} stroke={stroke}  cx="12" cy="12" r="10"></circle>
            <line fill={fill} stroke={stroke}  x1="12" y1="8" x2="12" y2="16"></line>
            <line fill={fill} stroke={stroke}  x1="8" y1="12" x2="16" y2="12"></line>
        </svg>
    );

export default SVG;
