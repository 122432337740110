import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={width}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} d="M13.27 14.437a1 1 0 01.312.312l3.26 5.161a.652.652 0 01-.551 1h-.632a1 1 0 01-.845-.466l-2.079-3.29-2.078 3.29a1 1 0 01-.845.466h-.63a.651.651 0 01-.551-.999l3.26-5.162a1 1 0 011.379-.312zM5.82 9.968l1.69 3.625-2.037.95a1 1 0 01-1.329-.483L3.3 12.247a1 1 0 01.484-1.328l2.037-.951zm13.395-6.18l1.602 3.435c.27.58.072 1.245-.442 1.486L9.378 13.453 7.09 8.545l10.703-5.374c.515-.24 1.153.037 1.423.617z" fillRule="evenodd"></path>
    </svg>
);

export default SVG;