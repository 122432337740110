import {
  REQUEST_DISCORD_REGISTRATION_TOKEN,
  RECEIVE_DISCORD_REGISTRATION_TOKEN
} from '../actions/registrationActions';
  
const settings = (
  state = {
    isFetching: false,
    registrationTokenId: null,
    linkedAccountUsername: null,
    linkedAccountAvatar: null,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_DISCORD_REGISTRATION_TOKEN:
      return Object.assign({}, {}, {
        isFetching: true,
        registrationTokenId: null,
        linkedAccountUsername: null,
        linkedAccountAvatar: null,
      })
    case RECEIVE_DISCORD_REGISTRATION_TOKEN:
    if (action.success)
      return Object.assign({}, {}, {
        isFetching: false,
        registrationTokenId: action.registrationTokenId,
        linkedAccountUsername: action.username,
        linkedAccountAvatar: action.avatar,
      })
    else
      return Object.assign({}, {}, {
        isFetching: false,
      })
    default:
      return state
  }
}
  
export default settings;