import React from 'react';
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import { StyledColorPicker } from './components';

class ColorPicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false,
            curX: 0,
            curY: 0,
        };
    }

    handleClick = (e) => {
        var rect = e.target.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.

        this.setState({ displayColorPicker: !this.state.displayColorPicker, curX: x, curY: y });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    handleChange = (color) => {
        this.props.onSelect(color.hex.substring(1));
    };

    render() {
        const styles = reactCSS({
            'default': {
                icon: {
                    filter: 'drop-shadow( 0px 0px 0px #fff)',
                },
                popover: {
                    position: 'absolute',
                    left: this.state.curX,
                    top: this.state.curY,
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
                color: {
                    position: 'relative',
                }
            },
        });

        return (
            <div style={styles.color}>
                <StyledColorPicker background={`#${this.props.color}`} onClick={this.handleClick} data-tip="Choose Color" data-for='__tooltip' />
                {this.state.displayColorPicker ?
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <ChromePicker color={this.props.color} onChange={this.handleChange} onChangeComplete={this.handleChange} disableAlpha={true} />
                    </div>
                : null}
            </div>
        )
    }
}

export default ColorPicker