import styled from 'styled-components';

const inputHeight = '45px';

export const StyledSplit = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 760px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledSplitLeft = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 760px) {
        width: 60%;
    }
`;

export const StyledSplitRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 760px) {
        width: 39%;
    }
`;

export const StyledColorPicker = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
    background: ${props => props.background};
    border: 1px solid #464749;
    cursor: pointer;

    @media (min-width: 760px) {
        height: 400px;
    }
`;

export const StyledHSLContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 760px) {
        flex-direction: row;
    }
`;

export const StyledHSLInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.8rem;

    @media (min-width: 760px) {
        width: 30%;
    }
`;

export const StyledHSLInputName = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: .5rem;
`;

export const StyledHSLInput = styled.input`
    display: flex;
    font-family: 'Inter', sans-serif;
    background: #333439 !important;
    width: 100%;
    height: ${inputHeight} !important;
    border-radius: 0px !important;
    font-size: 1.2rem !important;
    border: 1px solid #464749 !important;
    font-weight: 500;
    box-shadow: none !important;
    color: #ffffff !important;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out !important;

    :placeholder {
        color: #7c7e87 !important;
    }

    :focus {
        background: #2d2e33 !important;
        border: 1px solid #464749 !important;
    }
`;

export const StyledColorPreviewImage = styled.img`
    background: #${props => props.background};
`;

export const StyledFooter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.3rem;
    color: #fafafa;
`;

export const StyledFooterItems = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    cursor: pointer;
`;

export const StyledFooterItem = styled.li`
    display: flex;
    list-style: none;
    margin-right: 1rem;

    :last-child {
        margin-right: 0;
    }

    :hover {
        text-decoration: underline;
    }
`;

