import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledSidebarItem = styled.div.attrs(props => ({
    buttonPadding: !!props.link ? '0' : '0.5rem 1rem',
    linkPadding: !!props.link ? '0.5rem 1rem' : '0',
    background: props.active ? (props.backgroundHover ? props.backgroundHover : 'var(--color-background-sidebar-item-selected)') : 'transparent',
    backgroundHover: props.backgroundHover ? props.backgroundHover : 'var(--color-background-sidebar-item-hover)',
    backgroundActive: props.backgroundActive ? props.backgroundActive : 'var(--color-background-sidebar-item-active)',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${props => props.height ? props.height : '48px' };
    cursor: pointer;
    padding: ${props => props.buttonPadding};

    font-weight: 600;

    color: var(--color-text-sidebar);
    background: ${props => props.background};

    transition: background 0.1s ease-in-out;

    :hover, :focus {
        background: ${props => props.backgroundHover};
    }

    :active {
        background: ${props => props.backgroundActive};
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        padding: ${props => props.linkPadding};
    }

    a:hover {
        text-decoration: none;
    }
`;

const renderSidebarItem = props => {
    if (props.linkTo)
        return (
            <StyledSidebarItem link={!!props.linkTo} active={props.active} height={props.height} onClick={props.onClick}>
                <Link to={props.linkTo}>
                    {props.children}
                </Link>
            </StyledSidebarItem>
        );
    else if (props.onClick)
        return (
            <StyledSidebarItem link={!!props.linkTo} onClick={props.onClick} active={props.active} height={props.height}>
                {props.children}
            </StyledSidebarItem>
        );
    else
        return (
            <StyledSidebarItem link={!!props.linkTo} active={props.active} height={props.height}>
                {props.children}
            </StyledSidebarItem>
        );
}

export default props => renderSidebarItem(props);