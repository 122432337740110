import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
    color: #1a1a1a;
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0 0 1rem 0;
`;


export const Title = (props) => (
    <StyledTitle>
        {props.children}
    </StyledTitle>
)

export default Title;