import React from 'react';
import styled from 'styled-components';
import { userRoles, userRoleColors } from '../../util/enums';
import Icon from '../../components/icons';
import ReactTooltip from 'react-tooltip';

const getDisplayedRole = (roles) => {
    if (roles.includes(userRoles.OWNER))
        return userRoles.OWNER;
    else if (roles.includes(userRoles.MODERATOR))
        return userRoles.MODERATOR;
    else
        return null;
};

const Badge = props => {
    const roles = props.roles;

    const badgeRole = getDisplayedRole(roles);

    if (!!badgeRole)
        return (
            <>
                <div className="badge" data-tip data-for="role-name">
                    <Icon name="shield" stroke={userRoleColors[badgeRole]} strokeWidth={props.strokeWidth || 2} width={props.width || 26} />
                </div>
                {
                    props.tooltip && (
                        <ReactTooltip id='role-name' effect='solid' place='bottom'>
                            <span>{userRoles.getName(badgeRole)}</span>
                        </ReactTooltip>
                    )
                }
            </>
        )
    else
        return null;
};

export default Badge;