import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fill={fill} stroke={stroke} d="M12,5.6c-3.501,0-6.339,2.838-6.339,6.339c0,3.502,2.838,6.339,6.339,6.339s6.339-2.837,6.339-6.339
	C18.339,8.438,15.501,5.6,12,5.6z M13.613,6.609c0.275-0.325,3.296,1.69,3.295,2.362c-0.002,0.379-2.016,0.889-2.796,0.347
	C13.419,8.837,13.338,6.934,13.613,6.609z M7.575,15.365c-0.417,0.085-1.541-3.369-1.123-3.895c0.235-0.297,2.133,0.55,2.41,1.458
	C9.108,13.735,7.993,15.28,7.575,15.365z M7.13,8.965c-0.222-0.364,2.645-2.594,3.282-2.38c0.359,0.12,0.207,2.193-0.554,2.762
	C9.184,9.853,7.352,9.329,7.13,8.965z M9.984,16.94c-0.218-0.31,1.13-1.893,2.08-1.901c0.843-0.007,2.01,1.498,1.975,1.922
	C14.001,17.387,10.371,17.488,9.984,16.94z M12,14.239c-1.271,0-2.3-1.029-2.3-2.3s1.03-2.3,2.3-2.3s2.3,1.03,2.3,2.3
	S13.271,14.239,12,14.239z M16.269,15.31c-0.358,0.122-1.49-1.623-1.228-2.536c0.231-0.809,2.008-1.499,2.405-1.343
	C17.842,11.587,16.905,15.096,16.269,15.31z"/>
            <path fill={fill} stroke={stroke} d="M12,10.036c-1.051,0-1.903,0.853-1.903,1.903c0,1.052,0.853,1.903,1.903,1.903c1.052,0,1.904-0.852,1.904-1.903
	C13.904,10.889,13.052,10.036,12,10.036z M12,13.005c-0.589,0-1.066-0.478-1.066-1.065c0-0.589,0.477-1.066,1.066-1.066
	s1.065,0.478,1.065,1.066C13.065,12.527,12.589,13.005,12,13.005z"/>
            <path fill={fill} stroke={stroke} d="M12,3.132c-4.864,0-8.808,3.943-8.808,8.808S7.136,20.747,12,20.747c4.865,0,8.808-3.943,8.808-8.808S16.865,3.132,12,3.132
	z M12,19.405c-4.124,0-7.466-3.343-7.466-7.466c0-4.124,3.343-7.467,7.466-7.467s7.466,3.343,7.466,7.467
	C19.467,16.063,16.124,19.405,12,19.405z"/>
        </svg>
    );

export default SVG;