import React from 'react';
import styled from 'styled-components';

import moment from 'moment';

import { Link } from 'react-router-dom';

import Icon from '../icons';

import withUser from '../hoc/withUser';

import Dropdown from '../dropdown/Dropdown';
import { DropdownItem, DropdownItemDivider } from '../dropdown/DropdownItem';

const StyledComment = styled.article`
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 1.4rem;

    .avatar {
        margin-right: .7rem;
        display: inherit;
    }

    .avatar img {
        border-radius: 2px;
        max-width: 40px;
        max-height: 40px;
        display: block;
    }

    .comment {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .comment .comment-data {
        display: block;
        padding: 0.8rem 0.8rem 1.2rem 0.8rem;
        background: var(--color-background-post-comment);
        border-radius: 3px;
    }

    .comment .comment-data .comment-info, .comment .comment-data .comment-info .username {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    .comment .comment-data .comment-info {
        margin-bottom: 0.8rem;
    }

    .comment .comment-data .comment-info .username {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;
        text-decoration: none;
        margin-right: 1rem;
    }

    .comment .comment-data .comment-info .username:hover {
        text-decoration: underline;
    }

    .comment .comment-data .comment-info .comment-time {
        font-size: .8rem;
        color: var(--color-text-post-comment-time);
    }

    .comment .comment-data .text {
        font-size: 1rem;
        word-break: break-word;
    }

    .options-container {
        display: flex;
        padding: 0.5rem 1rem 0 1rem;
        align-items: center;
    }

    .options-container .option {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.1s ease-in-out;
        color: #cccccc;
    }

    .options-container .option.delete:hover {
        color: #f94f4f;
    }

    .at-me-bro {
        color: #9658fe;
    }
`;

const parseComment = (authorUsername, commentText) => {
    const parse = commentText.split(' ');

    return (
        <span>
            {parse.map((word, index) => (
                word.charAt(0) === '@' ? <Link key={index} className="at-me-bro" to={`/profile/${word.substring(1)}`}>{word}</Link> : ` ${word} `
            ))}
        </span>
    );
}

const Comment = ({ commentId, authorId, authorUsername, authorAvatar, timeCreated, commentText, deleteComment, userId, roles, canDelete }) => (
    <StyledComment>
        <Link className="avatar" to={`/profile/${authorUsername}`}>
            <img src={authorAvatar || 'https://i.imgur.com/I8xE73K.png'} alt={authorUsername} />
        </Link>
        <div className="comment">
            <div className="comment-data">
                <div className="comment-info">
                    <Link className="username" to={`/profile/${authorUsername}`}>
                        {authorUsername}
                    </Link>
                    <time className="comment-time">{moment(timeCreated).fromNow()}</time>
                </div>
                <div className="text">
                    {parseComment(authorUsername, commentText)}
                </div>
            </div>
            <div className="options-container">
                {canDelete(authorId, userId, roles) && (
                    <div className="option delete" onClick={() => deleteComment(commentId)}>
                        <Icon name='trash-2' fill='currentColor' stroke='none' width={20} height={20} />
                    </div>
                )}
            </div>
        </div>
    </StyledComment>
);

export default withUser(Comment);
