import {
    REQUEST_USER_DETAILS,
    RECEIVE_USER_DETAILS,
} from '../actions/userActions'

import {
  RECEIVE_CLAIM_CHARACTER
} from '../actions/characterActions'
  
const settings = (
  state = {
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case REQUEST_USER_DETAILS:
      return Object.assign({}, {}, {
        isFetching: true,
      })
    case RECEIVE_USER_DETAILS:
      return Object.assign({}, state, {
        isFetching: false,
        ...action.userDetails,
      })
    case RECEIVE_CLAIM_CHARACTER:
      if (state._id == action.ownerId)
        return Object.assign({}, state, {
          isFetching: false,
          characters: action.characters,
        })
    default:
      return state
  }
}
  
export default settings;