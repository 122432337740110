import { verify } from '../services/LoginService';

export const REQUEST_VERIFY = 'REQUEST_VERIFY';
export const RECEIVE_VERIFY = 'RECEIVE_VERIFY';

export const requestVerify = () => ({
  type: REQUEST_VERIFY,
});

export const receiveVerify = (verified) => ({
  type: RECEIVE_VERIFY,
  verified,
});

export const attemptVerify = (token) => (
  dispatch => {
    dispatch(requestVerify())
    return verify(token)
      .then(result => dispatch(receiveVerify(result.verified)))
  }
);