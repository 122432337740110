import React from 'react';
import styled from 'styled-components';

const StyledCommentList = styled.div`

`;

const CommentList = (props) => (
  <StyledCommentList>
    {props.children}
  </StyledCommentList>
);

export default CommentList;