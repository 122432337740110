import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <rect fill={fill} stroke={stroke} x="3.415" y="10.042" width="7.578" height="7.578"/>
	        <polygon fill={fill} stroke={stroke} points="15.354,11.939 10.124,20.998 20.585,20.998 	"/>
	        <path fill={fill} stroke={stroke} d="M17.591,13.664c1.395-1.092,2.293-2.788,2.293-4.696c0-3.295-2.671-5.967-5.967-5.967c-3.295,0-5.967,2.671-5.967,5.967
		h4.117v5.672c0.138,0.045,0.277,0.084,0.418,0.12l2.869-4.969L17.591,13.664z"/>
        </svg>
    );

export default SVG;