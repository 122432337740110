const getUserById = (id) => (
  fetch(`/api/user/${id}`)
    .then(res => res.json())
);

const getUserDetailsById = (id) => (
  fetch(`/api/user/${id}/details`)
    .then(res => res.json())
);

const getUserByUsername = (username, getNsfwPosts) => (
  fetch(`/api/user/name/${username}?nsfw=${!!getNsfwPosts}`)
    .then(res => res.json())
);

const getAuthenticatedUser = () => (
  fetch(`/api/user/`)
    .then(res => res.json())
);

// roles email banned banDate banExpiryDays username createdAt profileColor verified settingViewNSFW
const editUser = (id, changes) => (
  fetch(`/api/user/${id}`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...changes,
    })
  })
    .then(res => res.json())
);

const claimCharacter = (userId, characterServer, characterName, characterFaction, characterGender) => (
  fetch(`/api/user/${userId}/characters/claim`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      server: characterServer,
      name: characterName,
      gender: characterGender,
      faction: characterFaction,
    })
  })
    .then(res => res.json())
);

const getBanVars = (userId) => (
  fetch(`/api/user/${userId}/ban`)
    .then(res => res.json())
)

const banUser = (userId, banExpiryDays) => (
  fetch(`/api/user/${userId}/ban`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      banExpiryDays,
    })
  })
    .then(res => res.json())
);

const unbanUser = (userId) => (
  fetch(`/api/user/${userId}/unban`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
);

const pruneUserContent = (userId) => (
  fetch(`/api/user/${userId}/prune`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
)

const updateAvatar = (userId, avatar) => {
  const data = new FormData();

  data.append('avatar', avatar, `upload_avatar.png`);

  return (
    fetch(`/api/user/${userId}/avatar`, { method: 'POST', body: data, })
      .then(res => res.json())
  )
}

const linkDiscord = (code) => (
  fetch(`/api/accounts/discord/link`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      code,
    })
  })
    .then(res => res.json())
);

const unlinkDiscord = () => (
  fetch(`/api/accounts/discord/unlink`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then(res => res.json())
);

const followUser = userId => {
  return fetch(`/api/user/${userId}/follow`, {
    method: 'GET',
  })
    .then(res => res.json())
}

const unfollowUser = userId => {
  return fetch(`/api/user/${userId}/unfollow`, {
    method: 'GET',
  })
    .then(res => res.json())
}

export {
  getUserById,
  getUserDetailsById,
  getUserByUsername,
  editUser,
  getAuthenticatedUser,
  claimCharacter,
  getBanVars,
  banUser,
  unbanUser,
  pruneUserContent,
  updateAvatar,
  linkDiscord,
  unlinkDiscord,
  followUser,
  unfollowUser
}
