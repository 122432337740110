import {
    REQUEST_POST,
    RECEIVE_POST,
    REQUEST_EDIT_POST_DETAIL,
    RECEIVE_EDIT_POST_DETAIL,
  } from '../actions/postActions'
  
  const post = (
    state = {
      isFetching: false,
      post: {},
      editPost: {},
    },
    action
  ) => {
    switch (action.type) {
      case REQUEST_POST:
        return Object.assign({}, state, {
          isFetching: true,
          post: {},
        })
      case RECEIVE_POST:
        return Object.assign({}, state, {
          isFetching: false,
          post: action.post,
        })
      case REQUEST_EDIT_POST_DETAIL:
        return Object.assign({}, state, {
          isFetching: true,
          editPost: {},
        })
      case RECEIVE_EDIT_POST_DETAIL:
        return Object.assign({}, state, {
          isFetching: false,
          editPost: action.post,
        })
      default:
        return state
    }
  }
  
  export default post;