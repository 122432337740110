import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 184.19 186.236"
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 184.19 186.236"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fillRule="evenodd" clipRule="evenodd" fill={fill} stroke={stroke} d="M95.963,0c5.143,4.95,10.479,9.298,16.171,13.129
		c4.191,2.821,8.662,5.283,13.198,7.517c4.134,2.035,8.49,3.64,12.818,5.251c2.266,0.842,4.657,1.382,7.026,1.895
		c4.154,0.9,8.324,1.745,12.51,2.479c3.432,0.603,6.894,1.037,10.349,1.501c2.25,0.302,4.519,0.48,6.76,0.832
		c0.394,0.061,0.974,0.697,0.991,1.09c0.097,2.194,0.42,4.475-0.028,6.579c-1.009,4.758-0.343,9.564-0.849,14.321
		c-0.348,3.263-0.504,6.549-0.899,9.806c-0.43,3.544-0.949,7.083-1.593,10.595c-0.604,3.291-1.32,6.567-2.165,9.803
		c-1.146,4.38-2.42,8.729-3.723,13.065c-0.807,2.687-1.677,5.362-2.686,7.979c-1.073,2.787-2.305,5.516-3.543,8.235
		c-1.082,2.378-2.229,4.731-3.425,7.055c-1.346,2.616-2.68,5.249-4.2,7.764c-1.908,3.16-3.918,6.27-6.039,9.291
		c-2.34,3.331-4.743,6.63-7.324,9.774c-3.077,3.747-6.229,7.453-9.612,10.919c-3.552,3.642-7.313,7.098-11.17,10.418
		c-4.089,3.519-8.335,6.861-12.636,10.121c-2.818,2.137-5.853,3.989-8.786,5.975c-0.306,0.206-0.588,0.448-1.103,0.844
		c0-22.59,0-44.939,0-67.413c12.651,9.198,25.164,18.297,38.01,27.637c-0.452-1.569-0.773-2.794-1.16-3.999
		c-0.726-2.255-1.488-4.499-2.239-6.747c-0.95-2.844-1.916-5.685-2.855-8.532c-0.598-1.809-1.146-3.632-1.74-5.441
		c-0.993-3.025-2.027-6.038-3.006-9.068c-0.653-2.021-1.208-4.073-1.867-6.091c-0.937-2.865-1.944-5.708-2.884-8.573
		c-0.194-0.594-0.326-1.099,0.46-1.646c3.628-2.529,7.159-5.196,10.732-7.801c4.428-3.229,8.859-6.452,13.297-9.668
		c4.297-3.113,8.608-6.205,12.899-9.325c1.352-0.98,2.659-2.021,3.859-3.299c-0.388,0-0.778,0-1.167,0
		c-15.832-0.001-31.664-0.002-47.496-0.002c-2.857,0-2.88,0.01-3.717-2.623c-1.188-3.739-2.301-7.502-3.521-11.232
		c-1.152-3.527-2.423-7.016-3.594-10.538c-0.679-2.037-1.231-4.116-1.893-6.16c-0.646-1.997-1.38-3.966-2.029-5.963
		c-0.173-0.533-0.248-1.121-0.248-1.685c-0.011-8.927-0.004-17.854,0.005-26.782C95.853,0.922,95.913,0.56,95.963,0z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill={fill} stroke={stroke} d="M88.039,186.064c-1.472-0.94-2.787-1.698-4.014-2.582
		c-3.547-2.552-7.149-5.044-10.561-7.77c-4.006-3.202-7.914-6.537-11.717-9.979c-3.079-2.787-5.88-5.881-8.914-8.723
		c-4.018-3.764-7.477-8.001-10.732-12.407c-3.076-4.163-6.127-8.354-8.97-12.677c-1.917-2.914-3.522-6.043-5.151-9.138
		c-1.819-3.459-3.61-6.94-5.218-10.5c-1.313-2.901-2.371-5.919-3.48-8.908c-0.999-2.692-1.933-5.409-2.857-8.128
		c-0.363-1.065-0.655-2.16-0.918-3.254c-0.914-3.808-1.851-7.61-2.682-11.435c-0.6-2.758-1.135-5.539-1.536-8.332
		c-0.626-4.359-1.233-8.728-1.629-13.111c-0.422-4.664-0.392-9.374-0.931-14.021C8.311,41.487,8.3,37.89,8.204,34.278
		c-0.035-1.322,0.399-1.668,1.692-1.798c5.132-0.517,10.255-1.136,15.365-1.832c2.707-0.368,5.406-0.87,8.065-1.496
		c4.208-0.993,8.424-1.997,12.553-3.269c3.487-1.074,6.931-2.369,10.255-3.875c3.351-1.519,6.587-3.319,9.767-5.181
		c3.084-1.807,6.168-3.666,9.005-5.83c4.181-3.187,8.141-6.666,12.202-10.011c0.243-0.202,0.561-0.312,1.002-0.551
		c0,0.856,0.001,1.508,0,2.16c0,8.181,0.076,16.361-0.054,24.54c-0.031,1.994-0.623,4.006-1.158,5.955
		c-0.554,2.014-1.369,3.955-2.037,5.938c-0.785,2.332-1.547,4.673-2.293,7.018c-0.631,1.979-1.197,3.98-1.845,5.953
		c-0.921,2.805-1.898,5.59-2.84,8.387c-0.524,1.556-1.102,3.1-1.515,4.686c-0.272,1.042-0.847,1.212-1.801,1.21
		c-16.228-0.024-32.457-0.018-48.685-0.018c-0.504,0-1.008,0-1.512,0c-0.029,0.097-0.057,0.191-0.086,0.287
		c1.735,1.265,3.472,2.526,5.206,3.792c3.809,2.781,7.597,5.586,11.424,8.339c4.705,3.386,9.452,6.717,14.16,10.1
		c3.348,2.406,6.634,4.9,10.008,7.271c1.254,0.881,0.737,1.887,0.44,2.857c-0.656,2.146-1.415,4.261-2.133,6.386
		c-0.992,2.929-2.004,5.852-2.97,8.788c-0.605,1.839-1.111,3.71-1.715,5.55c-0.968,2.951-2,5.881-2.962,8.832
		c-0.657,2.014-1.215,4.06-1.873,6.072c-0.79,2.418-1.646,4.812-2.456,7.222c-0.479,1.428-0.924,2.867-1.384,4.301
		c0.089,0.072,0.178,0.143,0.268,0.215c12.511-9.075,25.022-18.149,37.739-27.374C88.039,141.422,88.039,163.609,88.039,186.064z"/>
        </svg>
    );

export default SVG;