import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M11.99,2.172c0.323,0.114,0.662,0.181,0.945,0.343
		c2.306,1.318,4.603,2.652,6.898,3.987c0.667,0.388,0.665,0.723,0.001,1.107c-2.252,1.303-4.514,2.59-6.756,3.911
		c-0.762,0.448-1.453,0.431-2.206-0.012c-2.227-1.31-4.47-2.591-6.707-3.885c-0.694-0.403-0.698-0.731,0-1.137
		C6.462,5.151,8.759,3.818,11.064,2.5C11.333,2.347,11.655,2.288,11.99,2.172z M13.596,7.233c-0.029-0.401-0.106-0.679-0.057-0.931
		c0.165-0.814-0.031-1.206-0.829-1.457c-0.234-0.073-0.489-0.123-0.733-0.117C10.4,4.764,9.171,5.531,8.02,6.632
		c0.4,0.22,0.75,0.413,1.11,0.611c0.073-0.098,0.113-0.153,0.154-0.208c0.439-0.587,0.952-1.069,1.718-1.175
		c0.634-0.088,0.896,0.202,0.757,0.827c-0.039,0.174-0.133,0.365-0.094,0.521c0.056,0.222,0.169,0.473,0.34,0.608
		c0.117,0.092,0.4,0.063,0.563-0.011C12.972,7.618,13.348,7.375,13.596,7.233z M12.773,8.279c0.393,0.224,0.705,0.417,1.034,0.582
		c0.108,0.055,0.283,0.09,0.38,0.041c0.467-0.237,0.918-0.508,1.424-0.793c-0.397-0.225-0.73-0.426-1.075-0.603
		c-0.094-0.048-0.247-0.067-0.332-0.022C13.738,7.726,13.285,7.991,12.773,8.279z"/>
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M3.039,12.603c0-1.18-0.004-2.361,0.001-3.542
		c0.002-0.717,0.32-0.908,0.946-0.549c2.228,1.278,4.449,2.568,6.673,3.854c0.612,0.354,0.912,0.894,0.914,1.59
		c0.007,2.397,0.011,4.794-0.003,7.19c-0.003,0.68-0.303,0.852-0.887,0.517c-2.259-1.295-4.514-2.6-6.764-3.911
		c-0.605-0.353-0.883-0.906-0.881-1.606C3.041,14.964,3.039,13.784,3.039,12.603z M5.342,12.523
		c0.294,0.048,0.543,0.039,0.743,0.134c0.406,0.192,0.859,0.372,1.16,0.685c0.4,0.418,0.241,0.846-0.285,1.092
		c-0.208,0.099-0.429,0.19-0.602,0.336c-0.299,0.252-0.309,0.737-0.026,0.918c0.368,0.237,0.758,0.439,0.987,0.571
		c0.325-0.251,0.531-0.472,0.784-0.596c0.479-0.234,0.75-0.537,0.74-1.112c-0.025-1.461-1.972-3.238-3.501-3.144
		C5.342,11.774,5.342,12.144,5.342,12.523z M6.167,16.246c-0.075,1.499,0.075,1.729,1.323,2.081
		C7.683,17.043,7.557,16.839,6.167,16.246z"/>
        <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M12.439,17.501c0-1.145,0.013-2.289-0.004-3.435
		c-0.013-0.816,0.334-1.382,1.042-1.785c2.155-1.225,4.297-2.475,6.445-3.709c0.756-0.435,1.036-0.271,1.037,0.592
		c0,2.308-0.007,4.614,0.004,6.921c0.002,0.792-0.323,1.356-1.01,1.75c-2.166,1.242-4.324,2.5-6.488,3.747
		c-0.77,0.444-1.021,0.295-1.025-0.594C12.436,19.826,12.439,18.663,12.439,17.501z M15.298,14.534
		c0.443-0.395,0.851-0.792,1.3-1.134c0.158-0.121,0.421-0.106,0.637-0.154c0.005,0.237,0.088,0.51-0.002,0.705
		c-0.214,0.466-0.535,0.883-0.764,1.346c-0.212,0.428-0.36,0.887-0.537,1.332c0.059,0.037,0.117,0.072,0.177,0.108
		c0.431-0.271,0.987-0.449,1.264-0.832c0.448-0.619,0.753-1.354,1.04-2.072c0.18-0.449,0.299-0.956,0.295-1.435
		c-0.004-0.583-0.416-0.852-0.959-0.623c-0.616,0.259-1.176,0.649-1.764,0.976C15.264,13.149,15.109,13.768,15.298,14.534z
		 M17.369,16.646c-1.309,0.497-1.544,0.888-1.265,2.09C17.455,18.142,17.568,17.955,17.369,16.646z"/>
    </svg>
);

export default SVG;