import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    solidEye = false,
    solidEyeFill = '#000'
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
        >
            {/* <path fill={fill} stroke={stroke} d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle> */}
            <path fill={fill} stroke={stroke} d="M12,4C5,4,1,12,1,12s4,8,11,8s11-8,11-8S19,4,12,4z M12,15c-1.657,0-3-1.344-3-3c0-1.657,1.343-3,3-3c1.656,0,3,1.343,3,3
            C15,13.656,13.656,15,12,15z"/>
            { solidEye && <circle fill={solidEyeFill} cx="12" cy="12" r="3"></circle> }
        </svg>
    );

export default SVG;