import React from 'react';
import styled from 'styled-components';

import withUser from '../hoc/withUser';

import { Link } from 'react-router-dom';

import Icon from '../../components/icons';

import Dropdown from '../dropdown/Dropdown';
import { DropdownItem, DropdownItemDivider } from '../dropdown/DropdownItem';

import { postCategories } from '../../util/enums';
import { createStore } from 'redux';

import { gameServers, gameFactions } from '../../util/enums';

import ReactTooltip from 'react-tooltip';


const PostContent = styled.section`
    @media (min-width: 760px) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-flow: row;
    }

    .image-content {
        margin: 0 0 40px 0;
    }

    @media (min-width: 760px) {
        .image-content {
            margin: 0 40px 0 0;
        }
    }

    .image-content .image-wrapper {
        display: block;
        margin: 0 auto;
    }

    @media (min-width: 760px) {
        .image-content .image-wrapper {
            margin: 0;
        }
    }

    .image-content .image-wrapper img {
        display: block;
        width: 100%;
        height: auto;
        background: var(--color-background-post-image);
        border: 1px solid var(--color-border-post-image);
        border-radius: 5px;
    }

    .image-content.symbol .image-wrapper, .image-content.clothing .image-wrapper {
        max-width: 342px;
    }

    // .image-content.symbol .image-wrapper img {
    //     min-width: 280px;
    //     min-height: 280px;
    // }

    // .image-content.clothing .image-wrapper img {
    //     min-width: 280px;
    //     min-height: 469px;
    // }

    // .image-content.vehicle .image-wrapper img {
    //     min-width: 469px;
    //     min-height: 280px;
    // }

    @media (min-width: 760px) {
        .image-content.symbol .image-wrapper img {
            min-width: 340px;
            min-height: 340px;
        }

        .image-content.clothing .image-wrapper img {
            min-width: 340px;
            min-height: 570px;
        }

        .image-content.vehicle .image-wrapper img {
            min-width: 570px;
            min-height: 340px;
        }
    }

    .image-content .image-selector {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        max-width: 340px;
        margin: 4px auto 10px;
    }

    .image-content.vehicle .image-selector {
        max-width: 100%;
    }

    @media (min-width: 760px) {
        .image-content.vehicle .image-selector {
            max-width: 572px;
        }
    }

    .image-content .image-selector img {
        cursor: pointer;
    }

    .image-content .image-selector .image-preview {
        width: 24%;
        max-height: 136px;
        background-color: var(--color-background-post-image-preview);
        border: 1px solid var(--color-border-post-image-preview);
        border-radius: 5px;
    }

    @media (min-width: 760px) {
        .post-info {
            flex-grow: 1;
        }
    }

    .post-info .post-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 2.2rem;
    }

    .post-info .post-author {
        margin-top: 1.2rem;
        display: -ms-flexbox;
        display: flex;
    }

    .post-info .post-author .author {
        display: -ms-inline-flexbox;
        display: inline-flex;
        align-items: center;
        font-size: 1.04rem;
        text-decoration: none;
        color: var(--color-text-username);
    }

    .post-info .post-author .author:visited {
        color: var(--color-text-username);
    }

    .post-info .post-author .author:hover {
        text-decoration: underline;
    }

    .post-info .post-author .author .avatar {
        border-radius: 3px;
        width: 23px;
        height: 23px;
        margin-right: .4rem;
    }

    .post-info .post-description {
        margin-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--color-border-post-description);
    }

    @media (min-width: 760px) {
        .post-info .post-description {
            max-width: 670px;
        }
    }

    .post-info .post-actions {
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
    }

    .post-info .post-actions .action:first-child {
        padding-left: 0;
    }

    .post-info .post-actions .action {
        padding: .8rem .5rem .8rem .5rem;
    }

    .post-info .post-actions .action .like-action {
        display: flex;
        align-items: center;
    }

    .post-info .post-actions .action .like-action .likes {
        margin-left: .2rem;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .post-info .post-actions .action .action-icon {
        cursor: pointer;
        opacity: .5;
        transition: all .2s cubic-bezier(0,1.26,.8,1.28);
    }

    .post-info .post-actions .action .like-action.liked svg,
    .post-info .post-actions .action .action-icon:hover {
        opacity: 1;
    }

    .post-info .post-actions .action .action-icon:hover {
        transform: scale(1.2);
    }

    .post-info .post-actions .action a, .post-info .post-actions .action a img {
        display: block;
    }

    .post-info .post-creator {
        font-size: 14px;
        color: var(--color-text-post-creator-character);
        padding-top: 0.8rem;
        font-weight: 600;
    }

    .post-info .post-selling {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-top: 0.8rem;
    }

    .post-info .post-selling .selling-badge {
        display: flex;
        align-items: center;
        padding: 0.1rem 0.5rem;
        border-radius: 0.2rem;
        background: var(--color-background-post-sell-price);
        color: var(--color-text-post-sell-price);
        font-weight: 500;
    }

    .post-info .post-selling .selling-price {
        margin-left: 0.3rem;
    }

    .post-info .post-selling .selling-small-text {
        font-size: 11px;
    }

    .post-info .post-tags {
        font-size: 0.85rem;
        color: var(--color-text-post-tags);
        padding-top: 0.8rem;
    }
`;

class Post extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedImage: null,
        };
    }

    selectImage = (e) => (this.setState({ selectedImage: e.target.src }));

    render() {
        const selectedImage = this.state.selectedImage;

        return (<PostContent>
            <div className={`image-content ${postCategories.getName(this.props.postCategory)}`}>
                <div className="image-wrapper">
                    <img src={this.state.selectedImage ? this.state.selectedImage : this.props.imageArray[0]} alt={this.props.postTitle} />
                </div>
                {
                    this.props.imageArray.length > 1 ? 
                    (
                        <div className="image-selector">
                            {/* <img className="image-preview" src="https://imgur.com/ZX6Alr1.png" alt={this.props.postTitle} onClick={(e) => this.selectImage(e)} /> */}
                            <img className="image-preview" src={this.props.imageArray[0]} alt={this.props.postTitle} onClick={(e) => this.selectImage(e)} />
                            {(this.props.imageArray[1] && <img className="image-preview" src={this.props.imageArray[1]} alt={this.props.postTitle} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                            {(this.props.imageArray[2] && <img className="image-preview" src={this.props.imageArray[2]} alt={this.props.postTitle} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                            {(this.props.imageArray[3] && <img className="image-preview" src={this.props.imageArray[3]} alt={this.props.postTitle} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                        </div>
                    ) : null
                }
            </div>
            <div className="post-info">
                <h1 className="post-title">
                    {this.props.postTitle}
                </h1>
                <div className="post-author">
                    <Link to={`/profile/${this.props.postAuthorUsername}`} className="author">
                        <img src={this.props.postAuthorAvatar || 'https://i.imgur.com/I8xE73K.png'} alt={this.props.postAuthorUsername} className="avatar" />
                        <span className="username">{this.props.postAuthorUsername}</span>
                    </Link>
                </div>
                <div className="post-description">
                    {this.props.postDescription}
                </div>
                { this.props.creator &&
                    <div className='post-creator'>
                        Created By: <Icon name={this.props.creatorFaction === 0 ? 'enforcer' : 'criminal' } width="15" height="15" fill='currentColor' /> {`${this.props.creatorName} — ${gameServers.getName(this.props.creatorServer)}`}
                    </div>
                }
                { this.props.forSale &&
                    <div className='post-selling'>
                        <span className='selling-badge' data-tip data-for="selling-description">
                            <Icon name='marketplace-icon' fill="currentColor" stroke="none" width="24" height="24" />
                        <span className='selling-price'>{this.props.forSalePrice > 0 ? `APB$ ${this.props.forSalePrice.toLocaleString()}` : `Free`}</span>
                        </span>
                        <ReactTooltip id='selling-description' effect='solid'>
                            <span>{this.props.forSalePrice > 0 ? `This creation is listed for sale at ${this.props.forSalePrice.toLocaleString()} APB $.` : `This creation is Free. Please contact ${this.props.postAuthorUsername}.`}</span>
                        </ReactTooltip>
                    </div>
                }
                <div className="post-actions">
                    <div className="action like">
                        <div className={`like-action${this.props.isLiked && ' liked' || ''}`} onClick={this.props.toggleLike}>
                            {
                                this.props.isLiked ? (
                                    <Icon name='heart' width="24" height="24" fill='var(--color-fill-post-like)' stroke='none' className="action-icon" />
                                ) : (
                                    <Icon name='heart' width="24" height="24" className="action-icon" />
                                )
                            }
                            <span className="likes">{this.props.numLikes}</span>
                        </div>
                    </div>
                    { this.props.canDelete(this.props.postAuthorId, this.props.userId, this.props.roles) && (
                        <div className="action more-options">
                            <Dropdown>
                                <Icon name='more-horizontal' width="24" height="24" className="action-icon" />
                                <DropdownItem link={`/post/${this.props.postId}/edit`}>Edit Post</DropdownItem>
                                <DropdownItemDivider />
                                <DropdownItem onClick={this.props.deletePost}>Delete Post</DropdownItem>
                            </Dropdown>
                        </div>
                    )}
                </div>
                {!!this.props.postTags && this.props.postTags.length > 0 &&
                    <div className="post-tags">
                        Tags: {this.props.postTags.length == 1 ? this.props.postTags[0] : this.props.postTags.join(' ') }
                    </div>
                }
            </div>
        </PostContent>)
    }
}

export default withUser(Post);
