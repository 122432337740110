import bcrypt from 'bcryptjs';

const encryptPassword = (password) => bcrypt.hash(password, '$2a$10$kEDDG/08PIOmHf3HzVs2te');

const login = (email, password, captcha) => (
    encryptPassword(password)
      .then(hash => (
        fetch(`/api/login`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email,
            password: hash,
          })
        })
      ))
      .then(res => res.json())
  );

const discordLogin = (code) => (
  fetch(`/api/accounts/discord/login`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      code
    })
  })
  .then(res => res.json())
);

const logout = () => (
  fetch(`/api/logout`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    }
  })
);

const emailRegister = (username, password, email) => (
  encryptPassword(password)
  .then(hash =>
    fetch(`/api/user`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password: hash,
        email,
      })
    })
  )
    .then(res => res.json())
);

const requestCreateDiscordRegistrationToken = (code) => (
  fetch(`/api/accounts/discord/register/token`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      code,
    })
  })
    .then(res => res.json())
);

const discordRegister = (registrationTokenId, username, email) => (
  fetch(`/api/accounts/discord/register`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      registrationTokenId,
      username,
      email,
    })
  })
    .then(res => res.json())
);

const verify = (token) => (
  fetch(`/api/verify?token=${token}`)
  .then(res => res.json())
);

const requestPasswordReset = (email, captcha) => (
  fetch(`/api/auth/password/reset`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      captcha,
    })
  })
    .then(res => res.json())
);

const resetPasswordWithToken = (token, newPassword, captcha) => (
  encryptPassword(newPassword)
    .then(hash => (
      fetch(`/api/auth/password/reset`, {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token,
          password: hash,
          captcha,
        })
      })
    ))
    .then(res => res.json())
)
  
export {
  login,
  discordLogin,
  logout,
  emailRegister,
  requestCreateDiscordRegistrationToken,
  discordRegister,
  verify,
  requestPasswordReset,
  resetPasswordWithToken,
}