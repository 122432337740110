import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavItemContainer = styled.div`
    display: flex;
    height: 100%;
`;

export default props =>
    <StyledNavItemContainer>
        {props.children}
    </StyledNavItemContainer>