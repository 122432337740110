import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavButton = styled.div.attrs(props => ({
    buttonPadding: !!props.link ? '0' : '0.5rem 1rem',
    linkPadding: !!props.link ? '0.5rem 1rem' : '0',
    background: props.background ? props.background : 'transparent',
    backgroundHover: props.backgroundHover ? props.backgroundHover : '#7668cb',
    backgroundActive: props.backgroundActive ? props.backgroundActive : '#4e4582',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    min-width: 150px;
    padding: ${props => props.buttonPadding};

    font-size: .9rem;
    font-weight: 600;

    color: #fff;
    background: ${props => props.background};
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;

    :hover, :focus {
        background: ${props => props.backgroundHover};
    }

    :active {
        background: ${props => props.backgroundActive};
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        padding: ${props => props.linkPadding};
    }

    a:hover {
        text-decoration: none;
    }
`;

export default props => (
    props.linkTo ?
        <StyledNavButton link={!!props.linkTo} background={props.background} backgroundHover={props.backgroundHover} backgroundActive={props.backgroundActive}>
            <Link to={props.linkTo}>
                {props.children}
            </Link>
        </StyledNavButton>
    :
        <StyledNavButton link={!!props.linkTo} background={props.background} backgroundHover={props.backgroundHover} backgroundActive={props.backgroundActive} onClick={props.onClick}>
            {props.children}
        </StyledNavButton>
);