import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fill={fill} stroke={stroke} fillRule="evenodd" clipRule="evenodd" d="M19.542,17.238c-0.021,0.151,0.017,0.371,0.117,0.469
	c0.656,0.637,1.347,1.236,2.004,1.872c0.528,0.511,0.558,0.86,0.168,1.274c-0.336,0.356-0.922,0.321-1.388-0.08
	c-0.692-0.598-1.38-1.202-2.089-1.78c-0.123-0.101-0.372-0.157-0.516-0.105c-1.275,0.464-2.571,0.498-3.89,0.26
	c-0.588-0.106-1.182-0.184-1.767-0.301c-1.181-0.235-2.04-0.985-2.904-1.763c-2.148-1.933-4.336-3.822-6.503-5.733
	c-1.17-1.032-1.145-2.514-0.016-3.592C3.653,6.905,4.54,6.044,5.426,5.183c0.468-0.458,0.942-0.911,1.391-1.387
	c1.154-1.219,2.816-1.164,4.052-0.012c2.378,2.219,4.883,4.3,7.257,6.523c0.557,0.523,0.926,1.346,1.151,2.099
	C20.139,15.335,19.618,16.707,19.542,17.238z M14.544,8.083c-0.36-0.331-0.636-0.625-0.956-0.854
	c-0.111-0.079-0.407-0.065-0.498,0.025c-0.333,0.334-0.55,0.285-0.896-0.026c-0.574-0.52-1.447-0.532-1.98-0.078
	c-0.376,0.321-0.737,0.674-1.04,1.063c-0.542,0.696-0.509,1.267,0.072,1.941c0.324,0.375,0.695,0.711,1.018,1.087
	c0.431,0.502,0.419,1.009,0.002,1.433c-0.365,0.37-0.817,0.354-1.327-0.049c-0.589-0.468-0.61-0.8-0.075-1.76
	C8.463,10.51,8.05,10.147,7.575,9.727c-0.335,0.42-0.701,0.771-0.921,1.198c-0.132,0.257-0.127,0.664-0.024,0.944
	c0.204,0.549,1.272,0.913,0.303,1.677c-0.01,0.009-0.006,0.036-0.016,0.114c0.131,0.124,0.286,0.273,0.444,0.42
	c0.156,0.144,0.315,0.283,0.475,0.425c0.113-0.078,0.194-0.11,0.24-0.168c0.344-0.441,0.651-0.463,1.141-0.116
	c0.941,0.665,1.612,0.551,2.449-0.251c0.173-0.165,0.354-0.325,0.525-0.492c0.874-0.854,0.864-1.536-0.026-2.393
	c-0.317-0.305-0.652-0.593-0.944-0.92C10.808,9.7,10.85,9.209,11.294,8.858c0.374-0.296,1.055-0.124,1.374,0.271
	c0.494,0.61,0.042,1.018-0.299,1.485c0.366,0.327,0.698,0.642,1.051,0.928c0.087,0.07,0.271,0.103,0.367,0.059
	c0.927-0.415,1.289-1.622,0.642-2.337C14.033,8.824,13.949,8.518,14.544,8.083z M16.711,17.646c-0.062-0.354-0.29-0.8-0.157-1.062
	c0.375-0.742,1.061-0.525,1.714-0.247c-0.245-0.813-0.77-1.214-1.406-1.14c-0.642,0.074-1.238,0.699-1.253,1.313
	C15.597,17.022,16.11,17.51,16.711,17.646z"/>
        </svg>
    );

export default SVG;