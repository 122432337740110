import { connect } from 'react-redux'

import AuthRoute from '../components/AuthRoute'

// taken from withUser hoc (temporary)
const isModerator = (userRoles) => !!userRoles && (userRoles.includes(3) || userRoles.includes(2) || userRoles.includes(1));

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        canAccess: isModerator(currentUser.roles),
    }
}

export default connect(mapStateToProps)(AuthRoute);