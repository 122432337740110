import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} d="M10.293,3.045l0.065-0.043l0.043-0.021h-0.064l-0.043,0.021h-0.042L10.23,3.023l-0.063,0.021l-0.021,0.021h0.064
		L10.293,3.045z"/>
        <path fill={fill} stroke={stroke} d="M6.179,6.499l0.042-0.063L6.179,6.456L6.158,6.499v0.106l0.021,0.022l0.064-0.043L6.2,6.563L6.179,6.499z" />
        <path fill={fill} stroke={stroke} d="M10.188,3.13l0.063-0.021l0.021-0.021V3.066l-0.063,0.021h-0.064l-0.021,0.021l0.021,0.021H10.188z" />
        <path fill={fill} stroke={stroke} d="M10.06,3.173l0.042-0.021V3.13H10.06l-0.021,0.021v0.021H10.06z" />
        <path fill={fill} stroke={stroke} d="M10.55,3.151l-0.063,0.021l0.021,0.042l-0.021,0.021h-0.064l0.021-0.021V3.194h-0.064l-0.064,0.043h-0.064l-0.042-0.021
		h-0.042l-0.042,0.043l-0.085,0.042l0.063-0.064h-0.021L9.974,3.258L9.932,3.237L9.868,3.258L9.847,3.301L9.825,3.322l0.042,0.042
		L9.825,3.407l0.106,0.022l0.042-0.022L9.953,3.385h0.256l0.084-0.042h0.021l0.022-0.021l0.021,0.042h0.043l0.042,0.021l0.128-0.021
		h0.17l-0.021-0.021h0.213l0.021-0.021V3.237l-0.042-0.021h0.021l-0.021-0.042h-0.128l0.042-0.021L10.763,3.13h-0.021V3.108h-0.085
		L10.614,3.13L10.55,3.151z"/>
        <path fill={fill} stroke={stroke} d="M7.011,6.179L6.926,6.243L6.691,6.393L6.583,6.435v0.063H6.52L6.499,6.541l0.085,0.086L6.648,6.67L6.67,6.733L6.583,6.776
		l0.042,0.021l0.15-0.064v0.021L6.691,6.861l0.021,0.042l0.021,0.021L6.84,6.819l0.042-0.085l0.043-0.085H6.883l0.085-0.085
		L6.947,6.478L6.904,6.499L6.883,6.435l0.043-0.042V6.35L6.862,6.371l0.106-0.085l0.106-0.064l0.042-0.021L7.096,6.179H7.011z"/>
        <path fill={fill} stroke={stroke} d="M6.541,6.264l0.021-0.021V6.158L6.541,6.073H6.52L6.499,6.158L6.52,6.243L6.541,6.264z" />
        <path fill={fill} stroke={stroke} d="M9.804,3.151L9.868,3.13l0.085-0.021H9.932l0.021-0.021H9.91l0.021-0.021L9.825,3.087L9.804,3.108H9.719L9.698,3.13v0.043
		L9.804,3.151z"/>
        <path fill={fill} stroke={stroke} d="M10.337,3.108V3.13H10.23l-0.063,0.021l-0.021,0.042v0.021h0.17l0.043-0.021l0.106-0.021l0.021-0.021l0.042-0.021
		l0.086-0.021V3.087h-0.086l-0.063-0.021l-0.063,0.021L10.337,3.108z"/>
        <path fill={fill} stroke={stroke} d="M9.398,3.535L9.313,3.577v0.022h0.021v0.063l0.042-0.021l0.064-0.064l0.021-0.042L9.441,3.514L9.398,3.535z" />
        <path fill={fill} stroke={stroke} d="M7.928,4.154H7.97l0.042-0.043L7.991,4.089l0.085-0.021L8.12,4.005l0.042-0.043l0.021-0.043H8.163l0.063-0.042l0.021-0.021
		L8.12,3.897L7.991,3.962H7.928l0.021,0.043L7.864,4.047h0.148L7.949,4.089L7.928,4.154z"/>
        <path fill={fill} stroke={stroke} d="M9.698,3.215l0.063-0.021H9.676L9.613,3.215L9.59,3.237h0.065L9.698,3.215z" />
        <path fill={fill} stroke={stroke} d="M7.778,4.132L7.735,4.175v0.021l0.086-0.021h0.021V4.132H7.778z" />
        <path fill={fill} stroke={stroke} d="M8.717,4.388L8.696,4.346l0.085-0.107V4.196l0.042-0.107h0.106l0.106-0.084L9.079,3.94l0.042-0.063l0.064-0.064
		L9.143,3.791l0.063-0.043l0.042-0.043L9.313,3.62H9.249L9.207,3.599l0.085-0.022l0.149-0.084L9.377,3.471L9.249,3.493l0.106-0.064
		h0.086L9.42,3.385H9.355L9.228,3.407L9.058,3.45v0.042H8.994L8.929,3.514L8.908,3.556L8.866,3.62L8.844,3.727l0.021,0.021h0.063
		L8.887,3.791L8.866,3.813L8.802,3.876H8.759L8.717,3.962L8.674,4.026L8.631,4.047L8.567,4.089L8.417,4.132L8.332,4.175L8.29,4.196
		L8.248,4.154L8.226,4.111L8.098,4.132L8.076,4.196L8.12,4.26l0.042-0.021L8.12,4.324L8.076,4.409H8.034L7.97,4.494L7.949,4.601
		v0.128l0.042-0.043l0.063-0.085l0.086-0.127L8.076,4.623v0.063l0.107-0.063l0.127-0.107l0.021-0.021l0.021-0.085l0.064-0.063
		l0.086-0.043l0.042,0.021L8.439,4.431L8.417,4.494l0.213-0.042L8.717,4.388z"/>
        <path fill={fill} stroke={stroke} d="M9.569,3.429L9.548,3.471h0.086L9.698,3.45l0.063-0.021V3.385L9.74,3.364L9.719,3.343V3.322L9.74,3.301l0.021-0.021
		l0.042-0.021l0.064-0.021L9.91,3.215l0.021-0.021l0.042-0.021L9.996,3.13H9.953L9.889,3.151L9.868,3.194L9.782,3.215L9.719,3.237
		L9.634,3.258L9.569,3.322L9.548,3.364v0.021L9.569,3.429z"/>
        <path fill={fill} stroke={stroke} d="M7.735,4.281L7.693,4.324v0.021l0.063-0.043l0.022-0.021V4.26L7.735,4.281z" />
        <path fill={fill} stroke={stroke} d="M8.546,4.026l0.021-0.021l0.064-0.022L8.674,3.94H8.631L8.546,3.962L8.481,4.005l0.022,0.021H8.546z" />
        <path fill={fill} stroke={stroke} d="M9.334,3.343h0.128l0.042-0.042l0.021-0.042L9.484,3.237L9.441,3.258H9.355L9.228,3.301L9.207,3.343l0.106-0.021
		L9.334,3.343z"/>
        <path fill={fill} stroke={stroke} d="M15.646,9.655l0.105-0.085l-0.021-0.021V9.526l0.042-0.085l-0.084,0.064l-0.086,0.021v0.043L15.54,9.59l0.042,0.064H15.646
		z"/>
        <path fill={fill} stroke={stroke} d="M12.747,6.861l0.042-0.106L12.747,6.67l-0.128,0.063l0.021,0.064L12.747,6.861z" />
        <path fill={fill} stroke={stroke} d="M12.619,8.929l0.043-0.127l-0.043-0.127l-0.064,0.063l-0.021,0.043l0.042,0.106L12.619,8.929z" />
        <path fill={fill} stroke={stroke} d="M11.531,6.904l-0.064-0.17V6.712L11.36,6.691l0.106-0.107l0.021-0.128h-0.085l-0.085,0.021l0.106-0.15h-0.171l-0.085,0.107
		l-0.042,0.127l0.021,0.085l-0.021,0.128l0.064-0.064l0.021,0.042l-0.042,0.064l0.021,0.042l0.107,0.021v0.021L11.36,6.99
		l-0.042,0.084l-0.128-0.021l-0.021,0.085l0.042,0.085l-0.127,0.042l0.021,0.064l0.148,0.021h0.022l-0.107,0.042l-0.17,0.15
		l0.064,0.021l0.063-0.042l0.106,0.021l0.065-0.064l0.042,0.021l0.17-0.043l0.149,0.021l0.085-0.085l-0.043-0.063l0.063-0.042
		l0.022-0.107L11.659,7.16l-0.021-0.063l-0.042-0.171L11.531,6.904z"/>
        <path fill={fill} stroke={stroke} d="M14.643,9.804l0.172-0.042l0.021-0.063L14.772,9.74V9.698h-0.106L14.581,9.74l-0.086-0.064v0.085h0.147V9.804z" />
        <path fill={fill} stroke={stroke} d="M8.887,5.347l0.064-0.084l0.149-0.064l0.106-0.064L9.271,5.07l0.106-0.064H9.59l0.171-0.021l0.17-0.107l0.085-0.021
		l0.042,0.021h0.17l0.192-0.063l0.192-0.064L10.55,4.729l-0.106-0.021l0.084-0.043l0.043-0.085v0.064l0.021,0.064h0.085l0.043-0.107
		l-0.022-0.063l-0.042-0.043l0.042-0.021l0.064,0.064l0.021-0.043V4.409h0.128l0.042-0.021l-0.021-0.064l0.085,0.021V4.239h0.042
		l0.042-0.085l-0.021-0.064l0.085-0.021l0.042,0.021V4.026l0.065-0.107l0.064-0.064l0.085-0.064H11.36h0.085l0.042-0.021
		l0.129-0.043V3.684l-0.042-0.021h-0.129L11.36,3.684l0.064-0.042V3.62L11.36,3.642l-0.021-0.042l-0.064-0.022V3.556l0.106,0.021
		l0.063,0.022l0.064-0.022l-0.043-0.084L11.36,3.45l-0.063-0.021L11.253,3.45L11.21,3.429L11.083,3.45l-0.042,0.021l-0.042,0.042
		l0.021-0.021l-0.021-0.021l0.021-0.042L10.913,3.45l0.042-0.021l-0.042-0.043h-0.065l-0.042-0.021l-0.063,0.042l-0.043-0.021
		h-0.127l-0.064,0.021L10.486,3.45h-0.171l-0.106-0.043l-0.064,0.022l-0.043,0.064l0.022,0.063l-0.043-0.021l-0.064-0.021
		L9.932,3.577L9.91,3.599l0.021,0.042l0.042,0.021l0.022,0.042L9.974,3.791L9.91,3.813L9.889,3.855L9.761,3.962L9.698,4.026
		L9.655,4.068l-0.15,0.042v0.043h0.043L9.526,4.196L9.505,4.281L9.463,4.239L9.441,4.217L9.355,4.26L9.271,4.303v0.064h0.17
		L9.334,4.431L9.271,4.452H9.164l-0.17,0.085L8.972,4.601L8.866,4.644L8.802,4.772L8.696,4.857L8.652,4.943l-0.085,0.17L8.503,5.241
		L8.481,5.326l0.085,0.042v0.149L8.652,5.54l0.129-0.107L8.887,5.347z"/>
        <path fill={fill} stroke={stroke} d="M11.041,6.99l0.064-0.107L11.02,6.798h-0.064L10.87,6.861l-0.148,0.086l0.021,0.149l-0.106,0.149l0.127,0.043l0.192-0.064
		l0.085-0.128V6.99z"/>
        <path fill={fill} stroke={stroke} d="M10.827,5.411l-0.021-0.085l0.042-0.085L10.742,5.22l-0.128,0.043L10.55,5.22l-0.106,0.043l-0.064-0.086h-0.086
		l-0.084,0.064l0.106,0.064l-0.021,0.021h-0.106l0.085,0.085l-0.085,0.043l0.171,0.085l0.064,0.021h0.064l0.235-0.063L10.827,5.411z
		"/>
        <path fill={fill} stroke={stroke} d="M13.301,9.612l0.106,0.043l0.021-0.085l-0.021-0.043l0.021-0.128L13.343,9.42l-0.127,0.021h-0.149v0.085l0.171,0.064
		L13.301,9.612z"/>
        <path fill={fill} stroke={stroke} d="M12.555,9.292l0.042,0.043l0.043-0.064h0.065V9.058l-0.086-0.106l-0.044,0.064l-0.084-0.021L12.533,9.1L12.555,9.292z" />
        <path fill={fill} stroke={stroke} d="M18.397,16.563l-0.022-0.043l-0.042-0.042l-0.043,0.085l-0.021,0.086l-0.063,0.106l-0.043,0.021v0.044l-0.042,0.085
		l-0.107,0.129l-0.085,0.104h-0.063l-0.045,0.044l-0.063,0.063l-0.065,0.021l-0.042,0.085l-0.043,0.085l-0.021,0.129v0.127
		l-0.021,0.085l-0.085,0.129l-0.022,0.044l-0.042,0.02l-0.064,0.107v0.17l-0.021,0.106v0.043l0.063,0.022h0.063l0.107-0.086
		l0.107-0.064l0.085-0.148l0.107-0.192l0.147-0.277l0.107-0.192l0.085-0.17l0.021-0.128l0.042-0.042l0.021-0.065v-0.085l0.043-0.042
		v0.064l0.042-0.043l0.022-0.086l-0.022-0.042l0.022-0.171V16.563z"/>
        <path fill={fill} stroke={stroke} d="M12.597,3.962l-0.063,0.021l0.042,0.043l-0.021,0.021l0.085,0.021l0.042-0.063h-0.063L12.597,3.962z" />
        <path fill={fill} stroke={stroke} d="M12.447,3.855l0.021,0.021h0.086l0.064-0.021V3.791L12.555,3.77h-0.064l-0.022,0.021L12.426,3.77l-0.063,0.042l0.042,0.042
		H12.447z"/>
        <path fill={fill} stroke={stroke} d="M12.298,4.047l0.064,0.021l-0.021,0.021l0.085,0.064h0.043V4.068l0.022-0.021l-0.022-0.085l0.064-0.043l-0.128-0.042
		l-0.042-0.042L12.32,3.855v0.021h-0.042l-0.021-0.021l-0.064,0.042l0.022,0.064L12.298,4.047z"/>
        <path fill={fill} stroke={stroke} d="M3.855,10.465v-0.043l-0.021-0.043l-0.042-0.042L3.77,10.4l0.022,0.043l0.042,0.042L3.855,10.465z" />
        <path fill={fill} stroke={stroke} d="M13.045,3.663l0.043,0.021l0.021-0.021l-0.021-0.021V3.62h0.021l-0.021-0.021h-0.043V3.62l0.021,0.021L13.024,3.62h-0.022
		l-0.021,0.042l0.043-0.021L13.045,3.663z"/>
        <path fill={fill} stroke={stroke} d="M13.535,3.215h0.191l0.044-0.021l-0.021-0.042L13.621,3.13h-0.149l-0.086,0.021l0.043,0.021L13.535,3.215z" />
        <path fill={fill} stroke={stroke} d="M13.897,3.194l-0.085-0.085l-0.063-0.021l-0.065,0.021l-0.02,0.021h0.02L13.897,3.194z" />
        <path fill={fill} stroke={stroke} d="M13.855,3.705v0.043l-0.021,0.064v0.085l0.021,0.085l0.042,0.022l0.043,0.106h0.043l0.043,0.106L14.09,4.26v0.043
		l0.064,0.043h0.085l0.063,0.021l0.107-0.042l0.021-0.021L14.26,4.26l-0.105-0.063L14.09,4.089l-0.086-0.084l-0.042-0.128V3.705
		l-0.043-0.021l-0.063-0.021V3.705z"/>
        <path fill={fill} stroke={stroke} d="M3.813,11.872v0.064l-0.021,0.021v0.021l0.063,0.042l0.021-0.127l-0.021-0.021H3.813z" />
        <path fill={fill} stroke={stroke} d="M21.552,10.955l-0.169-1.044l-0.256-1.024l-0.363-1.001l-0.448-0.938l-0.532-0.896L19.165,5.22l-0.704-0.747l-0.769-0.682
		l-0.831-0.576l-0.896-0.491l-0.938-0.383l-0.979-0.277l-1.003-0.171L12,1.829l-0.98,0.064l-1.002,0.171L9.036,2.341L8.098,2.725
		L7.203,3.215L6.35,3.791l-0.79,0.661L4.857,5.198L4.217,6.03L3.663,6.925L3.216,7.864l-0.362,0.98L2.619,9.868l-0.15,1.045
		L2.405,12l0.042,1.044l0.171,1.046l0.256,1.022l0.362,1.004l0.447,0.937l0.533,0.897l0.618,0.831l0.704,0.745l0.768,0.684
		l0.831,0.575l0.896,0.489l0.938,0.385l0.98,0.276l1.003,0.17l1.023,0.065l0.98-0.065l1.003-0.17l0.981-0.276l0.938-0.385
		l0.895-0.489l0.853-0.575l0.79-0.662l0.702-0.746l0.64-0.832l0.555-0.895l0.448-0.939l0.363-0.979l0.256-1.023l0.148-1.046
		L21.596,12L21.552,10.955z M17.587,8.588L17.48,8.695l-0.149,0.021l-0.086-0.042h-0.106l-0.086-0.107l-0.042-0.064l0.022-0.042
		V8.397L16.99,8.269l0.063-0.042l-0.042-0.021h-0.043L16.883,8.12l-0.085-0.064l-0.171-0.127l-0.043-0.107l-0.148-0.107v-0.17
		l0.063-0.064l-0.021-0.106l0.064-0.064l0.042-0.107h0.086l0.063-0.042l0.064,0.085l0.063,0.149h-0.085l-0.043,0.042l0.064,0.107
		l-0.085,0.021l0.021,0.042l0.064,0.021l0.106,0.107h0.129l0.042,0.042l0.021,0.064l0.021,0.043l0.085,0.085L17.16,7.864L17.203,7.8
		l0.063,0.064l0.107,0.064l-0.043,0.085h-0.086l0.044,0.128l0.063-0.021l0.043,0.106l0.086,0.021l0.063,0.17l0.063,0.106
		L17.587,8.588L17.587,8.588z M14.41,3.13l-0.064-0.021h-0.021L14.41,3.13L14.41,3.13z M20.551,10.4l0.021,0.171V10.55L20.551,10.4z
		 M9.377,3.194H9.42H9.377z M20.636,12.959l-0.149,0.96l-0.234,0.938l-0.319,0.895l-0.406,0.875l-0.49,0.81l-0.575,0.747
		l-0.641,0.684l-0.702,0.616l-0.769,0.534l-0.81,0.447l-0.853,0.341l-0.875,0.256l-0.896,0.15L12,21.254l-0.896-0.042l-0.896-0.15
		l-0.875-0.256l-0.853-0.341l-0.81-0.447l-0.767-0.534L6.2,18.867l-0.597-0.641L5.561,18.12l-0.042-0.065L5.476,17.97l0.021-0.085
		l0.063-0.042l0.085,0.021l0.043-0.021l0.127,0.021l0.107,0.042v-0.063l0.063-0.022l-0.021-0.128l0.063-0.127l-0.021-0.171
		l0.021-0.043v-0.085l-0.021-0.191l-0.064-0.256l0.021-0.086h0.021l0.063-0.085l0.043-0.129l0.149-0.148l0.043-0.064l0.021-0.234
		l-0.021-0.105l-0.064-0.215l-0.042-0.063l-0.106-0.043l-0.085-0.085l-0.171-0.233l-0.171-0.192l-0.17-0.064L5.262,14.9H5.134
		l0.021-0.086l-0.063-0.106l-0.149-0.15l-0.128-0.127l-0.063,0.064v-0.15L4.58,14.218l-0.042-0.064l0.063-0.085v-0.107l-0.042-0.063
		l-0.042-0.299l-0.021-0.105H4.474l-0.021-0.065l-0.086-0.191l-0.042-0.085l-0.021-0.042l-0.085-0.107l-0.063-0.043l-0.021,0.022
		l-0.085-0.107l-0.021-0.085l-0.043-0.107l-0.021-0.021v-0.085l-0.042-0.15l-0.021-0.063l-0.021-0.064l-0.042-0.021v-0.127
		l-0.042-0.128l-0.043-0.063v-0.085h0.064l-0.086-0.085l-0.085-0.063v0.042l-0.042,0.021l-0.042-0.063l-0.021-0.106l-0.064-0.042
		l-0.043-0.065v-0.064l-0.021-0.128l-0.021-0.042v-0.128l-0.021,0.021v0.085H3.343V11.02l0.149-0.96l0.022-0.084l0.021,0.042
		l0.021,0.021l0.021,0.064l-0.021,0.043v0.042l0.042-0.085H3.62v0.043l0.021,0.021l0.021-0.021l0.021,0.021l0.021,0.021v0.064
		l0.043-0.042v-0.085l-0.021-0.043l0.021-0.043l-0.021-0.021V9.953L3.705,9.932V9.847H3.684L3.663,9.868L3.642,9.782L3.62,9.74
		L3.599,9.761L3.62,9.825L3.599,9.89v0.085L3.578,9.996L3.557,9.932l0.191-0.811l0.321-0.895l0.383-0.79l0.021-0.021l0.021,0.021
		l0.063-0.042l0.042,0.021l0.085-0.064l0.086-0.149L4.793,7.16V7.139l0.084-0.128L4.9,6.925V6.99l0.063-0.086l0.043-0.042
		L4.921,6.968L4.9,7.053L4.835,7.117l0.085-0.085L4.963,6.99l0.043-0.042l0.021-0.043L5.07,6.819L5.049,6.861v0.021L5.028,6.925
		l0.085-0.063l0.085-0.085V6.755l0.042-0.021L5.22,6.755l0.021,0.021l0.063,0.021l0.021-0.043L5.262,6.712h0.042l0.021,0.021
		l0.021,0.021L5.39,6.776l0.021,0.021l0.042,0.021l0.021-0.042l0.021-0.043L5.454,6.776L5.433,6.755L5.476,6.67L5.54,6.605
		l0.021-0.021l0.085-0.043L5.71,6.563h0.064l0.085-0.021L5.88,6.499l0.064,0.042l0.064,0.064L5.859,6.67L5.774,6.776l0.021,0.042
		l0.128-0.042h0.085l0.17,0.021l0.128-0.043V6.691l0.064-0.086L6.2,6.67L6.115,6.627V6.499l0.063-0.106l0.107-0.149l0.107-0.042
		l0.021-0.085L6.35,6.073L6.179,6.094L5.987,6.179L5.945,6.158l0.128-0.064l0.191-0.085H6.35l0.106-0.043l0.042,0.043l0.063,0.064
		l0.064,0.106l0.086,0.042l0.149-0.042l0.106,0.021l0.064-0.021l0.084-0.021L7.246,6.03l0.021-0.064L7.246,5.923l0.085-0.106V5.753
		L7.288,5.667l0.021-0.085l0.021-0.106l0.085-0.064l0.106-0.17L7.65,5.07l0.085-0.106L7.65,4.985L7.479,5.07H7.373l0.063-0.085
		V4.943l0.15-0.128l0.106-0.085l0.042-0.085l0.043-0.043l0.064-0.085l0.021-0.064H7.821V4.409l0.021-0.063H7.8L7.586,4.494
		L7.458,4.58L7.309,4.623L7.225,4.729L7.117,4.835L7.032,4.9L6.926,4.943L6.819,4.964L6.733,4.943L6.67,5.028l-0.192,0.17
		L6.35,5.284H6.307l0.106-0.149l0.127-0.149L6.67,4.878l0.106-0.084V4.686L6.84,4.601l0.042-0.063l0.064-0.064l0.703-0.49
		l0.192-0.106v0.021l0.085-0.042h0.063v0.042l0.085-0.021l0.065-0.021l0.127-0.021l0.063-0.021L8.29,3.855l0.021,0.021h0.106
		l0.064-0.021l0.085-0.042l0.085-0.021L8.717,3.77l0.063-0.065l0.021-0.042V3.62L8.696,3.684L8.567,3.705L8.46,3.727l0.107-0.064
		L8.674,3.62l0.107-0.064H8.674l0.149-0.042l0.042-0.042h0.063l0.171-0.086l0.063-0.042l0.042-0.042H9.101L8.929,3.343L8.866,3.385
		L8.759,3.471l-0.15,0.042V3.493L8.738,3.45h0.021l0.064-0.021l0.021-0.022l0.256-0.106l0.042,0.021l0.17-0.063l0.085-0.043h0.065
		l0.042-0.021V3.173l0.043-0.021H9.505l0.704-0.192h0.149l0.021,0.021l0.043-0.021h0.021l0.703-0.107L12,2.746l0.896,0.042
		l0.895,0.15l0.257,0.063l-0.044,0.043l0.022,0.042l0.042,0.021h-0.064V3.13l-0.042-0.021L13.919,3.13l0.085,0.043l0.064,0.021
		l0.064,0.021v0.021l0.085,0.042h-0.043l0.021,0.042l0.043,0.021l0.021,0.042l0.064,0.065v0.021l0.086,0.042l0.085,0.021v0.042
		L14.473,3.62l0.022,0.042l0.147,0.064l0.13,0.043h-0.106l-0.023,0.042l0.045,0.064l0.105,0.042H14.75l-0.127-0.064l-0.065-0.021
		v0.021l0.108,0.042l0.063,0.085l0.128,0.043l0.128,0.085l0.086,0.043l0.042-0.022l0.086,0.064l0.085,0.021l0.043,0.064l0.063,0.043
		l0.022,0.042l0.043,0.042l-0.043,0.021L15.263,4.26l-0.086-0.063l-0.105-0.021l-0.192-0.106l-0.106-0.042l-0.064-0.021
		l-0.107-0.086l-0.085-0.042l-0.085,0.021l0.021,0.021l0.105,0.086l0.085,0.106l0.045,0.021l0.127,0.043l0.064,0.042l0.043-0.021
		l0.021,0.021l0.106,0.021l0.063,0.064l-0.256-0.021h-0.085l-0.149,0.043l0.02,0.042l0.107,0.021l0.043,0.085l-0.105-0.042v0.063
		l-0.064,0.043l0.02,0.042l-0.062,0.021l-0.023-0.063h-0.042l-0.043,0.149l-0.042,0.086l0.064,0.063l-0.043,0.042l-0.063-0.021
		l-0.064-0.063l0.043-0.043h-0.234l0.085,0.064l0.042,0.063l0.086,0.043l0.043,0.085l-0.086-0.021H14.26l-0.042,0.149l0.106,0.064
		l-0.021,0.042l-0.191-0.042v0.064l0.063,0.042l0.021,0.063h-0.063l-0.106-0.021L13.94,5.22l-0.021-0.043l-0.148-0.064l0.021-0.021
		l0.299,0.021l0.064-0.064l0.021-0.085L14.111,4.9l-0.085-0.043l-0.277-0.063h-0.15L13.472,4.75l-0.022,0.043L13.365,4.75
		l0.042-0.043l-0.191-0.042l-0.064,0.042h-0.085l-0.042,0.107h-0.086l-0.106,0.063l-0.107,0.171L12.64,5.134l-0.106,0.234
		L12.447,5.54L12.32,5.667l-0.171,0.15l-0.063,0.085l0.021,0.299l0.042,0.149l0.129,0.064l0.106-0.042l0.15-0.17l0.063,0.085
		l0.085,0.192l0.085,0.149l0.043,0.128l0.107-0.021l0.021-0.106h0.106l0.021-0.128l-0.021-0.234l0.085-0.042l0.044-0.149
		l-0.107-0.064l-0.085-0.085V5.731l0.106-0.128l0.063-0.106l-0.042-0.065l0.021-0.085l0.086-0.042l0.106,0.042l0.021,0.042
		l-0.021,0.042L13.28,5.561l-0.043,0.085v0.085l0.063,0.107l0.021,0.127l0.085,0.043l0.064,0.064l0.107-0.043l0.104-0.064
		l0.129-0.042l0.106,0.042l-0.043,0.085l-0.085,0.022h-0.085l-0.085,0.042l-0.086,0.042l0.044,0.085l0.063,0.042l0.022-0.021
		l0.02,0.085l0.022,0.106h-0.064l-0.106-0.085l-0.042,0.042l-0.021,0.085l0.021,0.106l0.042,0.107l-0.106,0.064V6.84h-0.086
		l-0.021-0.042l-0.085-0.021L13.13,6.882l-0.128,0.086l-0.063,0.042l-0.042-0.042l-0.107-0.043l-0.042,0.043L12.64,6.99V6.947
		l-0.106-0.022V6.861l-0.021-0.063l0.063-0.107l0.021,0.021l0.022-0.064l-0.044-0.021l-0.021-0.043l0.021-0.043V6.478L12.512,6.52
		l-0.021,0.042l-0.086,0.021l-0.021,0.043L12.362,6.67l0.021,0.149l0.042,0.085V6.99l0.021,0.063l-0.064,0.064l-0.021-0.021h-0.085
		l-0.021,0.021h-0.084l-0.129,0.064l-0.085,0.213l-0.042,0.042L11.83,7.458l-0.085,0.021l-0.043,0.128l-0.256,0.106L11.36,7.65
		l0.021,0.17l-0.15-0.085l-0.127,0.021v0.107l0.148,0.064l0.065,0.085l0.106,0.17l-0.042,0.32l-0.064,0.085h-0.277l-0.127-0.043
		h-0.15l-0.127-0.042l-0.171,0.106l0.042,0.065l-0.021,0.106v0.063l0.021,0.064v0.064l-0.042,0.085l-0.021,0.063l-0.064,0.064
		l-0.021,0.106l0.021,0.064l0.043,0.021v0.106l-0.043,0.128l0.064-0.021l0.063,0.021l0.064-0.042l0.106,0.042l0.021,0.107
		l0.043,0.063l0.063,0.021L10.87,9.74l0.086-0.042h0.297l0.107-0.128l0.106-0.021l0.043-0.107l0.085-0.063l-0.043-0.085l0.043-0.128
		l0.085-0.085l0.021-0.063l0.148-0.021l0.107-0.107V8.802l0.021-0.106l0.169-0.043l0.235,0.043l0.107-0.086l0.042-0.021l0.063-0.085
		l0.043-0.021l0.107,0.042l0.063,0.021l0.043,0.15l0.086,0.085l0.106,0.106l0.106,0.064h0.086l0.063,0.064H13.28l0.042,0.063
		L13.365,9.1l0.042,0.085l0.064,0.085l-0.022,0.042l-0.021,0.085v0.042l0.043-0.021l0.042-0.149l0.043-0.022V9.164l-0.085-0.043
		l0.042-0.106l0.107,0.021L13.706,9.1l0.021-0.063V9.015l-0.105-0.064l-0.086-0.043l-0.106-0.042l0.021-0.042l-0.042-0.042h-0.085
		l-0.148-0.129l-0.064-0.127L13.002,8.46l-0.043-0.085V8.332l-0.021-0.084l0.086-0.064l0.085,0.021l-0.021,0.021V8.29l0.042,0.042
		l0.021-0.063l0.064,0.021v0.042l0.064,0.065v0.042l0.105,0.106l0.107,0.043l0.086,0.042l0.104,0.043l0.065,0.021l0.042,0.042h0.021
		l0.021,0.021v0.149l0.021,0.086l0.063,0.042v0.042h0.021l0.021,0.042l0.086,0.085l0.063,0.064l0.043,0.106l0.063,0.128l0.106,0.064
		l0.086-0.021l-0.064-0.107L14.41,9.42l-0.043-0.085l0.128,0.021l-0.022-0.085l-0.084-0.043l-0.086-0.064l0.043-0.043l-0.064-0.063
		l-0.042-0.085l0.021-0.043l0.086,0.065h0.064l0.042-0.043l-0.106-0.064l0.127-0.063h0.128v0.042l0.065,0.063l0.063-0.106
		l0.043-0.064L14.9,8.717V8.674l-0.107-0.043l-0.021-0.042l-0.064-0.085V8.375l0.042-0.064L14.729,8.12h0.043l0.042-0.042
		l-0.042-0.022l0.042-0.127V7.842L14.9,7.8l0.021,0.064h0.17l0.043,0.021l-0.063,0.063v0.042l0.128,0.021v0.085l0.063,0.021
		l0.106-0.128l0.086-0.042V7.885l-0.086,0.042l-0.064-0.021l-0.042-0.085l0.064-0.085l0.085-0.022l0.043-0.063l0.063-0.021
		l0.063-0.043l0.021,0.021l-0.106,0.106l0.085,0.043l-0.021,0.149l-0.063,0.042l0.128,0.064l0.127,0.021l0.172,0.086l0.064,0.042
		h0.063l0.085,0.042l0.065,0.085l0.021,0.064L16.136,8.46l-0.085,0.021l-0.106,0.063l-0.171-0.021L15.561,8.46L15.39,8.503
		l-0.105,0.064l-0.085,0.128l-0.214,0.021l-0.021,0.127l-0.172,0.042l-0.085,0.171l0.085,0.063l-0.063,0.107l0.105,0.064
		l0.108,0.149l0.128-0.021l0.128,0.063l0.085-0.042V9.377l0.128-0.021l0.106,0.064l0.171-0.064l0.042-0.107l0.107,0.022l0.063-0.022
		l-0.021,0.064l0.063,0.064v0.063l0.044,0.128l-0.022,0.128v0.319l-0.021,0.085l-0.021,0.064l-0.042,0.063l-0.085,0.021
		l-0.107-0.021l-0.022,0.064l-0.042-0.085H15.54l-0.107,0.042l-0.043,0.042l-0.085,0.064l-0.063-0.021l-0.129-0.021h-0.128
		l-0.171,0.021l-0.042-0.042h-0.13l-0.042-0.021h-0.043l-0.063-0.064h-0.171l-0.085,0.042l-0.085,0.085l-0.021,0.085l0.042,0.128
		l-0.042,0.086l-0.064,0.042l-0.149-0.064l-0.213-0.064l-0.127-0.021l-0.086-0.149l-0.192-0.063l-0.085-0.021l-0.064,0.021
		l-0.149-0.042l-0.043-0.021l-0.042-0.085h-0.064l-0.043-0.085l0.087-0.085V9.847l-0.044-0.043V9.719l0.064-0.085l-0.021-0.021
		l-0.107,0.064V9.59l-0.084-0.021l-0.15,0.085h-0.086l-0.043-0.021h-0.127l-0.127,0.064l-0.065-0.022l-0.213,0.022L11.68,9.719
		l-0.128,0.042l-0.085,0.064l-0.149,0.021l-0.128,0.085h-0.064L10.998,9.89H10.87l-0.064-0.086h-0.084l-0.043,0.107l-0.106,0.17
		l-0.107,0.064l-0.15,0.064l-0.085,0.085l-0.021,0.085l-0.064,0.128v0.214l-0.128,0.127l-0.064,0.042l-0.127,0.107H9.698
		l-0.085,0.064l-0.108,0.149l-0.107,0.042L9.334,11.36l-0.021,0.085l-0.042,0.085l-0.043,0.043l-0.085,0.085l-0.064,0.127v0.064
		l-0.064,0.064l-0.063,0.042l-0.022,0.085l-0.021,0.064l0.064,0.085l0.021,0.085l-0.021,0.085v0.257l-0.043,0.147l-0.042,0.085
		v0.087l-0.042,0.086l-0.042,0.085L8.738,13.13l0.063,0.064l0.042,0.148l-0.021,0.064l0.021,0.128v0.043l0.042,0.042v0.021
		l0.021,0.042l0.064,0.022l0.086,0.085l0.042,0.043l0.021,0.042l0.021,0.085l0.042,0.043l0.042,0.022l0.064,0.063l0.063,0.106
		l0.021,0.128l0.021,0.063l0.086,0.107L9.59,14.58l0.043,0.021l0.106,0.107l0.127,0.106l0.128,0.129l0.149,0.084h0.064l0.149-0.042
		l0.106-0.042l0.192-0.023h0.106l0.106,0.045h0.064l0.149,0.042l0.149-0.042l0.086-0.045l0.256-0.085l0.127-0.021l0.128-0.021h0.276
		l0.107,0.107l0.042,0.106l0.085,0.105h0.128l0.064-0.042h0.063l0.17-0.063v0.043l0.043,0.021l0.043,0.064l0.064,0.021l0.064,0.105
		l-0.085,0.15l-0.044,0.191l0.021,0.021l-0.021,0.106l-0.043,0.106l-0.043,0.043v0.043l0.107,0.148l0.107,0.129l0.17,0.148
		l0.129,0.149l0.042,0.105l0.021,0.044l-0.021,0.021l0.085,0.085l0.021,0.086l0.043,0.127l-0.043,0.065v0.02l0.043,0.085
		l0.042,0.087l0.043,0.042v0.085l-0.063,0.17l-0.044,0.065l-0.084,0.085l-0.043,0.063l-0.064,0.13l-0.021,0.063l-0.063,0.127
		l-0.022,0.107v0.191l0.106,0.107l0.022,0.085l0.063,0.149l0.064,0.086l0.042,0.042l0.022,0.063v0.149l0.021,0.17l0.021,0.087
		l0.021,0.105l0.044,0.064l0.085,0.063l0.085,0.128l0.042,0.086l0.064,0.086l0.042,0.063l-0.042,0.021l0.021,0.063l-0.021,0.063
		l0.021,0.021v-0.021l0.043,0.043h0.043l0.042,0.021h0.064l0.086-0.043l0.105-0.021l0.15-0.063h0.042l0.086-0.021h0.128l0.063-0.021
		h0.063l0.021-0.021l0.064-0.021l0.148-0.063l0.107-0.065l0.107-0.085l0.17-0.128l0.085-0.085l0.043-0.064l0.063-0.063l0.021-0.023
		l0.106-0.063l0.042-0.064l0.043-0.085l0.065-0.086l0.021-0.063h-0.021v-0.043l0.063-0.065l0.172-0.105l0.105-0.063l0.064-0.065
		l0.042-0.063l-0.021-0.021l0.043-0.063l0.043-0.128l-0.021,0.021v-0.17l0.021-0.086l0.064-0.043l0.106-0.104l0.063-0.044
		l0.171-0.172l0.15-0.105l0.127-0.085l0.085-0.107l0.063-0.106l0.065-0.105V17.16l0.021-0.213v-0.128l0.021-0.149v-0.065
		l-0.042-0.021l-0.044-0.127l-0.042-0.086l0.021-0.064v-0.042l0.043-0.105v-0.043l-0.085-0.022v-0.085l0.063-0.213l0.064-0.064
		l0.021-0.106l0.043-0.064l0.021-0.106l0.042-0.021l0.044-0.063l0.085-0.085l0.021-0.042l0.021-0.086l0.127-0.236l0.108-0.148
		l0.17-0.214l0.171-0.213l0.128-0.276l0.086-0.213l0.084-0.277l0.043-0.235l0.042-0.213v-0.191l0.023-0.064l-0.023-0.085V12.64
		l-0.042,0.021l-0.043,0.086l-0.063,0.042l-0.044,0.063l-0.042,0.022l-0.064,0.021l-0.042,0.043l-0.043,0.043l-0.106,0.085
		L17.95,13.13l-0.107,0.086l-0.064,0.021l-0.063-0.042l-0.022-0.064l-0.042-0.021l-0.063-0.022l0.063-0.063v-0.065l-0.043-0.042
		l-0.063-0.021l-0.043-0.044l-0.085-0.063l-0.085-0.064l-0.192-0.105l-0.086-0.065l-0.063-0.149l-0.086-0.191l-0.085-0.042
		l-0.063-0.021l-0.065-0.192l-0.042-0.171l0.021-0.042l-0.064-0.149l-0.042-0.021l-0.171-0.107l-0.021-0.106l0.021-0.043
		l-0.149-0.149l-0.063-0.085l-0.063-0.085l-0.15-0.234l-0.106-0.149l-0.086-0.17v-0.022l0.15,0.214l0.064,0.063l0.042,0.043
		l0.021-0.021l0.023-0.085v-0.128l0.02-0.063v0.234l0.065-0.021l0.084,0.107l0.106,0.106l0.086,0.106l0.042,0.022l0.042,0.085v0.042
		l0.064,0.085l0.064,0.021l0.064,0.043l0.106,0.17l0.021,0.107l0.042,0.107l0.107,0.127h0.063l0.107,0.085L17.224,12l0.086,0.128
		l0.064,0.043l0.021,0.063l0.042,0.042l0.021,0.064l0.022,0.064v0.043l0.021,0.063l-0.021,0.022l0.042,0.063l0.043,0.107
		l0.021,0.043v0.085l0.042,0.085l0.086-0.021l0.043-0.044l0.063-0.02l0.022-0.043l0.022-0.022l0.02-0.042l0.022-0.021l0.085-0.043
		l0.063-0.064l0.064-0.086h0.042l0.043-0.02l0.085-0.15l0.149-0.149l0.085-0.107v-0.148l0.065-0.086l0.042-0.021l0.064-0.084
		l0.063-0.021l0.043-0.064l-0.021-0.064l0.021-0.064l0.065-0.021l0.02-0.042v-0.085l0.042-0.085l0.044-0.021v-0.022l-0.044-0.106
		V11.02l0.044-0.021V10.87l0.021-0.064v-0.149l-0.021-0.042h-0.044l-0.02-0.107l-0.065-0.063h-0.063l-0.064,0.021l-0.065-0.021
		l-0.085-0.086l-0.063-0.128v-0.042l-0.022-0.063h-0.021v0.17l-0.042,0.128l-0.021,0.149l-0.064,0.021l-0.085,0.043l-0.064,0.063
		l-0.021-0.042l-0.021,0.021l-0.044-0.042v-0.107l-0.021-0.084L18.035,10.4l-0.022,0.021v0.085l0.043,0.107l-0.021-0.043
		l-0.022-0.043l-0.063-0.021l-0.022-0.042V10.4l-0.021-0.064L17.8,10.294l-0.042-0.042l-0.065-0.021l-0.085-0.128l-0.063-0.106
		V9.953L17.501,9.89l0.064-0.022l0.021-0.085l0.086,0.021l0.042-0.042l0.15,0.171l0.104,0.149h0.107l0.128,0.085h0.128l0.064-0.128
		l0.063-0.063h0.043l0.105,0.17l0.107-0.043l0.106-0.021l0.171-0.043l0.106-0.085l0.106-0.085l0.106-0.127l0.107,0.085l0.063,0.085
		h0.065l0.127,0.064l0.043,0.042v0.085l0.149,0.107l0.043-0.021l0.042-0.171l0.064,0.128l0.064,0.191l0.086,0.192l0.105,0.278
		l0.106,0.17l0.043,0.086l0.063,0.17l0.064,0.128l0.021,0.042l0.042,0.128l0.063,0.192l0.065,0.085v0.171L20.636,12.959
		L20.636,12.959z M15.177,14.239l-0.105-0.064h0.021L15.177,14.239z"/>
    </svg>
);

export default SVG;