import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import ModificationIcon from '../../components/gameObject/modificationIcon';

import { gameModifierCategories } from '../../util/enums';

import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import Icon from '../../components/icons';
import RandomLoadoutGeneratorLogo from '../../static/images/random-loadout-logo.png';
import Advert from '../../components/advert';

import {
    StyledContainer,
    StyledContentContainer,
    StyledLogoContainer,
    StyledLogo,
    StyledCardContentContainer,
    StyledCardContainer,
    StyledCard,
    StyledCardHeader,
    StyledCardHeaderNavContainer,
    StyledCardTitle,
    StyledCardBody,
    StyledWideButton,
    StyledCardBodyText,
    StyledCardCombinedSelectContainer,
    StyledCardCombinedSelect,
    StyledCardCombinedSelectButton,
    StyledOrText,
    StyledRandomLoadoutSection,
    StyledRandomLoadoutSectionTitle,
    StyledRandomLoadoutSectionWeapon,
    StyledRandomLoadoutSectionWeaponIconContainer,
    StyledRandomLoadoutSectionWeaponNameContainer,
    StyledRandomLoadoutSectionModificationContainer,
    StyledRandomLoadoutSectionPresetModification,
    StyledRandomLoadoutSectionModification,
    StyledRandomLoadoutSectionLockedModification,
    StyledLoadoutManagementListItemAction,
} from './components';

const LoadoutPage = (props) => {

    const [selectedLoadout, setSelectedLoadout] = useState(0);
    const [loadoutChosen, setLoadoutChosen] = useState(false);

    const [randomPrimaryWeapon, setRandomPrimaryWeapon] = useState();
    const [randomPrimaryWeaponModifications, setRandomPrimaryWeaponModifications] = useState();
    const [randomSecondaryWeapon, setRandomSecondaryWeapon] = useState();
    const [randomGrenades, setRandomGrenades] = useState();
    const [randomCharacterModifications, setRandomCharacterModifications] = useState();

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const selectLoadout = () => {
        ReactTooltip.hide();
        generateLoadout();
        setLoadoutChosen(true);
    }

    const navigateBack = () => {
        ReactTooltip.hide();
        setLoadoutChosen(false);
    }

    const generateLoadout = () => {
        const loadoutSet = props.getProfileInventory(selectedLoadout);

        // Check to make sure we have a primary weapon to select
        if (loadoutSet.primaryWeapons.length > 0) {
            const randomWeapon = loadoutSet.primaryWeapons[Math.floor(Math.random() * loadoutSet.primaryWeapons.length)];
            const randomModifications = [];

            if (randomWeapon.modificationsVacant > 0) {
                const usedModificationCategories = [];
                
                // 19 is upper rail modification -- counts as an orange mod
                randomWeapon.modifications.forEach(mod => mod.emodifiercategory === 19 ? usedModificationCategories.push(gameModifierCategories.WEAPON_MOD_ORANGE) : usedModificationCategories.push(mod.emodifiercategory));

                const modificationCategories = [];

                if (loadoutSet.primaryWeaponModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.WEAPON_MOD_RED).length > 0)
                    modificationCategories.push(gameModifierCategories.WEAPON_MOD_RED);

                if (loadoutSet.primaryWeaponModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.WEAPON_MOD_BLUE).length > 0)
                    modificationCategories.push(gameModifierCategories.WEAPON_MOD_BLUE);

                if (loadoutSet.primaryWeaponModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.WEAPON_MOD_PURPLE).length > 0)
                    modificationCategories.push(gameModifierCategories.WEAPON_MOD_PURPLE);

                if (loadoutSet.primaryWeaponModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.WEAPON_MOD_ORANGE).length > 0)
                    modificationCategories.push(gameModifierCategories.WEAPON_MOD_ORANGE);

                let availableModificationCategories = modificationCategories.filter(mod => !usedModificationCategories.includes(mod));

                if (availableModificationCategories.length > 0) {
                    const randomModificationCategories = [];

                    for (let i = 0; i < randomWeapon.modificationsVacant; i++) {
                        if (availableModificationCategories.length > 0) {
                            const randomModificationCategoryIndex = Math.floor(Math.random() * availableModificationCategories.length);
                            const randomModificationCategory = availableModificationCategories[randomModificationCategoryIndex];
                            availableModificationCategories.splice(randomModificationCategoryIndex, 1);
                            randomModificationCategories.push(randomModificationCategory);
                        }
                    }

                    for (let i = 0; i < randomModificationCategories.length; i++) {
                        const category = randomModificationCategories[i];
                        const filteredLoadoutModificationsByCategory = loadoutSet.primaryWeaponModifications.filter(mod => mod.emodifiercategory === category);

                        randomModifications.push(filteredLoadoutModificationsByCategory[Math.floor(Math.random() * filteredLoadoutModificationsByCategory.length)]);
                    }
                }
            }

            setRandomPrimaryWeapon(randomWeapon);
            if (!!randomModifications)
                setRandomPrimaryWeaponModifications(randomModifications);
        }

        if (loadoutSet.secondaryWeapons.length > 0) {
            const randomWeapon = loadoutSet.secondaryWeapons[Math.floor(Math.random() * loadoutSet.secondaryWeapons.length)];
            setRandomSecondaryWeapon(randomWeapon);
        }

        if (loadoutSet.grenades.length > 0) {
            const randomGrenade = loadoutSet.grenades[Math.floor(Math.random() * loadoutSet.grenades.length)];
            setRandomGrenades(randomGrenade);
        }

        if (loadoutSet.characterModifications.length > 0) {
            const randomModifications = [];
            const availableModificationCategories = [];

            if (loadoutSet.characterModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.CHARACTER_MOD_GREEN).length > 0)
                availableModificationCategories.push(gameModifierCategories.CHARACTER_MOD_GREEN);

            if (loadoutSet.characterModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.CHARACTER_MOD_BLUE).length > 0)
                availableModificationCategories.push(gameModifierCategories.CHARACTER_MOD_BLUE);

            if (loadoutSet.characterModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.CHARACTER_MOD_ORANGE).length > 0)
                availableModificationCategories.push(gameModifierCategories.CHARACTER_MOD_ORANGE);

            if (loadoutSet.characterModifications.filter(mod => mod.emodifiercategory === gameModifierCategories.CHARACTER_MOD_YELLOW).length > 0)
                availableModificationCategories.push(gameModifierCategories.CHARACTER_MOD_YELLOW);

            if (availableModificationCategories.length > 0) {
                const randomModificationCategories = [];

                for (let i = 0; i < 4; i++) {
                    if (availableModificationCategories.length > 0) {
                        const randomModificationCategoryIndex = Math.floor(Math.random() * availableModificationCategories.length);
                        const randomModificationCategory = availableModificationCategories[randomModificationCategoryIndex];
                        availableModificationCategories.splice(randomModificationCategoryIndex, 1);
                        randomModificationCategories.push(randomModificationCategory);
                    }
                }

                randomModificationCategories.sort();

                for (let i = 0; i < randomModificationCategories.length; i++) {
                    const category = randomModificationCategories[i];
                    const filteredLoadoutModificationsByCategory = loadoutSet.characterModifications.filter(mod => mod.emodifiercategory === category);

                    randomModifications.push(filteredLoadoutModificationsByCategory[Math.floor(Math.random() * filteredLoadoutModificationsByCategory.length)]);
                }

                setRandomCharacterModifications(randomModifications);
            }

        }

        // loadout generated
    }

    const renderRandomPrimaryWeaponModifications = () => {
        const modifications = [];

        randomPrimaryWeapon.modifications.forEach((mod, index) =>
            modifications.push(
                <StyledRandomLoadoutSectionPresetModification key={`preset${index}`}>
                    <ModificationIcon
                        sdisplayname={mod.sdisplayname}
                        sapbdb={mod.sapbdb}
                        icon_url={mod.icon_url}
                        url={mod.url}
                        grayscale={false}
                    />
                </StyledRandomLoadoutSectionPresetModification>
        ));

        randomPrimaryWeaponModifications.forEach((mod, index) =>
            modifications.push(
                <StyledRandomLoadoutSectionModification key={`random${index}`}>
                    <ModificationIcon
                        sdisplayname={mod.sdisplayname}
                        sapbdb={mod.sapbdb}
                        icon_url={mod.icon_url}
                        url={mod.url}
                        grayscale={false}
                    />
                </StyledRandomLoadoutSectionModification>
        ));

        if (randomPrimaryWeaponModifications.length < randomPrimaryWeapon.modificationsVacant) {
            let n = randomPrimaryWeapon.modificationsVacant - randomPrimaryWeaponModifications.length;
            for (let i = 0; i < n; i++) {
                modifications.push(
                    <StyledRandomLoadoutSectionModification key={`vacant${i}`}>
                        <ModificationIcon
                            sdisplayname='Modification Vacant'
                            sapbdb='Mod_Vacant'
                            icon_url={'https://legacy.apbdb.com/static/img/APBMenus_Art/ItemIcons/Item_Vacant.png'}
                            grayscale={false}
                        />
                    </StyledRandomLoadoutSectionModification>
                )
            }
        }

        const lockedModificationSlots = 3 - modifications.length;

        for (let i = 0; i < lockedModificationSlots; i++) {
            modifications.push(
                <StyledRandomLoadoutSectionLockedModification key={`locked${i}`}>
                    <ModificationIcon
                        sdisplayname='Modification None'
                        sapbdb='Mod_None'
                        icon_url={'https://legacy.apbdb.com/static/img/APBMenus_Art/ItemIcons/Item_None.png'}
                        grayscale={false}
                    />
                </StyledRandomLoadoutSectionLockedModification>
            )
        }

        return modifications;
    }

    const renderRandomLoadout = () => {
        return (
            <StyledCardContainer>
                <StyledCard>
                    <StyledCardHeader>
                        <StyledCardHeaderNavContainer>
                            <StyledLoadoutManagementListItemAction data-tip='Back' data-for='__tooltip' onClick={e => navigateBack()}>
                                <Icon name='back-arrow' stroke='none' fill='currentColor' />
                            </StyledLoadoutManagementListItemAction>
                        </StyledCardHeaderNavContainer>
                        <StyledCardTitle>
                            Roll Loadout
                        </StyledCardTitle>
                    </StyledCardHeader>
                    <StyledCardBody padding='2rem'>
                        <StyledRandomLoadoutSectionTitle>
                            Profile: {props.loadoutProfileNames[selectedLoadout]}
                        </StyledRandomLoadoutSectionTitle>
                        { !!randomPrimaryWeapon && (
                            <>
                                <StyledRandomLoadoutSection>
                                    <StyledRandomLoadoutSectionTitle>
                                        Primary Weapon:
                                    </StyledRandomLoadoutSectionTitle>
                                    <StyledRandomLoadoutSectionWeapon>
                                        <StyledRandomLoadoutSectionWeaponIconContainer>
                                            <img src={randomPrimaryWeapon.icon_url} width={64} height={64} alt={randomPrimaryWeapon.sapbdb} />
                                        </StyledRandomLoadoutSectionWeaponIconContainer>
                                        <StyledRandomLoadoutSectionWeaponNameContainer>
                                            {randomPrimaryWeapon.sdisplayname}
                                        </StyledRandomLoadoutSectionWeaponNameContainer>
                                    </StyledRandomLoadoutSectionWeapon>
                                </StyledRandomLoadoutSection>
                                <StyledRandomLoadoutSection>
                                    <StyledRandomLoadoutSectionTitle>
                                        Primary Weapon Modifications:
                                    </StyledRandomLoadoutSectionTitle>
                                    <StyledRandomLoadoutSectionModificationContainer>
                                        {renderRandomPrimaryWeaponModifications()}
                                    </StyledRandomLoadoutSectionModificationContainer>
                                </StyledRandomLoadoutSection>
                            </>
                        )}

                        { !!randomSecondaryWeapon && (
                            <StyledRandomLoadoutSection>
                                <StyledRandomLoadoutSectionTitle>
                                    Secondary Weapon:
                            </StyledRandomLoadoutSectionTitle>
                                <StyledRandomLoadoutSectionWeapon>
                                    <StyledRandomLoadoutSectionWeaponIconContainer>
                                        <img src={randomSecondaryWeapon.icon_url} width={64} height={64} alt={randomSecondaryWeapon.sapbdb} />
                                    </StyledRandomLoadoutSectionWeaponIconContainer>
                                    <StyledRandomLoadoutSectionWeaponNameContainer>
                                        {randomSecondaryWeapon.sdisplayname}
                                    </StyledRandomLoadoutSectionWeaponNameContainer>
                                </StyledRandomLoadoutSectionWeapon>
                            </StyledRandomLoadoutSection>
                        )}

                        { !!randomGrenades && (
                            <StyledRandomLoadoutSection>
                                <StyledRandomLoadoutSectionTitle>
                                    Grenades:
                            </StyledRandomLoadoutSectionTitle>
                                <StyledRandomLoadoutSectionWeapon>
                                    <StyledRandomLoadoutSectionWeaponIconContainer>
                                        <img src={randomGrenades.icon_url} width={64} height={64} alt={randomGrenades.sapbdb} />
                                    </StyledRandomLoadoutSectionWeaponIconContainer>
                                    <StyledRandomLoadoutSectionWeaponNameContainer>
                                        {randomGrenades.sdisplayname}
                                    </StyledRandomLoadoutSectionWeaponNameContainer>
                                </StyledRandomLoadoutSectionWeapon>
                            </StyledRandomLoadoutSection>
                        )}

                        { !!randomCharacterModifications && (
                            <StyledRandomLoadoutSection>
                                <StyledRandomLoadoutSectionTitle>
                                    Character Modifications:
                                </StyledRandomLoadoutSectionTitle>
                                <StyledRandomLoadoutSectionModificationContainer>
                                    { randomCharacterModifications.map((mod, index) =>
                                        <StyledRandomLoadoutSectionModification key={`randomCharMod${index}`}>
                                            <ModificationIcon
                                                sdisplayname={mod.sdisplayname}
                                                sapbdb={mod.sapbdb}
                                                icon_url={mod.icon_url}
                                                url={mod.url}
                                                grayscale={false}
                                            />
                                        </StyledRandomLoadoutSectionModification>
                                    )}
                                </StyledRandomLoadoutSectionModificationContainer>
                            </StyledRandomLoadoutSection>
                        )}

                        <StyledWideButton background='#7668cb' hoverBackground='#6156a5' activeBackground='#4e4582' onClick={e => generateLoadout()}>Reroll Loadout</StyledWideButton>
                    </StyledCardBody>
                </StyledCard>
            </StyledCardContainer>
        )
    };

    const renderHome = () => (
        <StyledCardContainer>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardTitle>
                        Home
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody padding='2rem'>
                    <StyledCardBodyText>
                        Select your unique profile of weapons and modifications to be randomized!
                    </StyledCardBodyText>

                    <StyledCardCombinedSelectContainer>
                        <StyledCardCombinedSelect onChange={e => setSelectedLoadout(e.target.value)} value={selectedLoadout}>
                            {props.loadoutProfileNames.map((name, index) => <option key={`loadout${index}`} id={`__loadout${index}`} name={`__loadout${index}`} value={index}>{name}</option>) }
                        </StyledCardCombinedSelect>
                        <StyledCardCombinedSelectButton background='#7668cb' hoverBackground='#6156a5' activeBackground='#4e4582' onClick={e => selectLoadout()} data-tip='Roll Loadout' data-for='__tooltip' >
                            <Icon name='random' fill='currentColor' stroke='none' strokeWidth={1.5} width={30} height={30} />
                        </StyledCardCombinedSelectButton>
                    </StyledCardCombinedSelectContainer>

                    <StyledOrText>
                        OR
                    </StyledOrText>

                    <StyledCardBodyText>
                        Manage your inventory of profiles, selecting a set of weapons and modifications for each.
                    </StyledCardBodyText>

                    <Link to='/loadout/manage'>
                        <StyledWideButton background='#bd801f' hoverBackground='#9c6a1b' activeBackground='#714e15'>Manage Inventory</StyledWideButton>
                    </Link>
                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    return (
        <StyledContainer>
            <FullscreenPageLayoutWave />
            <StyledContentContainer>
                <StyledLogoContainer>
                    <StyledLogo src={RandomLoadoutGeneratorLogo} />
                </StyledLogoContainer>

                <StyledCardContentContainer>
                    {
                        loadoutChosen ? (
                            renderRandomLoadout()
                        ) : (
                            renderHome()
                        )
                    }
                </StyledCardContentContainer>

                <div>
                    <Advert padding='2rem 0' />
                </div>
            </StyledContentContainer>
        </StyledContainer>
    )
};

export default LoadoutPage;
