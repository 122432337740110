import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { withAlert } from 'react-alert';

import logo from '../../static/images/logo-bg.svg';
import Icon from '../icons';
import NavItemContainer from './NavItemContainer';
import NavItem from './NavItem';
import NavDropdownItem from './NavDropdownItem';
import NavButton from './NavButton';

import ReactTooltip from 'react-tooltip';

import Dropdown from '../../components/dropdown/Dropdown';
import { DropdownItem, DropdownItemDivider } from '../../components/dropdown/DropdownItem';
import NotificationDropdown from '../../containers/notificationDropdown';

const StyledNav = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--color-background-top-nav);
  z-index: 20;
  border-bottom: 1px solid var(--color-border-top-nav);

  @media(max-width: 768px) {
      .hide-mobile {
          display: none;
      }
  }
`;

const Container = styled.div`
  display: flex;
  height: 58px;

  color: var(--color-text-top-nav);
  flex-wrap: wrap;

  .left-pane, .right-pane {
      display: flex;
  }

  .right-pane {
    margin-left: auto;
    display: none;

    @media (min-width: 768px) {
        display: flex;
    }
  }

  .left-pane .logo {
      max-width: 40px;
  }

  .right-pane .profile-avatar {
      width: 42px;
      height: 42px;
      border-radius: 3px;
  }

  .navbar {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    height: 100%;
  }

  .navbar > li, .navbar > li > a  {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .navbar > li {
      padding: 0 15px;
      font-size: .875rem;
  }

  .icon {
      margin: 0;
      border: none;
      border-radius: 0;
      overflow: visible;
      font: inherit;
      color: inherit;
      text-transform: none;
      padding: 0;
      background-color: transparent;
      display: inline-block;
      fill: currentcolor;
      line-height: 0;
  }
`;

const HamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
`;

const Navbar = props => {

  return (
  <StyledNav>
    <Container>
      <div className="left-pane">
          <NavItemContainer>
            <NavItem onClick={props.toggleSidebar}>
              <HamburgerContainer>
                <Icon name="hamburger" width={24} height={24} />
              </HamburgerContainer>
            </NavItem>
            <NavItem linkTo='/'>
                <img src={logo} className="logo" alt="Breakwater" />
            </NavItem>
            <NavItem linkTo='/search'>
                Search
            </NavItem>
            <NavDropdownItem className="hide-mobile" dropdownIcon={true}>
                <div>Tools</div>
                <DropdownItem link={`/loadout`}>Random Loadout Generator</DropdownItem>
                <DropdownItem link={`/colorconverter`}>APB Color Converter</DropdownItem>
                <DropdownItem><a href="https://forums.gamersfirst.com/topic/13865-apbreloaded-streaming-starter-pack/" target="_blank">Streamer Starter Pack</a></DropdownItem>
            </NavDropdownItem>
          </NavItemContainer>
      </div>
      <div className="right-pane">
            <NavItemContainer>
              {
                  props.isLoggedIn ? (
                      <>
                        <NotificationDropdown />
                        <NavDropdownItem right={true} dropdownTooltip={props.userUsername} dropdownTooltipId='user' dropdownIcon={true}>
                            <img className="profile-avatar" src={props.userAvatar || 'https://i.imgur.com/I8xE73K.png'} alt={props.userUsername} />
                            <DropdownItem link={`/profile/${props.userUsername}`}>My Profile</DropdownItem>
                            <DropdownItem link={`/settings`}>Settings</DropdownItem>
                            <DropdownItemDivider />
                            <DropdownItem link="/" onClick={props.logout}>Sign Out</DropdownItem>
                        </NavDropdownItem>
                        <NavButton linkTo='/post/create' data-tip="New Creation" data-for="__tooltip" background="#7668cb" backgroundHover="#6b5fb7">
                            Submit
                        </NavButton>
                    </>
                  ) : (
                    <>
                        <NavItem linkTo='/join'>
                            Join
                        </NavItem>

                        <NavItem linkTo='/login'>
                            Log In
                        </NavItem>

                        <NavButton linkTo='/login' background="#7668cb" backgroundHover="#6b5fb7">
                            Submit
                        </NavButton>
                    </>
                  )
              }
            </NavItemContainer>
        </div>
      </Container>
    </StyledNav>
)};

export default withAlert()(Navbar);
