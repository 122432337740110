import { login, discordLogin } from '../services/LoginService';
import { getAuthenticatedUser } from '../services/UserService';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const REQUEST_DISCORD_LOGIN = 'REQUEST_DISCORD_LOGIN';
export const REQUEST_AUTHENTICATED_USER = 'REQUEST_AUTHENTICATED_USER';
export const RECEIVE_AUTHENTICATED_USER = 'RECEIVE_AUTHENTICATED_USER';

const requestLogin = (email, password, captcha) => ({
  type: REQUEST_LOGIN,
  email,
  password,
  captcha
});

const requestDiscordLogin = (code) => ({
  type: REQUEST_DISCORD_LOGIN,
  code
});

const receiveLogin = (success, message, id, username, email, profileColor, avatar, verified, roles, settingViewNSFW, characters, notifications, unreadNotificationCount, discordId) => ({
  type: RECEIVE_LOGIN,
  success,
  message,
  id,
  username,
  email,
  profileColor,
  avatar,
  verified,
  roles,
  settingViewNSFW,
  characters,
  notifications,
  unreadNotificationCount,
  discordId,
});

const requestAuthenticatedUser = () => ({
  type: REQUEST_AUTHENTICATED_USER,
});

const receiveAuthenticatedUser = (success, id, username, email, profileColor, avatar, verified, roles, settingViewNSFW, characters, notifications, unreadNotificationCount, discordId) => ({
  type: RECEIVE_AUTHENTICATED_USER,
  success,
  id,
  username,
  email,
  profileColor,
  avatar,
  verified,
  roles,
  settingViewNSFW,
  characters,
  notifications,
  unreadNotificationCount,
  discordId,
});


export const attemptLogin = (email, password, captcha) => (
  dispatch => {
    dispatch(requestLogin(email, password, captcha))
    return login(email, password, captcha)
      .then(result => dispatch(receiveLogin(result.success, result.message, result.id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW, result.characters, result.notifications, result.unreadNotifications, result.discordId)))
  }
);

export const attemptDiscordLogin = (code) => (
  dispatch => {
    dispatch(requestDiscordLogin(code))
    return discordLogin(code)
      .then(result => dispatch(receiveLogin(result.success, result.message, result.id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW, result.characters, result.notifications, result.unreadNotifications, result.discordId)))
  }
);

export const refreshUserData = () => (
  dispatch => {
    dispatch(requestAuthenticatedUser())
    return getAuthenticatedUser()
      .then(result => dispatch(receiveAuthenticatedUser(result.success, result._id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW, result.characters, result.notifications, result.notificationCount, result.discordId)))
  }
)