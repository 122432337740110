import React from 'react';
import styled from 'styled-components';

const StyledPageLayout = styled.div`
    display: flex;
    padding-top: ${props => props.hasPadding ? '58px' : '0'};
    min-height: calc(100vh - 200px);
`;

export default props =>
    <StyledPageLayout {...props}>
        {props.children}
    </StyledPageLayout>