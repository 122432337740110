import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    width = "26px",
    height = "26px",
    className = "",
    viewBox = "0 0 26 26"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 26 26"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        fill={fill}
        stroke={stroke}
    >
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,5.4c0,0.1-0.1,0.2-0.2,0.2H6.6c-0.1,0-0.2-0.1-0.2-0.2V4.2C6.4,4.1,6.5,4,6.6,4h1.2C7.9,4,8,4.1,8,4.2V5.4z
		"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M10.3,5.4c0,0.1-0.1,0.2-0.2,0.2H8.9c-0.1,0-0.2-0.1-0.2-0.2V4.2C8.7,4.1,8.8,4,8.9,4h1.2
		c0.1,0,0.2,0.1,0.2,0.2V5.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M12.7,5.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2V4.2C11,4.1,11.1,4,11.2,4h1.2
		c0.1,0,0.2,0.1,0.2,0.2V5.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M15,5.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2V4.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		C14.9,4,15,4.1,15,4.2V5.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M17.3,5.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2V4.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V5.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M19.6,5.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2V4.2C18,4.1,18.1,4,18.2,4h1.2
		c0.1,0,0.2,0.1,0.2,0.2V5.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,7.7C5.7,7.9,5.6,8,5.5,8H4.3C4.1,8,4,7.9,4,7.7V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2c0.1,0,0.2,0.1,0.2,0.2V7.7
		z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M8,7.7C8,7.9,7.9,8,7.8,8H6.6C6.5,8,6.4,7.9,6.4,7.7V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2C7.9,6.3,8,6.4,8,6.5V7.7z"
        />
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,10.1c0,0.1-0.1,0.2-0.2,0.2H4.3c-0.1,0-0.2-0.1-0.2-0.2V8.9c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V10.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,10.1c0,0.1-0.1,0.2-0.2,0.2H6.6c-0.1,0-0.2-0.1-0.2-0.2V8.9c0-0.1,0.1-0.2,0.2-0.2h1.2C7.9,8.7,8,8.8,8,8.9
		V10.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M10.3,7.7c0,0.1-0.1,0.2-0.2,0.2H8.9C8.8,8,8.7,7.9,8.7,7.7V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V7.7z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M12.7,7.7c0,0.1-0.1,0.2-0.2,0.2h-1.2C11.1,8,11,7.9,11,7.7V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V7.7z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M15,7.7C15,7.9,14.9,8,14.8,8h-1.2c-0.1,0-0.2-0.1-0.2-0.2V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V7.7z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M17.3,7.7c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V7.7z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,19.5c0,0.1-0.1,0.2-0.2,0.2H4.3c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,19.5c0,0.1-0.1,0.2-0.2,0.2H6.6c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M10.3,19.5c0,0.1-0.1,0.2-0.2,0.2H8.9c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M12.7,19.5c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M15,19.5c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M17.3,19.5c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M19.6,19.5c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M22,19.5c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V19.5z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M8,21.8C8,21.9,7.9,22,7.8,22H6.6c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M10.3,21.8c0,0.1-0.1,0.2-0.2,0.2H8.9c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M12.7,21.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M15,21.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M17.3,21.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M19.6,21.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V21.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M19.6,7.7c0,0.1-0.1,0.2-0.2,0.2h-1.2C18.1,8,18,7.9,18,7.7V6.5c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V7.7z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,12.4c0,0.1-0.1,0.2-0.2,0.2H4.3c-0.1,0-0.2-0.1-0.2-0.2v-1.2C4,11.1,4.1,11,4.3,11h1.2
		c0.1,0,0.2,0.1,0.2,0.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,12.4c0,0.1-0.1,0.2-0.2,0.2H6.6c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		C7.9,11,8,11.1,8,11.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M19.6,12.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M22,12.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M15,12.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M17.3,12.4c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V12.4z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,14.8c0,0.1-0.1,0.2-0.2,0.2H4.3C4.1,15,4,14.9,4,14.8v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,14.8C8,14.9,7.9,15,7.8,15H6.6c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M19.6,14.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M22,14.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M5.7,17.1c0,0.1-0.1,0.2-0.2,0.2H4.3c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V17.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M8,17.1c0,0.1-0.1,0.2-0.2,0.2H6.6c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V17.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M19.6,17.1c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V17.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill={fill} d="M22,17.1c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V17.1z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M15,14.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#BCBEC1" d="M17.3,14.8c0,0.1-0.1,0.2-0.2,0.2h-1.2c-0.1,0-0.2-0.1-0.2-0.2v-1.2c0-0.1,0.1-0.2,0.2-0.2h1.2
		c0.1,0,0.2,0.1,0.2,0.2V14.8z"/>
    </svg>
);

export default SVG;