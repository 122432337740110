import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { attemptDiscordLogin } from '../../actions/loginActions';
import { getDiscordRegistrationToken } from '../../actions/registrationActions';
import { linkUserDiscord } from '../../actions/userActions';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { feedbackAlertMessages } from '../../util/enums';

const Discord = (props) => {

    const [redirect, setRedirect] = useState();

    useEffect(() => {
        if (props.error)
            switch(props.type) {
                case 'login':
                    toast.error(props.errorDescription);
                    return setRedirect('/login');
                case 'link':
                    toast.error(props.errorDescription);
                    return setRedirect('/settings');
                case 'join':
                    toast.error(props.errorDescription);
                    return setRedirect('/join');
                default:
                    return setRedirect('/');
            }
        else
            switch(props.type) {
                case 'login':
                    props.login(props.code)
                        .then(res => {
                            if (res.success === true) {
                                toast.success(feedbackAlertMessages.USER_LOG_IN_SUCCESS);
                                setRedirect('/');
                            } else {
                                toast.error(res.message);
                                setRedirect('/login');
                            }
                        });
                    break;
                case 'link':
                    props.linkDiscordAccount(props.code)
                        .then(res => {
                            if (res.success === true) {
                                toast.success(res.message);
                                setRedirect('/settings');
                            } else {
                                toast.error(res.message);
                                setRedirect('/settings');
                            }
                        });
                    break;
                case 'join':
                    props.requestRegistrationToken(props.code)
                        .then(res => {
                            if (res.success === true)
                                setRedirect('/join/discord');
                            else {
                                toast.error(res.message);
                                setRedirect('/join');
                            }
                        });
                    break;
                default:
                    return setRedirect('/');
            }
    }, []);

    return <div>{ redirect && <Redirect to={redirect} />}</div>
};

const mapStateToProps = (state, ownProps) => {
    return {
        code: queryString.parse(ownProps.location.search).code,
        type: queryString.parse(ownProps.location.search).type,
        error: queryString.parse(ownProps.location.search).error,
        errorDescription: queryString.parse(ownProps.location.search).error_description,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    login: (code) => {
        return dispatch(attemptDiscordLogin(code))
            .then(result => {
                if (result.success === true && result.id)
                    dispatch({ type: 'WS_CONNECT' });

                return result;
            });
    },
    linkDiscordAccount: (code) =>
        dispatch(linkUserDiscord(code)),
    requestRegistrationToken: (code) =>
        dispatch(getDiscordRegistrationToken(code)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Discord);