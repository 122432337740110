import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';

import { Croppie } from "croppie";

import ReactTooltip from 'react-tooltip';

import SaveIcon from '../../../static/images/svg/save.svg';
import UploadIcon from '../../../static/images/svg/upload.svg';

import './style.css';
import Icon from "../../icons";


const UploadMessage = styled.div`
    text-align: center;
    font-size: 22px;
    cursor: pointer;

    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 1rem;

    padding: 28px 0;
    background: rgba(120, 155, 236, 0.06);
    border: 2px dashed #d5dee8;
    border-radius: 14px;
    color: #bbc2d4;
    transition: all 0.2s ease;
`;

const ImageAction = styled.div`
    width: 86px;
    height: 144px;
    min-height: 144px;
    background-color: #d8dde3;
    border: 1px solid #c3c8ce;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

let c = null;

class AvatarUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFileUploaded: false,
            changeSaved: false,
            avatarImageData: null,
        };

        this.croppieOptions = {
            showZoomer: true,
            enableOrientation: true,
            mouseWheelZoom: 'true',
            viewport: {
                width: this.props.width || 400,
                height: this.props.height || 400,
                type: "square"
            },
            boundary: {
                width: (this.props.boundaryWidth && `${this.props.boundaryWidth}px`) || "500px",
                height: (this.props.boundaryHeight && `${this.props.boundaryHeight}px`) || "500px",
            }
        };

        this.file = React.createRef();
        this.croppie = React.createRef();
        this.img = React.createRef();
    }

    onFileUpload = e => {
        if (!this.state.isFileUploaded)
            c = new Croppie(this.croppie.current, this.croppieOptions);
        else
            this.props.handleModify();

        const fileInput = this.file.current;
        const file = fileInput.files[0];

        if (file) {
            this.setState({ isFileUploaded: true, changeSaved: false }, () => {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => {
                    c.bind({ url: reader.result });

                    const avatarResult = reader.result;

                    this.setState({ avatarImageData: avatarResult });
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };

                fileInput.value = '';
            });
        }
    };

    onSave = e => {
        c.result("blob").then(base64 => {
            this.setState(
                { avatarImageData: base64, changeSaved: true }, () => (this.props.handleUpdate(this.state.avatarImageData))
            );
        });
    };

    render() {
        const { isFileUploaded } = this.state;
        const { defaultImage } = this.props;

        return (
            <div>
                {/* { !isFileUploaded &&
                    (
                        <>
                            <div className="upload-avatar-placeholder">
                                <div className="avatar" />
                            </div>

                            <div className="avatar-crop-options">
                                <div className="option" data-tip data-for="re-upload-image" onClick={e => this.file.current.click()}><img src={UploadIcon} /></div>
                                <ReactTooltip id='re-upload-image' effect='solid'>
                                    <span>Upload Image</span>
                                </ReactTooltip>
                            </div>
                        </>
                    )
                } */}
                { !isFileUploaded &&
                    (
                        <>
                            <div className="upload-avatar-placeholder-two" data-tip data-for="re-upload-image" onClick={e => this.file.current.click()}>
                                { defaultImage ? (
                                    <>
                                        <div className="edit">
                                            <Icon name="edit" width={25} height={25} />
                                        </div>
                                        <img className="avatar" src={defaultImage} />
                                    </>
                                ) : (
                                    <img src={UploadIcon} />
                                )}
                            </div>
                            <ReactTooltip id='re-upload-image' effect='solid'>
                                <span>Upload Image</span>
                            </ReactTooltip>
                        </>
                    )
                }
                <div ref={this.croppie} hidden={this.state.changeSaved} />
                <input
                    type="file"
                    id="files"
                    style={{
                        visibility: 'hidden',
                    }}
                    accept='image/*'
                    ref={this.file}
                    onChange={this.onFileUpload}
                />

                { this.state.changeSaved && (
                    <div className="saved-avatar-preview">
                        <img className="avatar" src={URL.createObjectURL(this.state.avatarImageData)} />
                    </div>
                )}

                { isFileUploaded &&
                    (
                        <div className="avatar-crop-options">
                            <div className="option" data-tip data-for="re-upload-image" onClick={e => this.file.current.click()}><img src={UploadIcon} /></div>
                            <ReactTooltip id='re-upload-image' effect='solid'>
                                <span>Upload Image</span>
                            </ReactTooltip>
                            { !this.state.changeSaved && (
                                <>
                                    <div className="option" data-tip data-for="save-image" onClick={this.onSave}><img src={SaveIcon} /></div>
                                    <ReactTooltip id='save-image' effect='solid'>
                                        <span>Save Avatar</span>
                                    </ReactTooltip>
                                </>
                            )}
                        </div>
                    )
                }
            </div>
        );
    }
}

export default AvatarUploader;