import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth="1",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fill={fill} stroke={stroke} d="M18.369,3.434c-1.313-0.86-2.981-0.514-2.981-0.514S14.444,5.051,12,5.051c-2.445,0-3.388-2.131-3.388-2.131
	S6.943,2.573,5.63,3.434c-1.315,0.814-4.141,3.02-4.141,3.02l2.916,4.094l1.627-1.177v11.778H12h5.968V9.371l1.627,1.177
	l2.917-4.094C22.512,6.454,19.685,4.248,18.369,3.434z"/>
        </svg>
    );

export default SVG;