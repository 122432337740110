import React from 'react';
import styled from 'styled-components';

const StyledFSh1 = styled.div`
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
  height: 72px;
  padding: 0 40px;
  width: 100%;
  align-items: center;
  color: #fff;
  background: #1c1d20;
`;

const StyledStickyContentHeader = styled.div`
  background: #1c1d20db;
  background: #141517e3;
  color: #fff;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  height: auto;
  padding: 24px;
  position: sticky;
  top: 58px;
  width: 100%;
  z-index: 1;
`;

export default StyledStickyContentHeader;