import React from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'
import { withAlert } from 'react-alert';

import { updatePost } from '../../services/PostService';

import Section from '../../components/section/Section';
import SectionHead from '../../components/section/SectionHead';
import Icon from '../../components/icons';
import { postCategories, gameServers } from '../../util/enums';

import './style.css';

import TagsInput from '@incoqnito.io/react-tagsinput';
import '@incoqnito.io/react-tagsinput/react-tagsinput.css';

import Input from '../../components/forms/Input';
import Button from '../../components/forms/Button';
import Switch from '../../components/forms/Switch';
import Select from '../../components/forms/Select';

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: var(--color-text-input-label);
    font-size: .8rem;
    .error & {
        color: var(--color-text-input-label-error);
        border-color: var(--color-border-input-error);
    }
`;

const StyledPostImagePreview = styled.div`
    .image-content {
        margin: 0 0 40px 0;
    }

    @media (min-width: 760px) {
        .image-content {
            margin: 0 40px 0 0;
        }
    }

    .image-content .image-wrapper {
        display: block;
        margin: 0 auto;
    }

    .image-content .image-wrapper img {
        display: block;
        width: 100%;
        height: auto;
        background: var(--color-background-post-image);
        border-radius: 5px;
    }

    .image-content.symbol .image-wrapper, .image-content.clothing .image-wrapper {
        max-width: 342px;
    }

    @media (min-width: 760px) {
        .image-content.symbol .image-wrapper img {
            min-width: 340px;
            min-height: 340px;
        }

        .image-content.clothing .image-wrapper img {
            min-width: 340px;
            min-height: 570px;
        }

        .image-content.vehicle .image-wrapper img {
            min-width: 570px;
            min-height: 340px;
        }
    }

    .image-content .image-selector {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        max-width: 340px;
        margin: 4px auto 10px;
    }

    .image-content.vehicle .image-selector {
        max-width: 100%;
    }

    @media (min-width: 760px) {
        .image-content.vehicle .image-selector {
            max-width: 572px;
        }
    }

    .image-content .image-selector img {
        cursor: pointer;
    }

    .image-content .image-selector .image-preview {
        width: 24%;
        max-height: 136px;
        background-color: var(--color-background-post-image-preview);
        border: 1px solid var(--color-border-post-image-preview);
        border-radius: 5px;
    }
`;

class EditPost extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: '',
            title: '',
            description: '',
            tags: [],
            nsfw: false,
            images: [],
            category: 0,
            characters: [],
            creator: 'DEFAULT',
            forSale: false,
            forSalePrice: 0,
            awaitingResponse: false,
            redirect: null,
            selectedImage: 0,
        };
    }

    componentWillMount() {
        this.props.fetchEditPostData()
            .then(res => {
                if (res.success === true) {
                    const { post } = res;

                    this.setState({
                        id: post._id,
                        title: post.title || '',
                        description: post.description || '',
                        tags: post.tags || [],
                        nsfw: post.nsfw || false,
                        images: post.images || [],
                        category: post.category || 0,
                        characters: post.owner.characters || [],
                        creator: post.creator || 'DEFAULT',
                        forSale: post.forSale || false,
                        forSalePrice: post.forSalePrice || 0,
                    });
                }
                else
                    this.setState({ redirect: '/' });
            });
    }

    selectImage = (e) => (this.setState({ selectedImage: e.target.src }));
    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCheckboxChange = (e) => this.setState({ [e.target.name]: e.target.checked });
    handleImageChange = (images) => this.setState({ images });
    handleTagsChange = (tags) => {
        // clear any old errors
        document.getElementById(`post-tags`).className = 'field';
        document.getElementById(`post-tags-error`).innerHTML = '';
        this.setState({ tags });
    };

    onCreate = () => {
        this.setState({ awaitingResponse: true });

        const fields = { title: '', description: '', creator: '', forSalePrice: '', tags: '' };
        const { id, title, description, creator, forSale, forSalePrice, tags, nsfw } = this.state;
        Object.keys(fields).map(key => document.getElementById(`post-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`post-${key}-error`).innerHTML = '');

        const errors = {
            title: [],
            description: [],
            images: [],
            creator: [],
            forSalePrice: [],
            tags: [],
        };

        if (title.length < 1 || title.length > 30)
            errors.title.push('Title must be between 1 and 30 characters.');

        if (description.length > 500)
            errors.description.push('Description must be no more than 500 characters.');

        if (forSale && (!!!creator || creator == "DEFAULT")) {
            errors.creator.push('Please select a character.');
            if (this.state.characters.length <= 0)
                errors.creator.push('Head over to your settings to add a character!');
        }

        if (forSale && (forSalePrice < 0 || forSalePrice > 9999999999))
            errors.forSalePrice.push('Price must be between $0 and $9,999,999,999');

        if (tags.length > 30)
            errors.tags.push('You can only use up to 30 tags on your creation!');

        if (errors.title.length + errors.description.length + errors.creator.length + errors.forSalePrice.length + errors.tags.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`post-${error}`).className += ' error'
                    document.getElementById(`post-${error}-error`).innerHTML = errors[error].join('<br />')
                }

            this.setState({ awaitingResponse: false });
        } else
            updatePost(id, title, description, tags, nsfw, creator === 'DEFAULT' ? null : creator, forSale, forSale ? (forSalePrice === '' ? 0 : forSalePrice) : null)
                .then(res => {
                    if (!res.success) {
                        Swal.fire({
                            icon: 'error',
                            title: res.message,
                        })

                        this.setState({ awaitingResponse: false });
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Post Updated',
                        })

                        this.setState({ redirect: `/post/${res.id}` });
                    }
                });
    }

    validateTag = (tag) => {
        // clear old errors
        document.getElementById(`post-tags`).className = 'field';
        document.getElementById(`post-tags-error`).innerHTML = '';

        const charLimit = 30;

        if (tag.length <= charLimit)
            return true;
        else {
            document.getElementById(`post-tags`).className += ' error';
            document.getElementById(`post-tags-error`).innerHTML = 'Tag length must be less than 30 characters!';
            return false;
        }
    }

    renderCategories = () => {
        return (
            <div className="choose-category">
                <div>
                    <div className='category selected'>
                        <Icon className="icon" name={`${postCategories.getName(this.state.category)}-icon`} fill="currentColor" stroke="none" width="40px" />
                    </div>
                    <div style={{
                        textAlign: 'center',
                        marginTop: '0.3rem',
                        color: '#2f2f2f',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                    }}>
                        {postCategories.getName(this.state.category)}
                    </div>
                </div>
            </div>
        )
    }

    renderForm = () => {
        return (
            <div className="new-post-form">

                <StyledPostImagePreview>
                    <div className={`image-content ${postCategories.getName(this.state.category)}`} style={{ maxWidth: '600px', margin: '1rem auto', }}>
                        <div className="image-wrapper">
                            <img src={this.state.selectedImage ? this.state.selectedImage : this.state.images[0]} alt={this.state.title} />
                        </div>
                        {
                            this.state.images.length > 1 ?
                                (
                                    <div className="image-selector">
                                        <img className="image-preview" src={this.state.images[0]} alt={this.state.title} onClick={(e) => this.selectImage(e)} />
                                        {(this.state.images[1] && <img className="image-preview" src={this.state.images[1]} alt={this.state.title} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                                        {(this.state.images[2] && <img className="image-preview" src={this.state.images[2]} alt={this.state.title} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                                        {(this.state.images[3] && <img className="image-preview" src={this.state.images[3]} alt={this.state.title} onClick={(e) => this.selectImage(e)} />) || <div className="image-preview"></div>}
                                    </div>
                                ) : null
                        }
                    </div>
                </StyledPostImagePreview>

                <div id="post-title" className="field">
                    <StyledLabel htmlFor="title">Title ({this.state.title.length}/30):</StyledLabel>
                    <Input name="title" type="text" value={this.state.title} onChange={(e) => this.handleChange(e)} />
                    <div id="post-title-error" className="error-message"></div>
                </div>

                <div id="post-description" className="field">
                    <StyledLabel htmlFor="description">Description ({this.state.description.length}/500):</StyledLabel>
                    <Input name="description" type="text" value={this.state.description} onChange={(e) => this.handleChange(e)} />
                    <div id="post-description-error" className="error-message"></div>
                </div>

                <div id="post-tags" className="field">
                    <StyledLabel htmlFor="tags">Tags ({this.state.tags.length}/30):</StyledLabel>
                    <TagsInput value={this.state.tags} addKeys={[9, 13, 188]} onlyUnique={true} maxTags={30} validate={this.validateTag} onChange={this.handleTagsChange} />
                    <div id="post-tags-error" className="error-message"></div>
                </div>

                <div id="post-creator" className="field">
                    <StyledLabel htmlFor="creator">Created By:</StyledLabel>
                    <Select name="creator" value={this.state.creator} onChange={(e) => this.handleChange(e)}>
                        <option value="DEFAULT" disabled>Choose a character</option>
                        {this.state.characters.map(character => (<option key={character._id} value={character._id}>{character.name} - {gameServers.getName(character.server)}</option>))}
                    </Select>
                    <div id="post-creator-error" className="error-message"></div>
                </div>

                <div id="post-nsfw" className="field">
                    <StyledLabel htmlFor="nsfw">NSFW - Sexually Suggestive, Partial Nudity, Nudity:</StyledLabel>
                    <Switch name="nsfw" type="checkbox" checked={this.state.nsfw} onChange={(e) => this.handleCheckboxChange(e)} />
                </div>

                <div id="post-forSale" className="field">
                    <StyledLabel htmlFor="forSale">For Sale:</StyledLabel>
                    <Switch name="forSale" type="checkbox" checked={this.state.forSale} onChange={(e) => this.handleCheckboxChange(e)} />
                </div>

                <div id="post-forSalePrice" className="field" hidden={!!!this.state.forSale}>
                    <StyledLabel htmlFor="forSalePrice">Selling Price (APB $):</StyledLabel>
                    <Input name="forSalePrice" type="number" value={this.state.forSalePrice} min="0" max="9999999999" onChange={(e) => this.handleChange(e)} />
                    <div id="post-forSalePrice-error" className="error-message"></div>
                </div>

                <div className="field new-post-button">
                    <Button disabled={this.state.awaitingResponse} onClick={this.onCreate}>Save</Button>
                </div>
            </div>
        )
    }

    renderFormVerified() {
        return (
            <Section>
                <SectionHead
                    title="Edit Post"
                />

                {
                    this.props.isFetching
                        ? (
                            <div>
                                Loading...
                            </div>
                        )
                        : this.renderForm()
                }
            </Section>
        )
    }

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderFormVerified()
    }
}

export default withAlert()(EditPost);