import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    &[type=text], &[type=password], &[type=number], &[type=email] {
        height: 35px;
        display: inline-block;
        padding: 5px 12px;
        font-size: 14px;
        line-height: 20px;
        color: #3b3b3b;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        outline: none;
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
        -webkit-transition-property: color, background-color, border;
        transition-property: color, background-color, border;
        box-shadow: inset 0 1px 0 rgba(225,228,232,.2);
    }

    &[type=text]:focus, &[type=password]:focus, &[type=number]:focus, &[type=email]:focus {
        outline: none;
        background-color: #fff;
        border-color: #9658fe;
    }

    &[type=text].error, &[type=password].error, .error &[type=text], .error &[type=password], .error &[type=number], .error &[type=email] {
        color: #f0506e;
        border-color: #f0506e;
    }
`;

export default ({ children, ...props }) => <StyledInput {...props}>{children}</StyledInput>;