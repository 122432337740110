import React from 'react';
import { useDropzone } from 'react-dropzone';

import './style.css';

function Dropzone(props) {
    const {
            acceptedFiles,
            getRootProps,
            getInputProps,
            isDragActive,
            
        } = useDropzone({ multiple: false, onDropAccepted: props.onDropAccepted, accept: props.accept });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <section className="dropzone-container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Paste from clipboard, drag-n-drop, or click here to select files</p>
            </div>
        </section>
    );
}

export default Dropzone;