import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Dropdown from '../../components/dropdown/Dropdown';
import NotificationDropdownItem from '../../components/notifications/NotificationDropdownItem';
import NavItem from '../navigation/NavItem';
import NavDropdownItem from '../navigation/NavDropdownItem';
import Icon from '../icons';

import AlertBadge from '../alertBadge';

import './style.css';

const NotificationIconContainer = styled.div`
    display: flex;
    position: relative;
`;

const StyledDropdownHeader = styled.div`
    padding: 1rem;
    background: var(--color-background-dropdown-header);
    border-radius: 5px 5px 0 0;
    color: var(--color-text-dropdown-header);
    font-weight: 600;
    border-bottom: 1px var(--color-border-dropdown-header) solid;
`;

const StyledNotificationDropdownContainer = styled.ul`
    padding: 0px;
    margin: 0px;
    list-style: none;
    width: 300px;

    li, li .notification-icon {
        transition: background 0.1s ease;
    }

    // li:first-child {
    //     border-radius: 5px 5px 0 0;
    // }

    // li:last-child {
    //     border-radius: 0 0 5px 5px;
    // }
`;

const EmptyNotificationMessage = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    color: #1a1a1a;
    color: var(--color-text-dropdown-header);
    padding: 1rem;
    font-weight: 400;
`;

class NotificationDropdown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sentReadNotifications: [],
            dropdownShown: false,
        };

        this.dropdownRef = React.createRef();
    }

    onShow = () => {
        const { notifications } = this.props;
        const unreadNotifications = notifications.filter(notification => !notification.read).map(notification => notification._id);
        
        if (unreadNotifications.length > 0)
            this.sendReadNotificationsToServer(unreadNotifications);

        this.setState({ dropdownShown: true });
    };

    onClose = () => {
        const { notifications } = this.props;
        const unreadNotifications = notifications.filter(notification => !notification.read).map(notification => notification._id);

        if (unreadNotifications.length > 0)
            this.props.markStateNotificationsRead(unreadNotifications);

        this.setState({ dropdownShown: false, sentReadNotifications: [] });
    }

    sendReadNotificationsToServer = (notificationIds) => {
        const { sentReadNotifications } = this.state;
        
        // filter out sent notifications
        const unsentNotificationIds = notificationIds.filter(id => !sentReadNotifications.includes(id));

        if (unsentNotificationIds.length > 0) {
            let newSentNotifications = sentReadNotifications;

            unsentNotificationIds.forEach(id => newSentNotifications.push(id));

            this.setState({ sentReadNotifications: newSentNotifications });

            // console.log('Notifications we are sending to server:', unsentNotificationIds);
            // console.log('Notifications marked as sent in our state:', this.state.sentReadNotifications);
            
            this.props.sendReadNotificationsToServer(unsentNotificationIds);
        }
    }

    componentDidUpdate(prevProps) {
        // If our dropdown is currently open
        if (this.state.dropdownShown) {
            // Check to see if we have new notifications
            const unreadNotifications = this.props.notifications.filter(notification => !notification.read).map(notification => notification._id);
            const oldUnreadNotifications = prevProps.notifications.filter(notification => !notification.read).map(notification => notification._id);
            let sendNotificationIds = [];

            if (unreadNotifications.length > oldUnreadNotifications.length) {
                for (const notificationId in unreadNotifications) {
                    if (!oldUnreadNotifications.includes(notificationId)) {
                        sendNotificationIds.push(unreadNotifications[notificationId]);
                    }
                }

                if (sendNotificationIds.length > 0) {
                    this.sendReadNotificationsToServer(sendNotificationIds);
                }
            }
        }
    }

    renderNotificationBadge(unreadNotificationCount) {
        return unreadNotificationCount > 0 ? <AlertBadge alertNum={unreadNotificationCount} /> : null;
    }

    render() {
        const { notifications, unreadNotificationCount } = this.props;
        const shownNotifications = notifications;
        const notificationsCount = shownNotifications.length;
        const badgeStyle = {
            top: '5px',
            right: notificationsCount >= 10 ? '-45px' : '-40px',
            backgroundColor: 'rgb(150, 88, 254)'
        };

        return (
            <NavDropdownItem right={true} dropdownTooltip="Notifications" dropdownTooltipId='nav-notifications' dropdownIcon={true} padding={'0px'} onShowMenu={this.onShow} onCloseMenu={this.onClose} ref={this.dropdownRef}>
                <NotificationIconContainer>
                    <Icon className="icon" name="bell" fill="currentColor" />
                    { this.renderNotificationBadge(unreadNotificationCount > 99 ? 99 : unreadNotificationCount) }
                </NotificationIconContainer>
                <StyledDropdownHeader className="dropdown-header">
                    Notifications
                </StyledDropdownHeader>
                <StyledNotificationDropdownContainer>
                    {notificationsCount > 0
                        ? shownNotifications.map(notification => {
                            const { sender, referenceEntityId, referenceEntityType, notifyingEntityType, createdAt, read } = notification;

                            return (
                                <NotificationDropdownItem key={notification._id} referenceEntity={referenceEntityId} senderUsername={sender.username} senderAvatar={sender.avatar} notificationType={notifyingEntityType} timestamp={createdAt} read={read} closeDropdown={this.dropdownRef.current ? this.dropdownRef.current.hideDropdown : null} />
                            );
                        })
                        : (
                            <EmptyNotificationMessage>
                                No activity yet!
                            </EmptyNotificationMessage>
                        )
                    }
                </StyledNotificationDropdownContainer>
                <Link className={'notification-dropdown-button'} to={'/notifications'}>
                    View All Notifications
                </Link>
            </NavDropdownItem>
        );
    }
}

export default NotificationDropdown;