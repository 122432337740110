import React from 'react';
import styled from 'styled-components';

import BackgroundImage from '../../static/images/backgrounds/art/art-1.png';

export const StyledContainer = styled.div`
    background: url(${BackgroundImage});
    background-size: cover;
    position: relative;

    @media(min-width: 425px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
`;

export const StyledContentContainer = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
`;

export const StyledLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem 0;
`;

export const StyledLogo = styled.img`
    display: flex;
    max-width: 100%;
`;

export const StyledCardContentContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledCardContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: ${props => props.width || '420px'};
`;

export const StyledCard = styled.div`
    font-family: 'Inter', sans-serif;
    color: #fff;
    width: 100%;
`;

export const StyledCardHeader = styled.div`
    display: flex;
    background: #1c1d20;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0 0;
    border: 1px solid #464749;
    position: relative;
`;

export const StyledCardHeaderNavContainer = styled.div`
    position: absolute;
    left: 15px;
    top: 0px;
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
`;


export const StyledCardTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
`;

export const StyledCardBody = styled.div`
    background: #27282c;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 8px 8px;
    padding: ${props => props.padding ? props.padding : '0px'};

    border: 1px solid #464749;
    border-top: 0;

    overflow: hidden;
`;

export const StyledWideButton = styled.button`
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #fff;
    padding: 1rem;
    width: 100%;
    border-radius: 3px;
    border: 1px solid transparent;
    background: ${props => props.background || '#198754'};
    font-size: 1rem;
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    :hover, :focus {
        background: ${props => props.hoverBackground || '#146d44'};
    }

    :active {
        background: ${props => props.activeBackground || '#0f5434'};
    }
`;

export const StyledLoadoutManagementList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin-top: 1.5rem;
`;

export const StyledLoadoutManagementListItem = styled.li`
    display: flex;
    align-items: center;
    list-style: none;
    padding: .5rem 0;
`;

export const StyledLoadoutManagementListItemTitle = styled.div`
    flex: 1;
    font-size: 1.1rem;
    word-break: break-word;
`;

export const StyledLoadoutManagementListItemActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLoadoutManagementListItemAction = styled.div.attrs(props => ({
    color: props.disabled ? '#afafaf' : (props.color || '#ffffff'),
    hoverColor: props.disabled ? '#afafaf' : (props.hoverColor || '#afafaf'),
    cursor: props.disabled ? 'no-drop' : 'pointer',
}))`
    display: flex;
    margin-right: 1rem;
    color: ${props => props.color};
    transition: color 0.1s ease-in-out;
    cursor: ${props => props.cursor};

    :hover {
        color: ${props => props.hoverColor};
    }

    :last-child {
        margin: 0;
    }
`;

export const StyledLoadoutManagementListItemActionLink = styled.a.attrs(props => ({
    color: props.disabled ? '#afafaf' : (props.color || '#ffffff'),
    hoverColor: props.disabled ? '#afafaf' : (props.hoverColor || '#afafaf'),
    cursor: props.disabled ? 'no-drop' : 'pointer',
}))`
    display: flex;
    margin-right: 1rem;
    color: ${props => props.color};
    transition: color 0.1s ease-in-out;
    cursor: ${props => props.cursor};

    :hover {
        color: ${props => props.hoverColor};
    }

    :last-child {
        margin: 0;
    }
`;

export const StyledCardSplitView = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 425px) {
        flex-direction: row;
    }
`;

export const StyledCardSplitViewMain = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const StyledCardSidebar = styled.div`
    display: flex;
    flex-direction: column;
    background: #323338;
    border-bottom: 1px solid #464749;

    @media(min-width: 425px) {
        width: 35%;
        border-bottom: 0px;
        border-right: 1px solid #464749;
    }
`;

const rowItemHeight = '56px';

export const StyledCardSidebarItem = styled.div.attrs(props => ({
    borderLeft: props.selected ? '#7668cb' : 'transparent',
    background: props.selected ? '#2d2e33' : 'transparent',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${rowItemHeight};
    border-left: 5px solid ${props => props.borderLeft};
    border-bottom: 1px solid #464749;
    cursor: pointer;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
    background: ${props => props.background};

    :hover {
        background: #2d2e33;
    }

    :last-child {
        border-bottom: 0px;
    }
`;

export const StyledCardSplitMainInputBoxContainer = styled.div`
    display: flex;
    width: 100%;
    height: ${rowItemHeight};
    border-bottom: 1px solid #464749;
`;

export const StyledCardSplitMainInputBox = styled.input`
    display: flex;
    flex: 1;
    font-family: 'Inter', sans-serif;
    background: #333439 !important;
    width: 100%;
    height: 100% !important;
    border-radius: 0px !important;
    font-size: 1.2rem !important;
    border: 0px !important;
    font-weight: 500;
    box-shadow: none !important;
    color: #ffffff !important;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out !important;

    :placeholder {
        color: #7c7e87 !important;
    }

    :focus {
        background: #2d2e33 !important;
    }
`;

export const StyledCardSplitMainInputBoxButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width: ${rowItemHeight};
    background: #333439;
    border: 0px;
    border-left: 1px solid #464749;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
    cursor: pointer;
    box-shadow: none;
    outline: none;

    :hover, :focus {
        background: #2d2e33;
    }

    :active {
        background: #7668cb;
    }
`;

export const StyledCardBodyText = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    margin-bottom: 1.5rem;
`;

export const StyledOrText = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin: 1.5rem 0;
`;

export const StyledCardCombinedSelectContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const StyledCardCombinedSelect = styled.select`
    display: flex;
    flex: 1;
    font-family: 'Inter', sans-serif;
    background: #333439 !important;
    width: 100%;
    height: 100% !important;
    border-radius: 3px 0 0 3px;
    font-size: 1rem !important;
    border: 0px !important;
    font-weight: 500;
    box-shadow: none !important;
    color: #ffffff !important;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out !important;
    padding: 1rem;

    :placeholder {
        color: #7c7e87 !important;
    }

    :focus {
        background: #2d2e33 !important;
    }
`;

export const StyledCardCombinedSelectButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width: ${rowItemHeight};
    background: ${props => props.background ? props.background : '#333439'};
    border: 0px;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border-radius: 0 3px 3px 0;

    :hover, :focus {
        background: ${props => props.hoverBackground ? props.hoverBackground : '#2d2e33'};
    }

    :active {
        background: ${props => props.activeBackground ? props.activeBackground : '#7668cb'}
    }
`;

export const StyledRandomLoadoutSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: .8rem;
`;

export const StyledRandomLoadoutSectionTitle = styled.div`
    display: flex;
    margin-bottom: .5rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: #ffffff;
`;

export const StyledRandomLoadoutSectionWeapon = styled.div`
    display: flex;
    border: 2px solid #000;
    border-radius: 3px;
`;

export const StyledRandomLoadoutSectionWeaponIconContainer = styled.div`
    width: 64px;
    height: 64px;
    display: flex;
    border-radius: 3px 0 0 3px;

    background: #646464;
    background: linear-gradient(to bottom, #646464 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);
`;

export const StyledRandomLoadoutSectionWeaponNameContainer = styled.div`
    display: flex;
    flex: 1;
    max-width: 100%;
    padding: 0.2rem;
    border-radius: 0 3px 3px 0;

    background: rgb(132,132,132);
    background: linear-gradient(180deg, rgba(132,132,132,1) 0%, rgba(96,96,96,1) 49%, rgba(63,63,63,1) 51%, rgba(63,63,63,1) 100%);
`;

export const StyledRandomLoadoutSectionModificationContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    :last-child {
        margin: 0px;
    }
`;

export const StyledRandomLoadoutSectionModification = styled.div`
    display: inline-flex;
    background: #646464;
    background: linear-gradient(to bottom, #646464 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);
    border: 2px #000 solid;
    border-radius: 3px;
    margin-right: 0.4rem;
`;

export const StyledRandomLoadoutSectionPresetModification = styled.div`
    display: inline-flex;
    background: rgb(94,54,54);
    background: linear-gradient(180deg, rgba(94,54,54,1) 0%, rgba(86,38,37,1) 49%, rgba(80,27,23,1) 51%, rgba(80,27,23,1) 100%);
    border: 2px #000 solid;
    border-radius: 3px;
    margin-right: 0.4rem;
`;

export const StyledRandomLoadoutSectionLockedModification = styled.div`
    display: inline-flex;
    background: #282828;
    border: 2px #000 solid;
    border-radius: 3px;
    margin-right: 0.4rem;
`;

export const StyledImportProfileButton = styled.button`
    background: #333439;
    border: 0px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
    padding: 1rem;
    transition: background 0.1s ease-in-out, border 0.1s ease-in-out;
    width: 100%;

    :focus, :hover {
        background: #2d2e33;
    }

    :active {
        background: #232427;
        border: none;
    }
`;