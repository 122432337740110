import { connect } from 'react-redux'
import { sendEmailRegistration } from '../actions/registrationActions'
import Register from '../pages/register/register'

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        redirect: currentUser.id ? `/profile/${currentUser.username}` : null
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    register: (username, password, email) => {
        return dispatch(sendEmailRegistration(username, password, email))
            .then(result => {
                if (result.success === true && result.id)
                    dispatch({ type: 'WS_CONNECT' });

                return result;
            });
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Register);