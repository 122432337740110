import { requestCreateDiscordRegistrationToken, emailRegister, discordRegister } from '../services/LoginService';

export const REQUEST_DISCORD_REGISTRATION_TOKEN = 'REQUEST_DISCORD_REGISTRATION_TOKEN';
export const RECEIVE_DISCORD_REGISTRATION_TOKEN = 'RECEIVE_DISCORD_REGISTRATION_TOKEN';
export const REQUEST_EMAIL_REGISTRATION = 'REQUEST_EMAIL_REGISTRATION';
export const RECEIVE_EMAIL_REGISTRATION = 'RECEIVE_EMAIL_REGISTRATION';
export const REQUEST_DISCORD_REGISTRATION = 'REQUEST_DISCORD_REGISTRATION';
export const RECEIVE_DISCORD_REGISTRATION = 'RECEIVE_DISCORD_REGISTRATION';

const requestDiscordRegistrationToken = (code) => ({
  type: REQUEST_DISCORD_REGISTRATION_TOKEN,
  code,
});

const receiveDiscordRegistrationToken = (success, message, registrationTokenId, username, avatar) => ({
  type: RECEIVE_DISCORD_REGISTRATION_TOKEN,
  success,
  message,
  registrationTokenId,
  username,
  avatar,
});

const requestEmailRegistration = (username, password, email) => ({
  type: REQUEST_EMAIL_REGISTRATION,
  username,
  password,
  email,
});

const receiveEmailRegistration = (success, message, id, username, email, profileColor, avatar, verified, roles, settingViewNSFW, characters, notifications, unreadNotificationCount, discordId) => ({
  type: RECEIVE_EMAIL_REGISTRATION,
  success,
  message,
  id,
  username,
  email,
  profileColor,
  avatar,
  verified,
  roles,
  settingViewNSFW,
  characters,
  notifications,
  unreadNotificationCount,
  discordId,
});

const requestDiscordRegistration = (registrationTokenId, username, email) => ({
  type: REQUEST_DISCORD_REGISTRATION,
  registrationTokenId,
  username,
  email,
});

const receiveDiscordRegistration = (success, message, id, username, email, profileColor, avatar, verified, roles, settingViewNSFW, characters, notifications, unreadNotificationCount, discordId) => ({
  type: RECEIVE_DISCORD_REGISTRATION,
  success,
  message,
  id,
  username,
  email,
  profileColor,
  avatar,
  verified,
  roles,
  settingViewNSFW,
  characters,
  notifications,
  unreadNotificationCount,
  discordId,
});

export const sendEmailRegistration = (username, password, email) => (
  dispatch => {
    dispatch(requestEmailRegistration(username, password, email))
    return emailRegister(username, password, email)
      .then(result => dispatch(receiveEmailRegistration(result.success, result.message, result.id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW, result.characters, result.notifications, result.unreadNotificationCount, result.discordId)))
  }
);

export const getDiscordRegistrationToken = (code) => (
  dispatch => {
    dispatch(requestDiscordRegistrationToken(code))
    return requestCreateDiscordRegistrationToken(code)
      .then(result => dispatch(receiveDiscordRegistrationToken(result.success, result.message, result.registrationTokenId, result.username, result.avatar)))
  }
);

export const sendDiscordRegistration = (registrationTokenId, username, email) => (
  dispatch => {
    dispatch(requestDiscordRegistration(registrationTokenId, username, email))
    return discordRegister(registrationTokenId, username, email)
      .then(result => dispatch(receiveDiscordRegistration(result.success, result.message, result.id, result.username, result.email, result.profileColor, result.avatar, result.verified, result.roles, result.settingViewNSFW, result.characters, result.notifications, result.unreadNotificationCount, result.discordId)))
  }
);