import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import { saveAs } from 'file-saver';
import filenamify from 'filenamify';

import { LoadoutWeaponManager } from './weaponManager';
import GameItemToggleList from '../../components/forms/GameItems/GameItemToggleList';
import GameItemToggle from '../../components/forms/GameItems/GameItemToggle';

import FullscreenPageLayoutWave from '../../components/fullscreenPageLayout/wave';
import Icon from '../../components/icons';
import RandomLoadoutGeneratorLogo from '../../static/images/random-loadout-logo.png';

import { generateProfileExport } from '../../util/randomLoadout';
import { getDetailWeaponDataFromArray } from '../../services/GameDataService';

import Spinner from '../../components/spinner';

import {
    liveWeaponModifications,
    liveGrenades,
    liveCharacterModifications,
    getPreloadedItemData
} from '../../util/preloadedDBData';

import { randomLoadoutProfileCategories } from '../../util/enums';

import {
    StyledContainer,
    StyledContentContainer,
    StyledLogoContainer,
    StyledLogo,
    StyledCardContentContainer,
    StyledCardContainer,
    StyledCard,
    StyledCardHeader,
    StyledCardHeaderNavContainer,
    StyledCardTitle,
    StyledCardBody,
    StyledWideButton,
    StyledLoadoutManagementList,
    StyledLoadoutManagementListItem,
    StyledLoadoutManagementListItemTitle,
    StyledLoadoutManagementListItemActionsContainer,
    StyledLoadoutManagementListItemAction,
    StyledCardSplitView,
    StyledCardSplitViewMain,
    StyledCardSidebar,
    StyledCardSidebarItem,
    StyledCardSplitMainInputBoxContainer,
    StyledCardSplitMainInputBox,
    StyledCardSplitMainInputBoxButton,
    StyledImportProfileButton,
} from './components';

import Advert from '../../components/advert';

const ManageLoadout = props => {

    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [profileName, setProfileName] = useState('');
    const [isImporting, setIsImporting] = useState(false);
    const inputFile = useRef(null);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const handleOnKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            switch (e.target.name) {
                case 'profileName':
                    editProfileName(profileName);
                    break;
            }
        }
    }
    
    const isCategorySelected = category => category === selectedCategory;

    const isWeaponSelected = (sapbdb, weaponType) => {
        switch (weaponType) {
            case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                return props.isGameItemSelected(sapbdb, randomLoadoutProfileCategories.PRIMARY_WEAPONS, editIndex);
        }
    };

    const chooseCreateProfile = () => {
        const newIndex = props.loadoutProfileCount;

        props.createProfile(`Loadout ${props.loadoutProfileCount + 1}`);
        setEditIndex(newIndex);
        setIsEditing(true);
    };

    const chooseDeleteProfile = (index) => {
        if (index > 0)
            props.deleteProfile(index);
    }

    const chooseEditProfile = index => {
        setEditIndex(index);
        setIsEditing(true);
    };

    const navigateBack = () => {
        ReactTooltip.hide();
        setEditIndex(false);
        setIsEditing(false);
        setSelectedCategory(0);
    };

    const addWeapon = (weaponData, weaponType) => {
        const { sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory } = weaponData;

        switch (weaponType) {
            case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                return addPrimaryWeapon(sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory);
            case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                return addSecondaryWeapon(sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory);
        }
    };

    const removeWeapon = (sapbdb, weaponType) => {
        switch (weaponType) {
            case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                return removePrimaryWeapon(sapbdb);
            case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                return removeSecondaryWeapon(sapbdb);
        }
    };

    const addPrimaryWeapon = (sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) =>
        props.addPrimaryWeapon(editIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory);

    const removePrimaryWeapon = (sapbdb) =>
        props.removePrimaryWeapon(editIndex, sapbdb);

    const addPrimaryWeaponModification = (sdisplayname, sapbdb, icon_url, url, emodifiercategory) =>
        props.addPrimaryWeaponModification(editIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory);

    const removePrimaryWeaponModification = (sapbdb) =>
        props.removePrimaryWeaponModification(editIndex, sapbdb);

    const addSecondaryWeapon = (sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) =>
        props.addSecondaryWeapon(editIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory);

    const removeSecondaryWeapon = (sapbdb) =>
        props.removeSecondaryWeapon(editIndex, sapbdb);

    const addGrenade = (sdisplayname, sapbdb, icon_url, url, einfracategory) =>
        props.addGrenade(editIndex, sdisplayname, sapbdb, icon_url, url, einfracategory);

    const removeGrenade = (sapbdb) =>
        props.removeGrenade(editIndex, sapbdb);
    
    const addCharacterModification = (sdisplayname, sapbdb, icon_url, url, emodifiercategory) =>
        props.addCharacterModification(editIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory);
    
    const removeCharacterModification = (sapbdb) =>
        props.removeCharacterModification(editIndex, sapbdb);

    const onExportProfile = () => {
        var blob = new Blob(
            [JSON.stringify(generateProfileExport(props.getProfileInventory(editIndex)))],
            {
                type: "text/plain;charset=utf-8"
            });

        saveAs(blob, filenamify(getLoadoutName(editIndex), { replacement: '' }) + '.ldt');
    };

    const onImportProfile = (e) => {
        setIsImporting(true);

        // grab file from inpout
        const file = e.target.files[0];

        // return if not correct file type
        if (file.type && file.type.indexOf('.ldt') === -1) {
            console.log('File is not an ldt file.', file.type, file);
            setIsImporting(false);
            return;
        }

        file.text()
            .then(result => {
                const profile = JSON.parse(result);
                
                if (profile.name &&
                    profile.primaryWeapons &&
                    profile.primaryWeaponModifications &&
                    profile.secondaryWeapons &&
                    profile.characterModifications &&
                    profile.grenades
                ) {
                    let primaryWeapons = [];
                    let primaryWeaponModifications = [];
                    let secondaryWeapons = [];
                    let grenades = [];
                    let characterModifications = [];
                    let newIndex;

                    // get primary weapon data
                    getDetailWeaponDataFromArray(profile.primaryWeapons)
                        .then(primaryWeaponArray => {
                            primaryWeapons = primaryWeaponArray;
                            return getDetailWeaponDataFromArray(profile.secondaryWeapons)
                        })
                        .then(secondaryWeaponArray => {
                            secondaryWeapons = secondaryWeaponArray;

                            // Get Preloaded Data
                            profile.primaryWeaponModifications.map(sapbdb => primaryWeaponModifications.push(getPreloadedItemData(sapbdb)));
                            profile.grenades.map(sapbdb => grenades.push(getPreloadedItemData(sapbdb)));
                            profile.characterModifications.map(sapbdb => characterModifications.push(getPreloadedItemData(sapbdb)));

                            // Import Data Into New Profile
                            newIndex = props.loadoutProfileCount;

                            const newProfile = {
                                name: profile.name,
                                primaryWeapons,
                                primaryWeaponModifications,
                                secondaryWeapons,
                                grenades,
                                characterModifications
                            };

                            props.importProfile(newProfile);

                            setIsImporting(false);
                            setEditIndex(newIndex);
                            setIsEditing(true);
                        })
                        .catch(err => {
                            setIsImporting(false);
                            console.error(err)
                        });             
                };
            })
            .catch(err => {
                setIsImporting(false);
                console.log(err)
            });
        
        e.target.value = null;
    }
    
    const getLoadoutNames = () => {
        return props.loadoutProfileNames;
    };
    
    const getLoadoutName = () => props.getProfileInventory(editIndex).name;

    const getSelectedPrimaryWeapons = () => props.getProfileInventory(editIndex).primaryWeapons;

    const getSelectedSecondaryWeapons = () => props.getProfileInventory(editIndex).secondaryWeapons;

    const getCategoryItemCount = category => {
        switch (category) {
            case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                return props.getProfileInventory(editIndex).primaryWeapons.length;
            case randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS:
                return props.getProfileInventory(editIndex).primaryWeaponModifications.length;
            case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                return props.getProfileInventory(editIndex).secondaryWeapons.length;
            case randomLoadoutProfileCategories.GRENADES:
                return props.getProfileInventory(editIndex).grenades.length;
            case randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS:
                return props.getProfileInventory(editIndex).characterModifications.length;
            default:
                return 0;
        }
    };

    const editProfileName = () => {
        if (!!profileName) {
            props.renameProfile(profileName, editIndex);
            setProfileName('');
        }
    };

    const renderProfileItem = (name, index) => (
        <StyledLoadoutManagementListItem key={index}>
            <StyledLoadoutManagementListItemTitle>
                {name}
            </StyledLoadoutManagementListItemTitle>
            <StyledLoadoutManagementListItemActionsContainer>
                <StyledLoadoutManagementListItemAction onClick={() => chooseEditProfile(index)}>
                    <Icon name='inventory-ui-game' fill='currentColor' stroke='none' width={26} height={26} />
                </StyledLoadoutManagementListItemAction>
                <StyledLoadoutManagementListItemAction disabled={index === 0} hoverColor='#f94f4f' onClick={e => chooseDeleteProfile(index)}>
                    <Icon name='trash-2' fill='currentColor' stroke='none' />
                </StyledLoadoutManagementListItemAction>
            </StyledLoadoutManagementListItemActionsContainer>
        </StyledLoadoutManagementListItem>
    );

    const renderProfileList = () => (
        <StyledCardContainer>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardHeaderNavContainer>
                        <Link to='/loadout'>
                            <StyledLoadoutManagementListItemAction data-tip='Back' data-for='__tooltip' onClick={e => navigateBack()}>
                                <Icon name='back-arrow' stroke='none' fill='currentColor' />
                            </StyledLoadoutManagementListItemAction>
                        </Link>
                    </StyledCardHeaderNavContainer>
                    <StyledCardTitle>
                        Manage Inventory
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody padding='2rem'>
                    <StyledWideButton onClick={e => chooseCreateProfile()}>Add New Profile</StyledWideButton>
                    <StyledImportProfileButton onClick={() => inputFile.current.click()}> Import Profile </StyledImportProfileButton>

                    <StyledLoadoutManagementList>
                        {getLoadoutNames().map((name, index) => renderProfileItem(name, index))}
                    </StyledLoadoutManagementList>

                    <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} accept='.ldt' onChange={e => onImportProfile(e)} />
                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    const renderEditProfileNameContent = () => (
        <StyledCardSplitMainInputBoxContainer>
            <StyledCardSplitMainInputBox id='profileName' name='profileName' type="text" value={profileName} placeholder={getLoadoutName()} onChange={e => setProfileName(e.target.value)} onKeyDown={handleOnKeyDown} />
            <StyledCardSplitMainInputBoxButton onClick={editProfileName}>
                <Icon name='check' strokeWidth={2} />
            </StyledCardSplitMainInputBoxButton>
        </StyledCardSplitMainInputBoxContainer>
    );

    const renderPrimaryWeaponContent = () => (
        <LoadoutWeaponManager
            isItemSelected={isWeaponSelected}
            onItemSelected={addWeapon}
            onItemRemoved={removeWeapon}
            selectedWeapons={getSelectedPrimaryWeapons()}
            searchItemType={randomLoadoutProfileCategories.PRIMARY_WEAPONS}
        />
    );

    const renderWeaponModificationsContent = () => {
        return (
            <GameItemToggleList>
                {
                    liveWeaponModifications.map((weaponModification, index) => {
                        const { sdisplayname, sapbdb, icon_url, url, emodifiercategory } = weaponModification;

                        return <GameItemToggle
                            key={sapbdb}
                            sdisplayname={sdisplayname}
                            sapbdb={sapbdb}
                            icon_url={icon_url}
                            url={url}
                            emodifiercategory={emodifiercategory}
                            selected={props.isGameItemSelected(sapbdb, randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS, editIndex)}
                            handleToggleOn={addPrimaryWeaponModification}
                            handleToggleOff={removePrimaryWeaponModification}
                        />
                    })
                }
            </GameItemToggleList>
        )
    };

    const renderSecondaryWeaponContent = () => (
        <LoadoutWeaponManager
            isItemSelected={isWeaponSelected}
            onItemSelected={addWeapon}
            onItemRemoved={removeWeapon}
            selectedWeapons={getSelectedSecondaryWeapons()}
            searchItemType={randomLoadoutProfileCategories.SECONDARY_WEAPONS}
        />
    );

    const renderGrenadesContent = () => (
        <GameItemToggleList>
            {
                liveGrenades.map((grenade, index) => {
                    const { sdisplayname, sapbdb, icon_url, url, einfracategory } = grenade;

                    return <GameItemToggle
                        key={sapbdb}
                        sdisplayname={sdisplayname}
                        sapbdb={sapbdb}
                        icon_url={icon_url}
                        url={url}
                        einfracategory={einfracategory}
                        selected={props.isGameItemSelected(sapbdb, randomLoadoutProfileCategories.GRENADES, editIndex)}
                        handleToggleOn={addGrenade}
                        handleToggleOff={removeGrenade}
                    />
                })
            }
        </GameItemToggleList>
    );

    const renderCharacterModificationsContent = () => (
        <GameItemToggleList>
            {
                liveCharacterModifications.map((characterModification, index) => {
                    const { sdisplayname, sapbdb, icon_url, url, emodifiercategory } = characterModification;

                    return <GameItemToggle
                        key={sapbdb}
                        sdisplayname={sdisplayname}
                        sapbdb={sapbdb}
                        icon_url={icon_url}
                        url={url}
                        emodifiercategory={emodifiercategory}
                        selected={props.isGameItemSelected(sapbdb, randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS, editIndex)}
                        handleToggleOn={addCharacterModification}
                        handleToggleOff={removeCharacterModification}
                    />
                })
            }
        </GameItemToggleList>
    );

    const renderEditProfileMainContent = () => {
        switch (selectedCategory) {
            case randomLoadoutProfileCategories.PROFILE_NAME:
                return renderEditProfileNameContent();
            case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                return renderPrimaryWeaponContent();
            case randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS:
                return renderWeaponModificationsContent();
            case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                return renderSecondaryWeaponContent();
            case randomLoadoutProfileCategories.GRENADES:
                return renderGrenadesContent();
            case randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS:
                return renderCharacterModificationsContent();
            default:
                return null;
        }
    };

    const renderEditProfile = () => (
        <StyledCardContainer width='840px'>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardHeaderNavContainer>
                        <StyledLoadoutManagementListItemAction data-tip='Back' data-for='__tooltip' onClick={e => navigateBack()}>
                            <Icon name='back-arrow' stroke='none' fill='currentColor' />
                        </StyledLoadoutManagementListItemAction>
                    </StyledCardHeaderNavContainer>
                    <StyledCardTitle>
                        Manage Profile
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody>
                    <StyledCardSplitView>

                        <StyledCardSidebar>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.PROFILE_NAME)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.PROFILE_NAME)}>
                                Profile Name
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.PRIMARY_WEAPONS)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.PRIMARY_WEAPONS)}>
                                Primary Weapons ({getCategoryItemCount(randomLoadoutProfileCategories.PRIMARY_WEAPONS)})
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS)}>
                                Weapon Modifications ({getCategoryItemCount(randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS)})
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.SECONDARY_WEAPONS)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.SECONDARY_WEAPONS)}>
                                Secondary Weapons ({getCategoryItemCount(randomLoadoutProfileCategories.SECONDARY_WEAPONS)})
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.GRENADES)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.GRENADES)}>
                                Grenades ({getCategoryItemCount(randomLoadoutProfileCategories.GRENADES)})
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem selected={isCategorySelected(randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS)} onClick={e => setSelectedCategory(randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS)}>
                                Character Modifications ({getCategoryItemCount(randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS)})
                            </StyledCardSidebarItem>
                            <StyledCardSidebarItem onClick={e => onExportProfile()}>
                                Export Profile
                            </StyledCardSidebarItem>
                        </StyledCardSidebar>
                        
                        <StyledCardSplitViewMain>
                            {renderEditProfileMainContent()}
                        </StyledCardSplitViewMain>

                    </StyledCardSplitView>

                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    const renderCardContent = () => isEditing ? renderEditProfile() : renderProfileList();
    
    const renderCardContentImport = () => (
        <StyledCardContainer>
            <StyledCard>
                <StyledCardHeader>
                    <StyledCardTitle>
                        Importing Profile
                    </StyledCardTitle>
                </StyledCardHeader>
                <StyledCardBody>
                    <Spinner />
                </StyledCardBody>
            </StyledCard>
        </StyledCardContainer>
    );

    return (
        <StyledContainer>
            <FullscreenPageLayoutWave />
            <StyledContentContainer>
                <StyledLogoContainer>
                    <StyledLogo src={RandomLoadoutGeneratorLogo} />
                </StyledLogoContainer>

                <StyledCardContentContainer>
                    {isImporting
                        ? renderCardContentImport()
                        : renderCardContent()
                    }
                </StyledCardContentContainer>

                <div>
                    <Advert padding='2rem 0' />
                </div>
            </StyledContentContainer>
        </StyledContainer>
    );

};

export default ManageLoadout;