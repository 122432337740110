import React from 'react';
import styled from 'styled-components';

const StyledSwitch = styled.label`
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;

    input { 
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-background-toggle);
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 4px;
        background-color: var(--color-background-toggle-handle);
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: var(--color-background-toggle-checked);
    }

    input:focus + .slider {
        box-shadow: 0 0 1px var(--color-background-toggle-focus);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;

const Switch = props => {
    return (
        <StyledSwitch>
            <input {...props} />
            <span className="slider round"></span>
        </StyledSwitch>
    );
};

export default Switch;