import React from "react";

import Search from "./Search";
import Heart from "./Heart";
import MoreHorizontal from "./MoreHorizontal";
import PlusCircle from "./PlusCircle";
import Shield from "./Shield";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";
import ChevronDown from "./ChevronDown";
import Comment from "./Comment";
import VehicleIcon from "./VehicleIcon";
import SymbolIcon from "./SymbolIcon";
import ClothingIcon from "./ClothingIcon";
import MusicStudioIcon from "./MusicStudioIcon";
import MarketplaceIcon from "./MarketplaceIcon";
import CharacterCustomizationIcon from "./CharacterCustomizationIcon";
import User from './User';
import Users from './Users';
import Tag from './Tag'
import Bell from './Bell';
import Zap from './Zap';
import Discord from './Discord';
import Twitter from './Twitter';
import CriminalIcon from './CriminalIcon';
import EnforcerIcon from './EnforcerIcon';
import XIcon from './X';
import SaveIcon from './Save';
import ArrowLeftIcon from './ArrowLeft';
import ArrowRightIcon from './ArrowRight';
import CropIcon from './Crop';
import TrashIcon from './Trash';
import EyeIcon from './Eye';
import EyeOffIcon from './EyeOff';
import EditIcon from './Edit';
import HomeIcon from './Home';
import MailIcon from './Mail';
import GlobeIcon from './Globe';
import InventoryUIIcon from './InventoryUI';
import Trash2Icon from './Trash-2';
import CheckIcon from './Check';
import RandomDiceIcon from './RandomDice';
import UndoIcon from './Undo';
import BackArrowIcon from './BackArrow';
import FireIcon from './Fire';
import HamburgerIcon from './Hamburger';
import VerifiedIcon from './Verified';
import ChatIcon from './Chat';
import GamersfirstIcon from './Gamersfirst';
import TwitchIcon from './Twitch';
import YouTubeIcon from './YouTube';
import CogIcon from './Cog';
import UserPlusIcon from './UserPlus';
import TelescopeIcon from './Telescope';
import EyeSidebarIcon from './EyeSidebar';

const Icon = props => {
    switch (props.name) {
        case "search":
            return <Search {...props} />;
        case "heart":
            return <Heart {...props} />;
        case "more-horizontal":
            return <MoreHorizontal {...props} />;
        case "plus-circle":
            return <PlusCircle {...props} />;
        case "shield":
            return <Shield {...props} />;
        case "chevron-left":
            return <ChevronLeft {...props} />;
        case "chevron-right":
            return <ChevronRight {...props} />;
        case "chevron-down":
            return <ChevronDown {...props} />;
        case "user-icon":
            return <User {...props} />
        case "users-icon":
            return <Users {...props} />
        case "comment-icon":
            return <Comment {...props} />;
        case "clothing-icon":
            return <ClothingIcon {...props} />
        case "symbol-icon":
            return <SymbolIcon {...props} />
        case "vehicle-icon":
            return <VehicleIcon {...props} />
        case "music-studio-icon":
            return <MusicStudioIcon {...props} />
        case "marketplace-icon":
            return <MarketplaceIcon {...props} />
        case "char-customization-icon":
            return <CharacterCustomizationIcon {...props} />
        case "tag":
            return <Tag {...props} />
        case "bell":
            return <Bell {...props} />
        case "zap":
            return <Zap {...props} />;
        case "discord":
            return <Discord {...props} />;
        case "twitter":
            return <Twitter {...props} />;
        case "criminal":
            return <CriminalIcon {...props} />;
        case "enforcer":
            return <EnforcerIcon {...props} />;
        case "close":
            return <XIcon {...props} />;
        case "save":
            return <SaveIcon {...props} />;
        case "crop":
            return <CropIcon {...props} />;
        case "arrow-left":
            return <ArrowLeftIcon {...props} />;
        case "arrow-right":
            return <ArrowRightIcon {...props} />;
        case "trash":
            return <TrashIcon {...props} />;
        case "eye":
            return <EyeIcon {...props} />;
        case "eye-off":
            return <EyeOffIcon {...props} />;
        case 'edit':
            return <EditIcon {...props} />;
        case 'home':
            return <HomeIcon {...props} />;
        case 'mail':
            return <MailIcon {...props} />;
        case 'globe':
            return <GlobeIcon {...props} />
        case 'inventory-ui-game':
            return <InventoryUIIcon {...props} />
        case 'trash-2':
            return <Trash2Icon {...props} />
        case 'check':
            return <CheckIcon {...props} />
        case 'random':
            return <RandomDiceIcon {...props} />
        case 'undo':
            return <UndoIcon {...props} />
        case 'back-arrow':
            return <BackArrowIcon {...props} />
        case 'fire':
            return <FireIcon {...props} />
        case 'hamburger':
            return <HamburgerIcon {...props} />
        case 'verified':
            return <VerifiedIcon {...props} />
        case 'chat':
            return <ChatIcon {...props} />
        case 'gamersfirst':
            return <GamersfirstIcon {...props} />
        case 'twitch':
            return <TwitchIcon {...props} />
        case 'youtube':
            return <YouTubeIcon {...props} />
        case 'cog':
            return <CogIcon {...props} />
        case 'user-plus':
            return <UserPlusIcon {...props}/>
        case 'telescope':
            return <TelescopeIcon {...props}/>
        case 'eye-sidebar':
            return <EyeSidebarIcon {...props}/>
        default:
            return <div>NO ICON FOUND</div>;
    }
};

export default Icon;
