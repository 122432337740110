import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.select`
    display: flex;
    flex: 1 1 0%;
    font-family: Inter, sans-serif;
    width: 100%;
    font-weight: 500;
    background: var(--color-background-input) !important;
    font-size: 1rem !important;
    border: 0px !important;
    box-shadow: none !important;
    color: var(--color-text-input) !important;
    transition: background 0.1s ease-in-out 0s, border 0.1s ease-in-out 0s !important;
    padding: 0.5rem 1rem !important;
    height: 42px !important;
    border-radius: 3px !important;

    :focus, :active {
        background: var(--color-background-input-active) !important;
    }
`;

export default ({ children, ...props }) => <StyledInput {...props}>{children}</StyledInput>;
