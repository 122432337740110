import { connect } from 'react-redux'
import { refreshUserData } from '../actions/loginActions'
import RefreshSession from '../pages/refreshSession';

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        isLoggedIn: currentUser.isLoggedIn,
        userId: currentUser.id
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    refreshUserData: (userId) =>
        dispatch(refreshUserData()),
    openSocketConnection: () =>
        dispatch({type: 'WS_CONNECT'}),
    closeSocketConnection: () =>
        dispatch({type: 'WS_DISCONNECT'}),
})

export default connect(mapStateToProps, mapDispatchToProps)(RefreshSession);