import React, {useEffect} from 'react';
import styled from 'styled-components';

const StyledAdvert = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1088px;
    margin: 0 auto;
    padding: ${props => props.padding || '0px'}

    @media (min-width: 780px) {
    }
`;

const StyledAdvertContainer = styled.div`
    display: flex;
    position: relative;
`;


let adsbygoogle;

const HorizontalAdvert = (props) => {

    useEffect(() => {
        (adsbygoogle = window.adsbygoogle || []).push({});
    }, [])
    
    return (
        <StyledAdvert padding={props.padding}>
            <StyledAdvertContainer>
                <ins className="adsbygoogle"
                    style={{ display: 'inline-block', width: 728, height: 90 }}
                    data-ad-client="ca-pub-5976291169768589"
                    data-ad-slot="9307542051">
                </ins>
            </StyledAdvertContainer>
        </StyledAdvert>
    )
}

export default HorizontalAdvert;