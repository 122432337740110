import { connect } from 'react-redux'
import { sendDiscordRegistration } from '../actions/registrationActions'
import DiscordRegister from '../pages/register/discord'

const mapStateToProps = (state, ownProps) => {
    const {  currentRegistration } = state;
    return {
        registrationTokenId: currentRegistration.registrationTokenId,
        discordUsername: currentRegistration.linkedAccountUsername,
        discordAvatar: currentRegistration.linkedAccountAvatar,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    registerWithDiscord: (registrationTokenId, username, email) => {
        return dispatch(sendDiscordRegistration(registrationTokenId, username, email))
            .then(result => {
                if (result.success === true && result.id)
                    dispatch({ type: 'WS_CONNECT' });

                return result;
            });
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscordRegister);