import {
    OPEN_SLIDING_SIDEBAR,
    CLOSE_SLIDING_SIDEBAR,
    TOGGLE_SLIDING_SIDEBAR
} from '../actions/sidebarActions';

const settings = (
    state = {
        open: false,
    },
    action
) => {
    switch (action.type) {
        case OPEN_SLIDING_SIDEBAR:
            return Object.assign({}, {}, {
                open: true,
            })
        case CLOSE_SLIDING_SIDEBAR:
            return Object.assign({}, {}, {
                open: false,
            })
        case TOGGLE_SLIDING_SIDEBAR:
            return Object.assign({}, {}, {
                open: !state.open,
            })
        default:
            return state
    }
}

export default settings;