import React from 'react';
import styled from 'styled-components';

import BreakwaterWave from '../../static/images/logo-bg.svg';


const StyledHeader = styled.div`
    display: none;

    @media(min-width: 425px) {
        display: block;
        justify-content: left;
    }
`;


const Header = () => (
    <StyledHeader>
        <img src={BreakwaterWave} alt="Breakwater" />
    </StyledHeader>
)

export default Header;