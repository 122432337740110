import React from 'react';

import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import './style.css';

import XIcon from '../../../../static/images/svg/x.svg';
import CropIcon from '../../../../static/images/svg/crop.svg';
import SaveIcon from '../../../../static/images/svg/save.svg';
import ArrowLeft from '../../../../static/images/svg/arrow-left.svg';
import ArrowRight from '../../../../static/images/svg/arrow-right.svg';

const PreviewContainer = styled.div`
    position: relative;
    border: 1px solid transparent;
`;

const UploadImagePreview = styled.img`
    display: block;
    width: ${props => Math.round(props.cropWidth / 4)}px;
    height: ${props => Math.round(props.cropHeight / 4)}px;
    min-height: ${props => Math.round(props.cropHeight / 4)}px;
    background-color: #d8dde3;
    border: 1px solid #c3c8ce;
`;

class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { imageId, currentImageAmount, maxImageAmount, handleClick, handleRemove, handleSave, handleMove, image, selected, allowDelete, cropWidth, cropHeight } = this.props;
        return (
            <div className="image-selector">
                <PreviewContainer className={`${selected && 'selected'}`}>
                    {selected ? (
                        <>
                            <div className="save-image" data-tip data-for="save-image" onClick={handleSave}>
                                <img className="save-icon" src={SaveIcon} alt="save" />
                            </div>
                            <ReactTooltip id='save-image' effect='solid'>
                                <span>Save Image</span>
                            </ReactTooltip>
                        </>
                    ) :
                        null
                    }
                    {allowDelete &&
                        (
                            <>
                            <div className="remove-image image-action-icon" data-tip data-for="delete-image" onClick={handleRemove}>
                                    <img className="icon" src={XIcon} width="18" height="18" alt="Remove Image" />
                                </div>
                                <ReactTooltip id='delete-image' effect='solid'>
                                    <span>Remove Image</span>
                                </ReactTooltip>
                            </>
                        )
                    }
                    <div className="crop-image image-action-icon" data-tip data-for="crop-image" onClick={handleClick}>
                        <img className="icon" src={CropIcon} width="18" height="18" alt="Crop Image" />
                    </div>
                    <ReactTooltip id='crop-image' effect='solid'>
                        <span>Re-crop Image</span>
                    </ReactTooltip>
                    {!!!image ? <div className="upload-image-preview"></div> : null}
                    {!!image ? (<UploadImagePreview src={URL.createObjectURL(image)} cropWidth={cropWidth} cropHeight={cropHeight} />) : null}
                </PreviewContainer>
                <div className="position-controls">
                    { imageId > 0 && (imageId != 0 && currentImageAmount != 0) && (
                        <>
                            <img className="image-action-icon left" src={ArrowLeft} data-tip data-for="move-left" onClick={() => handleMove(imageId, false)} />
                            <ReactTooltip id='move-left' effect='solid'>
                                <span>Move Left</span>
                            </ReactTooltip>
                        </>
                    )}
                    
                    { imageId < currentImageAmount - 1 && !(imageId === 0 && currentImageAmount === 1) && (
                        <>
                            <img className="image-action-icon right" src={ArrowRight} data-tip data-for="move-right" onClick={() => handleMove(imageId, true)} />
                            <ReactTooltip id='move-right' effect='solid'>
                                <span>Move Right</span>
                            </ReactTooltip>
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default ImagePreview;
