import React from 'react';
import styled from 'styled-components';

const StyledNotificationList = styled.div`

`;

const NotificationList = (props) => (
  <StyledNotificationList>
    {props.children}
  </StyledNotificationList>
);

export default NotificationList;