import { logout } from '../services/LoginService';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

export const requestLogout = () => ({
  type: REQUEST_LOGOUT,
});

export const receiveLogout = () => ({
  type: RECEIVE_LOGOUT,
});

export const attemptLogout = () => (
  dispatch => {
    dispatch(requestLogout())
    return logout()
      .then(() => dispatch(receiveLogout()))
  }
);