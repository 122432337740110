import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
        <svg
            width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
        >
            <circle fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" cx="12" cy="12" r="1" />
            <circle fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" cx="19" cy="12" r="1" />
            <circle fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" cx="5" cy="12" r="1" />
        </svg>
    );

export default SVG;
