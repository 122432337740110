import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import BreakwaterWave from '../../static/images/logo-bg.svg';

const StyledWaveContainer = styled.div`
    display: none;
    
    @media(min-width: 760px) {
        display: flex;
        position: absolute;
        left: 1rem;
        top: 1rem;
    }

    a, img {
        display: flex;
    }
`;

const StyledWave = styled.img`
    width: 40px;
`;


const Wave = () => (
    <StyledWaveContainer>
        <Link to="/">
            <StyledWave src={BreakwaterWave} alt="Breakwater" />
        </Link>
    </StyledWaveContainer>
)

export default Wave;