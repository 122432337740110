
const retrieveHomeDashboard = (nsfw) => (
  fetch(`/api/dashboard/home?nsfw=${!!nsfw}`, { method: 'GET' })
    .then(res => res.json())
);

const retrieveFollowingDashboard = (page, nsfw) => (
  fetch(`/api/dashboard/following?page=${page || 0}&nsfw=${!!nsfw}`, { method: 'GET' })
    .then(res => res.json())
);

export {
    retrieveHomeDashboard,
    retrieveFollowingDashboard
}
  