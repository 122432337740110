import { connect } from 'react-redux'
import { closeSidebar, toggleSidebar } from '../actions/sidebarActions';
import { attemptLogout } from '../actions/logoutActions';
import SlideoutSidebar from '../components/fullscreenPageLayout/SlideoutSidebar';

const mapStateToProps = (state, ownProps) => {
    const { currentUser = {},
            sidebar = {},
            notifications ={}
        } = state;

    return {
        isOpen: sidebar.open,
        isLoggedIn: !!currentUser.id,
        userId: currentUser.id,
        userUsername: currentUser.username,
        userAvatar: currentUser.avatar,
        unreadNotificationCount: notifications.unreadNotificationCount
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    closeSidebar: () => dispatch(closeSidebar()),
    logout: () => {
        dispatch({ type: 'WS_DISCONNECT' });
        return dispatch(attemptLogout());
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(SlideoutSidebar);