import React from 'react';

import { Route, Redirect } from 'react-router-dom';

const AuthRoute = ({ component: Component, canAccess, ...rest }) => (
    <Route {...rest} render={(props) => (
        canAccess ? <Component {...props} /> : <Redirect to="/" />
    )} />
);

export default AuthRoute;