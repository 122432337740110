import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <path fill={fill} stroke={stroke} d="M6.82,11.506c-0.466,0.271-0.466,0.716,0,0.987l9.861,5.743c0.466,0.271,0.848,0.075,0.848-0.437V6.2
		c0-0.511-0.382-0.708-0.848-0.436L6.82,11.506z"/>
    </svg>
);

export default SVG;