import React from 'react';
import { userRoles, userRoleColors } from '../../util/enums';
import RoleBadge from './RoleBadge';

const getDisplayedRole = (roles) => {
    if (roles.includes(userRoles.OWNER))
        return userRoles.OWNER;
    else if (roles.includes(userRoles.MODERATOR))
        return userRoles.MODERATOR;
    else
        return null;
};

export default props => {
    const roles = props.roles;
    const badgeRole = getDisplayedRole(roles);

    return badgeRole ?
        <div className="user-group" style={{ color: userRoleColors[badgeRole] }}>
            <RoleBadge width={18} strokeWidth={3} roles={roles} /> {userRoles.getName(badgeRole)}
        </div> : null
}