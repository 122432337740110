import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 184.19 186.236"
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 184.19 186.236"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fillRule="evenodd" clipRule="evenodd" fill={fill} stroke={stroke} d="M27.478,105.539c4.478,4.257,9.004,8.56,13.638,12.964
	c9.173-9.588,18.27-19.096,27.43-28.671c-1.834-2-3.629-3.965-5.435-5.921c-2.539-2.75-5.07-5.508-7.634-8.234
	c-2.125-2.259-4.359-4.417-6.422-6.73c-1.932-2.166-3.819-4.391-5.51-6.746c-1.82-2.535-3.511-5.183-5.032-7.908
	c-1.523-2.729-2.921-5.552-4.093-8.446c-1.449-3.579-2.742-7.234-3.849-10.932c-0.76-2.54-1.178-5.195-1.588-7.824
	c-0.397-2.552-0.599-5.135-0.868-7.707c-0.135-1.292-0.383-2.595-0.313-3.878c0.142-2.567,0.274-5.163,0.775-7.674
	c0.475-2.381,1.416-4.669,2.154-6.998c0.24-0.758,0.648-0.974,1.24-0.324c0.884,0.972,1.719,1.989,2.62,2.944
	c2.944,3.118,5.939,6.188,8.855,9.33c2.56,2.758,5,5.626,7.573,8.371c1.156,1.233,2.584,2.21,3.751,3.435
	c2.547,2.674,5.026,5.415,7.495,8.163c1.504,1.674,2.952,3.398,4.393,5.127c0.789,0.946,1.943,0.976,2.784,0.617
	c2.499-1.066,3.748,0.569,5.144,2.032c1.097,1.15,2.207,2.288,3.279,3.46c0.202,0.221,0.256,0.576,0.429,0.989
	c-1.97,0-3.775,0-5.907,0c0.795,0.865,1.398,1.548,2.031,2.202c1.525,1.574,3.031,3.17,4.625,4.672
	c0.332,0.313,1.006,0.322,1.53,0.351c0.353,0.019,0.711-0.229,1.077-0.263c3.378-0.314,3.371-0.301,5.709,2.146
	c1.33,1.392,2.699,2.746,4.031,4.136c0.152,0.158,0.178,0.436,0.333,0.841c-0.926,0-1.715,0-2.505,0c-0.793,0-1.587,0-2.38,0
	c-0.064,0.104-0.129,0.207-0.193,0.31c1.758,1.92,3.517,3.84,5.399,5.896c1.893-1.987,3.706-3.888,5.517-5.79
	c-0.043-0.119-0.087-0.238-0.13-0.357c-1.641,0-3.281,0-5.244,0c0.794-0.937,1.319-1.614,1.903-2.233
	c1.362-1.444,2.713-2.905,4.158-4.263c0.455-0.428,1.199-0.774,1.808-0.768c1.33,0.014,2.671,0.208,3.981,0.461
	c1.099,0.211,1.422-0.66,1.959-1.206c1.909-1.943,3.78-3.923,5.828-6.059c-2.086,0-3.948,0-6.034,0
	c0.301-0.468,0.449-0.793,0.68-1.043c1.592-1.733,3.234-3.42,4.79-5.185c0.837-0.95,1.859-0.715,2.792-0.525
	c2.293,0.468,3.339-0.042,4.804-1.876c1.941-2.433,4.114-4.683,6.184-7.015c1.349-1.52,2.643-3.091,4.049-4.555
	c1.163-1.211,2.474-2.279,3.685-3.447c1.057-1.02,2.09-2.068,3.089-3.145c2.559-2.758,5.091-5.542,7.639-8.312
	c2.846-3.095,5.708-6.173,8.533-9.286c0.586-0.646,0.968-0.749,1.351,0.13c0.375,0.858,0.885,1.672,1.147,2.562
	c1.392,4.722,1.998,9.551,1.789,14.476c-0.034,0.804-0.001,1.614-0.083,2.412c-0.291,2.807-0.432,5.647-0.983,8.404
	c-0.593,2.959-1.524,5.857-2.419,8.748c-0.762,2.462-1.609,4.903-2.554,7.301c-0.911,2.311-1.875,4.617-3.033,6.81
	c-1.431,2.71-2.973,5.377-4.675,7.923c-1.842,2.754-3.819,5.435-5.932,7.987c-1.941,2.346-4.137,4.482-6.227,6.705
	c-2.708,2.878-5.415,5.758-8.137,8.623c-2.005,2.11-4.058,4.176-6.017,6.326c-0.236,0.259-0.217,1.079,0.017,1.374
	c0.773,0.974,1.704,1.822,2.567,2.725c2.104,2.201,4.2,4.411,6.311,6.607c2.576,2.679,5.168,5.342,7.743,8.021
	c2.084,2.167,4.156,4.347,6.228,6.526c1.266,1.332,2.52,2.676,3.937,4.182c4.572-4.335,9.066-8.597,13.786-13.072
	c1.083,1.2,2.182,2.416,3.28,3.634c1.43,1.584,2.83,3.195,4.304,4.738c0.466,0.486,0.517,0.708,0,1.193
	c-2.271,2.135-4.506,4.308-6.728,6.494c-1.23,1.211-2.424,2.46-3.596,3.728c-0.203,0.221-0.273,0.615-0.302,0.938
	c-0.405,4.66-0.345,4.606,3.025,8.113c3.942,4.103,7.755,8.33,11.659,12.469c2.218,2.353,4.523,4.623,6.76,6.958
	c2.948,3.08,5.879,6.175,8.771,9.307c0.21,0.228,0.227,0.983,0.024,1.188c-2.156,2.181-4.345,4.33-6.583,6.426
	c-2.51,2.35-5.112,4.602-7.617,6.957c-2.95,2.775-5.839,5.617-8.864,8.536c-2.52-2.63-4.921-5.121-7.307-7.629
	c-4.518-4.75-9.024-9.511-13.539-14.264c-2.966-3.121-5.941-6.232-8.899-9.36c-0.278-0.294-0.452-0.686-0.69-1.02
	c-1.617-2.271-4.03-1.673-6.199-1.521c-0.69,0.048-1.3,1.132-1.963,1.724c-1.518,1.352-3.078,2.659-4.56,4.05
	c-0.988,0.929-1.885,1.957-2.793,2.967c-0.561,0.623-1.061,0.716-1.667,0.056c-2.11-2.297-4.242-4.574-6.361-6.861
	c-0.505-0.545-0.709-0.928,0.044-1.618c3.198-2.931,6.298-5.97,9.429-8.975c0.359-0.347,0.773-0.684,0.992-1.112
	c0.15-0.292,0.162-0.88-0.031-1.089c-3.172-3.424-6.387-6.807-9.589-10.203c-1.612-1.709-3.206-3.436-4.827-5.136
	c-3.613-3.788-7.239-7.565-10.962-11.454c-1.593,1.717-3.089,3.359-4.618,4.973c-3.478,3.671-6.971,7.327-10.46,10.986
	c-2.34,2.455-4.686,4.906-7.029,7.359c-1.099,1.149-2.245,2.261-3.257,3.481c-0.207,0.25-0.092,1.096,0.175,1.355
	c2.599,2.517,5.291,4.938,7.894,7.451c1.027,0.992,1.896,2.148,3.024,3.443c-0.856,0.874-1.991,2.008-3.098,3.168
	c-1.331,1.395-2.638,2.811-3.956,4.218c-0.519,0.554-0.798,0.582-1.457-0.073c-2.845-2.828-5.793-5.557-8.792-8.224
	c-0.498-0.442-1.419-0.439-2.157-0.578c-2.308-0.434-3.817,0.596-5.25,2.396c-1.632,2.052-3.732,3.723-5.54,5.644
	c-3.289,3.495-6.5,7.063-9.767,10.58c-1.901,2.046-3.851,4.046-5.772,6.072c-2.412,2.543-4.812,5.097-7.231,7.633
	c-0.471,0.494-1.009,0.925-1.58,1.441c-2.165-2.054-4.274-4.07-6.4-6.068c-3.312-3.114-6.646-6.205-9.95-9.328
	c-1.957-1.85-3.87-3.745-5.809-5.612c-0.266-0.256-0.695-0.415-0.828-0.716c-0.165-0.374-0.304-1.038-0.105-1.254
	c3.018-3.268,6.081-6.493,9.157-9.705c3.182-3.323,6.412-6.6,9.58-9.937c2.638-2.779,5.207-5.624,7.823-8.424
	c1.144-1.224,2.34-2.398,3.49-3.617c0.207-0.219,0.432-0.536,0.437-0.813c0.025-1.526,0.044-3.06-0.07-4.579
	c-0.032-0.421-0.469-0.863-0.813-1.201c-3.304-3.249-6.636-6.47-9.928-9.73c-0.216-0.214-0.361-0.861-0.218-1.022
	c2.486-2.801,5.022-5.559,7.552-8.321C27.156,105.611,27.262,105.61,27.478,105.539z"/>
        </svg>
    );

export default SVG;