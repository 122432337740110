import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={width}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
    >
        <path fill={fill} stroke={stroke} d="M6 4h12v3H6zM6 18h12v3H6zM2 11h20v3H2z"></path>
    </svg>
);

export default SVG;