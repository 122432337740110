import { connect } from 'react-redux'
import { fetchUser, resetProfile, unbanUserById, followUserById, unfollowUserById } from '../actions/userActions'
import { getPostsByUserId } from '../services/PostService'
import Profile from '../pages/profile/profile'

const mapStateToProps = (state, ownProps) => {
  const { currentUser, currentProfile } = state;
  return {
    isFetching: currentProfile.isFetching,
    user: currentProfile.user,
    currentUserId: currentUser.id,
    showNsfwPosts: currentUser.settingViewNSFW,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUser: (showNsfwPosts) =>
    dispatch(fetchUser(ownProps.match.params.user_id, showNsfwPosts)),
  resetProfile: () =>
    dispatch(resetProfile()),
  fetchPosts: (userId, category, page, nsfw) =>
    getPostsByUserId(userId, category, page, nsfw),
  unbanUser: (userId) =>
    dispatch(unbanUserById(userId)),
  followUser: (userId) =>
    dispatch(followUserById(userId)),
  unfollowUser: (userId) =>
    dispatch(unfollowUserById(userId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);