import { connect } from 'react-redux';
import Navbar from '../components/navigation/Navbar';
import { attemptLogout } from '../actions/logoutActions';
import { toggleSidebar } from '../actions/sidebarActions';

const mapStateToProps = (state, ownProps) => {
    const { currentUser = {} } = state;
    return {
        isLoggedIn: !!currentUser.id,
        userId: currentUser.id,
        userUsername: currentUser.username,
        userAvatar: currentUser.avatar,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    logout: () => {
        dispatch({ type: 'WS_DISCONNECT' });
        return dispatch(attemptLogout());
    },
    toggleSidebar: () => dispatch(toggleSidebar()),
  })

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);