import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
    strokeLinecap = "round",
    strokeLinejoin = "round",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
            fill={fill}
            stroke={stroke}
        >
            {/* <path fill={fill} stroke={stroke} d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline fill={fill} stroke={stroke} points="9 22 9 12 15 12 15 22"></polyline> */}
            <path fill={fill} stroke={stroke} d="M12.664 3.59l8 7.112a1 1 0 01.336.747v.051a.5.5 0 01-.5.5H19v8a1 1 0 01-1 1h-4v-7h-4v7H6a1 1 0 01-1-1v-8H3.5a.5.5 0 01-.5-.5v-.051a1 1 0 01.336-.747l8-7.111a1 1 0 011.328 0z" fillRule="evenodd"></path>
        </svg>
    );

export default SVG;