import { connect } from 'react-redux'
import {
    createLoadoutSet,
    deleteLoadoutSet,
    renameLoadoutSet,
    importLoadoutSet,
    addPrimaryWeaponToSet,
    removePrimaryWeaponFromSet,
    addPrimaryWeaponModificationToSet,
    removePrimaryWeaponModificationFromSet,
    addSecondaryWeaponToSet,
    removeSecondaryWeaponFromSet,
    addGrenadeToSet,
    removeGrenadeFromSet,
    addCharacterModificationToSet,
    removeCharacterModificationFromSet,
} from '../actions/loadoutActions';

import ManageLoadout from '../pages/loadout/manage';

import { randomLoadoutProfileCategories } from '../util/enums';

const mapStateToProps = (state, ownProps) => {
    const { currentLoadouts } = state;

    return {
        loadoutProfileNames: currentLoadouts.loadoutSets.map(loadout => loadout.name),
        loadoutProfileCount: currentLoadouts.loadoutSets.length,
        getProfileInventory: (profileIndex) =>
            currentLoadouts.loadoutSets[profileIndex],
        isGameItemSelected: (sapbdb, type, profileIndex) => {
            let gameItemIndex = -1;
            const loadoutSet = currentLoadouts.loadoutSets[profileIndex];

            switch (type) {
                case randomLoadoutProfileCategories.PRIMARY_WEAPONS:
                    gameItemIndex = loadoutSet.primaryWeapons.findIndex(weapon => weapon.sapbdb === sapbdb);
                    break;
                case randomLoadoutProfileCategories.PRIMARY_WEAPON_MODIFICATIONS:
                    gameItemIndex = loadoutSet.primaryWeaponModifications.findIndex(weaponMod => weaponMod.sapbdb === sapbdb);
                    break;
                case randomLoadoutProfileCategories.SECONDARY_WEAPONS:
                    gameItemIndex = loadoutSet.secondaryWeapons.findIndex(weapon => weapon.sapbdb === sapbdb);
                    break;
                case randomLoadoutProfileCategories.GRENADES:
                    gameItemIndex = loadoutSet.grenades.findIndex(grenade => grenade.sapbdb === sapbdb);
                    break;
                case randomLoadoutProfileCategories.CHARACTER_MODIFICATIONS:
                    gameItemIndex = loadoutSet.characterModifications.findIndex(characterMod => characterMod.sapbdb === sapbdb);
                    break;
            }

            return gameItemIndex >= 0;
        },
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    createProfile: (name) => 
        dispatch(createLoadoutSet(name)),
    deleteProfile: (profileIndex) => 
        dispatch(deleteLoadoutSet(profileIndex)),
    renameProfile: (name, profileIndex) => 
        dispatch(renameLoadoutSet(name, profileIndex)),
    importProfile: (profileData) => 
        dispatch(importLoadoutSet(profileData)),
    addPrimaryWeapon: (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) =>
        dispatch(addPrimaryWeaponToSet(profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory)),
    removePrimaryWeapon: (profileIndex, sapbdb) =>
        dispatch(removePrimaryWeaponFromSet(profileIndex, sapbdb)),
    addPrimaryWeaponModification: (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) =>
        dispatch(addPrimaryWeaponModificationToSet(profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory)),
    removePrimaryWeaponModification: (profileIndex, sapbdb) =>
        dispatch(removePrimaryWeaponModificationFromSet(profileIndex, sapbdb)),
    addSecondaryWeapon: (profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory) =>
        dispatch(addSecondaryWeaponToSet(profileIndex, sdisplayname, sapbdb, icon_url, url, modifications, modificationsVacant, einfracategory)),
    removeSecondaryWeapon: (profileIndex, sapbdb) =>
        dispatch(removeSecondaryWeaponFromSet(profileIndex, sapbdb)),
    addGrenade: (profileIndex, sapbdb, sdisplayname, icon_url, url, einfracategory) =>
        dispatch(addGrenadeToSet(profileIndex, sapbdb, sdisplayname, icon_url, url, einfracategory)),
    removeGrenade: (profileIndex, sapbdb) =>
        dispatch(removeGrenadeFromSet(profileIndex, sapbdb)),
    addCharacterModification: (profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory) =>
        dispatch(addCharacterModificationToSet(profileIndex, sdisplayname, sapbdb, icon_url, url, emodifiercategory)),
    removeCharacterModification: (profileIndex, sapbdb) =>
        dispatch(removeCharacterModificationFromSet(profileIndex, sapbdb)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageLoadout);