import { connect } from 'react-redux'
import { fetchNotifications, sendUserReadNotifications } from '../actions/notificationActions'
import Notifications from '../pages/notifications'

const mapStateToProps = (state, ownProps) => {
    const { notifications } = state;
    return {
        isFetching: notifications.isFetching,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchNotifications: (page) =>
        dispatch(fetchNotifications(null, page)),
    sendReadNotificationsToServer: (notificationIds) => 
        dispatch(sendUserReadNotifications(notificationIds)),    
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);