import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    border-radius: 3px;
    font-family: 'Inter',sans-serif;
    font-weight: 600;
    color: #fff;
    border-radius: 3px;
    border: 1px solid transparent;
    background: ${props => props.background ? props.background : '#198754'};
    transition: background 0.1s ease-in-out;
    cursor: pointer;
    height: 42px;
    width: ${props => props.width ? props.width : 'auto'};
    padding: 0 30px;
    font-size: 14px;
    line-height: 38px;

    @media (min-width: 760px) {
        margin-left: .5rem;
        width: auto;
    }

    &[disabled], :hover[disabled] {
        color: var(--color-text-button-disabled);
        background: var(--color-background-button-disabled);
        cursor: auto;
    }

    :hover, :focus {
        background: ${props => props.backgroundHover ? props.backgroundHover : '#146d44'};
    }

    :active {
        background: ${props => props.backgroundActive ? props.backgroundActive : '#0f5434'};
    }
`;

export default ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>