import {
    REQUEST_HOME_POSTS,
    RECEIVE_HOME_POSTS,
    REQUEST_FOLLOWING_POSTS,
    RECEIVE_FOLLOWING_POSTS,
    REQUEST_EXPLORE_POSTS,
    RECEIVE_EXPLORE_POSTS
} from '../actions/feedActions';

/*
    Store X Scroll position in array
    Only load X Scroll if you hit back

    When you hit back, check if you're on a post. If you are, make sure that state is not cleared or set it again.
*/

const feed = (
    state = {
        home: {
            posts: []
        },
        following: {
            posts: []
        },
        explore: {
            posts: []
        },
    },
    action
) => {
    switch (action.type) {
        case REQUEST_HOME_POSTS:
            return Object.assign({}, state, {
                home: {
                    posts: []
                }
            })
        case RECEIVE_HOME_POSTS:
            return Object.assign({}, state, {
                home: {
                    posts: action.posts
                }
            })  
        case REQUEST_FOLLOWING_POSTS:
            return Object.assign({}, state, {
                following: {
                    posts: [],
                    pageCount: null,
                    currentPage: null,
                    category: null
                }
            })
        case RECEIVE_FOLLOWING_POSTS:
            return Object.assign({}, state, {
                following: {
                    posts: action.posts,
                    pageCount: action.pageCount,
                    currentPage: action.pageNumber - 1,
                    category: action.category
                }
            })  
        case REQUEST_EXPLORE_POSTS:
            return Object.assign({}, state, {
                explore: {
                    posts: [],
                    pageCount: null,
                    currentPage: null,
                    category: null
                }
            })
        case RECEIVE_EXPLORE_POSTS:
            return Object.assign({}, state, {
                explore: {
                    posts: action.posts,
                    pageCount: action.pageCount,
                    currentPage: action.pageNumber - 1,
                    category: action.category
                }
            })  
        default:
            return state
    }
}

export default feed;