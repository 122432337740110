import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import queryString from 'query-string';

import Icon from '../../components/icons';
import Filter from '../../components/search/sidebar/Filter';
import Spinner from '../../components/spinner';
import { gameServers } from '../../util/enums';
import { GeneratePostGrid, RegeneratePostGrid } from '../../components/posts/DynamicPostGrid';
import StickyContentHeader from '../../components/fullscreenPageLayout/contentStickyHeader';
import { 
  StyledCardSplitMainInputBoxContainer,
  StyledCardSplitMainInputBox,
  StyledCardSplitMainInputBoxButton,
} from '../loadout/components';

import Input from '../../components/forms/Input';
import Select from '../../components/forms/Select';

import { postCategories } from '../../util/enums';

import './style.css';

import { searchPosts } from '../../services/PostService';

const StyledButtonThin = styled.button`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: #fff;
    display: block;
    padding: 0.5rem;
    background: #323338;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #464749;
    outline: none;
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    :hover {
        background: #2d2e33;
    }

    :active {
        background: #7668cb;
    }
`;

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: queryString.parse(this.props.location.search).query || '',
      posts: [],
      postCount: 0,
      pageCount: 1,
      currentPage: 1,
      category: queryString.parse(this.props.location.search).filterCategory || null,
      // FILTERS
      showFilters: false,
      filterForSale: queryString.parse(this.props.location.search).filterForSale === "true" || false,
      filterPriceRangeMin: queryString.parse(this.props.location.search).filterPriceRangeMin || 0,
      filterPriceRangeMax: queryString.parse(this.props.location.search).filterPriceRangeMax || 9999999999,
      filterCreatorUsername: queryString.parse(this.props.location.search).filterCreatorUsername || '',
      filterCreatorCharacterName: queryString.parse(this.props.location.search).filterCreatorCharacterName || '',
      filterCreatorServer: queryString.parse(this.props.location.search).filterCreatorServer || 'DEFAULT',
      // FILTER INTERFACE
      filterCreatorActive: false,
      filterForSaleActive: false,
      filterSymbolsActive: false,
      filterClothingActive: false,
      filterVehiclesActive: false,
      filterCharacterCustomizationActive: false,
      // AWAITING RESPONSE
      awaitingResponse: true,
    }
  }

  componentDidMount() {
    let { searchText, category, filterCreatorUsername, filterCreatorCharacterName, filterCreatorServer, filterForSale, filterPriceRangeMin, filterPriceRangeMax } = this.state;

    // Activate Filters
    let filtersToActivate = [];

    if (filterCreatorUsername || filterCreatorCharacterName || filterCreatorServer != 'DEFAULT')
      filtersToActivate.push('filterCreatorActive');

    // Activate for sale filter
    if (this.state.filterForSale)
      filtersToActivate.push('filterForSaleActive');

    // Activate symbol filter
    if (category && (category.includes(String(postCategories.SYMBOL)) || category === String(postCategories.SYMBOL)) )
      filtersToActivate.push('filterSymbolsActive');

    // Activate clothing filter
    if (category && (category.includes(String(postCategories.CLOTHING)) || category === String(postCategories.CLOTHING)) )
      filtersToActivate.push('filterClothingActive');
    
    if (category && (category.includes(String(postCategories.VEHICLE)) || category === String(postCategories.VEHICLE)) )
      filtersToActivate.push('filterVehiclesActive');

    if (category && (category.includes(String(postCategories.CHARACTER_CUSTOMIZATION)) || category === String(postCategories.CHARACTER_CUSTOMIZATION)) )
      filtersToActivate.push('filterCharacterCustomizationActive');

    if (filtersToActivate.length > 0) {
      let activateFilters = {};

      filtersToActivate.forEach(filter => activateFilters[filter] = true);
      this.setState({ ...activateFilters, showFilters: true });
    }

    // If we have a page in the URL, let's skip to the page
    const page = queryString.parse(this.props.location.search).page || 1;

    this.loadPosts(searchText, page, category, filterForSale, filterPriceRangeMin, filterPriceRangeMax, filterCreatorUsername, filterCreatorCharacterName, filterCreatorServer);
  }

  loadPosts(searchText, page, category, filterForSale, filterPriceRangeMin, filterPriceRangeMax, filterCreatorUsername, filterCreatorCharacterName, filterCreatorServer) {
    searchPosts(searchText, category, this.props.nsfw, page, filterForSale, filterPriceRangeMin, filterPriceRangeMax, filterCreatorUsername, filterCreatorCharacterName, filterCreatorServer)
      .then(json => {
        this.setState({
          searchText,
          posts: json.posts,
          postCount: json.postCount,
          pageCount: json.pageCount,
          currentPage: page - 1,
          category,
          awaitingResponse: false,
        })
        
        RegeneratePostGrid();
    });
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleFilterPriceRangeChange = (e) => {
    if (e.target.value >= 0)
      this.setState({ [e.target.name]: e.target.value })
    else
      this.setState({ [e.target.name]: 0 })
  }
  handleFilterActiveChange = (filter, activeState) => this.setState({ [filter]: activeState });
  handleShowSearchFilters = (e) => this.setState({ showFilters: true });
  handleSearchButton = (e) => this.handleSearch(1);
  handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.handleSearch(1);
    }
  }
  
  handleSearch = (page = 1) => {
    const {
            searchText,
            filterForSaleActive,
            filterSymbolsActive,
            filterClothingActive,
            filterVehiclesActive,
            filterCharacterCustomizationActive,
            filterPriceRangeMin,
            filterPriceRangeMax,
            filterCreatorActive,
            filterCreatorUsername,
            filterCreatorCharacterName,
            filterCreatorServer,
          } = this.state;

    let newWindowPath = window.location.pathname;

    newWindowPath += `?query=${searchText}`;
    
    if (filterSymbolsActive)
      newWindowPath += `&filterCategory=${postCategories.SYMBOL}`;

    if (filterClothingActive)
      newWindowPath += `&filterCategory=${postCategories.CLOTHING}`;

    if (filterVehiclesActive)
      newWindowPath += `&filterCategory=${postCategories.VEHICLE}`;

    if (filterCharacterCustomizationActive)
      newWindowPath += `&filterCategory=${postCategories.CHARACTER_CUSTOMIZATION}`;
    
    if (filterForSaleActive) {
      newWindowPath += `&filterForSale=true`;
      newWindowPath += `&filterPriceRangeMin=${filterPriceRangeMin}`;
      newWindowPath += `&filterPriceRangeMax=${filterPriceRangeMax}`;
    }

    if (filterCreatorActive) {
      if (filterCreatorUsername != '')
        newWindowPath += `&filterCreatorUsername=${filterCreatorUsername}`;

      if (filterCreatorCharacterName != '')
        newWindowPath += `&filterCreatorCharacterName=${filterCreatorCharacterName}`;

      if (filterCreatorServer != 'DEFAULT')
        newWindowPath += `&filterCreatorServer=${filterCreatorServer}`;
    }
    
    newWindowPath += `&page=${page}`;

    this.props.history.push(newWindowPath);
  }

  handlePageClick = data => {
    // Normalize from page 0 to 1
    let selected = data.selected + 1;

    this.handleSearch(selected);
  };

  handleClearFilters = () => {
    this.setState({
      showFilters: false,
      filterForSale: false,
      filterPriceRangeMin: 0,
      filterPriceRangeMax: 9999999999,
      filterCreatorUsername: '',
      filterCreatorCharacterName: '',
      filterCreatorServer: 'DEFAULT',
      // FILTER INTERFACE
      filterCreatorActive: false,
      filterForSaleActive: false,
      filterSymbolsActive: false,
      filterClothingActive: false,
      filterVehiclesActive: false,
      filterCharacterCustomizationActive: false,
    })
  }

  renderSidebar() {
    return (
      <div className="sidebar">
        
            <div className="filters">

              <StyledCardSplitMainInputBoxContainer>
                <StyledCardSplitMainInputBox name="searchText" type="text" onChange={this.handleChange} onKeyDown={this.handleOnKeyDown} placeholder="Search" maxLength={30} value={this.state.searchText} />
                <StyledCardSplitMainInputBoxButton onClick={this.handleSearchButton}>
                  <Icon name='search' strokeWidth={2} />
                </StyledCardSplitMainInputBoxButton>
              </StyledCardSplitMainInputBoxContainer>

              {
                this.state.showFilters ? (

                  <>
                    <StyledButtonThin onClick={this.handleClearFilters}>
                      Clear Filters [-]
                    </StyledButtonThin>

                    <Filter filterActive={this.state.filterCreatorActive} filterName='Creator' filterActiveColor='3a3a3a' filterIcon={<Icon name='user-icon' stroke="currentColor" strokeWidth={1.8} width={25} height={25} />} parentActiveState='filterCreatorActive' onUpdate={this.handleFilterActiveChange}>
                      <div className="option">
                        <div className="name">
                          Username:
                        </div>
                        <div className="input">
                          <Input id="filterCreatorUsername" name="filterCreatorUsername" type="text" placeholder="" value={this.state.filterCreatorUsername} onChange={this.handleChange} onKeyDown={this.handleOnKeyDown} />
                        </div>
                      </div>
                      <div className="option">
                        <div className="name">
                          Character Name:
                        </div>
                        <div className="input">
                          <Input id="filterCreatorCharacterName" name="filterCreatorCharacterName" type="text" placeholder="" value={this.state.filterCreatorCharacterName} onChange={this.handleChange} onKeyDown={this.handleOnKeyDown} />
                        </div>
                      </div>
                      <div className="option">
                        <div className="name">
                          Character Server:
                        </div>
                        <div className="input">
                          <Select id="filterCreatorServer" name="filterCreatorServer" value={this.state.filterCreatorServer} onChange={e => this.handleChange(e)}>
                            <option value="DEFAULT"> Choose a server </option>
                            <option value="5">{gameServers.getName(5)}</option>
                            <option value="6">{gameServers.getName(6)}</option>
                            <option value="1">{gameServers.getName(1)}</option>
                            <option value="2">{gameServers.getName(2)}</option>
                            <option value="3">{gameServers.getName(3)}</option>
                            <option value="4">{gameServers.getName(4)}</option>
                          </Select>
                        </div>
                      </div>
                    </Filter>

                    <Filter filterActive={this.state.filterForSaleActive} filterName='For Sale' filterActiveColor='2dcb73' filterIcon={<Icon name='marketplace-icon' fill="currentColor" stroke="none" strokeWidth={1.8} width={25} height={25} />} parentActiveState='filterForSaleActive' onUpdate={this.handleFilterActiveChange}>
                      <div className="option">
                        <div className="name">
                          Price Range:
                        </div>
                        <div className="input range">
                          <div className="form-input-range">
                            <Input id="filterPriceRangeMin" name="filterPriceRangeMin" className="input-range" type="number" min="0" max="9999999999" placeholder="0" value={this.state.filterPriceRangeMin} onChange={this.handleFilterPriceRangeChange} onKeyDown={this.handleOnKeyDown} />
                            <span>-</span>
                            <Input id="filterPriceRangeMax" name="filterPriceRangeMax" className="input-range" type="number" min="0" max="9999999999" placeholder="9999999999" value={this.state.filterPriceRangeMax} onChange={this.handleFilterPriceRangeChange} onKeyDown={this.handleOnKeyDown} />
                          </div>
                        </div>
                      </div>
                    </Filter>

                    <Filter filterActive={this.state.filterSymbolsActive} filterName='Symbols' filterActiveColor='3bc9ff' filterIcon={<Icon name='symbol-icon' fill="currentColor" stroke="none" strokeWidth={1.8} width={25} height={25} />} parentActiveState='filterSymbolsActive' onUpdate={this.handleFilterActiveChange} />
                    <Filter filterActive={this.state.filterClothingActive} filterName='Clothing' filterActiveColor='8e9bff' filterIcon={<Icon name='clothing-icon' fill="currentColor" stroke="none" strokeWidth={1.8} width={25} height={25} />} parentActiveState='filterClothingActive' onUpdate={this.handleFilterActiveChange} />
                    <Filter filterActive={this.state.filterVehiclesActive} filterName='Vehicles' filterActiveColor='ff3c43' filterIcon={<Icon name="vehicle-icon" fill="currentColor" stroke="none" width={30} height={30} />} parentActiveState='filterVehiclesActive' onUpdate={this.handleFilterActiveChange} />
                    <Filter filterActive={this.state.filterCharacterCustomizationActive} filterName='Customization' filterActiveColor='da8e18' filterIcon={<Icon name="char-customization-icon" fill="currentColor" stroke="none" width={30} height={30} />} parentActiveState='filterCharacterCustomizationActive' onUpdate={this.handleFilterActiveChange} />
                  </>
                ) : (
                  <StyledButtonThin onClick={this.handleShowSearchFilters}>
                    Advanced Search [+]
                  </StyledButtonThin>
                )
              }
        </div>
        
      </div>
    );
  }

  renderMainContent() {
    return (
      <div className="main-content">

        <StickyContentHeader>
          Search
        </StickyContentHeader>

        {this.state.awaitingResponse && <Spinner />}

        <div className="search-results">
          {GeneratePostGrid(this.state.posts, true)}

          {this.state.pageCount > 1 && (
            <ReactPaginate
              forcePage={this.state.currentPage}
              previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
              nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="search-page">

        {/* <div className="search-header">
          <div className="title">Search</div>
        </div> */}

        <div className="search-area">
          {this.renderSidebar()}
          {this.renderMainContent()}
        </div>

      </div>
    );
  }

};

export default Search;
