import {
  REQUEST_USER,
  RECEIVE_USER,
  RESET_PROFILE,
  RECEIVE_UNBAN_USER,
  RECEIVE_FOLLOW_USER,
  RECEIVE_UNFOLLOW_USER
} from '../actions/userActions'

const user = (
  state = {
    isFetching: false,
    user: {}
  },
  action
) => {
  switch (action.type) {
    case REQUEST_USER:
      return Object.assign({}, state, {
        isFetching: true,
        user: {},
      })
    case RECEIVE_USER:
      return Object.assign({}, state, {
        isFetching: false,
        user: action.user,
      })
    case RESET_PROFILE:
      return Object.assign({}, state, {
        isFetching: false,
        user: {},
      })
    case RECEIVE_UNBAN_USER:
      if (!!action.success)
        return Object.assign({}, state, {
          ...state,
          user: {
            ...state.user,
            banned: action.banned,
          }
        })
      else
        return state;
    case RECEIVE_FOLLOW_USER:
      if (!!action.success && state.user._id == action.userId)
        return Object.assign({}, state, {
          ...state,
          user: {
            ...state.user,
            following: true,
            numFollowers: state.user.numFollowers + 1
          }
        });
    else
        return state;
    case RECEIVE_UNFOLLOW_USER:
      if (!!action.success && state.user._id == action.userId)
        return Object.assign({}, state, {
          ...state,
          user: {
            ...state.user,
            following: false,
            numFollowers: state.user.numFollowers - 1
          }
        });
      else
        return state;
    default:
      return state
  }
}

export default user;