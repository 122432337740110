import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={width}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
    >
        <path fill={fill} stroke={stroke} d="M4.761,5.143h-2.67v7.444c0,0.271-0.22,0.492-0.491,0.492H1.047v4.793c0,0.544,0.44,0.985,0.983,0.985h2.73
		c0.543,0,0.983-0.441,0.983-0.985V6.126C5.744,5.583,5.304,5.143,4.761,5.143z"/>
        <path fill={fill} stroke={stroke} d="M10.109,12.587V5.143H7.767c-0.543,0-0.983,0.44-0.983,0.984v11.746c0,0.544,0.44,0.985,0.983,0.985h2.73
		c0.543,0,0.984-0.441,0.984-0.985v-4.793h-0.881C10.329,13.079,10.109,12.858,10.109,12.587z"/>
        <path fill={fill} stroke={stroke} d="M15.771,12.587V5.143h-1.79v7.444c0,0.271-0.22,0.492-0.492,0.492h-0.971v4.793c0,0.544,0.44,0.985,0.984,0.985h2.729
		c0.545,0,0.985-0.441,0.985-0.985v-4.793h-0.956C15.99,13.079,15.771,12.858,15.771,12.587z"/>
        <path fill={fill} stroke={stroke} d="M21.97,5.143h-2.327v7.444c0,0.271-0.219,0.492-0.491,0.492h-0.896v4.793c0,0.544,0.44,0.985,0.983,0.985h2.73
		c0.543,0,0.983-0.441,0.983-0.985V6.126C22.953,5.583,22.513,5.143,21.97,5.143z"/>
    </svg>
);

export default SVG;