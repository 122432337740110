import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import queryString from 'query-string';

import Icon from '../../components/icons';
import { GeneratePostGrid, RegeneratePostGrid } from '../../components/posts/DynamicPostGrid';
import StickyContentHeader from '../../components/fullscreenPageLayout/contentStickyHeader';

const StyledImageContainer = styled.div`
  padding: 10px;
`;

const StyledPaginateContainer = styled.div`
  padding-bottom: 3rem;
`;

const Following = props => {

    useEffect(() => {
        const page = queryString.parse(props.location.search).page || 1;

        // loadPosts(page, category);
        props.getPosts(page, props.nsfw)
            .then(() => RegeneratePostGrid());
    }, [])

    const handlePageClick = data => {
        // Normalize from page 0 to 1
        let selected = data.selected + 1;

        props.history.push(`${window.location.pathname}?page=${selected}`);
    };

    return (
        <>
            <StickyContentHeader>
                Following
            </StickyContentHeader>

            <StyledImageContainer>
                {GeneratePostGrid(props.posts, true)}
            </StyledImageContainer>

            { props.pageCount > 1 &&
                <StyledPaginateContainer>
                    <ReactPaginate
                        forcePage={props.currentPage}
                        previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
                        nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
                        breakClassName={'break-me'}
                        pageCount={props.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                </StyledPaginateContainer>
            }
        </>
    );
};

export default Following;
