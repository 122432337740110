import React from 'react';
import styled from 'styled-components';

const StyledModificationIcon = styled.div`
    display: flex;
    background-image: url(${props => props.icon_url});
    background-size: ${props => props.width}px ${props => props.height}px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    ${props => props.grayscale ? 'filter: contrast(0%);' : null}
    transition: filter ease-in-out 0.1s;
`;

const ModificationIcon = props => {
    const imageWidth = props.width || 64;
    const imageHeight = props.height || 64;

    return (
        <StyledModificationIcon icon_url={props.icon_url} width={imageWidth} height={imageHeight} grayscale={props.grayscale} data-tip={props.sdisplayname} data-for='__tooltip' />
    )
};

export default ModificationIcon;