import { getPostById, getEditPostDetail } from '../services/PostService';

export const REQUEST_POST = 'REQUEST_POST';
export const RECEIVE_POST = 'RECEIVE_POST';
export const REQUEST_EDIT_POST_DETAIL = 'REQUEST_EDIT_POST_DETAIL';
export const RECEIVE_EDIT_POST_DETAIL = 'RECEIVE_EDIT_POST_DETAIL';

const requestPost = id => ({
  type: REQUEST_POST,
  id,
});

const receivePost = post => ({
  type: RECEIVE_POST,
  post,
});

const requestEditPostDetail = id => ({
  type: REQUEST_EDIT_POST_DETAIL,
  id,
});

const receiveEditPostDetail = (success, post) => ({
  type: RECEIVE_EDIT_POST_DETAIL,
  success,
  post,
});

export const fetchPost = id => (
  dispatch => {
    dispatch(requestPost(id))
    return getPostById(id)
      .then(post => dispatch(receivePost(post)))
  }
);

export const fetchEditPostData = id => (
  dispatch => {
    dispatch(requestEditPostDetail(id))
    return getEditPostDetail(id)
      .then(res => dispatch(receiveEditPostDetail(res.success, res.post)))
  }
)