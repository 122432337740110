import { connect } from 'react-redux'
import { fetchPost } from '../actions/postActions'
import Post from '../pages/post/post'
import { likePost, unlikePost, deletePost, getPostComments, createComment, deleteComment } from '../services/PostService';

const mapStateToProps = (state, ownProps) => {
  const { currentUser, currentPost } = state;
  return {
    post: currentPost.post,
    isLoggedIn: !!currentUser.id,
    userUsername: currentUser.username,
    userAvatar: currentUser.avatar,
    userIsVerified: currentUser.verified,
    userRoles: currentUser.roles,
    isFetching: currentPost.isFetching
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPost: () =>
    dispatch(fetchPost(ownProps.match.params.post_id)),
  likePost: (postId, ownerId) => {
    dispatch({ type: 'SEND_LIKE_POST', postId, ownerId });
    return likePost(postId);
  },
  createComment: (text, postId, ownerId) => {
    dispatch({ type: 'SEND_COMMENT_POST', postId, ownerId });
    return createComment(postId, text);
  },
  unlikePost,
  deletePost,
  getPostComments,
  deleteComment,
})

export default connect(mapStateToProps, mapDispatchToProps)(Post);