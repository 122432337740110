import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "1.5",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        height={height}
        style={style}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        strokeLinejoin={strokeLinejoin}
    >
        <rect fill={fill} stroke={stroke} x="16.806" y="4.969" transform="matrix(0.7095 -0.7047 0.7047 0.7095 0.0493 15.0684)" width="2.995" height="5.01" />
        <path fill={fill} stroke={stroke} d="M22.11,10.002L21.054,8.94l-1.712,1.703l-0.337,0.334l-0.335-0.336l-3.531-3.554L14.804,6.75l0.336-0.333l1.715-1.703
		l-1.15-1.156c-0.576-0.581-1.515-0.583-2.094-0.006l-2.464,2.447l1.372,0.628c0.124,0.057,0.239,0.131,0.344,0.22
		c0.03,0.023,0.062,0.049,0.09,0.077c0.017,0.016,0.031,0.034,0.047,0.052l1.148,1.4c0.159,0.195,0.198,0.434,0.103,0.639
		c-0.094,0.206-0.302,0.332-0.552,0.336l-1.812,0.035c-0.062,0.001-0.121-0.005-0.179-0.02c-0.135-0.02-0.267-0.058-0.391-0.115
		L8.959,8.171l-4.401,4.375c-0.582,0.578-0.584,1.518-0.008,2.099l6.406,6.443c0.576,0.579,1.516,0.584,2.095,0.006l9.054-8.998
		C22.684,11.521,22.687,10.583,22.11,10.002z M8.075,13.858l-0.899-0.904l0.905-0.899l0.898,0.904L8.075,13.858z M9.904,12.04
		l-0.899-0.904l0.904-0.899l0.899,0.905L9.904,12.04z M12.692,18.506l-3.697-3.722l0.903-0.899l3.698,3.722L12.692,18.506z
		 M14.521,16.687l-3.698-3.722l0.905-0.899l3.697,3.722L14.521,16.687z"/>
        <path fill={fill} stroke={stroke} d="M11.801,8.902c0.022,0.007,0.047,0.011,0.076,0.011l1.81-0.035c0.14-0.003,0.181-0.093,0.093-0.201l-1.147-1.4
		c-0.007-0.007-0.013-0.013-0.017-0.018c-0.015-0.015-0.03-0.026-0.044-0.034c-0.073-0.067-0.155-0.123-0.25-0.167l-9.98-4.575
		c-0.296-0.144-1.078,1.5-0.836,1.742C1.514,4.233,1.522,4.24,1.534,4.246l9.98,4.575C11.607,8.864,11.705,8.89,11.801,8.902z"/>
    </svg>
);

export default SVG;