import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker, ChromePicker } from 'react-color';
import Icon from '../components/icons';

class SketchExample extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false,
            color: this.props.preSelectColor && `${this.props.preSelectColor}` || '#e5e5e5',
        };
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
        this.props.onSelect(this.state.color);
    };

    handleChange = (color) => {
        this.setState({ color: color.hex });
        this.props.onSelect(this.state.color);
    };

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '42px',
                    borderRadius: '3px',
                    background: `${this.state.color}`,
                    border: '1px solid rgba(0,0,0,0.13)',
                    cursor: 'pointer',
                    color: 'rgba(0,0,0,0.8)',
                },
                icon: {
                    filter: 'drop-shadow(0px 0px 4px #fff)',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div>
                <div style={styles.color} onClick={this.handleClick}>
                    <Icon style={styles.icon} name="edit" width={18} height={18} strokeWidth={3} alt="Edit Profile Color" />
                </div>
                {this.state.displayColorPicker ?
                    <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <ChromePicker color={this.state.color} onChange={this.handleChange} onChangeComplete={this.handleChange} disableAlpha={true} />
                    </div> : null
                }

            </div>
        )
    }
}

export default SketchExample