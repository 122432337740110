import React from 'react';
import styled from 'styled-components';

const StyledContentContainer = styled.div`
    display: block;
    width: 100%;
`;

export default props =>
    <StyledContentContainer>
        {props.children}
    </StyledContentContainer>