import { connect } from 'react-redux'
import { fetchUserDetails, unlinkUserDiscord } from '../actions/userActions'
import { editUser } from '../services/UserService';
import { updateSelfUserData, updateUserAvatar } from '../actions/userActions';
import { tryClaimCharacter } from '../actions/characterActions';
import EditProfile from '../pages/profile/edit';

const mapStateToProps = (state, ownProps) => {
    const { currentUser, currentSettings } = state;
    const userId = ownProps.match.params.user_id;

    let current = currentUser.id === userId || !userId
        ? currentUser
        : currentSettings;

    return {
        currentUserId: currentUser.id,
        isFetching: current.isFetching,
        id: current.id || current._id,
        username: current.username,
        avatar: current.avatar,
        email: current.email,
        profileColor: current.profileColor,
        verified: current.verified,
        nsfw: current.settingViewNSFW,
        adminEdit: !!userId,
        characters: current.characters,
        discordId: current.discordId,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchUser: () => {
        dispatch(fetchUserDetails(ownProps.match.params.user_id))
    },
    saveUser: (id, data) =>
        dispatch(updateSelfUserData(id, data)),
    updateAvatar: (id, avatarBlob) =>
        dispatch(updateUserAvatar(id, avatarBlob)),
    claimCharacter: (id, server, name, faction, gender) =>
        dispatch(tryClaimCharacter(id, server, name, faction, gender)),
    unlinkDiscord: () =>
        dispatch(unlinkUserDiscord()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);