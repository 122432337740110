import { gameModifierCategories } from '../util/enums';

import FnMod_Character_MobileCover_Icon from '../static/images/game_assets/FnMod_Character_MobileCover.png';

//#region Weapon Modifications

const FnMod_Weapon_CoolingJacket1 = {
    sdisplayname: 'Cooling Jacket 1',
    sapbdb: 'FnMod_Weapon_CoolingJacket1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_CoolingJacket1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_CoolingJacket1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_CoolingJacket2 = {
    sdisplayname: 'Cooling Jacket 2',
    sapbdb: 'FnMod_Weapon_CoolingJacket2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_CoolingJacket2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_CoolingJacket2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_CoolingJacket3 = {
    sdisplayname: 'Cooling Jacket 3',
    sapbdb: 'FnMod_Weapon_CoolingJacket3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_CoolingJacket3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_CoolingJacket3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_HeavyBarrel1 = {
    sdisplayname: 'Heavy Barrel 1',
    sapbdb: 'FnMod_Weapon_HeavyBarrel1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HeavyBarrel1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HeavyBarrel1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_HeavyBarrel2 = {
    sdisplayname: 'Heavy Barrel 2',
    sapbdb: 'FnMod_Weapon_HeavyBarrel2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HeavyBarrel2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HeavyBarrel2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_HeavyBarrel3 = {
    sdisplayname: 'Heavy Barrel 3',
    sapbdb: 'FnMod_Weapon_HeavyBarrel3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HeavyBarrel3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HeavyBarrel3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_Rifling1 = {
    sdisplayname: 'Improved Rifling 1',
    sapbdb: 'FnMod_Weapon_Rifling1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Rifling1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Rifling1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_Rifling2 = {
    sdisplayname: 'Improved Rifling 2',
    sapbdb: 'FnMod_Weapon_Rifling2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Rifling2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Rifling2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_Rifling3 = {
    sdisplayname: 'Improved Rifling 3',
    sapbdb: 'FnMod_Weapon_Rifling3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Rifling3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Rifling3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_MuzzleBrake_Horizontal = {
    sdisplayname: 'Muzzle Brake (Horizontal)',
    sapbdb: 'FnMod_Weapon_MuzzleBrake_Horizontal',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_MuzzleBrakeHorizontal.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_MuzzleBrake_Horizontal',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_RED,
};

const FnMod_Weapon_Bandolier1 = {
    sdisplayname: 'Bandolier 1',
    sapbdb: 'FnMod_Weapon_Bandolier1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Bandolier1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Bandolier1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_Bandolier2 = {
    sdisplayname: 'Bandolier 2',
    sapbdb: 'FnMod_Weapon_Bandolier2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Bandolier2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Bandolier2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_Bandolier3 = {
    sdisplayname: 'Bandolier 3',
    sapbdb: 'FnMod_Weapon_Bandolier3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Bandolier3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Bandolier3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_ExtendedMagazine1 = {
    sdisplayname: 'Extended Magazine 1',
    sapbdb: 'FnMod_Weapon_ExtendedMagazine1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ExtendedMagazine1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ExtendedMagazine1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_ExtendedMagazine2 = {
    sdisplayname: 'Extended Magazine 2',
    sapbdb: 'FnMod_Weapon_ExtendedMagazine2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ExtendedMagazine2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ExtendedMagazine2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_ExtendedMagazine3 = {
    sdisplayname: 'Extended Magazine 3',
    sapbdb: 'FnMod_Weapon_ExtendedMagazine3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ExtendedMagazine3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ExtendedMagazine3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_MagazinePull1 = {
    sdisplayname: 'Magazine Pull 1',
    sapbdb: 'FnMod_Weapon_MagazinePull1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_MagazinePull1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_MagazinePull1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_MagazinePull2 = {
    sdisplayname: 'Magazine Pull 2',
    sapbdb: 'FnMod_Weapon_MagazinePull2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_MagazinePull2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_MagazinePull2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_MagazinePull3 = {
    sdisplayname: 'Magazine Pull 3',
    sapbdb: 'FnMod_Weapon_MagazinePull3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_MagazinePull3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_MagazinePull3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_BLUE,
};

const FnMod_Weapon_ThreePointSling1 = {
    sdisplayname: '3-Point Sling 1',
    sapbdb: 'FnMod_Weapon_ThreePointSling1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ThreePointSling1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ThreePointSling1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_PURPLE,
};

const FnMod_Weapon_ThreePointSling2 = {
    sdisplayname: '3-Point Sling 2',
    sapbdb: 'FnMod_Weapon_ThreePointSling2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ThreePointSling2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ThreePointSling2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_PURPLE,
};

const FnMod_Weapon_ThreePointSling3 = {
    sdisplayname: '3-Point Sling 3',
    sapbdb: 'FnMod_Weapon_ThreePointSling3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ThreePointSling3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ThreePointSling3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_PURPLE,
};

const FnMod_Weapon_MobilitySling = {
    sdisplayname: 'Mobility Sling',
    sapbdb: 'FnMod_Weapon_MobilitySling',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_MobilitySling.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_MobilitySling',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_PURPLE,
};

const FnMod_Weapon_Tagger = {
    sdisplayname: 'Tagger',
    sapbdb: 'FnMod_Weapon_Tagger',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_Tagger.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_Tagger',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_PURPLE,
};

const FnMod_Weapon_HighMagnificationScope = {
    sdisplayname: 'High-Magnification Scope',
    sapbdb: 'FnMod_Weapon_HighMagnificationScope',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HighMagnificationScope.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HighMagnificationScope',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_HuntingSight1 = {
    sdisplayname: 'Hunting Sight 1',
    sapbdb: 'FnMod_Weapon_HuntingSight1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HuntingSight1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HuntingSight1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_HuntingSight2 = {
    sdisplayname: 'Hunting Sight 2',
    sapbdb: 'FnMod_Weapon_HuntingSight2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HuntingSight2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HuntingSight2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_HuntingSight3 = {
    sdisplayname: 'Hunting Sight 3',
    sapbdb: 'FnMod_Weapon_HuntingSight3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_HuntingSight3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_HuntingSight3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_ReflexSight1 = {
    sdisplayname: 'Reflex Sight 1',
    sapbdb: 'FnMod_Weapon_ReflexSight1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ReflexSight1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ReflexSight1',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_ReflexSight2 = {
    sdisplayname: 'Reflex Sight 2',
    sapbdb: 'FnMod_Weapon_ReflexSight2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ReflexSight2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ReflexSight2',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

const FnMod_Weapon_ReflexSight3 = {
    sdisplayname: 'Reflex Sight 3',
    sapbdb: 'FnMod_Weapon_ReflexSight3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Weapons/FnMod_Weapon_ReflexSight3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Weapon_ReflexSight3',
    emodifiercategory: gameModifierCategories.WEAPON_MOD_ORANGE,
};

//#endregion

//#region Grenades

const Weapon_Grenade_Frag = {
    sdisplayname: 'Frag Grenade',
    sapbdb: 'Weapon_Grenade_Frag',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_Frag.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_Frag',
    einfracategory: 81,
};

const Weapon_Grenade_Percussion = {
    sdisplayname: 'Percussion Grenade',
    sapbdb: 'Weapon_Grenade_Percussion',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_Frag.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_Percussion',
    einfracategory: 81,
};

const Weapon_Grenade_Concussion = {
    sdisplayname: 'Concussion Grenade',
    sapbdb: 'Weapon_Grenade_Concussion',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_Frag.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_Concussion',
    einfracategory: 81,
};

const Weapon_Grenade_LightFrag = {
    sdisplayname: 'Low-Yield Fragmentation Grenade',
    sapbdb: 'Weapon_Grenade_LightFrag',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_LightFrag.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_LightFrag',
    einfracategory: 81,
};

const Weapon_Grenade_LTL = {
    sdisplayname: 'Stun Grenade',
    sapbdb: 'Weapon_Grenade_LTL',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_LTL.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_LTL',
    einfracategory: 81,
};

const Weapon_Grenade_Physical_EightBall = {
    sdisplayname: 'Eight-Ball',
    sapbdb: 'Weapon_Grenade_Physical_EightBall',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_EightBall.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_Physical_EightBall',
    einfracategory: 81,
};

const Weapon_Grenade_Physical_Brick = {
    sdisplayname: 'Half-Brick',
    sapbdb: 'Weapon_Grenade_Physical_Brick',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_Weapons/Icon_Grenade_HouseBrick.png',
    url: 'https://legacy.apbdb.com/items/Weapon_Grenade_Physical_Brick',
    einfracategory: 81,
};


//#endregion

//#region Character Modifications

const FnMod_Character_ClottingAgent1 = {
    sdisplayname: 'Clotting Agent I',
    sapbdb: 'FnMod_Character_ClottingAgent1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_ClottingAgent1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_ClottingAgent1',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_ClottingAgent2 = {
    sdisplayname: 'Clotting Agent II',
    sapbdb: 'FnMod_Character_ClottingAgent2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_ClottingAgent2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_ClottingAgent2',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_ClottingAgent3 = {
    sdisplayname: 'Clotting Agent III',
    sapbdb: 'FnMod_Character_ClottingAgent3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_ClottingAgent3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_ClottingAgent3',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_Kevlar1 = {
    sdisplayname: 'Kevlar Implants 1',
    sapbdb: 'FnMod_Character_Kevlar1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Kevlar1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Kevlar1',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_Kevlar2 = {
    sdisplayname: 'Kevlar Implants 2',
    sapbdb: 'FnMod_Character_Kevlar2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Kevlar2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Kevlar2',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_Kevlar3 = {
    sdisplayname: 'Kevlar Implants 3',
    sapbdb: 'FnMod_Character_Kevlar3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Kevlar3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Kevlar3',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_FlakJacket = {
    sdisplayname: 'Flak Jacket',
    sapbdb: 'FnMod_Character_FlakJacket',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_FlakJacket.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_FlakJacket',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_Fragile = {
    sdisplayname: 'Fragile',
    sapbdb: 'FnMod_Character_Fragile',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_Fragile.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Fragile',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_GREEN,
};

const FnMod_Character_HappyLandings1 = {
    sdisplayname: 'Happy Landings 1',
    sapbdb: 'FnMod_Character_HappyLandings1',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_HappyLandings1.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_HappyLandings1',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FnMod_Character_HappyLandings2 = {
    sdisplayname: 'Happy Landings 2',
    sapbdb: 'FnMod_Character_HappyLandings2',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_HappyLandings2.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_HappyLandings2',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FnMod_Character_HappyLandings3 = {
    sdisplayname: 'Happy Landings 3',
    sapbdb: 'FnMod_Character_HappyLandings3',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_HappyLandings3.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_HappyLandings3',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FnMod_Character_CarSurfer = {
    sdisplayname: 'Car Surfer',
    sapbdb: 'FnMod_Character_CarSurfer',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_CarSurfer.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_CarSurfer',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FNMod_Character_HardLanding = {
    sdisplayname: 'Hard Landing',
    sapbdb: 'FNMod_Character_HardLanding',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FNMod_Character_HardLanding.png',
    url: 'https://legacy.apbdb.com/items/FNMod_Character_HardLanding',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FnMod_Character_Valzipram = {
    sdisplayname: 'Valzipram Tablets',
    sapbdb: 'FnMod_Character_Valzipram',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Valzipram.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Valzipram',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_BLUE,
};

const FnMod_Character_Blowtorch = {
    sdisplayname: 'Blowtorch',
    sapbdb: 'FnMod_Character_Blowtorch',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_Blowtorch.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Blowtorch',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_Flag = {
    sdisplayname: 'Display Banner',
    sapbdb: 'FnMod_Character_Flag',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_Flag.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Flag',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_FieldSupplier = {
    sdisplayname: 'Field Supplier',
    sapbdb: 'FnMod_Character_FieldSupplier',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_FieldSupplier.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_FieldSupplier',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_AmmoCarrier_Radius = {
    sdisplayname: 'Field Supplier - Wide Radius',
    sapbdb: 'FnMod_Character_AmmoCarrier_Radius',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_FieldSupplier_WideRadius.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_AmmoCarrier_Radius',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_RadarJammer = {
    sdisplayname: 'Radar Jammer',
    sapbdb: 'FnMod_Character_RadarJammer',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_RadarJammer.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_RadarJammer',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_RemoteDetonator = {
    sdisplayname: 'Remote Detonator',
    sapbdb: 'FnMod_Character_RemoteDetonator',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Character_RemoteDetonator.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_RemoteDetonator',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_MobileCover = {
    sdisplayname: 'Mobile Cover',
    sapbdb: 'FnMod_Character_MobileCover',
    icon_url: FnMod_Character_MobileCover_Icon,
    url: 'https://legacy.apbdb.com/items/FnMod_Character_MobileCover',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const FnMod_Character_Spotter = {
    sdisplayname: 'Spotter',
    sapbdb: 'FnMod_Character_Spotter',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/Character/FnMod_Character_Spotter.png',
    url: 'https://legacy.apbdb.com/items/FnMod_Character_Spotter',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_ORANGE,
};

const Usable_Deployable_BoomBox = {
    sdisplayname: 'Boom Box',
    sapbdb: 'Usable_Deployable_BoomBox',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_BoomBox.png',
    url: 'https://legacy.apbdb.com/items/Usable_Deployable_BoomBox',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_YELLOW,
};

const Usable_Consumable_Epinephrine = {
    sdisplayname: 'Epinephrine Injector',
    sapbdb: 'Usable_Consumable_Epinephrine',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_Epinephrine.png',
    url: 'https://legacy.apbdb.com/items/Usable_Consumable_Epinephrine',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_YELLOW,
};

const Usable_Consumable_Medikit = {
    sdisplayname: 'Med Spray',
    sapbdb: 'Usable_Consumable_Medikit',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_MediKit.png',
    url: 'https://legacy.apbdb.com/items/Usable_Consumable_Medikit',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_YELLOW,
};

const Usable_Deployable_FieldSupplier_Large = {
    sdisplayname: 'Resupply Box (Large)',
    sapbdb: 'Usable_Deployable_FieldSupplier-Large',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_FeildSupplier_Medium.png',
    url: 'https://legacy.apbdb.com/items/Usable_Deployable_FieldSupplier-Large',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_YELLOW,
};

const Usable_Deployable_SatchelCharge = {
    sdisplayname: 'Satchel Charge',
    sapbdb: 'Usable_Deployable_SatchelCharge',
    icon_url: 'https://legacy.apbdb.com/static/img/APBMenus_Art_UpgradeIcons/FnMod_Consumable_SatchelCharge.png',
    url: 'https://legacy.apbdb.com/items/Usable_Deployable_SatchelCharge',
    emodifiercategory: gameModifierCategories.CHARACTER_MOD_YELLOW,
};

//#endregion

const liveRedWeaponModifications = [
    FnMod_Weapon_CoolingJacket1,
    FnMod_Weapon_CoolingJacket2,
    FnMod_Weapon_CoolingJacket3,
    FnMod_Weapon_HeavyBarrel1,
    FnMod_Weapon_HeavyBarrel2,
    FnMod_Weapon_HeavyBarrel3,
    FnMod_Weapon_Rifling1,
    FnMod_Weapon_Rifling2,
    FnMod_Weapon_Rifling3,
    FnMod_Weapon_MuzzleBrake_Horizontal
];

const liveBlueWeaponModifications = [
    FnMod_Weapon_Bandolier1,
    FnMod_Weapon_Bandolier2,
    FnMod_Weapon_Bandolier3,
    FnMod_Weapon_ExtendedMagazine1,
    FnMod_Weapon_ExtendedMagazine2,
    FnMod_Weapon_ExtendedMagazine3,
    FnMod_Weapon_MagazinePull1,
    FnMod_Weapon_MagazinePull2,
    FnMod_Weapon_MagazinePull3,
];

const livePurpleWeaponModifications = [
    FnMod_Weapon_ThreePointSling1,
    FnMod_Weapon_ThreePointSling2,
    FnMod_Weapon_ThreePointSling3,
    FnMod_Weapon_MobilitySling,
    FnMod_Weapon_Tagger,
];

const liveOrangeWeaponModifications = [
    FnMod_Weapon_HuntingSight1,
    FnMod_Weapon_HuntingSight2,
    FnMod_Weapon_HuntingSight3,
    FnMod_Weapon_ReflexSight1,
    FnMod_Weapon_ReflexSight2,
    FnMod_Weapon_ReflexSight3,
    FnMod_Weapon_HighMagnificationScope,
];

const liveWeaponModifications = [
    ...liveRedWeaponModifications,
    ...liveBlueWeaponModifications,
    ...livePurpleWeaponModifications,
    ...liveOrangeWeaponModifications
];

const liveGrenades = [
    Weapon_Grenade_Frag,
    Weapon_Grenade_Percussion,
    Weapon_Grenade_Concussion,
    Weapon_Grenade_LightFrag,
    Weapon_Grenade_LTL,
    Weapon_Grenade_Physical_EightBall,
    Weapon_Grenade_Physical_Brick
];

const liveGreenCharacterModifications = [
    FnMod_Character_ClottingAgent1,
    FnMod_Character_ClottingAgent2,
    FnMod_Character_ClottingAgent3,
    FnMod_Character_Kevlar1,
    FnMod_Character_Kevlar2,
    FnMod_Character_Kevlar3,
    FnMod_Character_FlakJacket,
    FnMod_Character_Fragile
];

const liveBlueCharacterModifications = [
    FnMod_Character_HappyLandings1,
    FnMod_Character_HappyLandings2,
    FnMod_Character_HappyLandings3,
    FnMod_Character_CarSurfer,
    FNMod_Character_HardLanding,
    FnMod_Character_Valzipram
];

const liveOrangeCharacterModifications = [
    FnMod_Character_Blowtorch,
    FnMod_Character_Flag,
    FnMod_Character_FieldSupplier,
    FnMod_Character_AmmoCarrier_Radius,
    FnMod_Character_RadarJammer,
    FnMod_Character_RemoteDetonator,
    FnMod_Character_MobileCover,
    FnMod_Character_Spotter
];

const liveYellowCharacterModifications = [
    Usable_Deployable_BoomBox,
    Usable_Consumable_Epinephrine,
    Usable_Consumable_Medikit,
    Usable_Deployable_FieldSupplier_Large,
    Usable_Deployable_SatchelCharge
];

const liveCharacterModifications = [
    ...liveGreenCharacterModifications,
    ...liveBlueCharacterModifications,
    ...liveOrangeCharacterModifications,
    ...liveYellowCharacterModifications
];

const getPreloadedItemData = (sapbdb) => {
    if (liveWeaponModifications.filter(mod => mod.sapbdb === sapbdb).length > 0) {
        return liveWeaponModifications.filter(mod => mod.sapbdb === sapbdb)[0];
    } else if (liveGrenades.filter(grenade => grenade.sapbdb === sapbdb).length > 0) {
        return liveGrenades.filter(grenade => grenade.sapbdb === sapbdb)[0];
    } else if (liveCharacterModifications.filter(mod => mod.sapbdb === sapbdb).length > 0) {
        return liveCharacterModifications.filter(mod => mod.sapbdb === sapbdb)[0];
    } else
        return null;
};

export {
    liveRedWeaponModifications,
    liveBlueWeaponModifications,
    livePurpleWeaponModifications,
    liveOrangeWeaponModifications,
    liveWeaponModifications,
    liveGrenades,
    liveGreenCharacterModifications,
    liveBlueCharacterModifications,
    liveOrangeCharacterModifications,
    liveYellowCharacterModifications,
    liveCharacterModifications,
    getPreloadedItemData
};