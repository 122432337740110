import React, { useState } from 'react';
import styled from 'styled-components';

import { gameModifierCategories } from '../../../../util/enums';

import ModificationIcon from '../../../gameObject/modificationIcon';

const StyledGameItemToggle = styled.div`
    display: inline-flex;

    border-radius: 3px;
    border: 2px #000 solid;
    background: #646464;
    background: -moz-linear-gradient(top, ${ props => !props.selected ? '#000000' : '#646464' } 0%, #3e3e3e 49%, #2c2c2c 50%, #282828 100%);
    background: -webkit-linear-gradient(top, ${ props => !props.selected ? '#000000' : '#646464' } 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);
    background: linear-gradient(to bottom, ${ props => !props.selected ? '#000000' : '#646464' } 0%,#3e3e3e 49%,#2c2c2c 50%,#282828 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${ props => !props.selected ? '#000000' : '#646464' }', endColorstr='#282828',GradientType=0 );
    user-select: none;
    cursor: pointer;

    :hover {
        border: 2px solid ${props => props.hoverBorderColor};
    }
`;

const GameItemToggle = props => {
    const [selected, setSelected] = useState(props.selected);
    const { sdisplayname, sapbdb, icon_url, url, emodifiercategory, handleToggleOn, handleToggleOff } = props;

    const toggleOn = () => {
        setSelected(true);
        handleToggleOn(sdisplayname, sapbdb, icon_url, url, emodifiercategory);
    };

    const toggleOff = () => {
        setSelected(false);
        handleToggleOff(sapbdb);
    };

    const toggleItem = () => selected ? toggleOff() : toggleOn();

    const getBorderColor = () => {
        switch (emodifiercategory) {
            case gameModifierCategories.WEAPON_MOD_RED:
                return '#de1d1e';
            case gameModifierCategories.WEAPON_MOD_BLUE:
                return '#08aade';
            case gameModifierCategories.WEAPON_MOD_PURPLE:
                return '#a252de';
            case gameModifierCategories.WEAPON_MOD_ORANGE:
                return '#d66c1e';
            case gameModifierCategories.CHARACTER_MOD_GREEN:
                return '#68be2e';
            case gameModifierCategories.CHARACTER_MOD_BLUE:
                return '#18abde';
            case gameModifierCategories.CHARACTER_MOD_ORANGE:
                return '#dd7020';
            case gameModifierCategories.CHARACTER_MOD_YELLOW:
                return '#e7ad02';
            default:
                return '#646464';
        }
    }

    return (
        <StyledGameItemToggle hoverBorderColor={getBorderColor()} onClick={e => toggleItem()} selected={selected}>
            <ModificationIcon sdisplayname={sdisplayname} sapbdb={sapbdb} icon_url={icon_url} url={url} grayscale={!selected} />
        </StyledGameItemToggle>
    );
};

export default GameItemToggle;