import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
    strokeLinecap = "round",
    strokeLinejoin = "round",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap={strokeLinecap}
            strokeLinejoin={strokeLinejoin}
        >
            <path fill={fill} stroke={stroke} strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinecap} d="M19,21H5
	c-1.104,0-2-0.896-2-2V5c0-1.104,0.896-2,2-2h11l5,5v11C21,20.104,20.104,21,19,21z"/>
            <polyline fill={fill} stroke={stroke} strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinecap} points="17,21 17,13 7,13 
	7,21 "/>
            <polyline fill={fill} stroke={stroke} strokeWidth={strokeWidth} strokeLinecap={strokeLinecap} strokeLinejoin={strokeLinecap} points="7,3 7,8 15,8 " />
        </svg>
    );

export default SVG;