const feedbackAlertMessages = {
    USER_LOG_IN_SUCCESS: 'Logged In Successfully!',
}

const postCategories = {
    SYMBOL: 0,
    CLOTHING: 1,
    VEHICLE: 2,
    CHARACTER_CUSTOMIZATION: 3,
    getName: (categoryId) => {
        switch(categoryId) {
            case 0:
                return 'Symbol';
            case 1:
                return 'Clothing';
            case 2:
                return 'Vehicle';
            case 3:
                return 'Character Customization'
            default:
                return 'Unknown';
        }
    },
};

const gameServers = {
    PC: 0,
    PS4_NORTH_AMERICA: 1,
    PS4_EUROPE: 2,
    XBOX_NORTH_AMERICA: 3,
    XBOX_EUROPE: 4,
    PC_NA: 5,
    PC_EU: 6,
    getName: (serverId) => {
        switch (serverId) {
            case 0:
                return 'PC';
            case 1:
                return 'PS4 (North America)';
            case 2:
                return 'PS4 (Europe)';
            case 3:
                return 'Xbox (North America)';
            case 4:
                return 'Xbox (Europe)';
            case 5:
                return 'PC (Jericho)';
            case 6:
                return 'PC (Citadel)';
            default:
                return 'unknown';
        }
    },
};

const gameFactions = {
    ENFORCER: 0,
    CRIMINAL: 1,
    getName: (factionId) => {
        switch (factionId) {
            case 0:
                return 'Enforcer';
            case 1:
                return 'Criminal';
            default:
                return 'unknown';
        }
    },
};

const userRoles = {
    MEMBER: 0,
    MODERATOR: 1,
    ADMIN: 2,
    OWNER: 3,
    getName: (role) => {
        switch (role) {
            case 0:
                return 'Member';
            case 1:
                return 'Moderator';
            case 2:
                return 'Admin';
            case 3:
                return 'Developer'; // Renamed from Owner
            default:
                return 'unknown';
        }
    },
};

const userRoleColors = {
    1: '#1377ff', // Moderator
    3: '#FF5200', // Owner
};

const gameModifierCategories = {
    CHARACTER_MOD_GREEN: 2,
    CHARACTER_MOD_BLUE: 4,
    CHARACTER_MOD_ORANGE: 7,
    CHARACTER_MOD_YELLOW: 8,
    VEHICLE_MOD_ORANGE: 9,
    VEHICLE_MOD_GREEN: 10,
    VEHICLE_MOD_RED: 11,
    VEHICLE_MOD_PURPLE: 12,
    VEHICLE_MOD_BLUE: 14,
    WEAPON_MOD_RED: 15,
    WEAPON_MOD_BLUE: 16,
    WEAPON_MOD_PURPLE: 17,
    WEAPON_MOD_ORANGE: 18,
}

const randomLoadoutProfileCategories = {
    PROFILE_NAME: 0,
    PRIMARY_WEAPONS: 1,
    PRIMARY_WEAPON_MODIFICATIONS: 2,
    SECONDARY_WEAPONS: 3,
    GRENADES: 4,
    CHARACTER_MODIFICATIONS: 5,
}

export {
    feedbackAlertMessages,
    postCategories,
    gameServers,
    gameFactions,
    userRoles,
    userRoleColors,
    gameModifierCategories,
    randomLoadoutProfileCategories,
};