import io from 'socket.io-client';
import { receivePushNotification, removePushNotification } from '../actions/notificationActions';


const socketMiddleware = () => {
    let socket = null;

    const onOpen = store => (event) => {
        // console.log('websocket open');
        // store.dispatch(actions.wsConnected(event.target.url));
    };

    const onClose = store => () => {
        // console.log('websocket disconnected by server');
        // store.dispatch(actions.wsDisconnected());
    };

    // the middleware part of this function
    return store => next => action => {
        switch (action.type) {
            case 'WS_CONNECT':
                if (socket === null) {
                    // connect to the remote host
                    socket = io();

                    // websocket handlers
                    socket.on('connect', () => console.log('websocket open'));
                    socket.on('disconnect', () => console.log('websocket disconnected by server'));
                    // socket.on('connect', () => onOpen(store));
                    // socket.on('disconnect', () => onClose(store));
                    socket.on('NEW_NOTIFICATION', (notification) => store.dispatch(receivePushNotification(notification)));
                    socket.on('DELETE_NOTIFICATION', (notificationId) => store.dispatch(removePushNotification(notificationId)));
                }
                break;
            case 'WS_DISCONNECT':
                if (socket !== null) {
                    socket.close();
                }
                socket = null;
                console.log('websocket closed');
                break;
            // case 'WS_SEND':
            //     console.log('sending a message', action.payload);
            //     socket.emit(action.eventName, action.payload);
            //     break;
            default:
                // console.log('the next action:', action);
                return next(action);
        }
    };
};

export default socketMiddleware();