import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';

import Dropzone from '../Dropzone';

import { Croppie } from "croppie";

import ReactTooltip from 'react-tooltip';

import ImagePreview from '../ImagePreview';

import Icon from '../../../icons';

import { postCategories } from '../../../../util/enums';

import './style.css';

const UploadImagePreview = styled.div`
    display: block;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    min-height: ${props => props.height}px;
    background-color: var(--color-background-upload-image-preview);
    border-radius: 5px;
    margin: 4px;
`;

const DEFAULT_MAX_IMAGE_AMOUNT = 4;
const DEFAULT_CROP_WIDTH = 340;
const DEFAULT_CROP_HEIGHT = 570;
const DEFAULT_CROP_BOUNDARY_HEIGHT = 590;
const ACCEPTED_IMAGE_FORMATS = 'image/*';

let c = null;

class ImageUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentImageAmount: 0,
            isCropping: false,
            selectedImage: 0,
            uploadedImageData: [],
            croppedImageData: [],
        };

        this.croppieOptions = {
            showZoomer: true,
            enableOrientation: true,
            // mouseWheelZoom: false,
            viewport: {
                width: this.props.width || DEFAULT_CROP_WIDTH,
                height: this.props.height || DEFAULT_CROP_HEIGHT,
                type: "square"
            },
            boundary: {
                height: (this.props.boundaryHeight && `${this.props.boundaryHeight}px`) || `${DEFAULT_CROP_BOUNDARY_HEIGHT}px`
            }
        };

        this.croppie = React.createRef();
    }

    componentDidMount() {
        c = new Croppie(this.croppie.current, this.croppieOptions);
        window.addEventListener("paste", this.onClipboardPaste, false);
    }

    componentWillUnmount() {
        window.removeEventListener("paste", this.onClipboardPaste, false);
    }

    // https://ourcodeworld.com/articles/read/491/how-to-retrieve-images-from-the-clipboard-with-javascript-in-the-browser
    retrieveImageFromClipboardAsBlob = (pasteEvent, callback) => {
        if (pasteEvent.clipboardData == false) {
            if (typeof (callback) == "function") {
                callback(undefined);
            }
        };

        var items = pasteEvent.clipboardData.items;

        if (items == undefined) {
            if (typeof (callback) == "function") {
                callback(undefined);
            }
        };

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            if (items[i].type.indexOf("image") == -1) continue;
            // Retrieve image on clipboard as blob
            var blob = items[i].getAsFile();

            if (typeof (callback) == "function") {
                callback(blob);
            }
        }
    }

    onClipboardPaste = pasteEvent => {
        const { isCropping } = this.state;
        if (isCropping === false)
            this.retrieveImageFromClipboardAsBlob(pasteEvent, blob => {
                this.onFileUpload(blob);
            });
    }

    onDropAccepted = acceptedFiles => {
        const file = acceptedFiles[0];
        this.onFileUpload(file);
    }

    onFileUpload = file => {
        const { isCropping } = this.state;
        if (file && isCropping === false) {
            const
                { currentImageAmount } = this.state,
                newImageSelection = currentImageAmount > 0 ? currentImageAmount : 0,
                reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onload = () => {
                c.bind({ url: reader.result });

                const { uploadedImageData } = this.state;
                uploadedImageData[newImageSelection] = reader.result;

                this.setState({ isCropping: true, selectedImage: newImageSelection, uploadedImageData });
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        }
    };

    onSave = e => {
        c.result("blob").then(base64 => {
            const { currentImageAmount, selectedImage, croppedImageData } = this.state;
            const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT } = this.props;

            let imageAmount = currentImageAmount + 1 < maxImageAmount ? currentImageAmount + 1 : maxImageAmount;

            if (selectedImage != currentImageAmount)
                imageAmount = currentImageAmount;

            const images = croppedImageData;
            images[selectedImage] = base64;
            this.setState(
                { croppedImageData: images, currentImageAmount: imageAmount, isCropping: false, }, () => (this.props.handleUpdate(croppedImageData))
            );
        });
    };

    onCancel = () => {
        this.setState({ isCropping: false });
    }

    cropImage = (imageNum) => {
        const { uploadedImageData } = this.state;

        if (!!uploadedImageData[imageNum]) {
            c.bind({ url: uploadedImageData[imageNum], zoom: 0 });
            this.setState({ selectedImage: imageNum, isCropping: true, });
        }
    }

    removeImage = (imageNum) => {
        const { croppedImageData, uploadedImageData, currentImageAmount, selectedImage } = this.state;
        const newImageAmount = currentImageAmount - 1 >= 0 ? currentImageAmount - 1 : 0;
        const newSelectedImage = selectedImage > imageNum ? selectedImage - 1 : selectedImage;

        uploadedImageData.splice(imageNum, 1);
        croppedImageData.splice(imageNum, 1);

        this.setState({ croppedImageData, uploadedImageData, currentImageAmount: newImageAmount, selectedImage: newSelectedImage }, () => this.props.handleUpdate(croppedImageData));
    }

    // dir will be right == false ==== left
    moveImage = (imageNum, right) => {
        const { croppedImageData, uploadedImageData, selectedImage } = this.state;
        const positionModifier = right ? 1 : -1;
        const switchNum = imageNum + positionModifier;

        let newSelectedImage = selectedImage;

        // if selected image is the one we're moving
        if (selectedImage === imageNum)
            newSelectedImage = switchNum;
        // if selected image is the one we're moving to
        else if (selectedImage === switchNum)
            newSelectedImage = imageNum;

        // switch images in array
        [croppedImageData[imageNum], croppedImageData[switchNum]] = [croppedImageData[switchNum], croppedImageData[imageNum]];
        [uploadedImageData[imageNum], uploadedImageData[switchNum]] = [uploadedImageData[switchNum], uploadedImageData[imageNum]];

        // update state
        this.setState({ croppedImageData, uploadedImageData, selectedImage: newSelectedImage })
    }


    renderImagePreviews = () => {
        const { currentImageAmount, selectedImage } = this.state;
        const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT, width, height, category } = this.props;
        const imagePreviews = [];

        const previewWidth = (width || DEFAULT_CROP_WIDTH) / ((category === postCategories.VEHICLE && 2.4) || 2 );
        const previewHeight = (height || DEFAULT_CROP_HEIGHT) / ((category === postCategories.VEHICLE && 2.4) || 2);

        for (let i = 0; i < currentImageAmount; i++)
            imagePreviews.push(<ImagePreview
                key={i}
                imageId={i}
                width={previewWidth}
                height={previewHeight}
                currentImageAmount={currentImageAmount}
                maxImageAmount={maxImageAmount}
                image={this.state.croppedImageData[i]}
                handleCrop={e => this.cropImage(i)}
                handleRemove={e => this.removeImage(i)}
                handleMove={this.moveImage} />
            );
        
        for (let i = 0, n = maxImageAmount - currentImageAmount; i < n; i++)
            imagePreviews.push(<UploadImagePreview
                key={i + currentImageAmount + 1}
                width={previewWidth}
                height={previewHeight} />
            );

        return imagePreviews;
    }

    renderCropTool(isCropping) {
        return (
            <div>
                <div ref={this.croppie} style={{ display: isCropping ? 'block' : 'none' }} />

                {
                    isCropping && (
                        <div className="post-crop-options">
                            <div className="option" data-tip data-for="cancel-crop" onClick={this.onCancel}><Icon name="close" strokeWidth={1.5} /></div>
                            <ReactTooltip id='cancel-crop' effect='solid' place="bottom">
                                <span>Cancel</span>
                            </ReactTooltip>
                            <div className="option" data-tip data-for="save-crop" onClick={this.onSave}><Icon name="save" strokeWidth={1.5} /></div>
                            <ReactTooltip id='save-crop' effect='solid' place="bottom">
                                <span>Save</span>
                            </ReactTooltip>
                        </div>
                    )
                }
            </div>
        )
    }

    render() {
        const { currentImageAmount, isCropping } = this.state;
        const { maxImageAmount = DEFAULT_MAX_IMAGE_AMOUNT } = this.props;

        return (
            <div>
                {!isCropping &&
                    (
                        <div className="image-preview-list">
                            {this.renderImagePreviews()}
                        </div>
                    )
                }

                {this.renderCropTool(isCropping)}

                {
                    !isCropping && currentImageAmount < maxImageAmount && (
                        <Dropzone onDropAccepted={this.onDropAccepted} accept={ACCEPTED_IMAGE_FORMATS} />
                    )
                }
            </div>
        );
    }
}

export default ImageUploader;