import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
}) => (
        <svg
            width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
        >
            <path fill={fill} stroke={stroke} d="M11.686,18.208c0,0.844-0.004,1.688,0.001,2.532c0.005,1.038-0.24,1.342-1.095,1.342c-0.679,0-1.183-0.565-1.184-1.396
	c-0.002-1.019,0.037-2.037,0.057-3.056c0.024-1.285,0.002-2.573,0.084-3.854c0.064-1.007,0.27-2.004,0.381-3.007
	c0.017-0.149-0.137-0.318-0.213-0.477c-0.104,0.13-0.271,0.248-0.303,0.394c-0.24,1.088-0.455,2.182-0.673,3.275
	c-0.1,0.5-0.435,0.735-0.903,0.678c-0.464-0.055-0.788-0.343-0.699-0.863c0.25-1.463,0.412-2.955,0.822-4.373
	c0.428-1.482,2.189-2.451,3.655-2.544c1.636-0.104,2.982,0.423,4.025,1.697c0.666,0.815,0.862,1.805,1.025,2.805
	c0.121,0.738,0.24,1.474,0.343,2.215c0.066,0.48-0.032,0.906-0.594,1.01c-0.553,0.101-0.789-0.218-0.897-0.725
	c-0.201-0.939-0.433-1.872-0.651-2.808c-0.036-0.149-0.04-0.325-0.126-0.439c-0.112-0.15-0.291-0.252-0.441-0.374
	c-0.056,0.186-0.194,0.395-0.153,0.556c0.479,1.848,0.673,3.714,0.616,5.626c-0.046,1.434,0.089,2.873,0.109,4.31
	c0.016,1.105-0.564,1.523-1.601,1.228c-0.601-0.172-0.66-0.653-0.674-1.142c-0.048-1.776-0.073-3.555-0.122-5.33
	c-0.008-0.299,0.145-0.772-0.375-0.752c-0.469,0.02-0.432,0.446-0.428,0.809c0.01,0.888,0.003,1.777,0.003,2.666
	C11.68,18.208,11.683,18.208,11.686,18.208z"/>
            <path fill={fill} stroke={stroke} d="M5.845,15.101c-0.315,0.893-0.567,1.654-0.861,2.398C4.928,17.641,4.68,17.824,4.559,17.8
	c-0.926-0.188-1.842-0.417-2.761-0.636c-0.004-0.06-0.009-0.118-0.013-0.178c0.317-0.202,0.635-0.406,0.945-0.605
	c-0.338-0.837-0.691-1.633-0.987-2.449c-0.408-1.129-0.267-2.259,0.146-3.357c0.479-1.271,1.275-2.301,2.426-3.042
	c0.368-0.238,0.619-0.287,0.861,0.221C5.689,8.828,5.755,8.851,4.859,9.58c-1.358,1.104-1.411,2.617-1.035,4.135
	c0.068,0.276,0.144,0.558,0.276,0.806c0.163,0.307,0.333,0.715,0.602,0.823C4.958,15.447,5.348,15.222,5.845,15.101z"/>
            <path fill={fill} stroke={stroke} d="M18.097,10.741c0.333-0.904,0.595-1.664,0.898-2.404c0.069-0.166,0.325-0.406,0.436-0.378
	c0.896,0.23,1.781,0.516,2.844,0.836c-0.499,0.289-0.813,0.469-1.075,0.621c0.354,0.918,0.771,1.771,1.006,2.671
	c0.455,1.754-0.07,3.345-1.119,4.752c-0.391,0.525-0.957,0.926-1.466,1.358c-0.32,0.271-0.604,0.241-0.856-0.157
	c-0.587-0.921-0.608-0.956,0.177-1.697c1.943-1.834,1.591-3.629,0.594-5.643c-0.154-0.313-0.398-0.363-0.711-0.22
	C18.627,10.57,18.415,10.628,18.097,10.741z"/>
            <path fill={fill} stroke={stroke} d="M13.812,4.288c0.119,1.399-0.936,1.957-2.045,2.027c-0.92,0.059-2.121-0.848-2.102-2.005
	c0.018-1.119,0.901-1.98,2.083-1.983C12.995,2.323,13.81,3.098,13.812,4.288z"/>
        </svg>
    );

export default SVG;