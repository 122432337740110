import { connect } from 'react-redux'
import Search from '../pages/search';

const mapStateToProps = (state, ownProps) => {
    const { currentUser } = state;
    return {
        nsfw: currentUser.settingViewNSFW,
    }
}

export default connect(mapStateToProps)(Search);