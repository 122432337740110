import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = 1.2,
    width = "7px",
    height= "12px",
    className = "",
    viewBox = "0 0 7 12"
}) => (
        <svg
            width={width}
            style={style}
            height={height}
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
        >
            <polyline fill={fill} stroke={stroke} strokeWidth={strokeWidth} points="6 1 1 6 6 11"></polyline>
            {/* <polyline fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" points="15 18 9 12 15 6"></polyline> */}
        </svg>
    );

export default SVG;
