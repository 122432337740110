import { claimCharacter } from '../services/UserService';

export const REQUEST_CLAIM_CHARACTER = 'REQUEST_CLAIM_CHARACTER';
export const RECEIVE_CLAIM_CHARACTER = 'RECEIVE_CLAIM_CHARACTER';

const requestClaimCharacter = id => ({
    type: REQUEST_CLAIM_CHARACTER,
    id,
});

const receiveClaimCharacter = (success, message, ownerId, characters) => ({
    type: RECEIVE_CLAIM_CHARACTER,
    success,
    message,
    ownerId,
    characters,
});

export const tryClaimCharacter = (userId, characterServer, characterName, characterFaction, characterGender) => (
    dispatch => {
        dispatch(requestClaimCharacter(userId))
        return claimCharacter(userId, characterServer, characterName, characterFaction, characterGender)
            .then(result => dispatch(receiveClaimCharacter(result.success, result.message, result.ownerId, result.characters)))
    })