import { getNotificationsBySession, sendReadNotifications } from '../services/NotificationService';

export const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const REQUEST_MARK_NOTIFICATIONS_READ = 'REQUEST_MARK_NOTIFICATIONS_READ';
export const RECEIVE_MARK_NOTIFICATIONS_READ = 'RECEIVE_MARK_NOTIFICATIONS_READ';
export const MARK_STATE_NOTIFICATIONS_READ = 'MARK_STATE_NOTIFICATIONS_READ';
export const RECEIVE_NOTIFICATION = 'RECEIVE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const requestNotifications = (id, page) => ({
    type: REQUEST_NOTIFICATIONS,
    id,
    page,
});

const receiveNotifications = (notifications, pageCount) => ({
    type: RECEIVE_NOTIFICATIONS,
    notifications,
    pageCount,
});

const requestMarkNotificationsRead = (notificationIds) => ({
    type: REQUEST_MARK_NOTIFICATIONS_READ,
    notificationIds
});

const receiveMarkNotificationsRead = (success, unreadCount) => ({
    type: RECEIVE_MARK_NOTIFICATIONS_READ,
    success,
    unreadCount,
});

const markStateNotificationsRead = (notificationIds) => ({
    type: MARK_STATE_NOTIFICATIONS_READ,
    notificationIds,
});

const receiveNotification = (notification) => ({
    type: RECEIVE_NOTIFICATION,
    notification,
});

const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    notificationId,
});

export const fetchNotifications = (id, page) => (
    dispatch => {
        dispatch(requestNotifications(id, page))
        return getNotificationsBySession(page)
            .then(result => dispatch(receiveNotifications(result.notifications, result.pageCount)))
    }
);

export const setStateNotificationsRead = (notificationIds) => (
    dispatch => (
        dispatch(markStateNotificationsRead(notificationIds))
    )
);

export const sendUserReadNotifications = (notificationIds) => (
    dispatch => {
        dispatch(requestMarkNotificationsRead(notificationIds))
        return sendReadNotifications(notificationIds)
            .then(result => dispatch(receiveMarkNotificationsRead(result.success, result.unreadNotificationCount)))
    }
);

export const receivePushNotification = (notification) => (
    dispatch => (
        dispatch(receiveNotification(notification))
    )
);

export const removePushNotification = (notificationId) => (
    dispatch => (
        dispatch(removeNotification(notificationId))
    )
);