import React from "react";

const SVG = ({
    style = {},
    fill = "none",
    stroke = "currentColor",
    width = "24px",
    className = "",
    viewBox = "0 0 24 24",
    strokeWidth = "1",
}) => (
        <svg
            width={width}
            style={style}
            height={width}
            viewBox={viewBox}
            enableBackground="new 0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={`svg-icon ${className || ""}`}
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                fill={fill}
                stroke={stroke}
                d="M20.84,4.61 c-2.147-2.148-5.629-2.149-7.777-0.001c-0.002,0-0.002,0.001-0.002,0.001L12,5.67l-1.06-1.06c-2.148-2.148-5.631-2.148-7.78,0 s-2.148,5.632,0,7.78l1.06,1.06L12,21.23l7.779-7.78l1.061-1.06c2.148-2.147,2.149-5.63,0.002-7.778 C20.842,4.611,20.841,4.61,20.84,4.61z"
            />
        </svg>
    );

export default SVG;
