import React from 'react';
import styled from 'styled-components';

import BreakwaterBackground from '../../static/images/backgrounds/social/social-2.png';
import AsylumBackground from '../../static/images/backgrounds/asylum/asylum-2.png';

import fullscreenHeader from './header';
import fullscreenFooter from './footer';

const SketchBackground = 'https://cdn.discordapp.com/attachments/597465771165351948/775218604622610453/Background.png';

const StyledBackground = styled.div`
    background: url(${BreakwaterBackground});
    background-size: cover;
    background-position: center;

    @media(min-width: 425px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: auto;
    }
`;


const FullscreenPageLayout = (props) => (
    <StyledBackground>
        {props.children}
    </StyledBackground>
)

export default FullscreenPageLayout;