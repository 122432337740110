import React, { useState } from 'react';
import styled from 'styled-components';
import './style.css';
import CharacterRender from '../../static/images/renders/richard-morg.png';
import Logotype from '../../static/images/breakwater_logotype_color.svg';
import { Link } from 'react-router-dom';

const StyledCoverContainer = styled.div`
    position: relative;
    padding-top: 60px;
    background-color: #1e1f23;
    background-size: cover;
    background-position-x: center;
    overflow: hidden;

    @media (min-width: 1280px) {
        padding: 0;
    }

    .link {
        display: flex;
    }
`;

const StyledCoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media (min-width: 1280px) {
        flex-direction: row;
        height: 400px;
        max-width: 1320px;
        max-height: 600px;
        margin: 0 auto;
    }
`;

const StyledCoverTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2em;
    margin-bottom: 1rem;

    @media (min-width: 1280px) {
        align-items: start;
        margin-bottom: 0;
    }
`;

const StyledLogotype = styled.img`
    display: block;
    width: 540px;
    max-width: 80vw;
`;

const StyledLogotypeHalloween = styled.img`
    display: block;
    width: 580px;
    max-width: 80vw;
    
    @media(min-width: 768px) {
        margin-left: -21px;
    }
`;

const StyledSlogan = styled.div`
    font-family: 'Montserrat',sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    color: #fafafa;
    margin-top: .5em;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 2em;
        text-align: left;
    }

    @media (min-width: 1280px) {
    }
`;

const StyledRender = styled.img`
    display: block;
    max-width: 70vw;
    max-height: 480px;
    height: auto;
    margin: 0 auto;

    @media (min-width: 1280px) {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 2em;
    }
`;

const HomepageCover = () => (
    <StyledCoverContainer className="homepage-cover">
        <StyledCoverWrapper>
            <StyledCoverTitle>
                <StyledLogotype src={Logotype} alt="Breakwater" />
                <StyledSlogan>
                    Create. Share. Socialize.
                    </StyledSlogan>
            </StyledCoverTitle>
            {/* <Link to={`/profile/${randomRender.username}`}> */}
            <StyledRender src={CharacterRender} />
            {/* </Link> */}
        </StyledCoverWrapper>
    </StyledCoverContainer>
);

export default HomepageCover;