import React from "react";

const SVG = ({
    style = {},
    fill = "currentColor",
    stroke = "none",
    width = "24px",
    height = "24px",
    className = "",
    viewBox = "0 0 24 24"
}) => (
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        enableBackground="new 0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >

        <path stroke={stroke} fill={fill} fillRule="evenodd" d="M19.914 4a1 1 0 011 1v12.586a1 1 0 01-.293.707l-1.414 1.414A1 1 0 0118.5 20h-15a.5.5 0 01-.5-.5 1 1 0 01.4-.8L5 17.5l.02-11.089a1 1 0 01.29-.703l1.396-1.411A1 1 0 017.417 4h12.497zM9 11a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2z"></path>
    </svg>
);

export default SVG;
