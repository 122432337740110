import { connect } from 'react-redux'
import Explore from '../pages/explore';

import { requestExplorePostFeed } from '../actions/feedActions';

const mapStateToProps = (state, ownProps) => {
    const { currentUser, postFeeds } = state;
    return {
        nsfw: currentUser.settingViewNSFW,
        posts: postFeeds.explore.posts,
        pageCount: postFeeds.explore.pageCount,
        currentPage: postFeeds.explore.currentPage,
        category: postFeeds.explore.category
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    getPosts: (page, category, nsfw) => dispatch(requestExplorePostFeed(page, category, nsfw))
})

export default connect(mapStateToProps, mapDispatchToProps)(Explore);