import React from 'react';
import SlidingPane from "react-sliding-pane";
import SlideoutMenu from './SlideoutMenu';

export default props => (
    <SlidingPane
        isOpen={props.isOpen || false}
        onRequestClose={() => props.closeSidebar()}
        width="250px"
        shouldCloseOnEsc={true}
        hideHeader={true}
        from="left"
    >
        <SlideoutMenu
            isLoggedIn={props.isLoggedIn}
            userId={props.userId}
            userUsername={props.userUsername}
            userAvatar={props.userAvatar}
            unreadNotificationCount={props.unreadNotificationCount}
            closeSidebarMenu={props.closeSidebar}
            logout={props.logout}
        />
    </SlidingPane>
);