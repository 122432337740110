import React from 'react';
import styled from 'styled-components';
import ContentLoader, { Instagram } from 'react-content-loader';
import ReactPaginate from 'react-paginate';
import { withAlert } from 'react-alert';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

import Section from '../../components/section/Section';
import PostDisplay from '../../components/posts/Post';
import CommentList from '../../components/comments/CommentList';
import Comment from '../../components/comments/Comment';
import Icon from '../../components/icons';
import SubSectionHead from '../../components/section/SubSectionHead';
import Input from '../../components/forms/Input';
import Button from '../../components/forms/Button';

import './style.css';

class Post extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comments: null,
            commentText: '',
            isLiked: null,
            numLikes: null,
            commentPage: 1,
            awaitingLikeResponse: false,
        }
    }

    componentWillMount() {
        this.props.fetchPost();
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.addComment();
        }
    }

    addComment = () => {
        const text = this.state.commentText;

        if (text.length > 0) {
            this.setState({ commentText: '' });

            const comments = this.state.comments || this.props.post.comments || [];

            this.props.createComment(text, this.props.post._id, this.props.post.owner._id)
                .then(json => {
                    Swal.fire({
                        title: 'Comment Created',
                        icon: 'success'
                    });
                    if (this.state.commentPage <= 1) {
                        comments.unshift(json);
                        this.setState({ comments });
                    }
                })
        }
    }

    deletePost = () => {
        Swal.fire({
            title: 'Delete Post?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.props.deletePost(this.props.post._id).then(() => {
                    Swal.fire({
                        title: 'Post Deleted',
                        icon: 'success'
                    })

                    this.props.history.push('/')
                });
            }
        })        
    }

    deleteComment = id => {
        console.log(id);

        Swal.fire({
            title: 'Delete Comment?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this.props.deleteComment(id)
                    .then(result => {
                        this.setState({ comments: (this.state.comments || this.props.post.comments).filter(comment => comment._id !== id) });
                        Swal.fire({
                            title: 'Comment Deleted',
                            icon: 'success'
                        })
                    });
            }
        })
    }

    toggleLikePost = e => {
        if (!this.props.isLoggedIn)
            this.props.history.push('/login');
        else {
            if (!this.state.awaitingLikeResponse)
                if (this.state.isLiked != null)
                    this.state.isLiked ? this.unlikePost() : this.likePost();
                else
                    this.props.post.liked ? this.unlikePost() : this.likePost();
        }
    }

    likePost = e => {
        this.setState({ awaitingLikeResponse: true });
        
        this.props.likePost(this.props.post._id, this.props.post.owner._id).then(res => {
            if (res.success) {
                this.props.alert.show(res.message, {
                    type: 'success'
                });

                let numLikes = this.props.post.liked ? this.props.post.numLikes : this.props.post.numLikes + 1;          

                this.setState({ isLiked: true, numLikes });
            }
            else
                this.props.alert.show(res.message, {
                    type: 'error'
                });
            
            this.setState({ awaitingLikeResponse: false });
        });
    }

    unlikePost = e => {
        this.setState({ awaitingLikeResponse: true });
        
        this.props.unlikePost(this.props.post._id).then(res => {
            if (res.success) {
                this.props.alert.show(res.message, {
                    type: 'success'
                });

                let numLikes = this.props.post.liked ? this.props.post.numLikes - 1 : this.props.post.numLikes;
                
                this.setState({ isLiked: false, numLikes });
            }
            else
                this.props.alert.show(res.message, {
                    type: 'error'
                });

            this.setState({ awaitingLikeResponse: false });
        });
    }

    isLiked = () => {
        if (this.state.isLiked != null)
            return this.state.isLiked;
        else
            return this.props.post.liked;
    }

    getNumLikes = () => {
        if (this.state.numLikes != null)
            return this.state.numLikes;
        else
            return this.props.post.numLikes;
    }

    loadComments = page => {
        // send comments request, set post comments state on response
        this.props.getPostComments(this.props.post._id, page)
            .then(res => {
                this.setState({ comments: res, commentPage: page });
            });
    }

    handleCommentPageClick = data => {
        // Normalize from page 0 to 1
        let selected = data.selected + 1;

        this.loadComments(selected);
    };

    generateDummyComment = () => {
        const { userUsername } = this.props;

        const comments = [
            'This is the design that will revive Riot and save apb',
            'a goth girl could literally shoot me and i\'d be okay with it',
            'what if I… put my apb design… next to yours .. aha ha, just kidding.. unless..?',
            'Wow this looks so beautiful <3',
        ];

        return comments[Math.floor(Math.random() * comments.length)];
    };

    renderContent = () => {
        let { post, isLoggedIn, userAvatar, userUsername, userIsVerified, userRoles } = this.props;
        const { _id = '', title = '', category = 1, description = '', images = [], owner = {}, comments = [], liked = false, numLikes = 0, commentPageCount = 1, creator = null, forSale = false, forSalePrice = 0, tags } = post;

        return (
            <div className="application">
                <PostDisplay
                    postId={_id}
                    postTitle={title}
                    postCategory={category}
                    postDescription={description}
                    imageArray={images}
                    postAuthorAvatar={owner.avatar}
                    postAuthorUsername={owner.username}
                    postAuthorId={owner._id}
                    deletePost={this.deletePost}
                    isLiked={this.isLiked()}
                    toggleLike={this.toggleLikePost}
                    numLikes={this.getNumLikes()}
                    creator={!!creator}
                    creatorServer={!!creator && creator.server}
                    creatorName={!!creator && creator.name}
                    creatorFaction={!!creator && creator.faction}
                    forSale={forSale}
                    forSalePrice={forSalePrice}
                    postTags={tags}
                />

                <section className="post-comments">
                    <SubSectionHead>
                        Comments
                    </SubSectionHead>

                    {isLoggedIn && (
                        <div className="new-comment">
                            <div className="comment-form">
                                <div className="input-area">
                                    <Link to={`/profile/${userUsername}`} className="avatar-container">
                                        <img className="avatar" src={userAvatar || 'https://i.imgur.com/I8xE73K.png'} height="44" width="44" alt={userUsername} />
                                    </Link>
                                    <Input
                                        name="commentText"
                                        type="text"
                                        placeholder={userIsVerified ? this.generateDummyComment() : "Please verify your email to comment on posts!"}
                                        value={this.state.commentText}
                                        onChange={(e) => this.handleChange(e)}
                                        onKeyDown={this.handleOnKeyDown}
                                        disabled={!userIsVerified}
                                        maxLength="100"
                                    />
                                </div>
                                <div className="submit-area">
                                    <Button className="comment-button" onClick={this.addComment} disabled={this.state.commentText.length <= 0 || !userIsVerified} >Comment</Button>
                                </div>
                            </div>
                        </div>
                    )}

                    <CommentList>
                        {(this.state.comments || comments).map(comment =>
                            <Comment key={comment._id} commentId={comment._id} authorId={comment.owner._id} authorUsername={comment.owner.username} authorAvatar={comment.owner.avatar} timeCreated={comment.createdAt} commentText={comment.text} deleteComment={this.deleteComment} />
                        )}
                    </CommentList>
                    {commentPageCount > 1 && (
                        <ReactPaginate
                            previousLabel={<Icon name="chevron-left" stroke="currentColor" strokeWidth={3} />}
                            nextLabel={<Icon name="chevron-right" stroke="currentColor" strokeWidth={3} />}
                            breakClassName={'break-me'}
                            pageCount={commentPageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handleCommentPageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    )}
                </section>
            </div>
        )
    }

    render() {
        return (
            <Section>
                <article className="section-container post-container">
                    {
                        this.props.isFetching
                            ? (
                                <ContentLoader
                                    height={140}
                                    speed={1}
                                    viewBox="0 0 380 70"
                                >
                                    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                </ContentLoader>
                            )
                            : this.renderContent()
                    }
                </article>
            </Section>
        );
    }
}

export default withAlert()(Post);