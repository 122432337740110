import React from "react"
import { useLocation } from "react-router-dom"

import analytics from "./analytics"

// USING: https://raptis.wtf/blog/custom-hook-to-connect-google-analytics-in-react/
// another good one: http://www.sheshbabu.com/posts/automatic-pageview-tracking-using-react-router/

export default function useGoogleAnalytics() {
    const location = useLocation()

    React.useEffect(() => {
        analytics.init()
    }, [])

    React.useEffect(() => {
        const currentPath = location.pathname + location.search
        analytics.sendPageview(currentPath)
    }, [location])
}