import React from 'react';

import styled from 'styled-components';

import Section from '../../components/section/Section';

const ErrorContainer = styled.div`
  text-align: center;
  font-family: 'Roboto Mono', monospace;
`;

const ErrorEmoji = styled.h1`
  color: rgba(0,0,0,.4);
  font-size: 10rem;
  font-weight: 400;
`;

const ErrorText = styled.h3`
  color: rgba(0,0,0,.4);
  line-height: 21px;
  margin-top: 60px;
  white-space: pre-wrap;
  font-size: 1.3rem;
  font-weight: 400;
`;

const Error404 = () => (
  <Section>
    <ErrorContainer>
      <ErrorEmoji>(˚Δ˚)b</ErrorEmoji>
      <ErrorText>Error 404: Page Not Found</ErrorText>
    </ErrorContainer>
  </Section>
);

export default Error404;
