import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';

import Swal from 'sweetalert2'
import { withAlert } from 'react-alert';

import { createPost } from '../../services/PostService';

import Section from '../../components/section/Section';
import SectionHead from '../../components/section/SectionHead';
import Icon from '../../components/icons';
import { postCategories, gameServers } from '../../util/enums';
import GameItemSearchInput from '../../components/forms/GameItems/SearchInput';

import ImageUploader from '../../components/forms/ImageUploader/ImageUploader';
import ImageUploader2 from '../../components/forms/ImageUploaderV2/ImageUploader';
import Input from '../../components/forms/Input';
import Button from '../../components/forms/Button';
import Select from '../../components/forms/Select';
import Switch from '../../components/forms/Switch';

import './style.css';

import TagsInput from '@incoqnito.io/react-tagsinput';
import '@incoqnito.io/react-tagsinput/react-tagsinput.css';

import ReCAPTCHA from "react-google-recaptcha";

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: var(--color-text-input-label);
    font-size: .8rem;
    .error & {
        color: var(--color-text-input-label-error);
        border-color: var(--color-border-input-error);
    }
`;

const StyledStep = styled.div`
    color: var(--color-text-section-sub-section);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 1rem 0;
`;

const ErrorContainer = styled.div`
  color: rgba(0,0,0,.4);
  text-align: center;
  font-family: 'Roboto Mono', monospace;
`;

const ErrorEmoji = styled.h1`
  font-size: 10rem;
  font-weight: 400;
`;

const ErrorText = styled.h3`
  line-height: 21px;
  margin-top: 60px;
  white-space: pre-wrap;
  font-size: 1.3rem;
  font-weight: 400;
`;

const SubErrorText = styled.h4`
  line-height: 21px;
  margin-top: 20px;
  white-space: pre-wrap;
  font-size: .9rem;
  font-weight: 400;
`;

class CreatePost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            tags: [],
            nsfw: false,
            images: [],
            redirect: null,
            category: null,
            categoryChosen: false,
            stepThree: false,
            creator: null,
            forSale: false,
            forSalePrice: 0,
            captcha: null,
            awaitingResponse: false,
        };
    }

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    handleCaptcha = (captchaResult) => this.setState({ captcha: captchaResult });
    handleCheckboxChange = (e) => this.setState({ [e.target.name]: e.target.checked });
    handleImageChange = (images) => this.setState({ images });
    handleCategoryChange = (category) => this.setState({ category, categoryChosen: true });
    handleTagsChange = (tags) => {
        // clear any old errors
        document.getElementById(`post-tags`).className = 'field';
        document.getElementById(`post-tags-error`).innerHTML = '';
        this.setState({ tags });
    };

    onCreate = () => {
        this.setState({ awaitingResponse: true });
        
        const fields = { title: '', description: '', images: '', creator: '', forSalePrice: '' };
        const { title, description, images, creator, forSale, forSalePrice, tags, captcha } = this.state;
        Object.keys(fields).map(key => document.getElementById(`post-${key}`).className = 'field');
        Object.keys(fields).map(key => document.getElementById(`post-${key}-error`).innerHTML = '');

        const errors = {
            title: [],
            description: [],
            images: [],
            creator: [],
            forSalePrice: [],
            tags: [],
        };

        if (title.length < 1 || title.length > 30)
            errors.title.push('Title must be between 1 and 30 characters.');
            
        if (description.length > 500)
            errors.description.push('Description must be no more than 500 characters.');
        
        if (images.length <= 0)
            errors.images.push("Post missing images.");
        
        if (forSale && (!!!creator || creator == "DEFAULT")) {
            errors.creator.push('Please select a character.');
            if (this.props.userCharacters.length <= 0)
                errors.creator.push('Head over to your settings to add a character!');
        }

        if (forSale && (forSalePrice < 0 || forSalePrice > 9999999999 || isNaN(forSalePrice) || forSalePrice === ''))
            errors.forSalePrice.push('Price must be between $0 (free) and $9,999,999,999');
        
        if (tags.length > 30)
            errors.tags.push('You can only use up to 30 tags on your creation!');
        
        if (errors.title.length + errors.description.length + errors.images.length + errors.creator.length + errors.forSalePrice.length + errors.tags.length > 0) {
            for (const error in errors)
                if (errors[error].length > 0) {
                    document.getElementById(`post-${error}`).className += ' error'
                    document.getElementById(`post-${error}-error`).innerHTML = errors[error].join('<br />')
                }
                
            this.setState({ awaitingResponse: false });
        } else
            createPost(this.state.title, this.state.category, this.state.images, this.state.description, this.state.tags, this.state.nsfw, creator, forSale, forSale ? forSalePrice : null, this.state.captcha)
                .then(res => {
                    if (!res.success)
                        Swal.fire({
                            icon: 'error',
                            title: res.message,
                        })
                    else {
                        this.setState({ redirect: `/post/${res._id}` });

                        Swal.fire({
                            icon: 'success',
                            title: 'Post Created',
                        })
                    }

                    this.setState({ awaitingResponse: false });
                });
    }

    getCropDimension = (dimension) => {
        switch(this.state.category) {
            case postCategories.SYMBOL:
                return 340;
                break;                
            case postCategories.CHARACTER_CUSTOMIZATION:
            case postCategories.CLOTHING:
                if (dimension == 'width')
                    return 340;
                else if (dimension == 'height')
                    return 570;
                break;
            case postCategories.VEHICLE:
                if (dimension == 'width')
                    return 570;
                else if (dimension == 'height')
                    return 340;
                break;
            default:
                return 0;
        };
    };

    getCropMaxImages = () => {
        switch (this.state.category) {
            case postCategories.SYMBOL:
                return 1;
                break;
            case postCategories.CHARACTER_CUSTOMIZATION:
            case postCategories.CLOTHING:
            case postCategories.VEHICLE:
                return 4;
                break;
            default:
                return 0;
        }
    };

    validateTag = (tag) => {
        // clear old errors
        document.getElementById(`post-tags`).className = 'field';
        document.getElementById(`post-tags-error`).innerHTML = '';

        const charLimit = 30;

        if (tag.length <= charLimit)
            return true;
        else {
            document.getElementById(`post-tags`).className += ' error';
            document.getElementById(`post-tags-error`).innerHTML = 'Tag length must be less than 30 characters!';
            return false;
        }
    }

    renderCategories = () => {
        return (
            <div className="choose-category">
                <div className="category symbol" onClick={() => this.handleCategoryChange(postCategories.SYMBOL)}>
                    <div className="icon-container">
                        <Icon className="icon" name="symbol-icon" fill="currentColor" stroke="none" width="78px" />
                        <div className="category-name">Symbol</div>
                    </div>
                    <div className="bg"></div>
                </div>
                <div className="category clothing" onClick={() => this.handleCategoryChange(postCategories.CLOTHING)}>
                    <div className="icon-container">
                        <Icon className="icon" name="clothing-icon" fill="currentColor" stroke="none" width="78px" />
                        <div className="category-name">Clothing</div>
                    </div>
                    <div className="bg"></div>
                </div>
                <div className="category vehicle" onClick={() => this.handleCategoryChange(postCategories.VEHICLE)}>
                    <div className="icon-container">
                        <Icon className="icon" name="vehicle-icon" fill="currentColor" stroke="none" width="78px" />
                        <div className="category-name">Vehicle</div>
                    </div>
                    <div className="bg"></div>
                </div>
                <div className="category character" onClick={() => this.handleCategoryChange(postCategories.CHARACTER_CUSTOMIZATION)}>
                    <div className="icon-container">
                        <Icon className="icon" name="char-customization-icon" fill="currentColor" stroke="none" width="78px" />
                        <div className="category-name">Character</div>
                    </div>
                    <div className="bg"></div>
                </div>
            </div>
        )
    }

    renderStepThree = () => {
        return (
            <div className={`step-3 ${this.state.stepThree && 'show'}`}>
                <StyledStep htmlFor="images">Step 3: Describe Your Creation</StyledStep>

                <div id="post-title" className="field">
                    <StyledLabel htmlFor="title">Title ({this.state.title.length}/30):</StyledLabel>
                    <Input name="title" type="text" value={this.state.title} onChange={(e) => this.handleChange(e)} />
                    <div id="post-title-error" className="error-message"></div>
                </div>

                <div id="post-description" className="field">
                    <StyledLabel htmlFor="description">Description ({this.state.description.length}/500):</StyledLabel>
                    <Input name="description" type="text" value={this.state.description} onChange={(e) => this.handleChange(e)} />
                    <div id="post-description-error" className="error-message"></div>
                </div>

                <div id="post-tags" className="field">
                    <StyledLabel htmlFor="tags">Tags ({this.state.tags.length}/30):</StyledLabel>
                    <TagsInput 
                        value={this.state.tags} 
                        addKeys={[9, 13, 188]} 
                        onlyUnique={true} 
                        maxTags={30} 
                        validate={this.validateTag} 
                        onChange={this.handleTagsChange}
                    />
                    <div id="post-tags-error" className="error-message"></div>
                </div>

                <div id="post-creator" className="field">
                    <StyledLabel htmlFor="creator">Created By:</StyledLabel>
                    <Select name="creator" defaultValue="DEFAULT" onChange={(e) => this.handleChange(e)}>
                        <option value="DEFAULT" disabled>Choose a character</option>
                        {this.props.userCharacters.map(character => (<option key={character._id} value={character._id}>{character.name} - {gameServers.getName(character.server)}</option>))}
                    </Select>
                    <div id="post-creator-error" className="error-message"></div>
                    {this.props.userCharacters.length <= 0 && 
                        <div style={{fontSize: '0.9rem'}}>
                            Tip: You can add your characters in your <Link to="/settings" style={{textDecoration: 'underline'}}>settings</Link>!
                        </div>}
                </div>

                <div id="post-nsfw" className="field">
                    <StyledLabel htmlFor="nsfw">NSFW - Sexually Suggestive, Partial Nudity, Nudity:</StyledLabel>
                    <Switch name="nsfw" type="checkbox" checked={this.state.nsfw} onChange={(e) => this.handleCheckboxChange(e)} />
                </div>

                <div id="post-forSale" className="field">
                    <StyledLabel htmlFor="forSale">For Sale:</StyledLabel>
                    <Switch name="forSale" type="checkbox" checked={this.state.forSale} onChange={(e) => this.handleCheckboxChange(e)} />
                </div>

                <div id="post-forSalePrice" className="field" hidden={!!!this.state.forSale}>
                    <StyledLabel htmlFor="forSalePrice">Selling Price (APB $):</StyledLabel>
                    <Input name="forSalePrice" type="number" value={this.state.forSalePrice} min="0" max="9999999999" onChange={(e) => this.handleChange(e)} />
                    <div id="post-forSalePrice-error" className="error-message"></div>
                </div>

                {/* <div className="field post-captcha">
                    <ReCAPTCHA 
                        sitekey="6Le6ofIUAAAAAC7IzdsrYxC7iPBna2pdxrBrRbLA"
                        onChange={this.handleCaptcha}
                    />
                </div> */}

                <div className="field new-post-button">
                    <Button disabled={/*this.state.captcha == null ||*/ this.state.images.length <= 0 || this.state.awaitingResponse} onClick={this.onCreate}>Create</Button>
                </div>
            </div>
        )
    }

    renderForm = () => {
        return (
            <div className="new-post-form">
                <StyledStep htmlFor="images">Step 2: Upload Images</StyledStep>

                <div id="post-images" className={`field ${postCategories.getName(this.state.category)}`}>
                    <ImageUploader2 width={this.getCropDimension('width')} height={this.getCropDimension('height')} maxImageAmount={this.getCropMaxImages()} category={this.state.category} handleUpdate={this.handleImageChange} />
                    <br />
                    <div id="post-images-error" className="error-message"></div>
                </div>

                { false && this.state.category === postCategories.CLOTHING && (
                    <div id="post-items" className="field">
                        <StyledLabel htmlFor="tags">Items Shown:</StyledLabel>
                        <GameItemSearchInput />
                    </div>
                )}

                { this.state.stepThree ? (
                    null
                ) : (
                    <div className="field new-post-button">
                        <Button disabled={!!this.state.images && this.state.images.length <= 0} onClick={e => this.setState({ stepThree: true })}>Next</Button>
                    </div>
                )}

                { this.renderStepThree() }
            </div>
        )
    }

    renderFormStep() {
        const { categoryChosen } = this.state;

        return (
            <div className="new-post-form">
                { 
                    categoryChosen ? (
                        this.renderForm()
                    ) : (
                        <div id="post-category" className="field">
                            <StyledStep htmlFor="images">Step 1: Choose a Category</StyledStep>
                            {this.renderCategories()}
                        </div>
                    )
                }
            </div>
        )
    }

    renderFormVerified() {
        return (
            <Section>
                <SectionHead title='New Creation' subtitle='Show off your work to the community!' />

                {this.props.userIsVerified ? (
                    this.renderFormStep()
                ) : (
                        <ErrorContainer>
                            <ErrorEmoji>(˚Δ˚)b</ErrorEmoji>
                            <ErrorText>Please verify your email to post!</ErrorText>
                            <SubErrorText>We are looking forward to it.</SubErrorText>
                        </ErrorContainer>
                    )}
            </Section>
        )
    }

    render() {
        return this.state.redirect
            ? <Redirect to={this.state.redirect} />
            : this.renderFormVerified()
    }
}

export default withAlert()(CreatePost);